import React from 'react'
import { useTranslation } from 'react-i18next'

import { prettifyDate } from '../../../../helpers/dates'
import { Fund } from '../../../../model/FundNew'
import { ReactComponent as ShareIcon } from '../../../../icons/share-icon.svg'
import cn from 'classnames'

import { ScaleDropdown } from '@telekom/scale-components-react'
import { GroupedButtons } from '../../../../components/GroupedButtons/GroupedButtons'
import { BOFundDetails } from '../../../../backend/respType'
import { INTERNAL_STATUS_OPTIONS, ONEPAGER_OPTIONS } from '../../../../env/fundEditModal/fundModalConfig'

interface Props {
  fund: Fund
  openAlertDeleteDialog: Function
  openAlertArchiveDialog: Function
  openEditDialog: Function
  onCopyFund: Function
  setActiveRow: Function
  editFund: (id: BOFundDetails['id'], body: any) => void
  active: boolean
}

export const RelevantRow = ({
  fund,
  openAlertDeleteDialog,
  openAlertArchiveDialog,
  editFund,
  openEditDialog,
  onCopyFund,
  setActiveRow,
  active,
}: Props) => {
  const { t } = useTranslation(["table", "BOFundTranslationKeys"])

  const publishedDate = prettifyDate(fund.info.creationDate)
  const relevantInternalStatuses = ['neu', 'in Bearbeitung', 'Rückfrage', 'zur Veröffentlichung', 'extern-fertig', 'nicht relevant']
  
  const onOnePagerChange = (event: any) => {
    const onePager = event.target.value
    editFund(fund.id as string, { info: { onePager: onePager } })
  }

  const onInternStatusChange = (event: any) => {
    const internalStatus = event.target.value
    editFund(fund.id as string, { internalStatus: internalStatus })
  }

  return (
    <tr
      key={`${fund.id}_relevant-row`}
      onClick={() => setActiveRow(fund.id)}
      className={cn('row', {
        active: active,
      })}
    >
      <td className="column column--small column--with-padding">
        <div className="publication-item publication-item--title bold">
          {fund.fp_number}
        </div>
      </td>
      <td className="column column--medium column--with-padding">
        <div className="publication-item publication-item--title bold">
          {fund.name}
        </div>
        <div 
          className="publication-item publication-item--summary"
          dangerouslySetInnerHTML={{ __html: fund.info.summary as string }}
        />
      </td>
      <td className="column column--medium column--with-padding">
        {fund.info.geoArea && fund.info.geoArea.map(area =>
            <div key={area.id} className="publication-item publication-item--title bold">
              {area.name}
            </div>
        )}

      </td>
      <td className="column column--medium column--with-padding">
        {publishedDate ? publishedDate : '-'}
      </td>
      <td className="column column--medium column--with-padding">
        {fund.info.primaryStream}
      </td>
      <td className="column column--medium column--with-padding">
        <div className="filter-wrapper">
          <ScaleDropdown
            key="one-pager-dropdown_relevant"
            value={fund.info.onePager || ONEPAGER_OPTIONS[0].value}
            size="small"
            label={String(t('BO:BO-FP-RFP1P'))}
            onScaleChange={(e) => onOnePagerChange(e)}
          >
            {ONEPAGER_OPTIONS.map((item, i) =>{ 
              return (
              <option
                value={item.value}
                key={i + '_one-pager'}
                disabled={item.value ===  ONEPAGER_OPTIONS[0].value}
              >
                {item.name}
              </option>
            )})}
          </ScaleDropdown>
        </div>
      </td>
      <td className="column column--medium column--with-padding">
        <div className="filter-wrapper">
          <ScaleDropdown
            key="status-dropdown_relevant"
            value={fund.internalStatus}
            size="small"
            label={'Status'}
            onScaleChange={(e) => {
              onInternStatusChange(e)
            }}
          >
            {INTERNAL_STATUS_OPTIONS.map((item, i) => (
              <option value={item.value}
                      key={item.value}
                      disabled={!relevantInternalStatuses.includes(item.value)} >
                {item.name}
              </option>
            ))}
          </ScaleDropdown>
        </div>
      </td>
      <td className="column column--large column--no-wrap column--right column--with-padding">
        <GroupedButtons onDelete={()=> openAlertDeleteDialog()}
                        onArchive={()=> openAlertArchiveDialog()}
                        onEdit={()=> openEditDialog()}
                        onCopy={()=> onCopyFund()}
        />
        <div className="action-link-container">
          {fund.info.url && (
            <a href={fund.info.url} target={'_blank'} rel="noreferrer">
              <span className="action-link">
                <ShareIcon />
              </span>
              <span className="action-link__text">{fund.info.url}</span>
            </a>
          )}
        </div>
      </td>
    </tr>
  )
}
