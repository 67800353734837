import React from "react";
import {ScaleButton} from "@telekom/scale-components-react";
import { useTranslation } from 'react-i18next'


interface Props {
    content: string,
    type: string,
    onAction: ()=> void,
    onClose: ()=> void
}

export const AlertWithActions = ({content, onClose, onAction, type}: Props) => {
    const { t } = useTranslation("details")

    const customContent = (text: string) => {
        return <>
            <div className="modal__centered">
                <div className="modal__text">{text}</div>
            </div>
            <div className="modal__actions">
                {onClose && type !== 'notification'
                && <ScaleButton slot="action" variant="secondary" onClick={()=> onClose()}>
                      {t("modalActionInterrupt")}
                </ScaleButton>}
                {onAction && <ScaleButton slot="action" type='submit' onClick={ ()=> onAction()}>
                    {type === 'change' && t('modalActionChange')}
                    {type === 'delete' && t('BO:BO-FP-NFPB1')}
                    {type === 'archive' && t('modalActionArchive')}
                    {type === 'notification' && t('modalActionAgree')}
                </ScaleButton>}
            </div>
        </>
    }

    return customContent(content)
}