import React, { useState } from 'react'

import { ScaleTable } from '@telekom/scale-components-react'

import { PublishedHeader } from './published-header'
import { PublishedRow } from './published-row'
import { Fund } from '../../../../model/FundNew'

import { FundTableProps } from '../types'
// import { useTranslation } from 'react-i18next'


export const PublishedTable = (
    {
        funds,
        changeSortParams,
        openArchiveModal,
        openEditModal,
        copyFund,
        editFund,
    }: FundTableProps) => {
    // const { t } = useTranslation('table')

    const [activeId, setActiveId] = useState<string>()

    const onSortingAction = (params: string) => {
        changeSortParams(params)
    }


    const renderList = (funds: Fund[]) => {

        return (<>
                <div className='funds-spacer-title'></div>
                {funds && <ScaleTable size={'medium'} className='publications-table'>
                  <table>
                    <thead>
                    <PublishedHeader onSortingAction={onSortingAction} />
                    </thead>
                    <tbody>
                    {funds.map(fund => <PublishedRow
                        key={fund.id}
                        fund={fund}
                        setActiveRow={setActiveId}
                        active={activeId === fund.id}
                        openAlertArchiveDialog={() => openArchiveModal(fund.id)}
                        openEditDialog={() => openEditModal(fund.id)}
                        editFund={(id, body) => editFund(id, body)}
                        onCopyFund={() => copyFund(fund.id)}
                    />)}
                    </tbody>
                  </table>
                </ScaleTable>}
            </>
        )
    }

    return renderList(funds)
}