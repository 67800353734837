import Office from "../../../model/Office";

import React from 'react'

import { Translation } from 'react-i18next';
import cn from 'classnames'
import { GroupedButtons } from '../../../components/GroupedButtons/GroupedButtons'

interface Props{
    office: Office
    openEditDialog: Function
    openAlertDeleteDialog: Function
    setActiveRow: Function
    active: boolean
    onClick: ()=> void
}

class OfficeRow extends React.Component<Props> {

    render() {
        const { office, openEditDialog, openAlertDeleteDialog, setActiveRow, active, onClick } = this.props
        const { street, zipCode, city, country, url, mail, phone, name, id } = office
        const link = url || ''
        const hasZipOrCity = zipCode || city

        let addressRow = () => {
            return <div className="address">
                {street && 
                    <span>{street}</span>
                }
                {street && (hasZipOrCity || country) &&
                    <span className='divider'>|</span>
                }
                {zipCode &&
                    <div>
                        <span>{zipCode}</span>
                    </div>
                }
                {city &&
                    <div>
                        <span className={zipCode ? 'city' : ''}>{city}</span>
                    </div>
                }
                {hasZipOrCity && country &&
                    <span className='divider'>|</span>
                }
                {country &&
                    <span>{country}</span>
                }
                {(!street && !hasZipOrCity && !country) && 
                    <span className='empty'></span>
                }
            </div>
        }

        let contactRow = () => {
            return <div className="contact">
                {phone && 
                    <span>{phone}</span>
                }
                {(phone && mail) &&
                    <span className='divider'>|</span>
                }
                {mail &&
                    <div>
                        <a className='office-item__mail' href={`mailto:${mail}`}>{mail}</a> 
                    </div>
                }
                {(mail && url) &&
                    <span className='divider'>|</span>
                }
                {url &&
                    <a className='office-item__url' href={link}>{url}</a> 
                }
                {(!phone && !mail && !url) && 
                    <span className='empty'></span>
                }
            </div>
        }

        return (
            <Translation>
                { (t) => <tr
                            onClick={() => {
                                setActiveRow(id)
                                onClick()
                            }}
                            className={cn('row', {
                                'active': active,
                            })}>
                            <td className='column column--large'>
                                <div className="office-item__title">{name}</div>
                                    {addressRow()}
                                    {contactRow()}
                            </td>
                            <td className="column column--large column--no-wrap column--right">
                                <GroupedButtons onEdit={() => openEditDialog(office)}
                                                onDelete={()=> openAlertDeleteDialog(id)}
                                />
                            </td>
                        </tr>
                }
            </Translation>
          
        )
    }
}

export default OfficeRow