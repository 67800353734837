import styled from 'styled-components/macro'

import Span from '../../Span'


const LinkIcon = styled('span')`
  position: relative;
  top: 3px;
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }

  & ~ ${Span} {
    width: calc(100% - 16px);
    word-wrap: break-word;
  }
`

export default LinkIcon