import { KeyValuePair } from '../../../model/KeyValuePair'

import SafeHtml from '../../../components/safe-html/SafeHtml'
import React from 'react'
import './key-value-item.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Props = {
    keyValuePair: KeyValuePair
}

export const KeyValueItem = ({ keyValuePair }: Props) => {
    const { key: title, value: content } = keyValuePair
    const { t } = useTranslation(['BOFundTranslationKeys'])

    const TagElements = () => {
        let param = ''
        switch (title) {
            case t("FP:RP-FP-FSeg"):
                param = 'segment'
                break
            case t("BOFundTranslationKeys:relatedPortfolioProducts"):
                param = 'related_portfolio_products'
                break
            default:
                break
        }
        return (
            <div style={{display:'flex', gap:'15px', marginTop:'16px', flexWrap:'wrap'}}>
                {content.map((tag:{name:string, _id:string}) =>
                     <Link className="segment-link" to={`/?${param}=${tag._id}`}><span className="pi pi-link" /> {tag.name} </Link>)}
            </div>
        )
    }
    return (
        <div className='key-value-item'>
            <div className='headline'>
                {title}
            </div>
            {title === t("FP:RP-FP-FSeg") || title === t("BOFundTranslationKeys:relatedPortfolioProducts") ?
                <TagElements />
                :
                <p className='radar-paragraph'>
                    <SafeHtml __html={content} refineOutsideLinks={true} />
                </p>
            }
        </div>
    )
}