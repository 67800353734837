import React, { createRef } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import '../scss/app.scss'
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

import DetailPage from '../pages/detail/detailPage'
import RadarPage from '../pages/radar/RadarPage'
import PageContainer from '../pages/pageContainer'

import { TooltipConfig } from '../components/Radar/RadarTooltip'
import { RadarForeground } from '../components/Radar/RadarForeground'
import { RadarRenderer, ExtendedEntry } from '../components/Radar/RadarRenderer'
import { AllocationPage } from '../pages/allocation/allocationPage'

import Stream from '../model/Stream'
import { backofficeLinks } from './RouterLinks'

interface State {
  tooltipConfig?: TooltipConfig
  currentFunds?: ExtendedEntry[]
  stream?: Stream['name']
}



export default class App extends React.Component<any, State> {
  pageContainerRef: React.RefObject<typeof PageContainer>
  constructor(props: any) {
    super(props)

    this.pageContainerRef = createRef<typeof PageContainer>()
    this.state = {}
  }

  componentDidMount() {
    this.setState({ currentFunds: RadarRenderer.extendedEntries})
  }

  onUpdateStreams(stream: string) {
    this.setState({ stream })
  }


  updateTooltip(config: TooltipConfig) {
    this.setState({ currentFunds: RadarRenderer.extendedEntries, tooltipConfig: config})

    // Add total funds in stream number for tooltip
    const tooltipConfig = {...this.state.tooltipConfig} as TooltipConfig
    tooltipConfig.info.position.total = this.state.currentFunds!.length
    this.setState({tooltipConfig})

    // Set correct index within stream funds
    const currentFunds = [...this.state.currentFunds!]
    currentFunds.forEach((fund, index) => {
      return currentFunds[index].tooltipInfo.position.index = this.state.currentFunds!.indexOf(fund)
    })
    this.setState({currentFunds})
  }

  onChangeTooltip(newIndex: number) {
    // Change tooltip info to new fund data
    const newTooltip = this.state.currentFunds![newIndex]
    const tooltipConfig = {...this.state.tooltipConfig} as TooltipConfig

    // Get coordinates of new tooltip
    // TO DO: Mark active Diamond
    tooltipConfig.getPivotCoordinates = () => {
      return  RadarForeground.getPivotCoordinates(newTooltip)
    }

    tooltipConfig.info = newTooltip.tooltipInfo as TooltipConfig['info']
    tooltipConfig.info.position.total = this.state.currentFunds!.length

    this.setState({tooltipConfig})
  }

  render() {
    return (
      <Router>
        <PageContainer tooltipConfig={this.state.tooltipConfig}
                       onChangeTooltip={this.onChangeTooltip.bind(this)}>
          <Switch>
            <Route
              path="/detail/:id"
              component={DetailPage} />
            <Route
                path="/more/:id"
                render={(props)=>
                    (<DetailPage  {...props}/>) }
            />
            <Route
              path="/allocations"
              component={AllocationPage} />
            {backofficeLinks.map(route => {
              if(route.disabled) return null
              return <Route
                  key={`${route}`}
                  path={route.href}
                  component={route.component} />
              }
            )}
            <Route
              path="/"
              render={(props) => (<RadarPage {...props} onUpdateStreams={this.onUpdateStreams.bind(this)} onUpdateTooltip={(config: TooltipConfig) => { this.updateTooltip(config) }} />)}
            />
          </Switch>
        </PageContainer>
      </Router>
    )
  }
}