import styled from 'styled-components/macro'

type Props = {
  theme: any
}

const TooltipClose = styled('div')<Props>`
  position: absolute;
  cursor: pointer;
  top: ${props => props.theme.spacings.large.gutter};
  right: ${props => props.theme.spacings.large.gutter};
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.gray.mineShaft};
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    transform: rotate(180deg);
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

export default TooltipClose