import React from 'react'
import { useTranslation } from 'react-i18next'
import { prettifyDate } from '../../../../helpers/dates'
import { ReactComponent as ShareIcon } from '../../../../icons/share-icon.svg'
import { ScaleDropdown } from '@telekom/scale-components-react'
import { Fund } from '../../../../model/FundNew'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { GroupedButtons } from '../../../../components/GroupedButtons/GroupedButtons'
import { PhaseTranslation } from '../../../../utils/phaseTranslation'
import { BOFundDetails } from '../../../../backend/respType'
import { chooseOption, INTERNAL_STATUS_OPTIONS, STATUS_OPTIONS } from '../../../../env/fundEditModal/fundModalConfig'

interface Props {
    fund: Fund
    openAlertArchiveDialog: Function
    openEditDialog: Function
    setActiveRow: Function
    onCopyFund: Function
    editFund: (id: BOFundDetails['id'], body: any) => void
    active: boolean
}

export const PublishedRow = ({
                                 fund,
                                 openAlertArchiveDialog,
                                 openEditDialog,
                                 setActiveRow,
                                 editFund,
                                 onCopyFund,
                                 active,
                             }: Props) => {
    const { t } = useTranslation('table')
    const publishedFundsStatusOptions = ['veröffentlicht', 'zur Bearbeitung', 'in Bearbeitung', 'nicht relevant']

    const publishedDate = (date: string | null | Date) => date !== null ? prettifyDate(date) : '-'

    const onHighlightChange = (event: any) => {
        let status = event.target.value
        editFund(fund.id,{ status: status })
    }

    const onInternStatusChange = (event: any) => {
        const internalStatus = event.target.value
        editFund(fund.id,{ internalStatus: internalStatus })
    }

    return (
        <tr key={`${fund.id}_published-row`}
            onClick={() => setActiveRow(fund.id)}
            className={cn('row', {
                'active': active,
            })}
        >
            <td className='column  column--with-padding'>
                <div className='publication-item publication-item--title bold'>
                    {fund.fp_number}
                </div>
            </td>
            <td className='column column--medium column--with-padding'>
                <div className='publication-item publication-item--title bold'>
                    <Link className='link link--no-underline' to={`/more/${fund.id}`}>{fund.name}</Link>
                </div>
                <div className='publication-item publication-item--summary'
                     dangerouslySetInnerHTML={{ __html: fund.info.summary as string }} />
            </td>
            <td className='column column--with-padding'>
                <div className='publication-item publication-item--title bold'>
                    {fund.info.primaryStream}
                </div>
            </td>
            <td className='column column--medium column--with-padding'>
                <div className='publication-item publication-item--title bold'>
                    {fund.info.maturity && PhaseTranslation(fund.info.maturity)}
                </div>
            </td>
            <td className='column column--medium column--with-padding'>
                {fund.info.geoArea && fund.info.geoArea.map(area =>
                    <div key={area.id} className='publication-item publication-item--title bold'>
                        {area.name}
                    </div>,
                )}
            </td>
            <td className='column column--medium column--with-padding'>
                {publishedDate(fund.info.startApplicationPeriod)}
            </td>
            <td className='column column--medium column--with-padding'>
                {publishedDate(fund.info.deadline)}
            </td>
            <td className='column column--medium column--with-padding'>
                <div className='filter-wrapper'>
                    <ScaleDropdown
                        key='highlight-dropdown_published'
                        value={fund.status}
                        size='small'
                        label={String(t('BO:BO-FP-RFPHighDd'))}
                        onScaleChange={(e) => onHighlightChange(e)}
                    >
                        {STATUS_OPTIONS.map((item, i) => (
                            <option
                                value={item.value}
                                key={item.value}
                                disabled={item.value === chooseOption}
                            >
                                {item.name}
                            </option>
                        ))}
                    </ScaleDropdown>
                </div>
            </td>
            <td className='column column--medium column--with-padding'>
                <div className='filter-wrapper'>
                    <ScaleDropdown
                        value={fund.internalStatus}
                        size="small"
                        label={'Status'}
                        onScaleChange={(e) => {
                            onInternStatusChange(e)
                        }}
                    >
                        {INTERNAL_STATUS_OPTIONS.map((item,i) =>
                            <option
                                value={item.value}
                                key={i + '_status'}
                                disabled={!publishedFundsStatusOptions.includes(item.value)}
                            >
                                {item.name}
                            </option>
                        )}
                    </ScaleDropdown>
                </div>
            </td>
            <td className='column column--large column--no-wrap column--right column--with-padding'>
                <GroupedButtons onArchive={() => openAlertArchiveDialog()}
                                onEdit={() => openEditDialog()}
                                onCopy={() => onCopyFund()}
                                lastModified={fund.info.last_modified}
                />
                <div className='action-link-container'>
                    {fund.info.url && (
                        <a href={fund.info.url} target={'_blank'} rel='noreferrer'>
              <span className='action-link'>
                <ShareIcon />
              </span>
                            <span className='action-link__text'>{fund.info.url}</span>
                        </a>
                    )}
                </div>
            </td>
        </tr>
    )
}