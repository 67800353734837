import { FundsPage } from '../pages/backoffice/funds/fundsPage'
import { PressReleasePage } from '../pages/backoffice/press-releases/pressReleasePage'
import { StreamsPage } from '../pages/backoffice/streams/streamsPage'
import { RSSFeedPage } from '../pages/backoffice/rss-feeds/rssFeedsPage'
import { OfficesPage } from '../pages/backoffice/offices/officesPage'
import { ReportsPage } from '../pages/backoffice/reports/reports'
import { t } from 'i18next'
import { SettingsPage } from '../pages/settings/settingsPage'

type RouteLinks = {
  href: string
  id: string
  linkName: string
  component: () => JSX.Element
  disabled: boolean
}

export const backofficeLinks:RouteLinks[] = [
  {
    href: '/backoffice/funds',
    id: 'backoffice-funds',
    linkName: t("menu:linkFundingPrograms"),
    component: FundsPage,
    disabled: false
  },
  {
    href: '/backoffice/pressreleases',
    id: 'backoffice-press',
    linkName: t("menu:linkPressReleases"),
    component: PressReleasePage,
    disabled: false
  },
  {
    href: '/backoffice/streams',
    id: 'backoffice-streams',
    linkName: t("menu:linkStreamManager"),
    component: StreamsPage,
    disabled: false
  },
  // {
  //   href: '/backoffice/keyword',
  //   id: 'backoffice-keyword',
  //   linkName: t("menu:linkKeywordList"),
  //   component: RSSFeedPage,
  //   disabled: true
  // },
  {
    href: '/backoffice/rss-feed',
    id: 'backoffice-rss',
    linkName: t("menu:linkRSSFeeds"),
    component: RSSFeedPage,
    disabled: false
  },
  {
    href: '/backoffice/offices',
    id: 'office-manager',
    linkName: t("menu:linkResponsePoints"),
    component: OfficesPage,
    disabled: false
  },
  {
    href: '/backoffice/reports',
    id: 'backoffice-reports',
    linkName: t("menu:linkReports"),
    component: ReportsPage,
    disabled: false
  },
  {
    href: '/backoffice/settings',
    id: 'backoffice-settings',
    linkName: t('menu:BO-Set-titel'),
    component: SettingsPage,
    disabled: false
  },
]