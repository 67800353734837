import { createGlobalStyle } from 'styled-components/macro'
import { bu } from './constants/BusinessUnit'
import { MainTheme } from './MainTheme'

const GlobalStyle = createGlobalStyle`
  :root {
    --trend-color: ${MainTheme.trend.color};

  }

  * {
    box-sizing: border-box;
    --doveGray: #6b6b6b;
    --white: #ffffff;
    --mineShaft: #262626;
    --gallery: #ededed;
    --bu: 16 * 1rem;
    --box-shadow-link-focus: none;
    --box-shadow-focus: none;
    --scl-color-text-link: ${MainTheme.colors.typography.allports};
    --scl-color-text-link-visited: ${MainTheme.colors.typography.allports};
    --scl-color-text-link-hover: ${MainTheme.colors.typography.allports};
  }

  body {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .sector-navigation {
    list-style-type: none;
  }

  /* HINT: use specific variables & theme specifications */
  /* --------------------------------------------------- */
  /* TODO: check if still neccessary */
  .dark {
    --background: var(--doveGray);
    color: var(--white);
    font-size: ${bu(18)};
    font-weight: 400;
    --spacing-body: 1.5rem;
  }

  /* TODO: check if still neccessary */
  .card-title {
    margin-bottom: 12px;
    display: block;
  }

  /* TODO: check if still neccessary */
  .card-value{
    font-size: ${bu(20)};
    font-weight: 800;
  }

  /* TODO: check if still neccessary */
  .light {
    color: var(--mineShaft);
    --background: var(--gallery);
    font-size: ${bu(16)};
    font-weight: 400;
    --spacing-body: 1.5rem;
    --box-shadow: none;
  }

  .light .card-title {
    font-size: ${bu(18)};
    font-weight: 800;
  }

  .card-is-dark {
    color: ${MainTheme.colors.primary.white};
    --background: ${MainTheme.colors.gray.doveGray};

    /* NOTE: This is a refactoring for TELFA-237 */
    .col-flex-element & {
      display: block;
      height: 100%;
      background: ${MainTheme.colors.gray.doveGray};
      --background: transparent;
      border-radius: var(--scl-radius-8);
      box-shadow: var(--scl-shadow-level-1);
      --box-shadow: none;
    }

    &.empty {

      @media (max-width: 1039px) {
        & .scl-font-variant-body-large {
          font-size: 14px;
          font-weight: 600;
          color: #3d3d3d;
        }
      }

      @media (min-width: 1040px) {
        & .scl-font-variant-body-large {
          font-size: 16px;
          font-weight: 600;
          color: #3d3d3d;
        }
      }

      &-fundingType {
        background-image: url(./../public-icons/auction_solid.svg);
        background-position: 95% 83%;
        background-repeat: no-repeat;
        background-size: 50px 50px;
      }

      &-geoArea {
        background-image: url(./../public-icons/regional_solid.svg);
        background-position: 97% 83%;
        background-repeat: no-repeat;
        background-size: 50px 50px;
      }

      &-deadline {
        background-image: url(./../public-icons/countdown_solid.svg);
        background-position: 97% 83%;
        background-repeat: no-repeat;
        background-size: 50px 50px;
      }

      &-fundingAmount {
        background-image: url(./../public-icons/price-tag_outline.svg);
        background-position: 100% 80%;
        background-repeat: no-repeat;
        background-size: 48px 48px;
      }
    }
  }
`

export default GlobalStyle