import styled from 'styled-components/macro'

export type Props = {
  href: string
  target?: string
}

const A = styled('a')<Props>`
  color: ${props => props.theme.colors.typography.allports};
  font-size: 16px;
  line-height: ${props => props.theme.typography.default.lineHeight};
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`

export default A