import React from "react"
import DetailHyperlink from "../../model/DetailHyperlink"
import FundMapped from "../../model/FundMapped"
import { t } from 'i18next'
import Utils from "../../utils/Utils"
import {
    ClipFunction,
    ExtendedEntry,
    MATURITY0,
    MATURITY1,
    MATURITY2,
    MATURITY3,
    MATURITY4,
    SectorMap,
    StyledRing
} from "./RadarRenderer"
import {Geometry, Point, Ring, Sector} from "./GeometryModule"
import {KeyValuePair, TooltipInfo} from "./RadarTooltip"
import RandomNumberGenerator from "./RandomNumberGenerator"
import SVGHelper from "./SVGHelper"
import {RADAR_CONFIG_QUARTER} from "./RadarConfig"
import ObjectUtil from "../../utils/ObjectUtil"
import { isGermanEnv } from '../../env/envBased'

interface IFundAccessor  {
    name: string
    getter: (fund: FundMapped) => string | undefined,
  }

export class FundsAccessor {
    
  private static points: Point[] = [];

    private constructor() {
    }

    static transformRawEntries(funds: FundMapped[], sectorForName: SectorMap, ringForName: Map<string, StyledRing>, randomNumberGenerator: RandomNumberGenerator, view?: string, env: string = "dev"): ExtendedEntry[] {
        const extendedEntries: ExtendedEntry[] = []

        funds.forEach( (item, i) => {
            try{
                const extendedEntry = FundsAccessor.transformFundOrThrow(item, i, funds.length, sectorForName, ringForName, randomNumberGenerator, view, env);
                extendedEntries.push(extendedEntry)
            } catch (error){
               // console.debug(`Skipped fund with index ${i}`, error)
            }
        })
        return extendedEntries
      }

    private static getFieldAccessorsForMaturityOrThrow(maturity: string, env: string = "dev"): IFundAccessor[] {
      const FUND_ACCESSORS: { [key: string]: IFundAccessor } = {
        summary: { name: t("F:RP-D-FPTarget"), getter: (it) => it.summary },
        targetGroup: { name: t("F:RP-D-FPTGroup"), getter: (it) => it.targetGroup },
        fundingObjectType: { name: t("F:RP-D-FPFObject"), getter: (it) => it.fundingObjectType },
        fundingType: { name: t("F:RP-D-FPFType"), getter: (it) => it.fundingType },
        fundingArea: { name: t("F:RP-F-FPArea"), getter: (it) => (it.geoArea && it.geoArea.map(area => area.name).join(', ')) || '' },
        fundingAmount: { name: t("F:RP-D-FPmaxF"), getter: (it) => it.fundingAmount?.toString() },
        deadline: { name: t("F:RP-D-FPDeadline"), getter: (it) => it.deadline && ObjectUtil.formatDateObj(it.deadline)},
        office: { name: t("details:officeRadar"), getter: (it) => it.office?.name },
        news: { name: t("BOFundTranslationKeys:news"), getter: (it) => it.news },
        related_portfolio_products: { name: t("BOFundTranslationKeys:relatedPortfolioProducts"), getter: (it) => (it.related_portfolio_products && it.related_portfolio_products.map(area => area.name).join(', ')) || '' },
      }

    const germanEnvFields = isGermanEnv ? [ FUND_ACCESSORS.related_portfolio_products ] : []

      const FIELD_ACCESSORS_FOR_MATURITY = {
        maturity0: [
          FUND_ACCESSORS.news,
            ...germanEnvFields,
          FUND_ACCESSORS.summary,
          FUND_ACCESSORS.fundingAmount,
        ],
        maturity1: [
          FUND_ACCESSORS.news,
            ...germanEnvFields,
          FUND_ACCESSORS.summary,
          FUND_ACCESSORS.fundingAmount,
          FUND_ACCESSORS.office,
        ],
        maturity2: [
          FUND_ACCESSORS.news,
            ...germanEnvFields,
          FUND_ACCESSORS.summary,
          FUND_ACCESSORS.targetGroup,
          FUND_ACCESSORS.fundingObjectType,
          env === "fund2cash" ? FUND_ACCESSORS.fundingType : FUND_ACCESSORS.fundingArea,
          FUND_ACCESSORS.fundingAmount,
          FUND_ACCESSORS.deadline,
        ],
        maturity3: [
          FUND_ACCESSORS.news,
            ...germanEnvFields,
          FUND_ACCESSORS.summary,
          FUND_ACCESSORS.targetGroup,
          FUND_ACCESSORS.fundingObjectType,
          env === "fund2cash" ? FUND_ACCESSORS.fundingType : FUND_ACCESSORS.fundingArea,
          FUND_ACCESSORS.fundingAmount,
          FUND_ACCESSORS.deadline,
        ],
        maturity4: [
          FUND_ACCESSORS.news,
            ...germanEnvFields,
          FUND_ACCESSORS.summary,
          FUND_ACCESSORS.targetGroup,
          FUND_ACCESSORS.fundingObjectType,
          env === "fund2cash" ? FUND_ACCESSORS.fundingType : FUND_ACCESSORS.fundingArea,
          FUND_ACCESSORS.fundingAmount,
          FUND_ACCESSORS.deadline,
        ],
      }

        switch (maturity) {
          case MATURITY0:
            return FIELD_ACCESSORS_FOR_MATURITY.maturity0
          case MATURITY1:
            return FIELD_ACCESSORS_FOR_MATURITY.maturity1
          case MATURITY2:
            return FIELD_ACCESSORS_FOR_MATURITY.maturity2
          case MATURITY3:
            return FIELD_ACCESSORS_FOR_MATURITY.maturity3
          case MATURITY4:
            return FIELD_ACCESSORS_FOR_MATURITY.maturity4
        }

        throw new Error(`Didn't find field accessor for maturity '${maturity}'!`)
      }

    // get information from tooltips
    private static generateTooltipInfoEntriesOrThrow(fund: FundMapped, maturity: string, env: string = "dev"): KeyValuePair[] {
        const fieldAccessors = FundsAccessor.getFieldAccessorsForMaturityOrThrow(maturity, env)

       return fieldAccessors.flatMap(({ name, getter }) => {
          const value = getter(fund)

          return value ? [{ key: name, value }] : []
        })
      }

    private static transformFundOrThrow(
        fund: FundMapped,
        index: number,
        fundsLength: number,
        sectorForName: SectorMap,
        ringForName: Map<string, StyledRing>,
        randomNumberGenerator: RandomNumberGenerator,
        view?: string,
        env?: string): ExtendedEntry {
        const { name, maturity, status, primaryStream, parent_stream_name, id, shortname } = fund
        const ring = ringForName.get(maturity)
        if (ring === undefined) {
          throw new Error(`No ring for name ${maturity}!`)
        }

        const entries = FundsAccessor.generateTooltipInfoEntriesOrThrow(fund, maturity, env)

        const detailLink = DetailHyperlink.createHyperlinkForDetailPage(fund.id)

        const sector = (primaryStream && sectorForName.get(primaryStream)) || sectorForName.get(parent_stream_name!)
        // Add index (place in overall fund list)
        const tooltipInfo: TooltipInfo = {
          title: name,
          detailLink,
          entries,
          stream: sector?.name as string,
          position: {
            index,
            total: fundsLength,
          }
        }

        if (sector === undefined) {
          throw new Error(`No sector for name ${primaryStream}!`)
        }

        let point = this.generateRandomPoint(ring, sector, Utils.getWeakHashNumber(id) , randomNumberGenerator, view);
        
        // if (view !== 'quarter') {
        //   point = this.normalizePoint(point, ring, sector);
        // }

        const clipFunction = this.generateClipFunction(ring, sector, point, view)
        const color = '#e20074' // '#e20074 #262626 #009de0 #218076'.split(' ')[rawEntry.quadrant]
        
        return {
          name,
          shortname,
          status,
          ring,
          sector,
          clipFunction,
          x: point.x,
          y: point.y,
          color,
          id,
          tooltipInfo,
        }
      }

  static generateClipFunction(ring: Ring, sector: Sector, point: Point, view?: string): ClipFunction {
    const { radiusMin, radiusMax } = ring
    const { thetaMin, thetaMax } = sector

    if (view === 'quarter') {
      return (pointItem: Point): string => {
        return SVGHelper.getTranslateStringFromPivot(pointItem)
      }
    } else {
      return (pointItem: Point): string => {
        const polar = Geometry.toPolar(pointItem)
        const boundedPolar = Geometry.boundToSegment(polar, radiusMin + 15, radiusMax - 15, thetaMin, thetaMax, 15)
        const cartesianPoint = Geometry.toCartesian(boundedPolar)
        return SVGHelper.getTranslateStringFromPivot(cartesianPoint)
      }
    }
  }

  private static findNewPlaceForPoint(intersectedPoints: Point[], nonintersectedPoints: Point[], ring: Ring, sector: Sector): Point {
    const minDistance = 16; // Minimum distance allowed
    const randomNumberGenerator = new RandomNumberGenerator(44)
    let addXandY = 0;

    while (true) {
      const { radiusMin, radiusMax } = ring
      const { thetaMin, thetaMax } = sector
        
      const newPoint = Geometry.toCartesian({
          t: randomNumberGenerator.randomBetween(thetaMin, thetaMax),
          r: randomNumberGenerator.randomBetween(radiusMin, radiusMax),
      });

      newPoint.x = newPoint.x + addXandY;
      newPoint.y = newPoint.y + addXandY;

      const isFarEnough = [...intersectedPoints, ...nonintersectedPoints].every((existedPoint) => {
        const distance = this.getDistance(existedPoint, newPoint);
        return distance >= minDistance;
      });
  
  
      if (isFarEnough) {
        return newPoint;
      } else {
        addXandY++;
      }
    }
  }
  
  private static getDistance(existedPoint: Point, newPoint: Point) {
    const difX = Math.abs(existedPoint.x - newPoint.x);
    const difY = Math.abs(existedPoint.y - newPoint.y);
    return Math.sqrt(difX * difX + difY * difY);
  }

  private static normalizePoint(point: Point, ring: Ring, sector: Sector) {
    let currentPoint = {...point};
    const intersectedPoints: Point[] = [];
    const nonIntersectedPoints: Point[] = [];

    FundsAccessor.points.forEach(existedPoint => {
      const distance = this.getDistance(existedPoint, currentPoint);
      if (distance < 16) {        
        intersectedPoints.push(existedPoint)
      } else {
        nonIntersectedPoints.push(existedPoint)
      }
    })

    if(intersectedPoints.length) {
      currentPoint = this.findNewPlaceForPoint(intersectedPoints, nonIntersectedPoints, ring, sector);
    }

    FundsAccessor.points.push(currentPoint);
    return currentPoint;

  }
  private static generateRandomPoint(ring: Ring, sector: Sector, id: number, randomNumberGenerator: RandomNumberGenerator, view?: string): Point {
    const { radiusMin, radiusMax } = ring
    const { thetaMin, thetaMax } = sector

    const offsetLeftRight = 20
    const offsetTopBottom = 30

    if (view === 'quarter') {
      const maxX = ((RADAR_CONFIG_QUARTER.radius / 2.24 + RADAR_CONFIG_QUARTER.leftMargin + RADAR_CONFIG_QUARTER.rightMargin) / 2) - offsetLeftRight
      const minX = maxX * -1

      const r = randomNumberGenerator.randomBetween(radiusMin + offsetTopBottom, radiusMax - offsetTopBottom)
      const x = randomNumberGenerator.randomBetween(minX, maxX)

      // Using Pythagoras' theorem to determine y using x and r.
      // Must be negative because of inverted y-axis
      const y = - Math.sqrt(Math.pow(r,2) - Math.pow(x,2))

      return {x, y,}
    } else {
      randomNumberGenerator.lastSeed = ((id * 32687) + 69539) % 986213;
      
      return Geometry.toCartesian({
        t: randomNumberGenerator.randomBetween(thetaMin, thetaMax),
        r: randomNumberGenerator.randomBetween(radiusMin, radiusMax),
      });
    }
  }
}