const LINK_NAME_DETAILPAGE = `Mehr Infos`
const ROUTE_DETAILPAGE = `/detail`
const ROUTE_MOREPAGE = `/more`


export interface Hyperlink {
  title: string
  href: string
}


export default class DetailHyperlink {
  static createHyperlinkForDetailPage(id: string): Hyperlink {
    return {
      href: `${ROUTE_DETAILPAGE}/${id}`,
      title: LINK_NAME_DETAILPAGE,
    }
  }
  static createHyperlinkFromAllocationsPage(id: string): Hyperlink {
    return {
      href: `${ROUTE_MOREPAGE}/${id}`,
      title: LINK_NAME_DETAILPAGE,
    }
  }
}