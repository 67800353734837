import {
  DEFAULT_VALUE,
  getGeoAreasList,
  getPortfolioList,
  getSegmentsList,
  getTagsList,
} from '../../components/F2CFilter/commonFilterOptions'
import { FilterOption } from '../../components/F2CFilter/F2CFilter'
import i18next from 'i18next'

export const getRadarPageFilters = async ():Promise<FilterOption[]> => {
  const geoData = await getGeoAreasList()
  const segments = await getSegmentsList()
  const tags = await getTagsList()
  const portfolios = await getPortfolioList()
  const radarFilters = [
    {
      name: i18next.t('F:RP-F-FPAmount'),
      urlParam: 'amountRange',
      filterLabel: 'Sum',
      values: [
        {label: '< 5 000 €', val: '0-5000'},
        {label: '5 000 € - 20 000 €', val: '5000-20000'},
        {label: '20 000 € - 50 000 €', val: '20000-50000'},
        {label: '50 000 € - 200 000 €', val: '50000-200000'},
        {label: '> 200 000 €', val: '200000-0'},
        DEFAULT_VALUE,
      ],
      id:0,
    },
    {
      name: i18next.t('F:RP-F-FPPeriod'),
      urlParam: 'deadlineRange',
      filterLabel: 'Age',
      values: [
        {label: `< 3 ${i18next.t('details:filterMonthOption')}`, val: '0-3'},
        {label: `3 - 6 ${i18next.t('details:filterMonthOption')}`, val: '3-6'},
        {label: `> 6 ${i18next.t('details:filterMonthOption')}`, val: '6-0'},
        DEFAULT_VALUE,
      ],
      id: 1,
    },
    {
      name: i18next.t('F:RP-F-FPArea'),
      urlParam: 'geoArea',
      filterLabel: 'Geo',
      values: [
        ...geoData,
        DEFAULT_VALUE,
      ],
      id: 2
    },
    {
      name: i18next.t('FP:RP-FP-FSeg'),
      urlParam: 'segment',
      filterLabel: 'Seg',
      values: [
        ...segments,
        DEFAULT_VALUE,
      ],
      id: 3
    },
    {
      name: i18next.t('FP:RP-FP-FTag'),
      urlParam: 'tags_added',
      filterLabel: 'Tag',
      values: [
        ...tags,
        DEFAULT_VALUE,
      ],
      id: 4
    },
    {
      name: i18next.t('SettingsPage:BO-Set-Item4-label-titel'),
      urlParam: 'related_portfolio_products',
      filterLabel: 'Portfolio',
      values: [
        ...portfolios,
        DEFAULT_VALUE,
      ],
      id: 5
    }
  ]

  return radarFilters
}