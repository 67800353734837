import styled from 'styled-components/macro'

const TableMobileView = styled('div')`
  div {
    display: flex;
    width: 100%;
    flex-direction: row nowrap;
    padding: 0;
    margin: 0;

    scale-card {
         width: 100%;
         margin-bottom: 12px;

         .row {
                display: flex;
                justify-content: space-between;
                span {
                padding: 0 6px 0 0;
                }
         }

        .row--last {
                 justify-content: center;
                 margin-top: 16px;
        }
         .col {
                margin-right:  12px;
         }
  }
`

export default TableMobileView