import React from "react";
import SecurityUtil from "../../utils/SecurityUtil";
import SafeHtmlOuter from "./__styled__/SafeHtmlOuter";
import { t } from 'i18next'
import { withTranslation } from 'react-i18next'

interface Props {
  __html: string
  refineOutsideLinks?: boolean
}


class SafeHtml extends React.Component<Props, any> {
  render() {
    let safeHtml = SecurityUtil.secureHtml(this.props.__html)
    const refineOutsideLinks = this.props.refineOutsideLinks
    if(refineOutsideLinks) {
      safeHtml = safeHtml.replaceAll("<a ", "<a class=\"link link--no-underline\" target=\"_blank\" rel=\"noreferrer\" alt=\"" + t("details:externalLink") + "\" title=\"" + t("details:externalLink") + "\"")
    }

    return (
      <SafeHtmlOuter>
        <div className={`scl-font-variant-body safe-html ${safeHtml === t("details:noDataAvailable") && 'text-disabled'}`}
             dangerouslySetInnerHTML={{__html: safeHtml}}/>
      </SafeHtmlOuter>
    )
  }
}

export default withTranslation("details")(SafeHtml)