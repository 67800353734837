import { t } from 'i18next'
import { object, string } from 'yup'

const req = t('BOFundTranslationKeys:validationNotFilled') || ''

export const pressReleaseFormSchema = object().shape({
    title: string().required(req),
    summary: string().required(req),
    published: string().required(req),
    // url: string().required(req).url(t('BOFundTranslationKeys:validationWrongUrl') || ''),
    stream: string().required(req)
})