import React from "react";
import { ReactComponent as RefreshIcon } from '../../icons/refresh-filters.svg'
import { useTranslation } from 'react-i18next'
import styles from './F2CFilter.module.scss'

interface Props {
  onClick: () => void
}

export const ResetButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation("f2cFilter")
  const handleKeypress = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    onClick();
  };

  return (
    <span className={styles.filterBarButtons__link} tabIndex={0} onKeyPress={handleKeypress} onClick={onClick}>
      <span className={styles.filterBarButtons_refresh_button}>
        <RefreshIcon className={styles.filterBarButtons__link__icon}/>
        <span className={styles.filterBarButtons__link__text}>{t("F:RP-F-BBack")}</span>
      </span>
    </span>
  );
};