import SortingIcons from '../../../../components/SortingIcons/SortingIcons'
import React, { useContext } from 'react'
import { HeaderProps } from '../types'
import { useTranslation } from 'react-i18next'
import { FundsContext } from '../fundsContext'

export const NewHeaders = ({onSortingAction}: HeaderProps) => {
  const { t } = useTranslation("table")
  const { sortParams } = useContext(FundsContext)

  return (
    <>
      <tr>
        <th className="column column--with-padding column--large column--header">
          <SortingIcons
            sortParamName="name"
            onSortingAction={onSortingAction}
            sortParams={sortParams}
          />
          <span className="title">{t("BO:BO-FP-NFPC1")}</span>
        </th>
        <th className="column column--with-padding column--medium column--header">
          <SortingIcons
            sortParamName="fundingObjectType"
            onSortingAction={onSortingAction}
            sortParams={sortParams}
          />
          <span className="title">{t("BO:BO-FP-NFPC2")}</span>
        </th>
        <th className="column column--with-padding column--medium column--header">
          <SortingIcons
            sortParamName="creationDate"
            onSortingAction={onSortingAction}
            sortParams={sortParams}
          />
          <span className="title">{t("BO:BO-FP-NFPC3")}</span>
        </th>
        <th className="column column--with-padding column--medium column--header">
          <span className="title">{t("BO:BO-FP-NFPC4")}</span>
        </th>
        <th className="column column--with-padding column--medium column--header">
          <SortingIcons
            sortParamName="internalStatus"
            onSortingAction={onSortingAction}
            sortParams={sortParams}
          />
          <span className="title">{t("BO:BO-FP-NFPC5")}</span>
        </th>
        <th className="column column--large" />
      </tr>
    </>
  )
}