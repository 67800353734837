import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Office from '../../../model/Office'
import {ScaleTable } from '@telekom/scale-components-react'
import { ReactComponent as PlusIcon } from '../../../icons/plus-icon.svg'
import OfficeRow from './office-row'
import SortingIcons from '../../../components/SortingIcons/SortingIcons'

import BackendFactory from '../../../backend/BackendFactory'

import './offices.scss'
import { DeleteOfficeModal } from './ModalDialog/deleteModal'
import { ModalActions } from '../../../components/F2CFormGenerator/types'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { contactPointFormConfig } from '../../../env/contactPointsModal/contactPointModalConfig'
import { contactPointModalSchema } from '../../../env/contactPointsModal/contactPointValidationSchema'
import { Toast } from 'primereact/toast'


export const OfficesTable = () => {
    const { t } = useTranslation(['BOFundTranslationKeys', 'details'])
    const toast = useRef<Toast>(null)
    const initContact: Office = {
        city: '',
        country: '',
        office: '',
        phone: '',
        street: '',
        url: null,
        zipCode: '',
        name: '', id: '', mail: '', affectedFunds: [],
    }
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)
    const [currentContact, setCurrentContact] = useState<Office>(initContact)
    const [allContacts, setAllContacts] = useState<Office[]>([])
    const [sortingParams, setSortingParams] = useState('+name')
    const [activeId, setActiveId] = useState<string>()


    useEffect(() => {
        getContactPointsList()
    }, [])

    const onSortingAction = (param: string) => {
        BackendFactory.produce().getBOOfficesOrderedBy(param)
            .then(res => {
                setSortingParams(param)
                setAllContacts(res)
            })
    }

    const deleteOffice = (id: string) => {
        BackendFactory.produce().deleteBOOffice(id).then(res => {
            if (res.detail === 'SUCCESS') {
                setModal(ModalActions.REMOVE)
                getContactPointsList()
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('BOFundTranslationKeys:officeDeleteTextSuccess'),
                    life: 5000,
                })
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('BOFundTranslationKeys:officeDeleteTextError'),
                    life: 5000,
                })
            }
        })
    }

    const closeModal = () => {
        setModal(ModalActions.REMOVE)
        setCurrentContact(initContact)
    }

    const getContactPointsList = () => {
        BackendFactory.produce().getBOOfficesOrderedBy(sortingParams)
            .then(resp => {
                if(resp) {
                    setAllContacts(resp)
                }else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: t('BOFundTranslationKeys:officeContactListError'),
                        life: 5000,
                    })
                }
            })
    }

    const createContactPoint = (data: Office) => {
        BackendFactory.produce().postBOOffice(data).then(res => {
            if(res.detail === 'SUCCESS') {
                getContactPointsList()
                closeModal()
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('BOFundTranslationKeys:officeCreateText'),
                    life: 5000,
                })
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('BOFundTranslationKeys:officeCreateTextError'),
                    life: 5000,
                })
            }
        })
    }

    const editContactPoint = (data: Office, id:string) => {
        BackendFactory.produce().patchBOOffice(data, id).then(res => {
            if(res.detail === 'SUCCESS') {
                getContactPointsList()
                closeModal()
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('BOFundTranslationKeys:officeEditText'),
                    life: 5000,
                })
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('BOFundTranslationKeys:officeEditTextError'),
                    life: 5000,
                })
            }
        })
    }

    const title = (!allContacts || allContacts.length !== 1) ? t('details:contactsTableTitle') : t('details:contactTableTitle')
    const officeNumber = allContacts.length || '0'

    return (
        <div className='offices-container'>
            <div className='backoffice-title'>
                <h3 className='offices-title'>{officeNumber} {title}</h3>
                <button className='action-button action-button--magenta'
                        onClick={()=> {
                            setCurrentContact(initContact)
                            setModal(ModalActions.CREATE)
                        }}><PlusIcon /> {t('details:contactPoints')}</button>
            </div>

            <ScaleTable size={'medium'}
                        className='offices-table'
            >
                <table>
                    <thead>
                    <tr>
                        <th className='column column--with-padding column--large column--header'>
                            <SortingIcons sortParamName='name' onSortingAction={onSortingAction} />
                            <span className='title'>{t('details:responsePoints')}</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {allContacts.map((office) =>
                        <OfficeRow
                            onClick={() => setCurrentContact(office)}
                            setActiveRow={setActiveId}
                            active={activeId === office.id}
                            key={office.id}
                            office={office}
                            openEditDialog={() => setModal(ModalActions.EDIT)}
                            openAlertDeleteDialog={() => setModal(ModalActions.ALERT_DELETE)}>
                        </OfficeRow>,
                    )}
                    </tbody>
                </table>
            </ScaleTable>

            <DeleteOfficeModal isOpen={modal === ModalActions.ALERT_DELETE}
                               currentContact={currentContact}
                               onClose={() => setModal(ModalActions.REMOVE)}
                               onAccept={() => deleteOffice(currentContact.id)}
            />
            <F2CFormGenerator isOpened={modal === ModalActions.EDIT || modal === ModalActions.CREATE}
                              modalType={modal}
                              modalTitle={modal === ModalActions.EDIT ? t('BOFundTranslationKeys:editOffice') : t('BOFundTranslationKeys:addOffice')}
                              onClose={() => closeModal()}
                              onValidationCreate={(formData) => {
                                  createContactPoint(formData as Office)
                              }}
                              onEdit={(formData) => {
                                  const updatedContactPoint = {
                                      ...currentContact,
                                      ...formData
                                  }
                                  editContactPoint(updatedContactPoint, currentContact.id)
                              }}
                              formConfig={contactPointFormConfig}
                              initialFormValues={currentContact}
                              validationSchema={contactPointModalSchema}
            />
            <Toast ref={toast} position='bottom-right' />
        </div>
    )
}