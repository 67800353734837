export interface FormValue {
    value: any,
    name: string
}

export const ALL_STREAMS = 'all'

export interface FormState {
    source: string,
    published: Date,
    title: string,
    segment: string,
    comment: string,
    summary?: string,
    fund_number?:string
    url?: string,
    stream?: string[],
    status?: {},
    publication_id?: string
}

export interface FormStateOptional {
    source?: string,
    published?: Date,
    title?: string,
    summary?: string,
    url?: string,
    stream?: string[],
    status?: {},
    publication_id?: string
}

export type PublicationModalActions = 'edit' | 'create' | 'close' | 'error'