import FundMapped from "../../model/FundMapped"


const MATURITY_IN_ALLOCATION = 'Zuteilung'


export interface FundingVolumeStats {
  total: number
  totalInAllocation: number
  count: number
}


export default class FundingVolumeHelper {
  private constructor() {}

  static analyzeFundingVolumeStats(funds: FundMapped[]): FundingVolumeStats {
    let total = 0.0
    let totalInAllocation = 0.0
    let count = 0

    for (const {fundingVolume, maturity} of funds) {
      count++
      if (fundingVolume) {
        total += fundingVolume
        totalInAllocation += (maturity === MATURITY_IN_ALLOCATION) ? fundingVolume : 0
      }
    }

    return {
      total,
      totalInAllocation,
      count,
    }
  }
}