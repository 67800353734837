import React from 'react'
// import { useTranslation } from 'react-i18next'
import {ReactComponent as ShareIcon} from '../../../../icons/share-icon.svg';
import {ScaleDropdown} from '@telekom/scale-components-react';
import {Fund} from '../../../../model/FundNew';
import { prettifyDate } from '../../../../helpers/dates'
import cn from 'classnames'
import { CommentTextArea } from '../../press-releases/components/commentTextArea'
import { GroupedButtons } from '../../../../components/GroupedButtons/GroupedButtons'
import { BEresponse, BOFundDetails } from '../../../../backend/respType'
import { INTERNAL_STATUS_OPTIONS } from '../../../../env/fundEditModal/fundModalConfig'

interface Props{
    fund: Fund
    openRelevanceDialog: (fund:Fund) => void
    openAlertDeleteDialog: Function
    setActiveRow: (id:string) => void
    changeInitialComment: (response: BEresponse) => void
    editFund: (id: BOFundDetails['id'], body: any) => void
    active: boolean
}

export const NewRow = ({
        fund,
        openRelevanceDialog,
        openAlertDeleteDialog,
        setActiveRow,
        editFund,
        active,
        changeInitialComment
    }: Props) => {
    // const { t } = useTranslation(["table", "BOFundTranslationKeys"])

    const newFundsStatusOptions = ['neu', 'in Bearbeitung', 'Rückfrage', 'nicht relevant']

    const date = prettifyDate(fund.info.lastCrawledDate)
    // const statuses = [t("statusNew"), t("statusInProgress"), t("statusConsultation")]

    const onInternStatusChange = (event: any) => {
        const internalStatus = event.target.value
        editFund(fund.id, { internalStatus: internalStatus })
    }

    return (
        <tr key={`${fund.id}_new-row`}
            id={fund.id} 
            onClick={() => setActiveRow(fund.id)}
            className={cn('row', {
            'active': active,
            })}
        >
            <td className='column column--large column--with-padding'>
                <div className="publication-item publication-item--title bold">
                    {fund.name}
                </div>
                <div className="publication-item publication-item--summary" dangerouslySetInnerHTML={{__html:  fund.info.summary as string}}/>
            </td>
          <td className='column column--large column--with-padding'>
            {fund.info.fundingObjectType}
          </td>
            <td className='column column--medium column--with-padding'>{date}</td>
            <td className='column column--large column--with-padding'>
              {fund.id &&
                <CommentTextArea activeRowId={fund.id}
                                 propComment={fund.internalComment || ''}
                                 commentFor="fund"
                                 response={(resp)=> {
                                     changeInitialComment(resp)
                                 } } />
              }
            </td>
            <td className='column column--medium column--with-padding'>
                <div className="filter-wrapper">
                    <ScaleDropdown
                        value={fund.internalStatus}
                        size="small"
                        label={'Status'}
                        onScaleChange={(e) => {
                            onInternStatusChange(e)
                        }}
                        >
                        {INTERNAL_STATUS_OPTIONS.map((item,i) =>
                            <option
                                value={item.value}
                                key={i + '_status'}
                                disabled={!newFundsStatusOptions.includes(item.value)}
                            >
                                {item.name}
                        </option>
                        )}
                    </ScaleDropdown>
                </div>
            </td>
            <td className="column column--large column--no-wrap column--right column--with-padding">
              <GroupedButtons onCheckRelevance={()=> openRelevanceDialog(fund)}
                              onDelete={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=> {
                                  setActiveRow(fund.id)
                                event.stopPropagation()
                                openAlertDeleteDialog(fund.id)
                              }}

              />
                <div className="action-link-container">
                    {fund.info.url && (
                        <a href={fund.info.url} target={'_blank'} rel="noreferrer">
                        <span className="action-link">
                            <ShareIcon />
                        </span>
                        <span className="action-link__text">{fund.info.url}</span>
                        </a>
                    )}
                </div>
            </td>
        </tr>
    )
}