import React from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as DeleteIcon } from '../../../icons/trash-icon.svg'
import { RSSFeed } from '../../../model/RSSFeed'
import { ScaleSwitch } from '@telekom/scale-components-react'
import cn from 'classnames'

interface Props {
  rssFeeds: RSSFeed
  openAlertDeleteDialog: Function
  onToggleActive: Function
  setActiveRow: Function
  active: boolean
}

export const RSSFeedRow = ({
  rssFeeds,
  openAlertDeleteDialog,
  onToggleActive,
  setActiveRow,
  active
}: Props) => {
  const { t } = useTranslation("table")
  return (
    <tr id={rssFeeds.id} 
        key={`${rssFeeds.id}_rss-feed-row`}
        onClick={(e: any) => {
          if(e.target.tagName === "TD") {
            setActiveRow(rssFeeds.id)
          }
        }} 
        className={cn('row', {
          'active': active,
        })}
    >
      <td className="column column--small column--with-padding">
        <div className="publication-item publication-item--title bold">
          {rssFeeds.source}
        </div>
      </td>
      <td className="column column--small column--with-padding">
        {rssFeeds.link_type}
      </td>
      <td className="column column--large column--no-wrap column--with-padding">
        <div className="action-link-container action-link-container--static">
          {rssFeeds.url && (
            <a href={rssFeeds.url} target={'_blank'} rel="noreferrer">
              <span className="action-link__text action-link__text--ellipsis">{rssFeeds.url}</span>
            </a>
          )}
        </div>
      </td>
      <td className="column column--small column--with-padding">
        <div className="action-buttons-container action-buttons-container--wmb action-buttons-container--rss-feeds">
          <button
            title={String(t("BO:BO-FP-NFPB1"))}
            className="action-button action-button--delete" onClick={(e) => {
              e.stopPropagation()
              openAlertDeleteDialog(rssFeeds.id)
            }}
          >
            <DeleteIcon />
          </button>
          <ScaleSwitch checked={rssFeeds.is_active} onScaleChange={(e) => {
              e.stopPropagation()
              onToggleActive(!rssFeeds.is_active, rssFeeds.id)
          }} />
        </div>
      </td>
    </tr>
  )
}
