import { Point } from "./GeometryModule"

export default class SVGHelper {

  private constructor() {

  }

  static getTranslateString(x: number, y: number): string {
    return `translate(${x},${y})`
  }

  static getTranslateStringFromPivot(pivot: Point): string {
    return `translate(${pivot.x},${pivot.y})`
  }

  static getDiamondPathString(factor: number): string {
    return `M -${factor} 0 L 0 ${factor} L ${factor} 0 L 0 -${factor} Z`
  }

  static getElementCoords(element: SVGGraphicsElement, coords: Point): Point {
    const ctm = element.getCTM()
    if (!ctm) {
        throw new Error('CTM not available')
    }
    const x = ctm.e + (coords.x * ctm.a) + (coords.y * ctm.c)
    const y = ctm.f + (coords.x * ctm.b) + (coords.y * ctm.d)
    return { x, y }
}
}