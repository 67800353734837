import '../../scss/layout/_modal.scss'
import { FormValue } from "../../pages/backoffice/press-releases/forms/form-types";
import React from "react";
import { ReactComponent as WarningSign2 } from "../../icons/warning-sign-2.svg";
import cn from 'classnames'
import TooltipClose from '../Radar/__styled__/TooltipClose';

interface Props {
    type: string,
    //todo type -> NotificationType
    title?: string,
    formContent?: JSX.Element,
    textContent?: JSX.Element,
    isOpened?: boolean,
    isWideModal?: boolean,
    isHighModal?: boolean,
    onClose?: () => void,
    onSubmit?: (values: FormValue[]) => void,
    onAction?: () => void,
    noScrollbar?: boolean,
    dynamicHeight?: boolean,
}

export const ModalDialog = ({ type, title, isOpened, formContent, textContent, isWideModal, isHighModal, noScrollbar, dynamicHeight }: Props) => {

    return <dialog className={`modal ${isOpened ? 'modal--is-open' : 'modal--is-closed'}  
                                    ${cn({ 'modal--dynamic-height': dynamicHeight },
        { 'modal--no-scrollbar': noScrollbar },
        { 'modal--is-high': isHighModal },
        { 'modal--is-wide': isWideModal })}`
    } aria-modal tabIndex={0}>
        <div className="modal__body">
            {type.includes('alert') && <div className="modal__sign"><WarningSign2 /></div>}
            {title && 
                <div className={`${type.includes('alert') ? 'modal__title modal__title--centered' : 'modal__title'}`}>
                    {title}
                </div>
            }
            {!type.includes('alert')
                && <div className="modal__content">
                    {formContent}
                </div>
            }
            {type.includes('alert') && <div className="modal__content">
                {textContent}
            </div>}
        </div>
    </dialog>
}