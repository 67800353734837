import { t } from 'i18next'
// import { TrendingStreams } from "../../theme/constants/TrendingStreams";
import { MainTheme } from "../../theme/MainTheme";
import {COLOR_MAGENTA, IRadarType, SectorMap, StyledRing } from "./RadarRenderer";
import { Geometry, Point } from "./GeometryModule";
import { RadarConfig } from "./RadarConfig";
import SVGHelper from "./SVGHelper";
import * as d3 from 'd3';

type D3SVGGElement = d3.Selection<SVGGElement, unknown, null, undefined>

export class RadarBackground {
    private constructor() {

    }

    static appendTo(radar: D3SVGGElement, isQuarter: boolean, radarConfig: RadarConfig, sectorForName: SectorMap, rings: StyledRing[], showModalWithFunds: ((arg0: string | string[], arg1: string) => void), radarType?: IRadarType) {

        const centerOffsetX = (isQuarter) ? radarConfig.leftMargin : (radarConfig.leftMargin + radarConfig.radius)

        const centerOffset = {
            x: centerOffsetX,
            y: radarConfig.radius + radarConfig.headerHeight // move y coordinate by radius and height of header
        }

        radar.attr("transform", SVGHelper.getTranslateString(centerOffset.x, centerOffset.y));

        const grid = radar.append("g");

        const radialLineThetas = new Set<number>()
        sectorForName.forEach(it => {
            radialLineThetas.add(it.thetaMin)
            radialLineThetas.add(it.thetaMax)
        })

        // generate labels for sectors/streams
        if (!isQuarter) {
            sectorForName.forEach(({ thetaMin, thetaMax, radarName }, streamName) => {
                const thetaMid = ((thetaMax - thetaMin) / 2.0) + thetaMin

                if(sectorForName.size > 1) {
                    RadarBackground.appendStreamLabelTo(grid, thetaMid, radarConfig, streamName, radarName)
                }
            })
        }


        const legendOffsetY = isQuarter ? 15 : 60
        const legendLabelOffset: Point = {
            x: -2,
            y: -20,
        }

        const legendLabelxOffsets: number[] = [-5, 10, 60];
        const legendLabelYMobileOffsets: number[] = [70, 70, 125];

        // draw rings and legend
        rings.map(ring => ring).sort((a, b) => b.radiusMax - a.radiusMax).forEach(({ radiusMax, radiusMin, color, name, label }, index) => {
            const isMostInnerCircle = (index === rings.length - 1)

            // draw circle and cut half
            const halfCircle = grid.append("circle")
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", radiusMax)
                .attr('clip-path', 'url(#cut-off)')
                .style("fill", color)

                halfCircle.on("click", (event) => {

                    const [x, y] = d3.pointer(event);
                    const angleRadians = Math.atan2(y, x);
                    const radius = Math.sqrt(x * x + y * y);
    
                    const foundSectorKey = Array.from(sectorForName.keys())
                        .find((key) => {
                            const sectorObj = sectorForName.get(key);
                            
                            if (!sectorObj) {
                                return;
                            }
    
                            return sectorObj.thetaMax > angleRadians && sectorObj.thetaMin < angleRadians;
                        });
    
                    const ringName = rings.find(ring => ring.radiusMin < radius && ring.radiusMax > radius)?.name
                    
                    
                    if (foundSectorKey && ringName) {
                        showModalWithFunds(ringName, foundSectorKey);
                    } 
    
                });

            if (isMostInnerCircle && !isQuarter) {
                halfCircle.style('stroke', 'white').style('stroke-width', 1.5)
            }

            if (index === 2 && radarType === 'four-rings') {
                halfCircle
                    .attr("stroke", "rgb(226, 0, 116)")
                    .attr("stroke-width", 2)
            }

            // draw line for legend
            if (!isQuarter) {
                if (isMostInnerCircle) {
                    radar.append('line')
                        .attr('x1', -radiusMax).attr('y1', legendOffsetY)
                        .attr('x2', radiusMax).attr('y2', legendOffsetY)
                        .style('stroke', 'black')
                        .style('stroke-width', 1)
                } else {
                    for (const factor of [-1, 1]) {
                        radar.append('line')
                            .attr('x1', factor * radiusMax).attr('y1', legendOffsetY)
                            .attr('x2', factor * radiusMin).attr('y2', legendOffsetY)
                            .style('stroke', 'black')
                            .style('stroke-width', 1)
                    }
                }


            }

            // draw max point for legend
            if (!isQuarter) {
                for (const factor of [-1, 1]) {
                    radar.append("circle")
                        .attr('cx', factor * radiusMax)
                        .attr('cy', legendOffsetY)
                        .attr('r', 2.5)
                        .style('fill', 'white')
                        .style('stroke', 'black')
                        .style('stroke-width', 1.5)
                }
            }

            // draw text for legend
            if (isQuarter) {
                if (isMostInnerCircle) {
                    const x = rings[0].radiusMax - 78
                    radar.append("text")
                        .text(label)
                        .attr("x", x)
                        .attr("y", -195)
                        .attr("text-anchor", 'middle')
                        .style('font-size', radarConfig.legend.fontSizeInner)
                        .style("fill", 'white')
                        .style("font-family", "'TeleNeoWeb', sans-serif")
                        .style("font-weight", "800")
                        .style("pointer-events", "none")
                        .attr("transform", "rotate(-90)")
                        .style("user-select", "none");
                } else {
                    const x = rings[0].radiusMax + (4 - index) * 92 - legendLabelYMobileOffsets[index];
                    ///
                    const addLabel = (label: string, yOffset: number, xOffset: number) => {
                        radar.append("text")
                            .text(label)
                            .attr('x', xOffset)
                            .attr("y", yOffset)
                            .attr("text-anchor", 'middle')
                            .style('font-size', radarConfig.legend.fontSize)
                            .style("fill", 'black')
                            .style("font-family", "'TeleNeoWeb', sans-serif")
                            .style("font-weight", "700")
                            .style("pointer-events", "none")
                            .style("user-select", "none")
                            .attr("transform", "rotate(-90)");
                    }

                    if (index !== 0) {
                        addLabel(label, -195, x);
                    } else {
                        addLabel(label.split("\n")[0], -195, x);
                        addLabel(label.split("\n")[1], -185, x);
                    }
                    ///
                }
            } else {
                if (isMostInnerCircle) {
                    radar.append("text")
                        .text(label)
                        .attr('x', 0)
                        .attr("y", legendOffsetY + legendLabelOffset.y)
                        .attr("text-anchor", 'middle')
                        .style('font-size', radarConfig.legend.fontSizeInner)
                        .style("fill", COLOR_MAGENTA)
                        .style("font-family", "'TeleNeoWeb', sans-serif")
                        .style("font-weight", "800")
                        .style("pointer-events", "none")
                        .style("user-select", "none");
                } else {
                    if (radarType === 'four-rings') {
                        const addLabel = (label: string, yOffset: number, xOffset: number, factor: number) => {
                            radar.append("text")
                                .text(label)
                                .attr('x', (radiusMax - xOffset) * factor)
                                .attr("y", legendOffsetY + yOffset)
                                .attr("text-anchor", (factor === 1) ? 'end' : 'start')
                                .style('font-size', radarConfig.legend.fontSize)
                                .style("fill", 'black')
                                .style("font-family", "'TeleNeoWeb', sans-serif")
                                .style("font-weight", "700")
                                .style("pointer-events", "none")
                                .style("user-select", "none");
                        }

                        const xOffset = legendLabelOffset.x + legendLabelxOffsets[index];

                        for (const factor of [-1, 1]) {
                            if (index !== 0) {
                                addLabel(label, legendLabelOffset.y, xOffset, factor);
                            } else {
                                addLabel(label.split("\n")[0], legendLabelOffset.y * 1.5, xOffset, factor);
                                addLabel(label.split("\n")[1], legendLabelOffset.y + 10, xOffset, factor);
                            }
                        }   
                    } else {
                        for (const factor of [-1, 1]) {
                            radar.append("text")
                                .text(label)
                                .attr('x', (radiusMax - legendLabelOffset.x) * factor)
                                .attr("y", legendOffsetY + legendLabelOffset.y)
                                .attr("text-anchor", (factor === 1) ? 'end' : 'start')
                                .style('font-size', radarConfig.legend.fontSize)
                                .style("fill", 'black')
                                .style("font-family", "'TeleNeoWeb', sans-serif")
                                .style("font-weight", "700")
                                .style("pointer-events", "none")
                                .style("user-select", "none");
                        }
                    }
                      
                }
            }
        })

        // draw radials from center
        // ays? 
        const ringsCount = rings.length - 1;
        const radialRadiusMin = rings[0].radiusMax
        const radialRadiusMax = rings[ringsCount].radiusMax

        Array(...radialLineThetas).filter(theta => {
            if (isQuarter) {
                if (Geometry.isAlmost(theta, 0, 0.01) || Geometry.isAlmost(theta, -Math.PI / 2.0, 0.01)) {
                    return false
                }
            } else {
                if (Geometry.isAlmost(theta, 0, 0.01) || Geometry.isAlmost(theta, -Math.PI, 0.01)) {
                    return false
                }
            }

            return true
        }).forEach(theta => {
            const { x: xa, y: ya } = Geometry.toCartesian({ t: theta, r: radialRadiusMin })
            const { x, y } = Geometry.toCartesian({ t: theta, r: radialRadiusMax })

            grid.append('line')
                .attr("x1", xa).attr("y1", ya)
                .attr("x2", x).attr("y2", y)
                .style("stroke", '#ffffff') // ZALANDO_CONFIG.colors.grid
                .style("stroke-width", 1.5)
                .style('stroke-dasharray', '4,5')
        })

    }

    private static appendStreamLabelTo(grid: D3SVGGElement, thetaMid: number, radarConfig: RadarConfig, streamName: string, radarName: string) {
        const { x, y } = Geometry.toCartesian({ t: thetaMid, r: radarConfig.sectorLabelDistance })

                // create dot in front of label
                // grid.append("circle")
                //     .attr("cx", x)
                //     .attr("cy", y)
                //     .attr("r", radarConfig.labels.dotRadius)
                //     .attr("fill", COLOR_GRAY2)

                const alignment = (thetaMid < (-0.5 * Math.PI)) ? 'right' : 'left'
                const textAnchor = (alignment === 'left') ? 'start' : 'end'
                const sign = (alignment === 'left') ? 1 : -1
                const deltaX = radarConfig.labels.offset.x * sign
                const deltaY = radarConfig.labels.offset.y


                // const trendingPlacementBackground = (alignment === 'left') ? 0 : -radarConfig.trend.width;
                // const trendOffsetY = 10;

                //Hide "Im Trend" Label inside the Radar
                // if trending, add trending label
                /* if (TrendingStreams.includes(streamName)) {

                    const trendLabelPivot = {
                        x: x + deltaX + trendingPlacementBackground,
                        y: y + trendOffsetY
                    }
                    RadarBackground.appendTrendLabelTo(grid, radarConfig, trendLabelPivot)
                } */

                // add actual stream name
                grid.append("text")
                    .attr('x', x + deltaX)
                    .attr('y', y + deltaY)
                    .style("font-size", radarConfig.labels.fontSize)
                    .style("display", 'inline-block')
                    .attr("text-anchor", textAnchor)
                    .attr("class", "radar__stream-label")
                    .text(radarName)
    }

    private static appendTrendLabelTo(grid: D3SVGGElement, radarConfig: RadarConfig, pivot: Point) {

        // const trendLabel = grid.append("g")
        //     .attr('transform', SVGHelper.translate(x + deltaX + trendingPlacementBackground, y + trendOffsetY))

        const trendLabel = grid.append("g")
            .attr('transform', SVGHelper.getTranslateStringFromPivot(pivot))

        const borderRadius = '4px'

        // TODO: Change color back to red (#eb455e) if necessary
        // create rectangular background with rounded edges
        trendLabel.append("rect")
            .attr("rx", borderRadius)
            .attr("ry", borderRadius)
            .style("fill", MainTheme.trend.color)
            .attr("width", radarConfig.trend.width)
            .attr("height", radarConfig.trend.height)

        // add "on trend" text
        trendLabel.append("text")
            .style("font-family", "'TeleNeoWeb', sans-serif")
            .style('font-weight', 'bold')
            .style("font-size", `${radarConfig.trend.fontSize}px`)
            .style("fill", "#fff")
            .style("display", "inline-block")
            .attr("text-anchor", 'middle')
            .text(t("details:trend"))
            .attr('transform', SVGHelper.getTranslateString(0, 0))
            .attr('x', radarConfig.trend.width / 2 + 1)
            .attr('y', radarConfig.trend.height - radarConfig.trend.fontSize * 0.4)
    }
}