import styled from 'styled-components/macro'

const CardsInner = styled('div')`

  @media (min-width: ${props => props.theme?.breakpoints?.tablet}) {
    display: flex;
    margin-right: -8px;
    margin-left: -8px;
  }

  @media (min-width: ${props => props.theme?.breakpoints?.desktopXXl}) {
    max-width: 800px;
    margin: 0 auto;
  }

  .card-is-default {
    display: block;
    margin-bottom: ${props => props.theme?.spacings?.smaller.gutter};
  }
`

export default CardsInner