import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Stream from "../../../model/Stream";
import {ReactComponent as DeleteIcon} from '../../../icons/trash-icon.svg';
import {ReactComponent as EditIcon} from '../../../icons/edit-icon.svg';
import {ReactComponent as PlusIcon} from '../../../icons/plus-icon.svg';
import {ReactComponent as ArrowDownIcon} from "../../../icons/long-arrow-down-right-icon.svg";
import {ReactComponent as ShevronLeftIcon} from "../../../icons/shevron-left-icon.svg";
import {ScaleTable, ScaleNotificationMessage} from '@telekom/scale-components-react'
import {ModalDialog} from "../../../components/modal-dialog/modal-dialog";
import {DialogWrapper} from "../../../components/modal-dialog/dialog-wrapper";
import BackendFactory from "../../../backend/BackendFactory";
import {AlertWithActions} from "../notifications/alert-with-actions";
import {FormField} from "../../../model/form-field";
import {StreamsFormState} from "./forms/form-types";
import cn from 'classnames';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import {CreateStreamForm} from "./forms/create-stream-form";
import {useOutsideCloser} from "../../../components/modal-dialog/outside-closer-utility"
import { CreateMainKeywords } from './forms/createMainKeywords';

interface Props{
    streams: Stream[]
}
interface ComponentState {
    isDialogOpen: boolean,
    dialogConfig: {
        type: string,
        title?: string,
        content?: FormField[] | string,
        index?: number,
    },
    model?: StreamsFormState,
    streams: Stream[],
    stream?: Stream,
    error?: boolean,
    success?: boolean,
    message?: string,
    currentClickedAccordionItemId?: string,
    isLast?: boolean
    activeRow?: string
}

interface IKeywordsState {
    isDialogOpen: boolean,
    dialogConfig: {
        type: string,
        title?: string,
        content?: FormField[] | string,
        index?: number,
    },
    error?: boolean,
    success?: boolean,
    message?: string,
}

export  const StreamsTable = ({streams}: Props) => {
    const { t } = useTranslation(["table", "BOFundTranslationKeys"])
    const initialState: ComponentState  = {
        streams,
        isDialogOpen: false,
        dialogConfig:{
            type: ''
        }
    }

    const initialKeyWordsState: IKeywordsState = {
        isDialogOpen: false,
        dialogConfig:{
            type: ''
        }
    }

    const [compState, setCompState] = useState(initialState)
    const [keywordState, setKeywordCompState] = useState(initialKeyWordsState)
    const messages = [{type:'create', text:  t("BOFundTranslationKeys:streamCreateText")},
        {type:'create-sub-stream', text: t("BOFundTranslationKeys:subStreamCreateText")},
        {type:'edit', text: t("BOFundTranslationKeys:streamEditText")},
        {type:'edit-sub-stream', text: t("BOFundTranslationKeys:subStreamEditText")},
        {type:'delete', text: t("BOFundTranslationKeys:streamDeleteText")},
        {type:'delete-sub-stream', text: t("BOFundTranslationKeys:subStreamDeleteText")},
        {type:'create-keyword', text: t("BOFundTranslationKeys:keywordAdded")},
    ]
    const streamNames = compState.streams?.map( item => item.name)

    const wrapperRef = useRef(null);

    const setActiveRow = (id: string) => {
        setCompState({
          ...compState,
          activeRow: id
        })
    }

   /** On click on create button */
    const openCreateDialog = (e: any) => {
       e.stopPropagation()
       if (compState.streams.length >= 10){
           setCompState({...compState, error: true, success: false, message: String(t("BOFundTranslationKeys:maximumStreamsCreatedText"))})
       } else {
           // manipulation with real dom node to show cover
           document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
           e.currentTarget.blur()
           // set up a dialog content
           setCompState({
               ...compState,
               isDialogOpen: true,
               dialogConfig: {
                   type: 'create',
                   content: [
                        {value: '', label: t("table:name")},
                       {value: '', label: 'Keywords', inputType: "text"}
                   ],
                   title: String(t("table:createStream"))
               }})
       }
    }

    const openCreateKeywordDialog = (e: any) => {
        e.stopPropagation()
        // manipulation with real dom node to show cover
        document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
        const message = messages.find( msg => msg.type === 'create-keyword')?.text
        e.currentTarget.blur()
        // set up a dialog content
        setKeywordCompState({
            ...keywordState,
            isDialogOpen: true,
            error: true,
            success: false,
            dialogConfig: {
                type: 'create-keyword',
                content: [{value: '', label: 'Keywords', inputType: "text"}],
                title:String(t("table:keywordManagement")),
            },
            message: message})
     }

    const openCreateSubStreamDialog = (e: any, stream: Stream, id: string) => {
        e.stopPropagation()
        
        if (stream.sub_streams && stream.sub_streams?.length >= 9) {
            setCompState({...compState, error: true, success: false, message: String(t("BOFundTranslationKeys:maximumSubStreamsCreatedText"))})
        } else {
            // manipulation with real dom node to show cover
            document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
            e.currentTarget.blur()
            // set up a dialog content
            setCompState({
                ...compState,
                isDialogOpen: true,
                dialogConfig: {
                    type: 'create-sub-stream',
                    content: [
                        {value: '', label: t("table:name")},
                        {value: streamNames, label: t("table:streams")},
                        {value: '', label: 'Keywords'}
                    ],
                    title: String(t("table:createTopic"))
                },
                model:{
                    name: '',
                    main_stream: stream.main_stream,
                    sub_streams: stream.sub_streams,
                    has_sub_streams: stream.has_sub_streams,
                    primaryStreamId: stream.id
                },
                currentClickedAccordionItemId: id,
                isLast: stream.sub_streams?.length === 0
            })
        }
    }

    const creationFormContent = (content: FormField[], type: string,  model?: StreamsFormState) => {
        return <CreateStreamForm content={content}
                                 onClose={()=>closeDialog()}
                                 onSubmit={()=> onSubmit(type)}
                                 streams={streamNames}
                                 type={type}
                                 model={model}/>
   }

   const createMainKeywords = (content:FormField[] ,type: string) => {
    return <CreateMainKeywords 
                            content={content}
                             onClose={()=>closeKeywordDialog()}
                             onSubmit={()=> closeKeywordDialog(true)}
                             type={type}
            />
    }

   /** On click on edit button */
    const openEditDialog = (e: any, stream: Stream, i: number) => {
       e.stopPropagation()
       // manipulation with real dom node to show cover
       document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
       e.currentTarget.blur()
       // set up a dialog content
       setCompState({
           ...compState,
           isDialogOpen: true,
           dialogConfig: {
               type: 'edit',
               content: [
                    {value: stream.name, label: t("table:name")},
                    {value: streamNames, label: t("table:streams")},
                    {value: stream.keywords, label: 'Keywords'},
               ],
               title: String(t("table:editStream")),
               index: i
           },
           model:{
               id: stream.id,
               name: stream.name,
               index_number: stream.index_number,
               main_stream: stream.main_stream,
               sub_streams: stream.sub_streams,
               has_sub_streams: stream.has_sub_streams,
               keywords: stream.keywords ? stream.keywords! : null
           }
       })
   }
    const openEditSubStreamDialog = (e: any, stream: Stream, primaryStreamId: string, i: number) => {
        e.stopPropagation()
        // manipulation with real dom node to show cover
        document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
        e.currentTarget.blur()
        // set up a dialog content
        setCompState({
            ...compState,
            isDialogOpen: true,
            dialogConfig: {
                type: 'edit-sub-stream',
                content: [
                    {value: stream.name, label:  t("table:name")},
                    {value: streamNames, label: t("table:streams")},
                    {value: stream.keywords, label: 'Keywords'},
                ],
                title: String(t("table:editTopic")),
                index: i
            },
            model:{
                id: stream.id,
                name: stream.name,
                index_number: stream.index_number,
                main_stream: stream.main_stream,
                sub_streams: stream.sub_streams,
                has_sub_streams: stream.has_sub_streams,
                primaryStreamId: primaryStreamId,
                keywords: stream.keywords ? stream.keywords! : null
            }
        })
    }
    const editFormContent = (content: FormField[], model: StreamsFormState, type:string) => {
        return <CreateStreamForm content={content}
                                 model={model}
                                 onSubmit={()=> onSubmit(type)}
                                 onClose={()=>closeDialog()}
                                 streams={streamNames}
                                 type={type}
                                 index={compState.dialogConfig.index}
        />
   }


    /** On click on delete button */
    const openAlertDeleteDialog = (e: any, stream: Stream, primaryStreamId?: string, isLast?: boolean, id?: string) => {
        e.stopPropagation()
        // manipulation with real dom node to show cover and removing focus from clicked button
        document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
        e.currentTarget.blur()
        // set up a dialog content
        setCompState({
            ...compState,
            isDialogOpen: true,
            dialogConfig: {
                type:'alert-delete',
                content: `${stream.has_sub_streams ? String(t("BOFundTranslationKeys:alertTextForStreamWithSubs")) : String(t("BOFundTranslationKeys:alertStreamsText"))}`,
                title:`${stream.main_stream ? String(t("BOFundTranslationKeys:deleteStream", {streamName: stream.name})) : String(t("BOFundTranslationKeys:deleteTopic", {streamName: stream.name}))}`
            },
            stream,
            isLast,
            currentClickedAccordionItemId: id,
            model:{
                name: stream.name,
                id: stream.id,
                primaryStreamId: primaryStreamId
            }
        })
    }
    const alertDialogDeleteContent = (content: string, stream: Stream) => {
        const contentType = stream.main_stream ? 'delete' : 'delete-sub-stream'
        const message = messages.find( msg => msg.type === contentType)?.text


        compState.currentClickedAccordionItemId && compState.isLast
        && document.getElementById('accordion__heading-' + compState.currentClickedAccordionItemId)
            ?.setAttribute('aria-expanded', 'false')

        return <AlertWithActions content={content} type={stream.has_sub_streams ? 'notification':'delete'}
                    onClose={()=>closeDialog()}
                    onAction={()=> {
                        if (!stream.has_sub_streams){
                            BackendFactory.produce().deleteStream(stream.id).then(res => {
                                if (!res || Object.getOwnPropertyNames(res).length === 0
                                    || res.detail !== 'SUCCESS') {
                                    setCompState({
                                        ...compState,
                                        model: initialState.model,
                                        error: true,
                                        success: false,
                                        isDialogOpen: false,
                                        message: String(t("BOFundTranslationKeys:cantDelete"))})
                                } else {
                                    BackendFactory.produce().getStreams()
                                        .then(res => setCompState({
                                            ...compState,
                                            model: initialState.model,
                                            streams: res,
                                            success: true,
                                            error: false,
                                            isDialogOpen: false,
                                            message: message}) )
                                }
                            })
                            if (compState.isDialogOpen) {
                                closeDialog()
                            }
                        } else {
                            closeDialog()
                        }
                    }}
                />
    }


    /** onSubmit action for dialog */
    const onSubmit = (type?: string) => {
        if (type){
            const message = messages.find( msg => msg.type === type)?.text
            BackendFactory.produce().getStreams()
                .then(res => setCompState({
                    ...compState,
                    isDialogOpen: false,
                    dialogConfig: initialState.dialogConfig,
                    streams: res,
                    success: true,
                    error: false,
                    message: message
                }))

            if (compState.currentClickedAccordionItemId){
                document.getElementById('accordion__heading-' + compState.currentClickedAccordionItemId)
                    ?.classList.remove('inactive')
                document.getElementById('accordion__heading-' + compState.currentClickedAccordionItemId)
                    ?.classList.add('active')
            }
        }
        const ar = new Array(...document.getElementsByClassName('inactive'))
        ar.forEach( item => item
            .getElementsByClassName('accordion__button')[0]
            .setAttribute('aria-expanded','false'))

        closeDialog(true)
    }

   /** on Close action for dialog */
    const closeDialog = (fromSubmit?: boolean) => {
       document.getElementsByClassName('App')[0].classList?.remove('with-modal-opened')
       document.getElementsByClassName('dialog-container')[0]?.remove()

       if(!fromSubmit){
           BackendFactory.produce().getStreams()
               .then(res => {
                
                    setCompState({
                        ...compState,
                        isDialogOpen: false,
                        dialogConfig: initialState.dialogConfig,
                        streams: res
                    })
                })
       }
   }

    const closeKeywordDialog = (fromSubmit?: boolean) => {
        document.getElementsByClassName('App')[0].classList?.remove('with-modal-opened')
        document.getElementsByClassName('dialog-container')[0]?.remove()
        if(fromSubmit){
            setKeywordCompState({
                ...keywordState,
                isDialogOpen: false,
                success: true,
                dialogConfig: initialKeyWordsState.dialogConfig,
            })
        } else {
            setKeywordCompState({
                ...keywordState,
                isDialogOpen: false,
                dialogConfig: initialKeyWordsState.dialogConfig,
            })
        }
    }

    useOutsideCloser(wrapperRef, closeDialog);

    const showDialogByType = () => {
        let contentConfig: FormField[] | string = []
        let content
        let textContent
        if (compState.dialogConfig.type === 'create') {
            contentConfig = compState.dialogConfig.content as FormField[]
            content = creationFormContent(contentConfig, 'create')
        } else if (compState.dialogConfig.type === 'create-sub-stream') {
             contentConfig = compState.dialogConfig.content as FormField[]
             content = creationFormContent(contentConfig, 'create-sub-stream', compState.model)
       } else if (compState.dialogConfig.type === 'edit'){
           contentConfig = compState.dialogConfig.content as FormField[]
           content = compState.model ? editFormContent(contentConfig,compState.model, 'edit') : <div>{t("table:error")}</div>
       } else if (compState.dialogConfig.type === 'edit-sub-stream'){
           contentConfig = compState.dialogConfig.content as FormField[]
           content = compState.model ? editFormContent(contentConfig,compState.model, 'edit-sub-stream') : <div>{t("table:error")}</div>
       } else if (compState.dialogConfig.type === 'alert-delete') {
           contentConfig = compState.dialogConfig.content as string
           textContent = compState.stream ? alertDialogDeleteContent(contentConfig, compState.stream) : <div>{t("table:error")}</div>
       } else {
           contentConfig = compState.dialogConfig.content as string
           content = <>{contentConfig}</>
       }
       return (
           <div ref={wrapperRef} className="modal-wrapper">
               <ModalDialog
                   title={compState.dialogConfig.title}
                   type={compState.dialogConfig.type}
                   formContent={content}
                   textContent={textContent}
                   isOpened = {compState.isDialogOpen}
                   onClose={()=>closeDialog()}
                   noScrollbar
               />
           </div>
       )
   }

    const keywordDialog = () => {
        let keywordConfig: FormField[] | string = ""
        let content
        let textContent
        if ( keywordState.dialogConfig.type === 'create-keyword' ) {          
            keywordConfig = keywordState.dialogConfig.content as FormField[]   
            content = createMainKeywords(keywordConfig, 'create')
        }

        return (
            <div ref={wrapperRef} className="modal-wrapper">
                <ModalDialog
                    title={keywordState.dialogConfig.title}
                    type={keywordState.dialogConfig.type}
                    formContent={content}
                    textContent={textContent}
                    isOpened = {keywordState.isDialogOpen}
                    onClose={() => closeKeywordDialog()}
                    noScrollbar
                />
            </div>
        )
    }

   /** Used https://www.npmjs.com/package/react-accessible-accordion */
    const accordionItem = (stream: Stream, i:number) => {
        return <AccordionItem key={'accordion_' + i} className={`${stream.sub_streams?.length === 0 ? 'inactive': 'active'}`}
                              uuid={i}
                              onClick={(e)=> {
                                  e.stopPropagation()
                                  const id = i.toString()
                                  setCompState({
                                      ...compState,
                                      activeRow: stream.id,
                                      currentClickedAccordionItemId: id,
                                      isLast: stream.sub_streams?.length === 1
                                  })
                              } }>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {renderStreamRow(stream,i)}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {stream.sub_streams
                        && (stream.sub_streams.length > 0)
                        && stream.sub_streams.map( (substr, substI) => renderStreamRow(substr,
                            i,
                            true,
                            substI + 1,
                            stream, stream.sub_streams?.length) )}
                    </AccordionItemPanel>
                </AccordionItem>
    }

    const renderStreamRow = (stream: Stream, i: number, substreams?: boolean, substI?: number, primaryStream?: Stream, isLast?: number)=>{
        const primaryStreamId = primaryStream?.id
        const id = i.toString()
        return (
            <ScaleTable size={"medium"}
                        className={`publications-table ${substreams ? 'second-level': 'first-level'}`}
                        id={'table_id' + i + stream.id}
                        key={'table_id' + i + 1 + stream.id}
            >
                <table>
                    <tbody>
                        <tr id={'tr_id_' + i}
                            key={i + '_key'}
                            onClick={() => setActiveRow(stream.id)} 
                            className={cn('row', {
                                'highlight': compState.activeRow === stream.id,
                                'second-level-tr': substreams,
                                'first-level-tr': !substreams
                                })
                            }
                            >
                            <td className={`column ${substreams ? 'column--small': 'column--x-small'}`}>
                                    {substreams && <ArrowDownIcon className={'icon'}/>}
                                    {!substreams ? i : (i + '.' + substI)}.
                            </td>
                            <td className={`column column--large bold`}>
                                {stream.name}
                                {!substreams && <span className={`action-button ${(stream.sub_streams && stream.sub_streams.length > 0)
                                ? 'action-button--counter-active':'action-button--counter'} `}>
                                        {`(${stream.sub_streams?.length || 0})`}
                                </span>}
                            </td>
                            <td className={`column column--large bold`}>
                                {stream.keywords?.join(", ")}
                            </td>
                            <td className='column column--large column--no-wrap column--right'>
                                <button className="action-button action-button--delete"
                                        title={String(t("BO:BO-FP-NFPB1"))}
                                        onClick={(e)=>{
                                    e.stopPropagation()
                                    {!stream.main_stream && primaryStreamId
                                        ? openAlertDeleteDialog(e, stream, primaryStreamId, isLast === 1, id) : openAlertDeleteDialog(e, stream)}
                                }}><DeleteIcon/></button>
                                <button className="action-button action-button--edit"
                                        onClick={(e)=>{
                                          e.stopPropagation()
                                          {!stream.main_stream && primaryStreamId
                                              ? openEditSubStreamDialog(e, stream, primaryStreamId, substI || 0) : openEditDialog(e, stream, i)}}}
                                        title={String(t("table:btnTitleEdit"))}
                                >
                                  <EditIcon/>
                                </button>
                                {!substreams
                                &&<button className="action-button action-button--add" onClick={(e)=>{
                                    e.stopPropagation()
                                    openCreateSubStreamDialog(e, stream, id)
                                }}><PlusIcon/>
                                </button>}
                            </td>
                            <td className='column column--x-small column--no-wrap column--right'>
                                {!substreams && <div className="action-buttons-container">
                                    <button className="action-button action-button--expand"><ShevronLeftIcon/></button>
                                </div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ScaleTable>
        )
    }

    const renderList = (streams: Stream[])=>{
        const title = (streams && streams.length > 1) ? t("table:streams") : t("BO:BO-FP-RFPStream")
        const sortedItems = streams.sort()
        return (<>
                <div className="notification-messages-container">
                    {compState.error && <ScaleNotificationMessage  variant={'error'} dismissible opened>
                        {String(t("BOFundTranslationKeys:streamsPageErrorText"))}
                    </ScaleNotificationMessage>}
                    {compState.success && <ScaleNotificationMessage  variant={'success'} dismissible opened>
                        {compState.message}
                    </ScaleNotificationMessage>}
                    {keywordState.success && <ScaleNotificationMessage  variant={'success'} dismissible opened>
                        {keywordState.message}
                    </ScaleNotificationMessage>}
                </div>
                <div className="backoffice-title">
                    <div >
                        <div className="publications-title">{streams.length} {title}</div>
                        <div className="publications-subtitle">
                            {streams.length === 0
                            &&<div className="publications-subtitle--alert">{String(t("BOFundTranslationKeys:noStreamsNotificationText"))}</div>}
                            {streams.length > 1 && streams.length < 10 && ` ${streams.length} ${t("table:createfrom")}`}
                            {streams.length >=10
                            && <div className="publications-subtitle--alert">{String(t("BOFundTranslationKeys:maximumStreamsNotificationText"))}</div>}
                        </div>
                    </div>
                    <div className='action-buttons-block'>
                        <button className="action-button action-button--magenta"
                            onClick={(e)=> openCreateKeywordDialog(e)}> <PlusIcon/>{t("table:keywordManagement")}</button>
                        <button className="action-button action-button--magenta"
                          onClick={(e)=> openCreateDialog(e)}> <PlusIcon/> {t("table:createStream")}</button>
                    </div>
                </div>
                {sortedItems && <Accordion allowMultipleExpanded allowZeroExpanded>
                    { sortedItems.map( (stream, i) => accordionItem(stream, i+1))}
                </Accordion>}

                {/*this is only a wrapper with configuration, the real html for dialog placed in PageContainer class*/}
                {compState.isDialogOpen && <DialogWrapper idParam={'modal-root'}>
                    {showDialogByType()}
                </DialogWrapper>}
                {keywordState.isDialogOpen && <DialogWrapper idParam={'modal-root'}>
                    {keywordDialog()}
                </DialogWrapper>}
            </>
        )
    }

    useEffect(()=>{
        if (compState.success || compState.error){
            const timer = setTimeout(() => {
                setCompState({...compState, error: false, success: false})
            }, 4000);
            return () => clearTimeout(timer);
        }
        if (keywordState.success || keywordState.error){
            const timer = setTimeout(() => {
                setKeywordCompState({...keywordState, error: false, success: false})
            }, 4000);
            return () => clearTimeout(timer);
        }
        if (compState.streams.length === 0 && initialState.streams.length >= 1){
            setCompState({...compState, streams: initialState.streams})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initialState.streams, compState.streams, compState.error, compState.success, keywordState.success, compState.dialogConfig])

    return renderList(compState.streams || initialState.streams)
}