import getEnvConfig, { EnvConfig } from './config'
export default class Adapter {
  uri: string
  config:EnvConfig

  constructor(uri: string) {
    if (window.location.host === 'localhost'){
      this.uri = uri && 'http://' + window.location.host + `:${uri}`
    } else {
      this.uri = uri && 'http://' + window.location.host.replace(':3000', `:${uri}`)
    }
    this.config = getEnvConfig()
  }
  async getJson(route: string): Promise<any> {
    const url = this.createURL(route)

    // TODO: clean up
    return fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // 'same-origin', // include, *same-origin, omit
      headers: new Headers({
        'Authorization': this.config.AUTHORIZATION_HEADER,
        'Accept': "application/json",
        'Content-Type': "application/json",
      }),
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    ).then(response => {
      // console.debug(`Server send response`, response)
      return response.json()
    }).catch(error => {
      console.error(`Error while receiving data from server`, error)
    })
  }

  async modifyJson(route: string, body: any, method: "POST" | "PUT" | "PATCH"): Promise<any> {
    const url = this.createURL(route)

    return fetch(url, {
          method: method, // *GET, POST, PUT, DELETE, etc.
          headers: new Headers({
            'Authorization': this.config.AUTHORIZATION_HEADER,
            'Accept': "application/json",
            'Content-Type': "application/json",
          }),
          body: JSON.stringify(body)
        }
    ).then(response => {
      // console.debug(`Server send response`, response)
      return response.json()
    }).catch(error => {
      console.error(`Error while posting data to server`, error)
    })
  }

  async deleteJson(route: string): Promise<any> {
    const url = this.createURL(route)

    return fetch(url, {
          method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
          headers: new Headers({
            'Authorization': this.config.AUTHORIZATION_HEADER,
            'Accept': "application/json",
            'Content-Type': "application/json",
          })
        }
    ).then(response => {
      // console.debug(`Server send response`, response)
      return response.json()
    }).catch(error => {
      console.error(`Error while deleting data from server`, error)
    })
  }

  async downloadFile(route:string, fileName:string): Promise<void> {
      const url = this.createURL(route)
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Authorization': this.config.AUTHORIZATION_HEADER,
        })
      })

      if (response.ok) {
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = fileName.replaceAll('.', ' '); // dots can brake the file type
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }else {
        throw new Error(`${response.status}`)
      }
  }

  private createURL(path: string): string {
    return `${this.uri}${path}`
  }
}