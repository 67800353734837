export default class RandomNumberGenerator {
  lastSeed: number;


  constructor(seed: number) {
    this.lastSeed = seed
  }

  random(): number {
    const x = Math.sin(this.lastSeed++) * 10_000;
    return x - Math.floor(x)
  }

  averagedRandom(n: number): number {
    let sumOfRandomNumbers = 0
    for (let index = 0; index < n; index++) {
      sumOfRandomNumbers += this.random()
    }
    const average =  sumOfRandomNumbers / n
    return average
  }

  randomBetween(min: number, max: number): number {
    return RandomNumberGenerator.scaleAndTranslate(this.random(), min, max)
  }

  randomInteger(min: number, max: number): number {
    return Math.floor(RandomNumberGenerator.scaleAndTranslate(this.random(), min, max))
  }

  averagedRandomBetween(min: number, max: number, n: number): number {
    const average = this.averagedRandom(n)
    return RandomNumberGenerator.scaleAndTranslate(average, min, max)
  }

  private static scaleAndTranslate(value: number, min: number, max: number): number {
    return min + value * (max - min);
  }
}