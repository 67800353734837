import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import SecurityUtil from '../utils/SecurityUtil';

type IdParam = { id: string; };

export interface RouteWithIdProps extends RouteComponentProps<IdParam> {
}

export abstract class RouteWithIdComponent<P extends RouteWithIdProps, S> extends React.Component<P, S> {
  getSafeId(): string {
    return SecurityUtil.getSafeUrlParamOrThrow( this.props.match.params.id )
  }
}
