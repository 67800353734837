import { ModalFormConfig } from '../../components/F2CFormGenerator/types'
import { t } from 'i18next'
import moment from 'moment/moment'
import { getCleanedSegmentsList, getStreamList } from '../../helpers/dataForFormElements'
import { chooseOption } from '../fundEditModal/fundModalConfig'
import { ALL_STREAMS } from '../../pages/backoffice/press-releases/forms/form-types'

type option = {
    name: string
    value: string
}
export const allStreamsValue = {
    name: t('table:allStreams'),
    id: ALL_STREAMS,
}

export const getPressReleaseConfig = async (): Promise<ModalFormConfig> => {
    const segments = await getCleanedSegmentsList()
    const streams = await getStreamList()
    const modifiedStreams = streams.reduce<option[]>((acc, val) => {
         if(val) {
             acc.push({
                 value: val.name,
                 name: val.name
             })
         }
        return acc
    }, [])
    modifiedStreams.unshift({
        value:chooseOption,
        name: chooseOption
    },{
        value: allStreamsValue.id,
        name:allStreamsValue.name
    })
    return {
        lines: [
            {
                index: 1,
                columns: [
                    {
                        colIndex: 1,
                        columnElements: [
                            {
                                value: 'Recherche Schubkraft',
                                label: t('table:source'),
                                dataField: 'source',
                                inputType: 'text',
                                disabled: true
                            },
                        ],
                    },
                    {
                        colIndex: 2,
                        columnElements: [
                            {
                                value: moment().format('YYYY-MM-DD'),
                                label: t('table:releaseOn'),
                                dataField: 'published',
                                inputType: 'date',
                                limitDate: moment().format('YYYY-MM-DD')
                            },
                        ],
                    },
                ],
            },
            {
                index: 2,
                columns: [
                    {
                        colIndex: 3,
                        columnElements: [
                            {
                                value: '',
                                label: t('table:title'),
                                dataField: 'title',
                                inputType: 'text',
                                maxLength: 140
                            },
                        ],
                    },
                ],
            },
            {
                index: 3,
                columns: [
                    {
                        colIndex: 4,
                        columnElements: [
                            {
                                value: '',
                                label: t('FP:RP-FP-DescTitel'),
                                dataField: 'summary',
                                inputType: 'textarea',
                                maxLength: 240
                            },
                        ],
                    },
                ],
            },
            {
                index: 4,
                columns: [
                    {
                        colIndex: 5,
                        columnElements: [
                            {
                                value: [],
                                label: t('FP:RP-FP-FSeg'),
                                dataField: 'segment',
                                inputType: 'multiselect',
                                multiselectOptions: segments
                            },
                        ],
                    },
                    {
                        colIndex: 55,
                        columnElements: [
                            {
                                value: '',
                                label: t('table:streams'),
                                dataField: 'stream',
                                inputType: 'dropdown',
                                options: modifiedStreams
                            },
                        ],
                    },
                ],
            },
            {
                index: 5,
                columns: [
                    {
                        colIndex: 6,
                        columnElements: [
                            {
                                value: '',
                                label: t('table:fpNumber'),
                                dataField: 'fund_number',
                                inputType: 'number',
                            },
                        ],
                    },
                ],
            },
            {
                index: 6,
                columns: [
                    {
                        colIndex: 7,
                        columnElements: [
                            {
                                value: '',
                                label: t('table:comment'),
                                dataField: 'comment',
                                inputType: 'textarea',
                            },
                        ],
                    },
                ],
            },
            {
                index: 7,
                columns: [
                    {
                        colIndex: 8,
                        columnElements: [
                            {
                                value: '',
                                label: t('table:url'),
                                dataField: 'url',
                                inputType: 'text',
                            },
                        ],
                    },
                ],
            },
        ]
    }
}