import styled from 'styled-components/macro'
import { MainTheme } from '../../../theme/MainTheme'


const SafeHtmlOuter = styled('div')<any>`
  font-size: ${MainTheme.typography.default.fontSize};
  line-height: ${MainTheme.typography.default.lineHeight};
`


export default SafeHtmlOuter