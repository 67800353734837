import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from '../../../icons/plus-icon.svg'
import {
  ScaleTable,
  ScaleNotificationMessage,
} from '@telekom/scale-components-react'
import { RSSFeedHeader } from './rssFeeds-header'
import { RSSFeedRow } from './rssFeeds-row'
import {FormField} from '../../../model/form-field';

import { RSSFeed } from '../../../model/RSSFeed';
import BackendFactory from '../../../backend/BackendFactory'
import { DialogWrapper } from '../../../components/modal-dialog/dialog-wrapper'
import { ModalDialog } from '../../../components/modal-dialog/modal-dialog'
import { useOutsideCloser } from '../../../components/modal-dialog/outside-closer-utility'
import { AlertWithActions } from '../notifications/alert-with-actions'
import { RSSFeedForm } from './form/create-form';

interface Props {
  rssFeeds: RSSFeed[]
}

interface ComponentState {
    isDialogOpen: boolean
    dialogConfig: {
        type: string
        title?: string
        content?: FormField[] | string
    },
    model: RSSFeed
    rssFeeds: RSSFeed[]
    error?: boolean
    success?: boolean
    message?: string
    id?: string
    sortingDirection: string
    activeRow?: string
}

export const RSSFeedTable = ({rssFeeds}: Props) => {
  const { t } = useTranslation(["BOFundTranslationKeys"])
    // const newModalContent = NewModalContent
    const initialState: ComponentState  = {
        isDialogOpen: false,
        dialogConfig:{
            type: '',
            // content: '',
            title: '',
        },
        model: {id: '', source: '', url: '', link_type: '', is_active: false},
        sortingDirection: '-is_active',
        rssFeeds
    }

  const [compState, setCompState] = useState(initialState)

  const wrapperRef = useRef(null)

  const messages = [
    { type: 'Gelöscht', text: t("BOFundTranslationKeys:rssFeedDeleteTextSuccess") },
    { type: 'create', text: t("BOFundTranslationKeys:rsssFeedCreateSuccess") },
    { type: 'error', text: t("BOFundTranslationKeys:rssFeedCreateError") },
  ]

  const onSortingAction = async (params: string, rssFeedId?: string) => {
    
    await BackendFactory.produce()
      .getBORSSFeedOrderBy(params)
      .then((res) => {
        return setCompState({
          ...compState,
          //@ts-ignore
          rssFeeds: res.links,
          sortingDirection: params,
          activeRow: rssFeedId
        })
      })
  }
  
  const onDeleteRSSFeed = (rssFeedId: string) => {
    BackendFactory.produce().deleteBORSSFeed(rssFeedId).then(res => {
      if (!res || Object.getOwnPropertyNames(res).length === 0
          || res !== 'Object deleted') {
          setCompState({
              ...compState,
              model: initialState.model,
              error: true,
              success: false,
              isDialogOpen: false,
              message: String(t("BOFundTranslationKeys:rssFeedDeleteTextError")),})
              closeDialog()
      } else {
          BackendFactory.produce().getBORSSFeedOrderBy(compState.sortingDirection)
              .then(res =>  setCompState({
                ...initialState,
                //@ts-ignore
                rssFeeds: res.links,
                error: false,
                success: true,
                message: String(t("BOFundTranslationKeys:rssFeedDeleteTextSuccess")),
              }) )

              closeDialog()
      }
  })
  }

  const onToggleActive = (active: boolean, rssFeedId: string) => {
    BackendFactory.produce()
      .patchRSSFeed({ is_active: active }, rssFeedId)

   onSortingAction(compState.sortingDirection, rssFeedId)

  }

  const openCreateDialog = () => {
    // manipulation with real dom node to show cover
    document.getElementsByClassName('App')[0].classList.add('with-modal-opened')

    // set up a dialog content
    setCompState({
      ...compState,
      model: initialState.model,
      isDialogOpen: true,
      dialogConfig: {
        type: 'create',
        title: 'RSS Feed',
        content: ''
      },
    })
  }

  const openAlertDeleteDialog = (rssFeedId: string) => {
    // manipulation with real dom node to show cover
    document.getElementsByClassName('App')[0].classList.add('with-modal-opened')
    // set up a dialog content
    setCompState({
      ...compState,
      isDialogOpen: true,
      dialogConfig: {
        type: 'alert-delete',
        content: String(t("BOFundTranslationKeys:alertContentDeleteRssFeedsText")),
        title: String(t("BOFundTranslationKeys:alertTitleDeleteRssFeedsText")) ,
      },
      id: rssFeedId,
      activeRow: rssFeedId,
    })
  }

  const alertDialogDeleteContent = (content: string, rssFeedId: string) => {
    return (
      <AlertWithActions
        content={content}
        type={'delete'}
        onClose={() => closeDialog()}
        onAction={() =>  onDeleteRSSFeed(rssFeedId)}
      />
    )
  }

  const createFormContent = (model: RSSFeed) => {
    return <RSSFeedForm 
    model={model}
    onClose={()=>closeDialog()}
    onSubmit={(type, rssFeedId) => onSubmit(type, rssFeedId)}
    />
  }

  const onSubmit = (type?: string, rssFeedId?: string) => {
    const message = messages.find( msg => msg.type === type)?.text
    const error = type === 'error'

    BackendFactory.produce()
      .getBORSSFeedOrderBy(compState.sortingDirection)
      .then((res) =>
        setCompState({
          ...compState,
          isDialogOpen: false,
          dialogConfig: initialState.dialogConfig,
          //@ts-ignore
          rssFeeds: res.links,
          success: !error,
          error: error,
          message: message,
          activeRow: rssFeedId,
        })
      )

    closeDialog()
  }

  const closeDialog = () => {
    // TODO - Move this to helper folder
    document.getElementsByClassName('App')[0].classList.remove('with-modal-opened')
    document.getElementsByClassName('dialog-container')[0].remove()

    setCompState({
      ...compState,
      isDialogOpen: false,
      dialogConfig: initialState.dialogConfig,
    })
  }

  const showDialogByType = () => {
    let contentConfig: FormField[] | string = []
    let content
    let textContent

    if (compState.dialogConfig.type === 'alert-delete') {
      contentConfig = compState.dialogConfig.content as string
      textContent = compState.id ? (
        alertDialogDeleteContent(contentConfig, compState.id)
      ) : (
        <div>Fehler</div>
      )
    } else if(compState.dialogConfig.type === 'create') {
      content = createFormContent(compState.model)
    }

    return (
      <div ref={wrapperRef} className="modal-wrapper">
         <ModalDialog
            title={compState.dialogConfig.title}
            type={compState.dialogConfig.type}
            formContent={content}
            textContent={textContent}
            isOpened = {compState.isDialogOpen}
            onClose={()=>closeDialog()}
            noScrollbar={['alert-delete', 'create'].includes(compState.dialogConfig.type)}
        />
      </div>)
  }

  useOutsideCloser(wrapperRef, closeDialog)

  const setActiveRow = (id: string) => {
    setCompState({
      ...compState,
      activeRow: id
    })
  }

  const renderList = (rssFeeds: RSSFeed[]) => {
    const title = (!rssFeeds || rssFeeds.length !== 1) ? 'RSS Feeds' : 'RSS Feed'
    const rssFeedNumber = rssFeeds ? rssFeeds.length : '0'
    
    return (
      <>
        <div
          className="notification-messages-container"
          data-testid="toast-message"
        >
          {compState.error && (
            <ScaleNotificationMessage variant={'error'} dismissible opened>
              {compState.message}
            </ScaleNotificationMessage>
          )}
          {compState.success && (
            <ScaleNotificationMessage variant={'success'} dismissible opened>
              {compState.message}
            </ScaleNotificationMessage>
          )}
        </div>
        <div className="backoffice-title">
          <span className="publications-title">
            {rssFeedNumber} {title}
          </span>
          <button className="action-button action-button--magenta" onClick={openCreateDialog}> <PlusIcon /> {t("BOFundTranslationKeys:addRSS")}</button>
        </div>

        <ScaleTable size={'medium'} className="publications-table">
          <table>
            <thead>
            <RSSFeedHeader onSortingAction={onSortingAction}  sortParams={compState.sortingDirection} />
            </thead>
            <tbody>
              {rssFeeds && rssFeeds.map((feed) => (
                <RSSFeedRow
                  key={feed.id}
                  rssFeeds={feed}
                  openAlertDeleteDialog={openAlertDeleteDialog} 
                  setActiveRow={setActiveRow}
                  active={compState.activeRow === feed.id}
                  onToggleActive={onToggleActive}
                />
              ))}
            </tbody>
          </table>
        </ScaleTable>

        {compState.isDialogOpen && (
          <DialogWrapper idParam={'modal-root'}>
            {showDialogByType()}
          </DialogWrapper>
        )}
      </>
    )
  }

  useEffect(() => {
    if (compState.success || compState.error) {
      const timer = setTimeout(() => {
        setCompState({ ...compState, error: false, success: false })
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [
    compState,
    initialState.rssFeeds,
    compState.rssFeeds,
    compState.error,
    compState.success,
    compState.dialogConfig,
  ])
  
  let data = compState.rssFeeds || rssFeeds

  return renderList(data)
}
