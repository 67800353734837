import React from 'react'
import styled from 'styled-components/macro'

const DetailPageTitle = styled('h1')`
  display: block;
  margin-top: 0;
  margin-bottom: ${props => props.theme.spacings.large.gutter};
  font-size: ${props => props.theme.typography.detailPage.title.fontSize.mobile};
  font-weight: ${props => props.theme.typography.detailPage.title.fontWeight};

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    font-size: ${props => props.theme.typography.detailPage.title.fontSize.static};
  }
`

export default React.memo(DetailPageTitle)