import styled from 'styled-components/macro'

const CardsCol = styled('div')`
  @media (min-width: ${props => props.theme?.breakpoints?.tablet}) {
    width: 50%;
    padding-right: 8px;
    padding-left: 8px;
  }

  @media (min-width: ${props => props.theme?.breakpoints?.desktopXXl}) {
    padding-right: 24px;
    padding-left: 24px;
  }
`

export default CardsCol