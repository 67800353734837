import React from 'react'

import * as types from '../../types'

import { ScaleCard } from '@telekom/scale-components-react'

import CardInner from './__styled__/CardInner'
import CardTitle from './__styled__/CardTitle'
import CardValue from './__styled__/CardValue'

type Props = {
	cardTitle?: string
	cardValue?: string
	linkTitle?: string
	linkType?: types.LinkType
	cardType?: types.CardColor
	children?: React.ReactNode
}

const Card = ({
	cardTitle,
	cardValue,
	cardType = 'default',
	children
}: Props) => {
	return (
		<CardInner cardType={cardType} className='card__inner'>
			<ScaleCard>

				{ cardTitle ? (
					<CardTitle>
						{cardTitle}
					</CardTitle>
				) : null }

				{ cardValue ? (
					<CardValue>
						{cardValue}
					</CardValue>
				) : null }

				{children}
			</ScaleCard>
		</CardInner>
	)
}

export default React.memo(Card)
