// TODO: Use correct abbreviations according to language
const ABBREVIATION_MILLIONS = 'Mio.'
const ABBREVIATION_BILLIONS = 'Mrd.'
const ABBREVIATION_TRILLIONS = 'Bio.'
const THOUSAND_SEPARATOR = '.'
const NON_BREAKING_SPACE = '\u00a0'


export default class NumberFormatter {
  static prettifyEuro(value: number): string {
    const prettifiedNumber = NumberFormatter.prettifyBigNumber(value)
    return prettifiedNumber + NON_BREAKING_SPACE + '€'
  }

  static prettifyBigNumber(value: number): string {
    const isNegative = value < 0
    const unsignedValue = isNegative ? -value : value
    const formattedUnsignedNumber = NumberFormatter.prettifyUnsignedBigNumber(unsignedValue)

    if (isNegative) {
      return `- ${formattedUnsignedNumber}`
    } else {
      return formattedUnsignedNumber
    }
  }

  static prettifyUnsignedBigNumber(unsignedValue: number): string {
    unsignedValue = Math.floor(unsignedValue)

    let base = Math.floor( Math.log( unsignedValue ) / Math.log( 1000 ) )

    const condition = Math.round( unsignedValue / ( 1000 ** base ) )
    if (condition >= 1000) {
        base += 1
    }

    if (base >= 4) {
      const trillions = Math.round( unsignedValue / ( 1000 ** 4 ) )
      return `${trillions} ${ABBREVIATION_TRILLIONS}`
    } else if (base >= 3) {
      const billions = Math.round( unsignedValue / ( 1000 ** 3 ) )
      return `${billions} ${ABBREVIATION_BILLIONS}`
    } else if (base >= 2) {
      const millions = Math.round( unsignedValue / ( 1000 ** 2 ) )
      return `${millions} ${ABBREVIATION_MILLIONS}`
    } if (base >= 1) {
      const formattedNumber = numberWithCommas( unsignedValue )
      return `${formattedNumber}`
    }

    return `${unsignedValue}`
  }
}

// TODO: implement option to use "." for other countries
export function numberWithCommas(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_SEPARATOR)
}