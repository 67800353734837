export const BREAKPOINTS = {
  tablet: 640,
  tabletMax: 1039,
  laptop: 1040,
  laptopMax: 1295,
  desktop: 1296,
  desktopMax: 1551,
  desktopXl: 1552,
  desktopXlMax: 1647,
  desktopXXl: 1648,
} as const

export const Breakpoints = {
  tablet: `${BREAKPOINTS.tablet}px`,
  tabletMax: `${BREAKPOINTS.tabletMax}px`,
  laptop: `${BREAKPOINTS.laptop}px`,
  laptopMax: `${BREAKPOINTS.laptopMax}px`,
  desktop: `${BREAKPOINTS.desktop}px`,
  desktopMax: `${BREAKPOINTS.desktopMax}px`,
  desktopXl: `${BREAKPOINTS.desktopXl}px`,
  desktopXlMax: `${BREAKPOINTS.desktopXlMax}px`,
  desktopXXl: `${BREAKPOINTS.desktopXXl}px`,
} as const
