import React from 'react'
import { NavLink } from 'react-router-dom'
import { ScaleTabHeader, ScaleTabNav, ScaleTabPanel } from '@telekom/scale-components-react'
import { Translation } from 'react-i18next'
import i18next from 'i18next'
import { KeyValuePair } from '../../../model/KeyValuePair'

import { KeyValueList } from '../KeyValueList/KeyValueList'
import './info-tabs.scss'
import SafeHtml from '../../../components/safe-html/SafeHtml'
import { Link, Office } from '../../../model/FundMapped'
import { ReactComponent as EditIcon } from '../../../icons/edit-icon.svg'
import Backend from '../../../backend/Backend'
import BackendFactory from '../../../backend/BackendFactory'

const SLOT_NAMES = {
  headings: 'headings',
  content: 'content',
  tab: 'tab',
  panel: 'panel'
}

interface Props {
  description?: string
  details?: KeyValuePair[]
  fundOffice?: Office
  fundLinks?: Link[]
  fundId?:string
}


export default class InfoTabs extends React.Component<Props, any> {
  backend: Backend = BackendFactory.produce()
  private addClickCount() {
    const backend: Backend = BackendFactory.produce()
    backend.addViewToElement('Fund details page: details tab clicked')
  }

  render() {
    const currentPage = window.location.pathname.split('/')[1]
    return (<div className='info-tabs'>
      <NavLink title={String(i18next.t("FP:RP-FP-FP-EditB"))} to={`/backoffice/funds?tab=published&&fundId=${this.props.fundId}&pageSrc=${currentPage}`} target="_blank" className="action-button tabs-edit-button ">
        <EditIcon />
      </NavLink>
      <ScaleTabNav>
        <ScaleTabHeader slot={SLOT_NAMES.tab} key='header-1'>
          <Translation>
            {
              (t) =>  t("FP:RP-FP-DescTitel")
            }
          </Translation>
        </ScaleTabHeader>
        <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-1' class='panel'>
          {this.props.description ? (
            <div className='description'>
              <SafeHtml __html={this.props.description} refineOutsideLinks={true}/>
            </div>
          ) : (
            <Translation>
              {
                (t) =>  <p className="radar-paragraph">{t("details:noDescription")}</p>
              }
            </Translation>
            
          )}
        </ScaleTabPanel>
        <ScaleTabHeader onClick={this.addClickCount} slot={SLOT_NAMES.tab} key='header-2'>
          <Translation>
              {
                (t) =>  t("FP:RP-FP-DetailTitel")
              }
          </Translation>
        </ScaleTabHeader>
        <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-2' class='panel'>
          {this.props.details ? (
            <KeyValueList entries={this.props.details} />
          ): (
            <Translation>
              {
                (t) => <p className="radar-paragraph">{t("details:noDetails")}</p>
              }
            </Translation>
          )}
        </ScaleTabPanel>
      </ScaleTabNav>
    </div>)
  }
}