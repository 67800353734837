import BackendFactory from '../../../backend/BackendFactory'
import { FilterOption } from '../../../components/F2CFilter/F2CFilter'
import { DEFAULT_VALUE, getGeoAreasList } from '../../../components/F2CFilter/commonFilterOptions'
import i18next from 'i18next'
import { phaseKeys } from '../../../utils/phaseTranslation'
import { HIGHLIGHT } from '../../../constants/BEstatuses'

export const getFundsPageFilters = async ():Promise<FilterOption[]> => {
  const backend = BackendFactory.produce();
  const streamList = await backend.getStreams().then(streams => {
    return streams.map(stream => {
      return {
        label: stream.name,
        val: stream.name
      }
    })
  });
  const geoData = await getGeoAreasList()
  const fundsFilters:FilterOption[] = [
    {
      name: i18next.t('BO:BO-FP-RFPStream'),
      urlParam: 'primaryStream',
      filterLabel: 'stream',
      values: [...streamList, DEFAULT_VALUE],
      id:0,
    },
    {
      name: i18next.t('BO:BO-FP-RFPPhase'),
      urlParam: 'maturity',
      filterLabel: 'phase',
      values: [
        ...phaseKeys,
        DEFAULT_VALUE,
      ],
      id: 1
    },
    {
      name: i18next.t('F:RP-F-FPArea'),
      urlParam: 'geoArea',
      filterLabel: 'Geo',
      values: [
        ...geoData,
        DEFAULT_VALUE,
      ],
      id: 2
    }
    ,
    {
      name: i18next.t('BO:BO-FP-RFPHigh'),
      urlParam: 'status',
      filterLabel: 'status',
      values: [
        {label: i18next.t('BOFundTranslationKeys:statusOptionNoChange'), val: HIGHLIGHT.NO_CHANGE},
        {label: i18next.t('BOFundTranslationKeys:statusOptionNewFP'), val: HIGHLIGHT.NEW_FUNDING_PROGRAM},
        {label: i18next.t('BOFundTranslationKeys:statusOptionContentUpdated'), val: HIGHLIGHT.CONTENT_UPLOADED},
        {label: i18next.t('BOFundTranslationKeys:statusOptionObserveDeadlines'), val: HIGHLIGHT.NOTE_DEADLINES},
        DEFAULT_VALUE,
      ],
      id: 3
    },
    {
      name: i18next.t('BO:BO-FP-RFP1P'),
      urlParam: 'onePager',
      filterLabel: 'pager',
      values: [
        {label: i18next.t('BOFundTranslationKeys:statusOptionNotMandatory'), val: 'nicht erforderlich'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionNecessary'), val: 'erforderlich'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionRequested'), val: 'angefordert'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionAvailable'), val: 'vorhanden'},
        DEFAULT_VALUE,
      ],
      id: 4
    },
    {
      name: i18next.t('BO:BO-FP-NFPC5'),
      urlParam: 'internalStatus',
      filterLabel: 'intStatus',
      values: [
        {label: i18next.t('BOFundTranslationKeys:statusOptionNew'), val: 'neu'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionInProgress'), val: 'in Bearbeitung'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionForProcessing'), val: 'zur Bearbeitung'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionConsultation'), val: 'Rückfrage'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionForRelease'), val: 'zur Veröffentlichung'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionExternalReady'), val: 'extern-fertig'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionPublished'), val: 'veröffentlicht'},
        {label: i18next.t('BOFundTranslationKeys:statusOptionNotRelevant'), val: 'nicht relevant'},
        DEFAULT_VALUE,
      ],
      id: 5
    }
  ]
  return fundsFilters

};