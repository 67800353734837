import React, { useEffect, useRef, useState } from 'react'
import { ItemsListForSettingsPage, SettingsElement } from '../../../backend/respType'
import { ModalActions } from '../../../components/F2CFormGenerator/types'
import Backend from '../../../backend/Backend'
import BackendFactory from '../../../backend/BackendFactory'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import styles from './PageContent.module.scss'
import { ScaleButton } from '@telekom/scale-components-react'
import { F2CAccordion } from '../../../components/F2CAccordion/F2CAccordion'
import { AlertModal } from './AlertModal'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { settingsFormConfig } from '../../../env/settingsPortfolio/portfolioFormConfig'
import { segmentsFormSchema } from '../../../env/settingsPortfolio/portfolioValidationSchema'

export const Portfolio = () => {
    const initTag: SettingsElement = { name: '', id: '', email: '', affectedFunds: [], subList: [] }
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)
    const [currentPortfolio, setCurrentPortfolio] = useState<SettingsElement>(initTag)
    const [portfolioList, setPortfolioList] = useState<ItemsListForSettingsPage>()
    const backend: Backend = BackendFactory.produce()
    const toast = useRef<Toast>(null)
    const { t } = useTranslation('SettingsPage')

    useEffect(() => {
        updatePortfolioList()
    }, [])
    
    const updatePortfolioList = (): void => {
        backend.getItemsListForSettingsPage('portfolio').then(resp => {
            if(resp.data) {
                setPortfolioList(resp)
            }else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('SettingsPage:portfolioListError'),
                    life: 5000,
                })
            }
        })
    }

    const openModal = (modalType: ModalActions, geoAreaContact: SettingsElement): void => {
        setModal(modalType)
        setCurrentPortfolio(geoAreaContact)
    }

    const closeModal = () => {
        setModal(ModalActions.REMOVE)
        setCurrentPortfolio(initTag)
    }

    const createPortfolio = (data: SettingsElement) => {
        backend.createElementForSettingsPage('portfolio',{
            affectedFunds: [], id: '', 
            subList: [],
            name:data.name
        }).then(resp => {
            if (resp?.detail === 'SUCCESS') {
                updatePortfolioList()
                setModal(ModalActions.REMOVE)
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('SettingsPage:portfolioCreateSuccess'),
                    life: 5000,
                })
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:portfolioCreateError'), life: 5000 })
            }
        })
    }

    const editPortfolio = (formData: SettingsElement) => {
        backend.editElementInSettingsPage('portfolio', formData)
            .then(resp => {
                if (resp?.detail === 'SUCCESS') {
                    updatePortfolioList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('SettingsPage:portfolioEditSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:portfolioEditError'), life: 5000 })
                }
            })
    }

    const deletePortfolio = (): void => {
      backend.deleteElementFromSettingsPage('portfolio', currentPortfolio.id)
          .then((resp) => {
              if (resp?.detail === 'SUCCESS') {
                  updatePortfolioList()
                  setModal(ModalActions.REMOVE)
                  toast.current?.show({
                      severity: 'success',
                      summary: 'Success',
                      detail: t('SettingsPage:portfolioDeletedSuccess'),
                      life: 5000,
                  })
              } else {
                  toast.current?.show({
                      severity: 'error',
                      summary: 'Error',
                      detail: t('SettingsPage:portfolioDeletedError'),
                      life: 5000,
                  })
              }
          })

  }

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.settingsHead}>
                <div>
                    <h1>{t('SettingsPage:BO-Set-Item4-titel')}</h1>
                    <h2>{t('SettingsPage:BO-Set-Item4-sub')}</h2>
                </div>
                <ScaleButton
                    variant='primary'
                    onClick={() => openModal(ModalActions.CREATE, initTag)}
                >
                    {t('SettingsPage:BO-Set-Item4-new')}
                </ScaleButton>
            </div>

            {portfolioList &&
              <F2CAccordion data={portfolioList.data}
                            onItemEdit={item => openModal(ModalActions.EDIT, item as SettingsElement)}
                            onItemDelete={item => {
                                openModal(ModalActions.ALERT_DELETE, item as SettingsElement)
                            }}
              />}
            <AlertModal isOpen={modal === ModalActions.ALERT_DELETE || modal === ModalActions.ALERT}
                        title={t('SettingsPage:BO-Set-Item4-titel')}
                        modal={modal}
                        onClose={() => closeModal()}
                        onAccept={() => deletePortfolio()}
                        currentContact={currentPortfolio}
            />
            <F2CFormGenerator isOpened={modal === ModalActions.EDIT || modal === ModalActions.CREATE}
                              modalType={modal}
                              modalTitle={t('SettingsPage:BO-Set-Item4-titel')}
                              onClose={() => {
                                  closeModal()
                              }}
                              onValidationCreate={(formData) => {
                                  createPortfolio(formData as SettingsElement)

                              }}
                              onEdit={(formData) => {
                                  const updatedGeoArea = {
                                      ...currentPortfolio,
                                      ...formData
                                  }
                                  editPortfolio(updatedGeoArea)
                              }}
                              formConfig={settingsFormConfig}
                              initialFormValues={currentPortfolio}
                              validationSchema={segmentsFormSchema}
            />
            <Toast ref={toast} position='bottom-right' />
        </div>
    )
}