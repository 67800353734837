import React from "react";

export type RadarButtonType = 'next' | 'previous'

export interface RadarButtonProps {
  type: RadarButtonType
  deactivated?: boolean
  onClick?: () => void
}

interface RadarButtonState {
  pressed: boolean
}

const BUTTON_PATHS = {
  next: `M23.001,54.5c-0.384,0-0.768-0.146-1.061-0.439c-0.586-0.586-0.586-1.535,0-2.121L41.88,32L21.94,12.061 c-0.586-0.586-0.586-1.535,0-2.121c0.586-0.586,1.535-0.586,2.121,0l21,21c0.586,0.586,0.586,1.535,0,2.121l-21,21 C23.769,54.354,23.385,54.5,23.001,54.5z`,
  previous: `M41,9.5c0.384,0,0.768,0.146,1.061,0.439c0.586,0.586,0.586,1.535,0,2.121L22.121,32l19.94,19.939c0.586,0.586,0.586,1.535,0,2.121c-0.586,0.586-1.535,0.586-2.121,0l-21-21c-0.586-0.586-0.586-1.535,0-2.121l21-21C40.232,9.646,40.616,9.5,41,9.5z`,
} as const

export default class RadarButton extends React.Component<RadarButtonProps, RadarButtonState> {
  constructor(props: RadarButtonProps) {
    super(props)

    this.state = {
      pressed: false
    }
  }

  renderDeactivatedState() {
    return (
      <div
        className='radar-button radar-button--inactive'
        onClick={(e) => {RadarButton.onNothing(e)}}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
          <path fill="none" d="M0 0H64.001V64H0z"/>
          <path fill={'#26262615'} d={this.props.type === 'next' ? BUTTON_PATHS.next : BUTTON_PATHS.previous} />
        </svg>
      </div>
    )
  }

  renderActiveState() {
    return (
      <div
        className='radar-button radar-button--active'
        onMouseDown={(e) => {this.onPress(e)}}
        onMouseUp={(e) => {this.onClick(e)}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
          <path fill="none" d="M0 0H64.001V64H0z"/>
          <path className='radar-button__path' d={this.props.type === 'next' ? BUTTON_PATHS.next : BUTTON_PATHS.previous} />
        </svg>
      </div>
    )
  }

  render() {
    if (this.props.deactivated) {
      return this.renderDeactivatedState()
    } else {
      return this.renderActiveState()
    }
  }

  private onPress(event: React.MouseEvent<any, any>) {
    event.preventDefault()
    this.setState({
      pressed: true,
    })
  }

  private onClick(event: React.MouseEvent<any, any>) {
    event.preventDefault()
    this.setState({
      pressed: false
    })

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  private static onNothing(event: React.MouseEvent<any, any>) {
    event.preventDefault()
  }
}