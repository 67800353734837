import { RouteWithIdComponent, RouteWithIdProps } from '../../routing/RouteWithIdComponent'
import { ScaleCard } from '@telekom/scale-components-react'

import { t } from 'i18next'
import { withTranslation, Translation } from 'react-i18next'

import FundMapped from '../../model/FundMapped'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'

import Progressbar from '../../components/Progressbar/Progressbar'
import Section from '../../components/Section'

import DescriptionBox from './DescriptionBox'
import DetailPageTitle from './DetailPageTitle'
import InfoTabs from './InfoTabs'
import PrimaryStream from './PrimaryStream'
import './detailPage.module.scss'
import ObjectUtil from '../../utils/ObjectUtil'
import BreadcrumbsElem, {Link} from '../../components/breadcrumbs/breadcrumbsElem'
import ContactInformation from './ContactInformation/ContactInformation'
import { BREAKPOINTS } from '../../theme/styles/Breakpoints'
import DeadlineBadge from './DeadlineBadge'
import React from 'react'
import Stream from "../../model/Stream";
import { FUND_STATUS } from '../../constants/BEstatuses'


export interface DetailPageProps extends RouteWithIdProps {

}

interface DetailPageState {
  fund?: FundMapped,
  name?: string,
  stream?: Stream,
  streams?: Stream[],
  parentId?: string,
  view?: string,
  links?: Link[]
}
class DetailPage extends RouteWithIdComponent<DetailPageProps, DetailPageState> {
  backend: Backend

  constructor(props: DetailPageProps) {
    super(props)
    this.state = {}

    this.backend = BackendFactory.produce()

    this.updateSize = this.updateSize.bind(this)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize)
  }

  componentDidMount() {
    this.backend.addViewToElement('Page loaded: Fund details')
    this.updateSize()
    window.addEventListener('resize', this.updateSize)
    this.getStreamsAndFund()
  }

  getStreamsAndFund() {
    this.backend.getStreams().then((res)=> {
      this.setState({
        streams: res
      })
      this.getFund()
    })  
  }  
  
  getFund() {
    let linksArray: Link[]
    let parentHasSubstreams: boolean
    const { streams } = this.state

    this.backend.getMappedFund(this.getSafeId()).then((fund: FundMapped) => {
      this.setState({ fund })
      this.setTabTitle(fund)
      let primaryStream = {} as Stream 

      if (streams ){
        for (const stream of streams) {
          const subStreams = stream.sub_streams
          if (stream && subStreams && subStreams.length > 0){
            const substream = subStreams.find( sub => sub.name === fund.primaryStream)
            if (substream){
              parentHasSubstreams = subStreams.length > 1
              primaryStream = substream
            }
          }
        }
      }

      const parent = streams?.find((stream) => stream.name === primaryStream?.parent_name)

      this.setState({
        parentId: parent?.id
      })
      
      // Use primary name if no other substreams
      if (primaryStream && !parentHasSubstreams) {
        primaryStream.name = primaryStream.parent_name!
      }

      this.setState({
        stream: primaryStream
      })
    }).finally(() => {
      const {stream, fund, parentId} = this.state
      const parentName = stream?.parent_name || fund?.primaryStream

      this.props.location.pathname.search('more') > 0
        ? linksArray = [
            { href: '/', text: t("RP:RP-M1") },
            { href: `/second=${parentId}`, text: `${parentName}` },
            { href: `/third=${parentId}/${stream?.id}`, text: `${stream?.name_radar}` },
            { href: '/allocations', text: t("RP:RP-M2")  }]
        : linksArray = [
            { href: '/', text: t("RP:RP-M1")  },
            { href: `/second=${parentId}`, text: `${stream?.parent_name}` },
            { href: `/third=${parentId}/${stream?.id}`, text: `${stream?.name_radar}` },
          ]

      // Remove 'Sonstige' Substream link from breadcrumbs if there are no other substreams
      if (!parentHasSubstreams) {
        linksArray.splice(linksArray.length - 1, 1)
      }

      this.setState({links: linksArray})
    })
  }

  setTabTitle(fund: FundMapped) {
    // Set title for tab + print
    if (!document.title.includes(fund.name!)) {
      document.title = `${fund.name} | ${document.title}`
    }
  }

  updateSize() {
    const width = window.innerWidth
    let thisView
    if (width > BREAKPOINTS.tabletMax) {
      thisView = "desktop"
    }
    else {
      thisView = "mobile"
    }

    if (thisView !== this.state.view) {
      this.setState({
        view: thisView
      })
    }
  }

  render() {
    const {fund, stream, view, links} = this.state
    if (fund === undefined) {
      return (<></>)
    } 
    const {
      deadline, 
      fundingType, 
      geoArea, 
      fundingAmount, 
      fundingStatus,
      primaryStream, 
      maturity, 
      summary,
      office,
    } = fund

    let possibleDeadline
    if (deadline !== undefined) {
      possibleDeadline = ObjectUtil.formatDateObj(deadline!)
      possibleDeadline = "Bis " + possibleDeadline;
    }

    const mobile = !!(view && view === "mobile")
    const isFundingTypeEmpty = !fundingType ? "empty empty-fundingType" : "";
    const isGeoAreaEmpty = geoArea?.length === 0 ? "empty empty-geoArea" : "";
    const isDeadlineEmpty = !deadline ? "empty empty-deadline" : "";
    const isFundingAmountEmpty = !fundingAmount ? "empty empty-fundingAmount" : "";

    const isFundingTypeEmptySpanClass = !fundingType ? "empty-span" : "";
    const isGeoAreaEmptySpanClass = geoArea?.length === 0 ? "empty-span" : "";
    const isDeadlineEmptySpanClass = !deadline ? "empty-span" : "";
    const isFundingAmountEmptySpanClass = !fundingAmount ? "empty-span" : "";

    const fundingTypeDisplay = (fundingType && fundingType !== "") ? fundingType : t("details:noFundingType")
    return (
      <div className="detail-page">
        {links && links.length > 0
        && <BreadcrumbsElem linksArray={links}
                            current={{ href: `/detail/${this.state.fund?.id}`, text: `${this.state.fund?.name}` }} />}
        <Section isContent>
          {fundingStatus === FUND_STATUS.ARCHIVED &&
              <h2 className='archive-message'>{t('details:archivedFund')}</h2>
          }

          <PrimaryStream>
            {stream?.name || primaryStream}
          </PrimaryStream>


          <DetailPageTitle>
            {fund.name}
          </DetailPageTitle>

          <div className='content'>
            <div className='process col-flex-element'>
             
              <Progressbar maturity={maturity!} />

              <div className='key-info-panel'>
                <div className='col-flex-inner'>
                  <div className='col-flex-element'>
                    <ScaleCard className={`card-is-dark ${isFundingTypeEmpty}`}>
                      <Translation>
                        {(t) => <span className='scl-font-variant-body card-heading'>{t("FP:RP-FP-Type")}</span>}
                      </Translation>
                      
                      <span className={`scl-font-variant-body-large card-description ${isFundingTypeEmptySpanClass}`}>{fundingTypeDisplay}</span>
                    </ScaleCard>
                  </div>
                  <div className='col-flex-element'>
                    <ScaleCard className={`card-is-dark ${isGeoAreaEmpty}`}>
                      <Translation>
                        {(t) => <span className='scl-font-variant-body card-heading'>{t("F:RP-F-FPArea")}</span>}
                      </Translation>
                      {geoArea && geoArea.length > 0 ?
                          geoArea?.map((area, index) => <span className={`scl-font-variant-body-large card-description ${isGeoAreaEmptySpanClass}`}
                                                     key={area.id + index}>
                            {area.name}
                          </span>)
                          :
                          <Translation>
                            {(t) => <span
                                className={`scl-font-variant-body-large card-description ${isGeoAreaEmptySpanClass}`}>
                          {t('details:noGeoArea')}
                             </span>}
                          </Translation>
                      }


                    </ScaleCard>
                    </div>

                  <div className='col-flex-element'>
                    <ScaleCard className={`card-is-dark ${isDeadlineEmpty}`}>
                      <Translation>
                        {(t) => <span className='scl-font-variant-body card-heading'>{t("F:RP-D-FPDeadline")}</span>}
                      </Translation>
                      
                      <span className={`scl-font-variant-body-large card-description ${isDeadlineEmptySpanClass}`}>{possibleDeadline || t("details:noDeadline")}</span>
                      {deadline &&
                        <DeadlineBadge deadline={deadline} />
                      }
                    </ScaleCard>
                    </div>

                    <div className='col-flex-element'>
                    <ScaleCard className={`card-is-dark ${isFundingAmountEmpty}`}>
                      <span className='scl-font-variant-body card-heading'>{t("F:RP-D-FPmaxF")}</span>
                      <span className={`scl-font-variant-body-large card-description ${isFundingAmountEmptySpanClass}`}>{fundingAmount || t("details:noFundingVolume")}</span>
                    </ScaleCard>
                  </div>
                </div>
              </div>

              {fund.news &&
                  <DescriptionBox title={t("BOFundTranslationKeys:news")} content={fund.news}/>
              }
              {summary && 
                <DescriptionBox title={t("F:RP-D-FPTarget")} content={summary} flags={fund.flags} />
              }

              <InfoTabs description={fund.description}
                        details={fund.details}
                        fundOffice={office}
                        fundLinks={fund.links}
                        fundId={fund.id}
              />

              {mobile && 
                <ContactInformation fundOffice={office} fund={fund}/>
              }
            </div>

          {!mobile &&
              <>
            <ContactInformation fundOffice={office} fund={fund}/>
              </>
          }

        </div>
      </Section>
      </div >
    )
  }
}

export default withTranslation("details")(DetailPage)