// import { t } from 'i18next'
import i18next from 'i18next'

interface StringConstants {
  labels: {
    [index: string]: string,
    trend: string,
    portfolioAllocation: string,
    fundingName: string,
    fundingRegulation: string,
    notification: string,
    tendering: string,
    allocation: string,
    fundingTypeRadar:string,
    targetGroupRadar: string,
    summaryRadar: string,
    officeRadar: string,
    fundingObjectType: string,
    maturity: string,
    primaryStream: string,
    parent_stream_name: string,
    showLabel: string,
    fundingType: string,
    geoArea: string,
    deadline: string,
    fundingVolume: string,
    fundingVolume_available: string,
    fundingAmount_float: string,
    phase: string,
    deadlineLabel: string,
    approved: string,
    status: string,
    days: string,
    day: string,
    noFundingType: string,
    noGeoArea: string,
    noDeadline: string,
    noFundingVolume: string,
    noDataAvailable: string,
    internalContact: string,
    portfolioProducts: string,
    office: string,
    links: string,
    summary: string,
    description: string,
    details: string,
    targetGroup: string,
    endDate: string,
    original_document: string,
    additional_link: string,
    creationDate: string,
    lastCrawledDate: string,
    name: string,
    segment: string,
    related_portfolio_products: string,
    fundingTypeDetails: string,
    fundingAmount: string,
    fundingAmountDetails: string,
    requirements: string,
    last_modified: string,
    fundingProcess: string,
    relatedPortfolioProducts: string,
    relatedPortfolio: string,
    shortname: string,
    keywords: string,
    host: string,
    allStreams: string,
    streamName: string,
    grants: string,
    mainTitle: string,
    lastUpdateNotAvailable: string,
    externalLink: string,
    goToAllocationPage: string,
    goToDetailPage: string,
    goTo: string,
    grw: string,
    security: string,
    sustainability: string,
  },
  filters: {
    reset: string,
    apply: string
  },
  legend: {
    title: string,
    default: string,
    new: string,
    update: string,
    deadline: string
  }
}

const DE: StringConstants = {
  labels: {
    allStreams: i18next.t("details:allStreams"),
    streamName: i18next.t("details:streamName"),
    grants: i18next.t("details:grants"),
    mainTitle: i18next.t("details:mainTitle"),
    lastUpdateNotAvailable: i18next.t("details:lastUpdateNotAvailable"),
    externalLink: i18next.t("details:externalLink"),
    goToAllocationPage: i18next.t("details:goToAllocationPage"),
    goToDetailPage: i18next.t("details:goToDetailPage"),
    goTo: i18next.t("details:goTo"),

    trend: i18next.t("details:trend"),
    portfolioAllocation: i18next.t("RP:RP-Status-1"),
    fundingRegulation: i18next.t("RP:RP-Status-2"),
    notification: i18next.t("RP:RP-Status-3"),
    tendering: i18next.t("RP:RP-Status-4"),
    allocation: i18next.t("RP:RP-Status-5"),
    fundingTypeRadar: i18next.t("F:RP-D-FPFType"),
    targetGroupRadar: i18next.t("F:RP-D-FPTGroup"),
    summaryRadar: i18next.t("F:RP-D-FPTarget"),
    officeRadar: i18next.t("details:officeRadar"),

    maturity: i18next.t("details:maturity"),
    phase: i18next.t("BO:BO-FP-RFPPhase"),
    fundingName: i18next.t("details:fundingName"),
    deadlineLabel: i18next.t("details:deadlineLabel"),
    primaryStream: i18next.t("details:primaryStream"),
    parent_stream_name: i18next.t("details:parent_stream_name"),
    showLabel: i18next.t("F:RP-F-Titel"),

    internalContact: i18next.t("FP:RP-FP-intContact"),
    portfolioProducts: i18next.t("details:portfolioProducts"),
    office: i18next.t("FP:RP-FP-extContact"),
    links: i18next.t("FP:RP-FP-RelLinkTitel"),

    summary: i18next.t("F:RP-D-FPTarget"),

    description: i18next.t("FP:RP-FP-DescTitel"),
    details: i18next.t("FP:RP-FP-DetailTitel"),

    targetGroup: i18next.t("F:RP-D-FPTGroup"),

    original_document: i18next.t("details:original_document"),
    additional_link: i18next.t("BO:BO-FP-RFP1P"),

    // Fund detail page //
    // misc
    shortname: i18next.t("details:shortname"),
    keywords: i18next.t("details:keywords"),
    host: i18next.t("details:host"),
    status: i18next.t("details:status"),
    days: i18next.t("details:days"),
    day: i18next.t("details:day"),
    last_modified: i18next.t("FP:RP-FP-FLastUpd"),
    relatedPortfolioProducts: i18next.t("details:relatedPortfolioProducts"),
    relatedPortfolio: i18next.t("FP:RP-FP-Portfolio"),

    // key info panels
    name: i18next.t("details:name"),
    fundingType: i18next.t("FP:RP-FP-Type"),
    geoArea: i18next.t("F:RP-F-FPArea"),
    deadline: i18next.t("F:RP-D-FPDeadline"),
    fundingVolume: i18next.t("F:RP-F-FPAmount"),
    fundingAmount: i18next.t("F:RP-D-FPmaxF"),
    fundingVolume_available: i18next.t("details:fundingVolume_available"),
    fundingAmount_float: i18next.t("details:fundingAmount_float"),
    approved: i18next.t("details:approved"),

    // Details
    creationDate: i18next.t("details:creationDate"),
    endDate: i18next.t("FP:RP-FP-Duration"),
    lastCrawledDate: i18next.t("FP:RP-FP-FLastUpd"),
    segment: i18next.t("FP:RP-FP-FSeg"),
    related_portfolio_products: i18next.t("BOFundTranslationKeys:relatedPortfolioProducts"),
    fundingTypeDetails: i18next.t("FP:RP-FP-Type"),
    fundingAmountDetails: i18next.t("FP:RP-FP-FAmountD"),
    requirements: i18next.t("FP:RP-FP-FReq"),
    fundingProcess: i18next.t("FP:RP-FP-ApplProc"),
    fundingObjectType: i18next.t("F:RP-D-FPFObject"),
    
    // if no info available
    noFundingType: i18next.t("details:noFundingType"),
    noGeoArea: i18next.t("details:noGeoArea"),
    noDeadline: i18next.t("details:noDeadline"),
    noFundingVolume: i18next.t("details:noFundingVolume"),
    noDataAvailable: i18next.t("details:noDataAvailable"),

    //flags
    grw: i18next.t("F:RP-F-Switch3"),
    security: i18next.t("F:RP-F-Switch2"),
    sustainability: i18next.t("F:RP-F-Switch1"),
  },
  filters: {
    reset: i18next.t("F:RP-F-BBack"),
    apply: i18next.t("F:RP-F-BSet"),
  },
  legend: {
    title: i18next.t("details:legendTitle"),
    default: i18next.t("RP:RP-DiamondsDefault"),
    new: i18next.t("RP:RP-DiamondsNew"),
    update: i18next.t("RP:RP-DiamondsUpdate"),
    deadline: i18next.t("RP:RP-DiamondsDeadline"),
  }
} as const

export const TRANSLATIONS = {
  de: DE
} as const

