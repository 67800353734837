import { ScaleCard } from "@telekom/scale-components-react";
import React from "react";

interface Props {
  children: React.ReactNode
}

const SCALE_CARD_STYLES = `
.card-border {
  margin-bottom: 24px;
}`

export default class DescriptionCard extends React.Component<any, Props> {
  render(){
    return(
      <ScaleCard styles={SCALE_CARD_STYLES} className="light">
        {this.props.children}
      </ScaleCard>
    )
  }
}