import React from 'react'

import { KeyValuePair } from '../../../model/KeyValuePair'
import { KeyValueItem } from '../KeyValueItem'
import './key-value-list.scss'

interface Props {
    entries: KeyValuePair[]
}

export const KeyValueList = ({ entries }: Props) => {
    if (!entries) {
        return null
    }

    return (
        <div className='key-value-list'>
            <div className='col-flex-inner'>
                {
                    entries.map((entry, index) => {
                        return (
                            <div className='col-flex-element' key={index}>
                                <KeyValueItem keyValuePair={entry} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}