import * as types from '../types'

type Props = {
  theme: any
}

export const setLinksStyles = (
  props: Props,
  linkType: types.LinkType
) => {

  return `

    span {
      margin-right: 12px;
    }
  `
}