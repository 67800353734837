import React from 'react'
import { NavLink } from 'react-router-dom'
import {ScaleCard, ScaleTable} from '@telekom/scale-components-react'
import { Translation } from 'react-i18next'
import TableInner from './__styled__/TableInner'
import TableMobileView from './__styled__/TableMobileView'
import FundMapped from '../../model/FundMapped'
import NumberFormatter from '../../utils/NumberFormatter'
import WidthDependent, { BreakpointDependentRenderer as BreakpointRenderer } from "../../components/WidthDependent/WidthDependent";
import { BREAKPOINTS } from "../../theme/styles/Breakpoints";
import CardsCol from "./__styled__/CardsCol";
import Span from "../../components/Span";
import { NON_BREAKING_SPACE } from '../../theme/constants/NonBreakingSpace'
import DetailHyperlink from '../../model/DetailHyperlink'
import ObjectUtil from '../../utils/ObjectUtil'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'


interface Props {
  funds: FundMapped[]
}


export default class AllocationTable extends React.Component<Props, any> {
  render() {
    const { funds } = this.props
    const sortedFunds = funds.sort((fundLeft, fundRight) => fundLeft.name.localeCompare(fundRight.name))
    const renderedRows = sortedFunds.map(fund => this.renderRow(fund))
    const renderedCards = sortedFunds.map(fund => this.renderCard(fund))
    /* breakpoints */
    const breakpointRenderers: BreakpointRenderer[] = [
      { breakpoint: 0, renderer: () => { return this.renderMobileView(renderedCards) } },
      { breakpoint: BREAKPOINTS.tabletMax, renderer: () => { return this.renderDesktopView(renderedRows) } },
      { breakpoint: BREAKPOINTS.desktop, renderer: () => { return this.renderDesktopView(renderedRows) } },
    ]

    return (
      <TableInner>
        <WidthDependent renderers={breakpointRenderers} />
      </TableInner>
    )
  }
  private addClickCount() {
    const backend: Backend = BackendFactory.produce()
    backend.addViewToElement('Click on fund in "In Zuteilung"')
  }

  private renderMobileView(renderedCards: JSX.Element[]) {
    return (
      <TableMobileView>
        {renderedCards}
      </TableMobileView>
    )
  }
  private renderRow(fund: FundMapped): JSX.Element {
    const { id, name, primaryStream, fundingVolume, lastModified } = fund
    const prettyFundingVolume = this.getPrettyFundingVolume(fundingVolume)
    const hyperlink = DetailHyperlink.createHyperlinkFromAllocationsPage(fund.id)
    const key = `_${id}`
    const lastModifiedDateString = lastModified && ObjectUtil.formatDateObj(lastModified)

    return (
      <tr key={key}>
        <td className="column column--large"><NavLink className="link" to={hyperlink.href} target='_self' rel='noreferrer'>{name}</NavLink></td>
        <td className="column column--medium">{primaryStream}</td>
        <td className="column column--small"><p className="column column--forAlign ">{prettyFundingVolume}</p></td>
        <td className="column column--small">{lastModifiedDateString}</td>
        <td className="column column--small">
          <Translation>{
                (t) => <NavLink onClick={this.addClickCount} className="link" to={hyperlink.href} target='_self' rel="noreferrer">{t("home:moreInfo")}</NavLink>
          }</Translation>
        </td>
      </tr>
    )
  }

  private renderCard(fund: FundMapped): JSX.Element {
    const { id, name, primaryStream, fundingVolume, lastModified } = fund
    const prettyFundingVolume = this.getPrettyFundingVolume(fundingVolume)
    const hyperlink = DetailHyperlink.createHyperlinkFromAllocationsPage(fund.id)
    const key = `_${id}`
    const lastModifiedDateString = lastModified && ObjectUtil.formatDateObj(lastModified)

    return (
      <CardsCol key={key}>
        <ScaleCard className='card card-is-default'>
          <Span isBlock className='bold  scl-font-variant-heading-5'>
            {name}</Span>
            <Translation>{
                (t) => <Span isBlock className="card-mobile-label">{t("BO:BO-FP-RFPStream")}</Span>
              }</Translation>
          
          <Span isBlock className='thin scl-font-variant-body'>{primaryStream}</Span>

          {prettyFundingVolume ?
            <>
              <Translation>{
                (t) => <Span isBlock className="card-mobile-label">{t("table:fundingAmount")}</Span>
              }</Translation>
              
              <Span isBlock className='thin scl-font-variant-body'>{prettyFundingVolume}</Span>
            </>
            : <></>}

          {lastModifiedDateString ?
            <>
              <Translation>{
                (t) => <Span isBlock className="card-mobile-label">{t("table:lastUpdate")}</Span>
              }</Translation>
              
              <Span isBlock className='thin scl-font-variant-body'>{lastModifiedDateString}</Span>
            </>
            : <></>}
          <div className="row row--last">
            <Translation>{
                (t) => <NavLink onClick={this.addClickCount} className="link link--no-underline" to={hyperlink.href} target='_self' rel="noreferrer">{t("home:moreInfo")}</NavLink>
              }</Translation>
          </div>
        </ScaleCard>
      </CardsCol>
    )
  }

  private renderDesktopView(renderedRows: any): JSX.Element {
    return (
      <ScaleTable className="allocation-table">
        <table>
          <thead>
            <Translation>{
                (t) => <tr>
                <th className="column column--large">{t("BO:BO-FP-NFPC1")}</th>
                <th className="column column--medium">{t("BO:BO-FP-RFPStream")}</th>
                <th className="column column--small">{t("table:fundingAmount")}</th>
                <th className="column column--small">{t("table:lastUpdate")}</th>
                <th className="column column--small">{t("FP:RP-FP-DetailTitel")}</th>
              </tr>
            }</Translation>
            
          </thead>
          <tbody>
            {renderedRows}
          </tbody>
        </table>
      </ScaleTable>

    )
  }


  private getPrettyFundingVolume(fundingVolume: number | undefined): string {
    if (!fundingVolume) {
      return ''
    }

    const prettyFundingVolume = NumberFormatter.prettifyBigNumber(fundingVolume)

    return `${prettyFundingVolume}${NON_BREAKING_SPACE}€`
  }
}