import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'
import isDeepEqual from 'fast-deep-equal/react'
import Publication from '../../model/Publication'
import { ReactComponent as DetailsIcon } from '../../icons/details-icon.svg'
import { ReactComponent as ChainIcon } from '../../icons/chain-icon.svg'
import { ReactComponent as FundDetails } from '../../icons/fund-details.svg'
import './publications.scss'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ALL_STREAMS } from '../../pages/backoffice/press-releases/forms/form-types'

interface State {
    publications: Publication[],
    primaryStream?: string,
    showTooltip?: string
}

interface Props {
    primaryStream?: string
}

export const PublicationsList = ({ primaryStream }: Props) => {

    const { t } = useTranslation('publications')

    let prStream = primaryStream
    const backend: Backend = BackendFactory.produce()

    if (primaryStream && primaryStream.indexOf('&') > 0) {
        prStream = primaryStream.replace('&', '%26')
    }

    const initialState: State = { publications: [], primaryStream: prStream || 'Alle' }
    const [compState, setCompState] = useState(initialState)

    // fix specially for linter
    const backendRef = useRef(backend)
    if (!isDeepEqual(backendRef.current, backend)) {
        backendRef.current = backend
    }

    const trimSummary = (contentText: string) => {
        let text: string
        let hidden: boolean
        if (contentText.length > 240) {
            text = contentText.slice(0, 239) + '...'
            hidden = true
        } else {
            text = contentText
            hidden = false
        }
        return (

            <span className={hidden ? 'text-container text-container--hidden' : 'text-container'}>
                {text}
            </span>
        )

    }




    const renderPubCard = (pub: Publication) => {
        const pubDate = moment(pub.published).format('DD.MM.yyyy')

        return (
            <div className='publication-card' key={pub.id + '_pub'}>
                <div className='publication-header'>
                    <div className='publication-item publication-item--date'>
                        {pubDate}
                    </div>
                    <div className='publication-item publication-item--icon'>
                        {pub.icon && <img src={pub.icon}
                                          alt={'icon'} />}
                    </div>
                    <div className='publication-item publication-item--status'>{pub.source_type}</div>
                    <div className='publication-item publication-item--icon publication-item--icon-aligned'>
                        {/*       {(compState.showTooltip === pub.id)
                        && <div className="tooltip tooltip--black" id={pub.id }>{t("copiedLink")}</div>}
                          <span onClick={()=>onCopyLinkClick(pub)} title={t('copyLink')!} className="link link--no-underline link-action">
                          <ChainIcon />
                        </span>*/}
                        {pub.fund_number &&
                          <Link title={t('fundProgram')!}
                                className='link link--no-underline link-action'
                                to={`/detail/${pub.fund_id}`} onClick={()=> backendRef.current.addViewToElement('Publication: fund info click')}>
                            <FundDetails />
                          </Link>
                        }
                    </div>
                </div>
                <div className='publication-item publication-item--title bold'>{pub.title}</div>
                <div className='publication-item publication-item--summary'>
                    {trimSummary(pub.summary || '')}
                </div>
                <div className='publication-footer'>
                    <div className='publication-footer__additional-info bold'>
                        <div className='publication-footer__stream'>
                            {pub.stream === ALL_STREAMS ? t('publications:allStreams') : pub.stream}
                        </div>
                        <span className='publication-footer__segment'>{t("FP:RP-FP-FSeg")}:
                            {(pub.segment && pub.segment.length > 0) ? pub.segment.map((el, index) =>
                            <span key={el.name}>{el.name}{pub.segment && pub.segment[index + 1] ? ', ' : ''}</span>,
                        ) : '-'}</span>
                    </div>
                    <div className='publication-item publication-item--details'>
                        {pub.source && <a className='link link--no-underline'
                                          href={`${pub.url}`}
                                          target={'_blank'}
                                          rel='noreferrer'
                                          onClick={() => backendRef.current.addViewToElement('Publication details')}
                        >
                          <span className='link__icon publication-item__optional'><DetailsIcon /></span>
                        </a>}
                    </div>
                </div>
            </div>
        )
    }

    const renderList = (pubs: Publication[]) => {
        return (
            <div className='publications-list'>
                <h1 className='publications-title'>{t('title')}</h1>
                <h2 className='publications-subtitle'> {pubs?.length} {pubs?.length !== 1 ? t('entries') : t('entry')} </h2>
                {pubs && pubs.map(pub => renderPubCard(pub))}
            </div>
        )
    }

    useEffect(() => {
        let correctName = prStream || 'Alle'
        const params = correctName !== 'Alle' ? correctName : undefined

        backendRef.current.getPublications(params).then(pubs => {
            setCompState({
                publications: pubs,
                primaryStream: prStream || 'Alle',
            })
        }).catch(e => console.debug(e))
    }, [prStream])
    return renderList(compState.publications)
}