import styled from 'styled-components/macro'

import StepItemNumber from './StepItemNumber'
import StepItemTitle from './StepItemTitle'

type Props = {
  isActive?: boolean
}

const StepItem = styled('div')<Props>`
  position: relative;
  min-height: 54px;
  width: 25%;
  padding: ${props => props.theme?.spacings?.small.margin};
  background: ${props => props.theme?.colors?.primary.white};
  border-color: ${props => props.theme?.colors?.gray.alto};
  border-radius: ${props => props.theme?.borderRadius?.default};
  border-style: solid;
  border-width: 1px 0 1px 1px;

  &:last-child {
    border-right-width: 1px;

    &::after {
      width: 0;
      height: 0;
      background: transparent;
      border: none;
    }
  }

  &::after {
    position: absolute;
    top: 5px;
    right: 6px;
    width: 41px;
    height: 41px;
    background: ${props => props.theme?.colors?.primary.white};
    border-top: 1px solid ${props => props.theme?.colors?.gray.alto};
    border-right: 1px solid ${props => props.theme?.colors?.gray.alto};
    border-radius: ${props => props.theme?.borderRadius?.default};
    transform: translateX(50%) rotate(45deg);
    content: '';
    z-index: ${props => props.theme?.zIndex?.default};
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:last-child {

    ${StepItemNumber} {
      left: 6px;

      @media (min-width: ${props => props.theme?.breakpoints?.tablet}) {
        left: 0;
      }
    }
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {

    ${StepItemTitle} {
      left: 12px;

      @media (min-width: ${props => props.theme?.breakpoints?.desktop}) {
        left: 0;
      }
    }
  }

  ${props => props.isActive && `

    &:first-child {
      background: ${props.theme.colors?.gray?.wildSand};

      &::after {
        background: ${props.theme.colors?.gray?.wildSand};
      }
    }

    &:nth-child(2) {
      background: ${props.theme.colors?.gray?.gallery};

      &::after {
        background: ${props.theme.colors?.gray?.gallery};
      }
    }

    &:nth-child(3) {
      background: ${props.theme.colors?.gray?.alto};

      &::after {
        background: ${props.theme.colors?.gray?.alto};
      }
    }

    &:nth-child(4) {
      background: ${props.theme.colors?.gray?.silverChalice};

      &::after {
        background: ${props.theme.colors?.gray?.silverChalice};
      }
    }

    &:last-child {
      background: ${props.theme.colors?.primary?.magenta};

      ${StepItemNumber},
      ${StepItemTitle} {
        color: ${props.theme.colors?.primary?.white};
      }

      &::after {
        background: ${props.theme.colors?.primary?.magenta};
      }
    }
  `}
`

export default StepItem