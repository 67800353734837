import { ScaleButton, ScaleModal } from '@telekom/scale-components-react'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './F2CFormGenerator.module.scss'
import { FormikProps, useFormik } from 'formik'
import { ObjectSchema } from 'yup'
import { ModalActions, ModalFormConfig } from './types'
import { GetFormElement } from './GetFormElement'
import { TableSource } from '../../pages/backoffice/funds/types'
import { prettifyDate } from '../../helpers/dates'
import { localizeDatepicker } from '../../utils/date-picker-locale'

// import { Dialog } from 'primereact/dialog'


interface F2CFormGeneratorProps {
    isOpened: boolean
    modalType: ModalActions
    onClose: () => void
    onPublish?: (changedFields: Record<string, any>, formData: Record<string, any>) => void
    onRelevant?: (changedFields: Record<string, any>, formData: Record<string, any>) => void
    onEdit?: (changedFields: Record<string, any>, formik:FormikProps<any>) => void
    onValidationCreate?: (formData: Record<string, any>) => void
    onCreate?: (formData: Record<string, any>) => void
    formConfig: ModalFormConfig
    initialFormValues: Record<string, any>
    validationSchema?: ObjectSchema<any>
    modalTitle: string
    currentTab?: TableSource //exclusive for fund tables
}

export const F2CFormGenerator: FC<F2CFormGeneratorProps> = (
    {
        isOpened,
        modalType,
        onClose,
        initialFormValues,
        validationSchema,
        onPublish,
        onRelevant,
        onEdit,
        onValidationCreate,
        onCreate,
        formConfig,
        modalTitle,
        currentTab,

    }) => {
    const { t } = useTranslation('SettingsPage, formGenerator')
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    useEffect(() => {
        // formik.resetForm()
        formik.setValues(initialFormValues)
        changeDateFormatForDatePickers()
        return () => formik.resetForm()
    }, [initialFormValues])

    const changeDateFormatForDatePickers = ():void => {
        const scaleDatePickers = document.querySelectorAll('scale-date-picker')
        if (scaleDatePickers) {
            scaleDatePickers.forEach((picker: any) => localizeDatepicker(picker))
        }
    }
    const formik = useFormik({
        initialValues: initialFormValues,
        onSubmit: (values, formikHelpers) => {
            // if (onSubmit) onSubmit(formik.touched)
            formikHelpers.setSubmitting(false)
        },
        validationSchema: validationSchema,
        validateOnMount: true,
    })
    const getTouchedValues = (): Record<string, any> => {
        const cloneValues = JSON.parse(JSON.stringify(formik.values))
        const touchedArr = Object.keys(formik.touched)
        const allValuesArr = Object.keys(cloneValues)
        allValuesArr.forEach(val => {
            if (!touchedArr.includes(val)) delete cloneValues[val]
        })
        return cloneValues
    }

    const validationErrorsCounter = Object.keys(formik.errors).length
    const notAllowedToSave = Object.keys(formik.errors).filter(err => formik.errors[err] !== t('BOFundTranslationKeys:validationNotFilled')).length > 0
    let formWasTouched = useMemo(() => Object.keys(formik.touched).length > 0, [formik.touched])

    const getClassNameByColumns = (columnsAmount: number) => {
        switch (columnsAmount) {
            case 2:
                return styles.column2
            case 4:
                return styles.column4
            case 3:
                return styles.column3
            default:
                return styles.column1
        }
    }

    return (
        <ScaleModal heading={modalTitle}
                    opened={isOpened}
                    size={'large'}
                    onScaleClose={() => onClose()}
                    onScaleBeforeClose={e => {
                        if (formWasTouched) {
                            setShowConfirmModal(true)
                            e.preventDefault()
                        }
                    }}
        >
            {/*      <Dialog header={modalTitle} visible={isOpened} style={{ width: '80%' }}
                        onHide={() => onClose()}>*/}

            <form className={styles.form} onSubmit={formik.handleSubmit}>
                {formConfig.lines.map((line) =>
                    <div key={line.index} className={styles.row}>
                        {line.columns.map((col) =>
                            <div key={col.colIndex + line.index}
                                 className={`${styles.column} ${getClassNameByColumns(line.columns.length)}`}>
                                {col.columnElements.map((element, elementIndex) =>
                                    GetFormElement(formik, element),
                                )}
                            </div>,
                        )}
                    </div>,
                )}
            </form>
            {showConfirmModal && formWasTouched &&
              <div className={styles.confirmMessage}>
                <div className={styles.messageBox}>
                  <span>{t('formGenerator:closeMessage')}</span>
                  <div className={styles.buttonsContainer}>
                    <ScaleButton slot='action'
                                 onClick={() => {
                                     setShowConfirmModal(false)
                                 }}
                    >
                        {t('formGenerator:confirmCloseCancel')}
                    </ScaleButton>
                    <ScaleButton variant='secondary' slot='action'
                                 onClick={() => {
                                     formik.resetForm()
                                     // formik.setValues(initialFormValues)
                                     // formik.validateForm(initialFormValues)
                                     //     .then(r => console.log(r))
                                     setShowConfirmModal(false)
                                     onClose()
                                 }}>
                        {t('formGenerator:confirmClose')}
                    </ScaleButton>
                  </div>
                </div>
              </div>
            }
            {modalType === ModalActions.EDIT && initialFormValues.last_modified &&
              <div slot='action'
                  className={styles.lastUpdateContainer}>{t('details:lastModified')}: {prettifyDate(initialFormValues.last_modified)}</div>}
            {onPublish && modalType === ModalActions.EDIT && currentTab === 'relevant' &&
              <ScaleButton type='submit' slot='action'
                           disabled={validationErrorsCounter > 0}
                           onClick={() => onPublish(getTouchedValues(), formik.values)}
              >
                  {validationErrorsCounter > 0 ? `${validationErrorsCounter} ${t('formGenerator:fieldsLeftTo')} ${t('formGenerator:publish')}` : t('formGenerator:publish')}
              </ScaleButton>}
            {onRelevant && modalType === ModalActions.EDIT && (currentTab === 'archived' || currentTab === 'new') &&
              <ScaleButton type='submit' slot='action'
                           onClick={() => onRelevant(getTouchedValues(), formik.values)}
              >
                  {t('details:modalActionRelevant')}
              </ScaleButton>}
            {(onEdit && modalType === ModalActions.EDIT) &&
              <ScaleButton disabled={!formWasTouched || notAllowedToSave} type='submit' slot='action' onClick={() => {
                  onEdit(getTouchedValues(), formik)
              }}>
                  {t('formGenerator:saveChanges')}
              </ScaleButton>}
            {(onCreate && modalType === ModalActions.CREATE) &&
              <ScaleButton disabled={!formWasTouched} type='submit' slot='action' onClick={() => {
                  onCreate(getTouchedValues())
                  setShowConfirmModal(false)
              }}>
                  {t('formGenerator:create')}
              </ScaleButton>}
            {(onValidationCreate && (modalType === ModalActions.CREATE || modalType === ModalActions.CREATE_SUB)) &&
              <ScaleButton type='submit' slot='action'
                           disabled={validationErrorsCounter > 0}
                           onClick={() => onValidationCreate(formik.values)}
              >
                  {validationErrorsCounter > 0 ? `${validationErrorsCounter} ${t('formGenerator:fieldsLeftTo')} ${t('formGenerator:create')}` : t('formGenerator:create')}
              </ScaleButton>}
            {/*</Dialog>*/}

        </ScaleModal>
    )
}