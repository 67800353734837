export interface Point {
  x: number
  y: number
}

export interface Polar {
  t: number
  r: number
}

export interface Sector  {
  thetaMin: number
  thetaMax: number
}

export interface Ring {
  radiusMax: number
  radiusMin: number
}

export class Geometry {
  static isAlmost(value: number, target: number, tolerance: number): boolean {
    return Math.abs(value - target) < tolerance
  }

  static toPolar(coord: Point): Polar {
    const x = coord.x;
    const y = coord.y;
    return {
      t: Math.atan2(y, x),
      r: Math.sqrt(x * x + y * y)
    }
  }

  static toCartesian(polar: Polar): Point {
    return {
      x: polar.r * Math.cos(polar.t),
      y: polar.r * Math.sin(polar.t)
    }
  }

  static boundToSegment(polar: Polar, rMin: number, rMax: number, thetaMin: number, thetaMax: number, distance: number): Polar {
    const r = boundToInterval(polar.r, rMin, rMax)
    const phi = Math.asin(distance / r)
    const t = boundToInterval(polar.t, thetaMin + phi, thetaMax - phi)
    return {t, r}
  }

  static boundToRing(polar: Polar, rMin: number, rMax: number): Polar {
    return {
      t: polar.t,
      r: boundToInterval(polar.r, rMin, rMax)
    }
  }

  static boundToBox(point: Point, min: Point, max: Point): Point {
    return {
      x: boundToInterval(point.x, min.x, max.x),
      y: boundToInterval(point.y, min.y, max.y)
    }
  }
}

function boundToInterval(value: number, firstLimit: number, secondLimit: number): number {
  const low = Math.min(firstLimit, secondLimit);
  const high = Math.max(firstLimit, secondLimit);
  return Math.min(Math.max(value, low), high);
}


