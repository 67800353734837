import React from 'react'

import { TooltipConfig } from './RadarTooltip';
import RadarData from './RadarData';
import RadarButton from './RadarButton';
import RadarSVG from './RadarSVG';
import { MainTheme } from '../../theme/MainTheme';
import { TrendingStreams } from '../../theme/constants/TrendingStreams';
import { Translation } from 'react-i18next';
import Stream from '../../model/Stream'
import { RadarSectorFundsModal } from './RadarSectorFundsModal';
import { updateUrlWithParams } from '../../utils/updateUrlWithParams';

export interface RadarProps {
  data?: RadarData,
  secondLevel?: boolean,
  onUpdateTooltip?: (config: TooltipConfig) => void,
  onUpdateStreams?(streamName: Stream['name']): any,
  isTooltipClosed?: boolean
}

interface StreamArray extends RadarData {
  currentIndex: number,
  subs?: Stream[]
}

interface RadarState {
  streamArray?: StreamArray,
  isOpenedModal?: boolean,
  stageName?: string | string[],
  sectorName?: string,
}

class Radar extends React.Component<RadarProps, RadarState> {


  constructor(props: RadarProps) {
    super(props)

    const data = this.props.data
    
    if (data && data.streams.length >= 1) {
      const streams = this.setRenamed(data.streams, this.props.secondLevel || false)
      const currentIndex = Radar.constrainStreamIndex(streams, 0)
      const streamArray = {  currentIndex,
        streams,
        basicFunds: data.basicFunds,
        view: data.view,
        showLabels: data.showLabels}

      // ATTENTION: Avoid infinite loop by checking if it was already set!
      this.state = {
        streamArray
      }
    } else {
      this.state = {

      }
    }
  }

  componentDidUpdate(prevProps: RadarProps, prevState: RadarState) {
    if (this.props.data !== prevProps.data) {
      const data = this.props.data

      if (data && data.streams.length >= 0) {
        const streams = this.setRenamed(data.streams, this.props.secondLevel || false)
        const currentIndex = Radar.constrainStreamIndex(streams, this.state.streamArray?.currentIndex)
        const currentStreamArray =  {
          currentIndex,
              streams,
              basicFunds: data.basicFunds,
              view: data.view,
              showLabels: data.showLabels,
        }

        this.setState({
          streamArray: currentStreamArray
        })

      } else {
        // ATTENTION: Avoid infinite loop by checking if it was already set!
        if (prevState.streamArray !== undefined) {
          this.setState({
            streamArray: undefined
          })
        }
      }
    }
  }

  setRenamed(streamArray: Stream[], secondLevel: boolean){

    let streamsRenamed: Stream[]  = streamArray
    if (secondLevel){
     streamsRenamed = streamArray.map(stream => {
        const renamed = {...stream}
        if (renamed.name.includes('Sonstige')){
          renamed.name_radar = 'Sonstige'
        } else {
          renamed.name_radar = renamed.name
        }
        return renamed
      })
    }
    return streamsRenamed
  }

  private static constrainStreamIndex(streams: any[], currentStreamIndex?: number) {
    return Math.max(0, Math.min(currentStreamIndex || 0, streams.length - 1))
  }

  private static createDataFrom(streamArray: StreamArray): RadarData {
    const { view, streams, currentIndex, basicFunds, showLabels } = streamArray

    if (view === 'quarter') {
      const currentStream = streams[ currentIndex ]

      return {
        streams: [ currentStream ],
        basicFunds,
        view,
        showLabels,
      }
    }

    return {
      streams,
      basicFunds,
      view,
      showLabels,
    }
  }

  private onPrevious() {
    this.setState((prevState) => {
      if (prevState.streamArray) {
        const nextIndex = Math.max(prevState.streamArray.currentIndex - 1, 0)

        return {
          streamArray: {
            ...prevState.streamArray,
            currentIndex: nextIndex
          }
        }
      }

      return null
    })
  }

  private onNext() {

    this.setState((prevState) => {
      if (prevState.streamArray) {
        const nextIndex = Math.min(prevState.streamArray.currentIndex + 1, prevState.streamArray.streams.length - 1)
        return {
          streamArray: {
            ...prevState.streamArray,
            currentIndex: nextIndex
          }
        }
      }

      return null
    })
  }

  render() {
    if (this.state.streamArray === undefined) {
      return null
    }
    
    return this.renderRadar(this.state.streamArray)
  }

  private renderRadar(streamArray: StreamArray) {
    const radarData = Radar.createDataFrom( streamArray )

    return (
      <div style={{display: 'block', position: 'relative', maxWidth: '1600px'}}>
        <div style={{textAlign: 'center'}}>
          <RadarSVG 
            data={radarData} 
            onModalOpened={(stageName, sectorName)=> this.setState({ ...this.state, isOpenedModal: true, stageName, sectorName})}
            onUpdateTooltip={this.props.onUpdateTooltip}
          />
        </div>

        {this.state.isOpenedModal && 
          <RadarSectorFundsModal
            currentFunds={this.state.streamArray?.basicFunds} 
            stageName={this.state.stageName} 
            sectorName={this.state.sectorName} 
            onModalClose={()=> {
                // const searchParams = new URLSearchParams(window.location.search);
                // updateUrlWithParams(searchParams.toString())
                this.setState({ ...this.state, isOpenedModal: false })
              }
            }
          />
        } 

        { (streamArray.view === 'quarter' && streamArray.subs) ? (
          this.renderStreamNavigationBar(streamArray)
        ) : null }
      </div>
    )
  }

  private renderStreamNavigationBar(streamArray: StreamArray) {
    const streamName = streamArray.streams[ streamArray.currentIndex ].name
    const isTrending = TrendingStreams.includes(streamName)
    const isPreviousButtonDeactivated = (0 === streamArray.currentIndex)
    const isNextButtonDeactivated = (streamArray.streams.length - 1 === streamArray.currentIndex)
    if (this.props.onUpdateStreams) {
      this.props.onUpdateStreams(streamName)
    }

    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%', marginTop: '2em'}}>
        <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center', width: '100%', maxWidth: '480px'}}>
          <RadarButton type={'previous'} deactivated={ isPreviousButtonDeactivated }
            onClick={() => {
              this.onPrevious()
            }}/>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
            <div style={{textAlign: 'center', lineHeight: '1.2em'}}><span style={{fontWeight: 800, fontSize: '24px', textAlign: 'center'}}>{ streamName }</span></div>
            { isTrending ? (
                <div>
                  <Translation>
                    { (t) => <span style={{borderRadius: 4, paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2,  fontWeight: 800, fontSize: '12px', color: 'white', backgroundColor: MainTheme.trend.color}}>{t("details:trend")}</span>}
                  </Translation>
                </div>
              ) : null }
          </div>
          <RadarButton type={'next'} deactivated={ isNextButtonDeactivated }
            onClick={() => {
              this.onNext()
            }} />
        </div>
      </div>
    )
  }
}

export default Radar