import React from 'react'

import SectionInner from './__styled__/SectionInner'

type Props = {
  isContent?: boolean
  hasSpacingBottom?: boolean
  children?: any
}

const Section = ({
  isContent,
  hasSpacingBottom,
  children
}: Props) => {

	return (
    <SectionInner isContent={isContent} hasSpacingBottom={hasSpacingBottom} className="section-inner">
      {children}
    </SectionInner>
	)
}

export default React.memo(Section)