import {ScaleDropdown} from "@telekom/scale-components-react";
import { useTranslation } from 'react-i18next'
import React, {useEffect, useState} from "react";
import Stream from "../../model/Stream";
import BackendFactory from "../../backend/BackendFactory";
import './primaryStreamDropdown.scss'

interface Props {
    currentStreamName: string,
    onSelectStream: (currentStreamName: string, StreamObj?: Stream) => void
    onInit:(currentStreamName: string)=>void
    streams:Stream[]
}

interface State {
    streamsArray: Stream[],
}

export const PrimaryStreamDropdown = ({currentStreamName, onSelectStream,streams,onInit}: Props) => {
    const { t } = useTranslation("details")
    const backend = BackendFactory.produce()
    const STREAM_DEFAULT_NAME = 'Alle'

    const initialState: State = {
        streamsArray: [{id: '0', name: STREAM_DEFAULT_NAME, name_radar: 'Alle'}, ...streams]
    }
    const [compState , setCompState] =  useState(initialState)

    useEffect(()=> {
        onInit(currentStreamName)
    },[])

    useEffect(()=>{
        const currentValue =  currentStreamName

            for(let stream of streams) {
                if (stream.name === currentValue){
                    backend.addViewToElement('Stream: ' + currentValue)
                    return
                }
                if(stream.sub_streams) {
                    for(let subStream of stream.sub_streams) {
                        if(subStream.name === currentValue) {
                            backend.addViewToElement('SubStream: ' + currentValue)
                            return
                        }
                    }
                }
            }
    }, [currentStreamName])

    const onChange = (streamName: string) => {

        const currentObjStr = compState.streamsArray.filter( item =>  item.name === streamName)[0]
        let currentObjSub

        for (const item of compState.streamsArray) {
            if (item && item.sub_streams && item.sub_streams.length > 0){
               const res = item.sub_streams.find( sub => sub.name === streamName)
                if (res){
                    currentObjSub = res
                }
            }
        }
        onSelectStream(streamName, currentObjStr || currentObjSub)
        setCompState({streamsArray: compState.streamsArray})
    }

    return(
        <div className="radar-stream-filter">
            {compState.streamsArray.length > 1
            &&   <ScaleDropdown value={currentStreamName} key={'stream-dd-1'}
                                label={String(t("RP:RP-StreamSel"))} size="small"
                                onScaleChange={(event: any) => onChange(event.target.value)}>
                {compState.streamsArray.map( (stream, i) => {
                    const streamName = stream.name.replace(/\s/g,'')
                    if (stream.has_sub_streams && stream.sub_streams && stream.sub_streams.length > 1){
                        return <React.Fragment key={`${streamName}-${i}--with-subs`}>
                            <option
                                    value={stream.name}
                                    className={`${stream.name === currentStreamName
                                        ? 'option option--with-subs option--current': 'option option--with-subs'}`}>
                                {stream.name}
                            </option>
                            {stream.sub_streams && stream.sub_streams?.length >= 1
                            && stream.sub_streams?.map((sub, ind)=> {
                                const subName = sub.name.replace(/\s/g,'')
                                return <option
                                   key={`${subName}-sub-${ind}`}
                                   value={sub.name}
                                   className={`${sub.name === currentStreamName ? 'sub-stream-option option--current': 'sub-stream-option'}`}>
                                   ↳&nbsp;{sub.name_radar}
                                </option>
                            })}

                        </React.Fragment >
                    } else if ((stream.has_sub_streams
                        && stream.sub_streams && stream.sub_streams.length === 1) || stream.name === STREAM_DEFAULT_NAME) {
                        return <option
                            className={`${stream.name === currentStreamName
                                ? 'option option--current': 'option'}`}
                            key={`${streamName}-${i}`} value={stream.name}>
                            
                            {stream.name === STREAM_DEFAULT_NAME
                            ? t("RP:RP-SelStream")
                            : stream.name
                            }
                        </option>
                    } else {
                        return true
                    } 
                })}
            </ScaleDropdown>}
            {compState.streamsArray.length === 0
            && <ScaleDropdown size="small"
                              label={String(t("RP:RP-StreamSel"))}
                              key={'stream-dd-2'}>
                <option value={'keine Themen'} key={'only-one-item-2'}>{t("noStreams")}</option>
            </ScaleDropdown>}
            {compState.streamsArray.length === 1
            && <ScaleDropdown size="small" label={String(t("RP:RP-StreamSel"))} key={'stream-dd-3'}>
                <option value={compState.streamsArray[0].name} key={'only-one-item-3'}>{compState.streamsArray[0].name}</option>
            </ScaleDropdown>}
        </div>
    )
 }