import styled from 'styled-components/macro'

export type Props = {
  isBlock?: boolean
  isBold?: boolean
  isExtraBold?: boolean
  hasMarginTop?: boolean
}

const Span = styled('span')<Props>`

  ${props => props.isBlock && `
    display: block;
  `}

  ${props => props.isBold && `
    font: bold 16px/18px TeleNeoWeb
  `}

  ${props => props.isExtraBold && `
    font-weight: 800;
  `}

  ${props => props.hasMarginTop && `
    margin-top: ${props.theme.spacings.smaller.margin};
  `}

  @media screen and (max-width: 1039px){
     display: block;
  }
`

export default Span