import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'
import isDeepEqual from 'fast-deep-equal/react'

import './publications.scss'
import { useTranslation } from 'react-i18next'
import Notification from '../../model/Notification'
import moment from 'moment'
import { HIGHLIGHT } from '../../constants/BEstatuses'

interface State {
    notifications: Notification[],
    primaryStream: string
}

interface Props {
    primaryStream?: string
}

// TODO: dont use static text in if-else statement

export const NotificationsList = ({ primaryStream }: Props) => {

    const { t } = useTranslation('notifications')

    let prStream = primaryStream
    if (primaryStream && primaryStream.indexOf('&') > 0) {
        prStream = primaryStream.replace('&', '%26')
    }
    const backend: Backend = BackendFactory.produce()
    const initialState: State = { notifications: [], primaryStream: prStream || 'Alle' }
    const [compState, setCompState] = useState(initialState)

    // fix specially for linter
    const backendRef = useRef(backend)
    if (!isDeepEqual(backendRef.current, backend)) {
        backendRef.current = backend
    }

    const notificationType = (status: string) => {
        let classes = ''
        let typeName = ''
        switch (status) {
            case HIGHLIGHT.NO_CHANGE:
                classes = 'status status--magenta'
                typeName = t('typeNoChanges')
                break
            case HIGHLIGHT.NEW_FUNDING_PROGRAM:
                classes = 'status status--blue'
                typeName = t('typeNew')
                break
            case HIGHLIGHT.CONTENT_UPLOADED:
                classes = 'status status--black'
                typeName = t('typeUpdated')
                break
            case HIGHLIGHT.NOTE_DEADLINES:
                classes = 'status status--yellow'
                typeName = t('typeDeadline')
        }
        return <div className={classes}>{typeName}</div>
    }

    const showDate = (date: Date) => {
        const pubDate = moment(date).format('DD.MM.yyyy')
        const differenceInDays = moment().diff(moment(date).startOf('days'), 'days')
        switch (differenceInDays) {
            case 1:
                return t('yesterday')
            case 2:
                return t('twoDaysAgo')
            case 3:
                return t('threeDaysAgo')
            default:
                return pubDate
        }
    }

    const renderPubCard = (pub: Notification) => {
        const date = showDate(pub.status_updated_at)

        return (
            <div className='publication-card publication-card--white' key={pub.id + '_pub'}>
                <div className='publication-header'>
                    <div className='publication-item publication-item--default publication-item--small'>
                        {date}
                    </div>
                    <div className='publication-item publication-item--default'>
                        {notificationType(pub.status)}
                    </div>
                </div>
                <div className='publication-item  bold'>
                    <NavLink className='link link--no-underline'
                             to={`/detail/${pub.id}`}
                             onClick={()=> backendRef.current.addViewToElement('Radar page: notification click')}
                    >
                        {pub.name}
                    </NavLink>
                </div>
                <div className='publication-item publication-item--footer publication-item--small bold'>
                    <span>{pub.stream}</span>
                    <div style={{display:'flex', flexDirection:'column', textAlign:'right'}}>
                        {pub.geoAreas && pub.geoAreas.map(area =>
                            <span key={area} >
                                {area}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const renderList = (pubs: any[]) => {
        const subTitleText = (len: number) => {
            let text
            if (len === 0 || len === undefined) {
                text = t('nothingNew')
            } else if (len === 1) {
                text = t('newMessage')
            } else {
                text = `${len} ${t('newMessages')}`
            }
            return text
        }
        return (
            <div className='publications-list'>
                <h1 className='publications-title'>{t('news')}</h1>
                <h2 className='publications-subtitle'> {subTitleText(pubs?.length)}</h2>
                {pubs?.map(pub => renderPubCard(pub))}
            </div>
        )
    }

    useEffect(() => {
        let correctName: string

        if (prStream === 'Direkte Förderung') {
            correctName = '%C3%96kosysteme;Forschung%20%26%20Innovation'
        } else {
            correctName = prStream || 'Alle'
        }

        const params = correctName !== 'Alle' ? correctName : undefined
        backendRef.current.getNotifications(params).then(res => {
            setCompState({
                notifications: res,
                primaryStream: prStream || 'Alle',
            })
        }).catch(e => console.debug(e))
    }, [prStream])
    return renderList(compState.notifications)
}