import { ModalActions } from '../../../components/F2CFormGenerator/types'
import { t } from 'i18next'

export const PR_STATUSES = {
    ARCHIVED: 'Archiviert',
    PUBLISHED:'Veröffentlicht',
    UNPUBLISHED: 'Unveröffentlicht',
    DELETED: 'Gelöscht'
}

export const getModalText = (modal:ModalActions): {text: string, title: string, buttonConfirmText: string, status: string, successMessage: string, errorMessage: string} => {
    let title = ''
    let text = ''
    let buttonConfirmText = ''
    let status = ''
    let successMessage = ''
    let errorMessage = ''
    switch (modal) {
        case ModalActions.ALERT_DELETE:
            title = t("BOFundTranslationKeys:alertTitlePressDeleteText")
            text = t("BOFundTranslationKeys:alertContentPressDeleteText")
            buttonConfirmText = t('BO:BO-FP-NFPB1')
            status = PR_STATUSES.DELETED
            successMessage = t('BOFundTranslationKeys:fundsDeleteTextSuccess')
            errorMessage = t('BOFundTranslationKeys:deletedTextError')
            break
        case ModalActions.ALERT_ARCHIVE:
            title = t("BOFundTranslationKeys:alertTitlePressArchiveText")
            text = t("BOFundTranslationKeys:alertContentPressArchiveText")
            buttonConfirmText = t('BO:BO-FP-RFPB4')
            status = PR_STATUSES.ARCHIVED
            successMessage = t('BOFundTranslationKeys:fundsArchiveTextSuccess')
            errorMessage = t('BOFundTranslationKeys:archivedTextError')
            break
        case ModalActions.ALERT_UNPUBLISHED:
            title = t('BOFundTranslationKeys:alertTitlePressText')
            text = t('BOFundTranslationKeys:alertContentPressText')
            buttonConfirmText = t('details:modalActionChange')
            status = PR_STATUSES.UNPUBLISHED
            successMessage = t("BOFundTranslationKeys:unpublishedText")
            errorMessage = t('BOFundTranslationKeys:unpublishedTextError')
            break
        case ModalActions.ALERT_PUBLISH:
            title = t('BOFundTranslationKeys:alertTitlePublish')
            text = t('BOFundTranslationKeys:alertContentPublish')
            buttonConfirmText = t('formGenerator:publish')
            status = PR_STATUSES.PUBLISHED
            successMessage = t("BOFundTranslationKeys:publishedText")
            errorMessage = t('BOFundTranslationKeys:publishedTextError')
            break
        default:
            title = 'text not found'
            text = 'text not found'
            buttonConfirmText = 'text not found'
            status = 'text not found'
            successMessage = 'text not found'
            errorMessage = 'text not found'

    }

    return {title, text, buttonConfirmText, status, successMessage, errorMessage}
}