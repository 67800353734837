import React from 'react'
import { useTranslation } from 'react-i18next'

import ContactInner from './__styled__/ContactInner'
import ContactCompany from './__styled__/ContactCompany'
import ContactEstablish from './__styled__/ContactEstablish'
import ContactStreet from './__styled__/ContactStreet'
import ContactCity from './__styled__/ContactCity'
import ContactPhone from './__styled__/ContactPhone'
import ContactInfo from './__styled__/ContactInfo'
import ContactFax from './__styled__/ContactFax'
import Link from '../Link'

type Props = {
  /* must haves */
  company: string
  zipcode: string
  city: string
  phoneNumber: string

  /* optional */
  street?: string
  establishment?: string
  infoNumber?: string
  fax?: string
}

const Contact = ({
  company,
  establishment,
  street,
  zipcode,
  city,
  phoneNumber,
  infoNumber,
  fax
}: Props) => {
  const { t } = useTranslation("details")
  return (  
    <ContactInner>
      <p className="scl-font-variant-body">
        <ContactCompany>
          {company}
        </ContactCompany>

        {establishment ? (
          <ContactEstablish>
            {establishment}
          </ContactEstablish>
        ) : null}

        {street ? (
          <ContactStreet>
            {street}
          </ContactStreet>
        ) : null}

        <ContactCity>
          {zipcode} {city}
        </ContactCity>
        <ContactPhone className="light">
          <Link linkType='phone' href={`tel:${phoneNumber}`} linkTitle={phoneNumber || ''}/>
        </ContactPhone>

        {infoNumber ? (
          <ContactInfo>
            {t("infocenter")}: {infoNumber}
          </ContactInfo>
        ) : null}

        {fax ? (
          <ContactFax>
            {t("fax")} {fax}
          </ContactFax>
        ) : null}
      </p>
    </ContactInner>
  )
}

export default React.memo(Contact)
