import React from 'react'

import ListItemContentInner from './__styled__/ListItemContentInner'

type Props = {
  children?: any
}

const ListItemContent = ({
  children
}: Props) => {
    return (
    <ListItemContentInner>
      {children}
    </ListItemContentInner>
	)
}

export default React.memo(ListItemContent)