import Adapter from "./Adapter"
import Backend from "./Backend"
import FundMapped, {Office} from "../model/FundMapped"
import FundMapper from "../model/FundMapper"
import ObjectUtil from "../utils/ObjectUtil"
import Stream from "../model/Stream"
import StreamMapper from "../model/StreamMapper"
import Publication, { PublicationsByType } from '../model/Publication'
import Notification from "../model/Notification"
import BO_OFFICE from "../model/Office"
import {Funds} from "../model/Funds"
import { RSSFeed } from '../model/RSSFeed';
import { TableSource } from '../pages/backoffice/funds/types'
import {
  BEresponse,
  BOFundDetails,
  SettingsElement,
  GeoAreaList,
  ItemsListForSettingsPage,
  TEnv, SettingsPage, SegmentsList, CrawlDate, BoEvents,
  TagsList,
} from './respType'

const ROUTE_PREFIX = '/api/v1'
const ROUTE_VERSION = `${ROUTE_PREFIX}/version`
const ROUTE_STREAMS = `${ROUTE_PREFIX}/streams`
const ROUTE_STREAMS_BO = `/bo/api/v1/streams`
const ROUTE_STREAMS_MAIN = `/bo/api/v1/streams/main`
const ROUTE_STREAMS_SUB = `/bo/api/v1/streams/sub`
const ROUTE_FUNDS = `${ROUTE_PREFIX}/funds`
const ROUTE_FUNDS_BO = `/bo/api/v1/funds`
const ROUTE_FUNDS_BO_COPY = `${ROUTE_FUNDS_BO}/copy`
const ROUTE_TRENDS = `${ROUTE_PREFIX}/trends`
const ROUTE_PUBLICATIONS = `${ROUTE_PREFIX}/publications`
const ROUTE_PUBLICATIONS_INTERNAL_BO = `/bo/api/v1/publications/internal`
const ROUTE_PUBLICATIONS_BO = `/bo/api/v1/publications`
const ROUTE_NOTIFICATIONS = `${ROUTE_PREFIX}/notifications`
const ROUTE_OFFICES = `${ROUTE_PREFIX}/offices`
const ROUTE_OFFICES_BO = `/bo/api/v1/offices`
const ROUTE_RSS_FEEDS = `/bo${ROUTE_PREFIX}/rss`
const ROUTE_MAIN_KEYWORDS = `/bo${ROUTE_PREFIX}/main-keywords`
const ROUTE_CLICK_COUNTER = `/bo${ROUTE_PREFIX}/click-counter`
const ROUTE_ENV = `${ROUTE_PREFIX}/env`
const ROUTE_GET_AREAS = `${ROUTE_FUNDS}/geo-area`
const ROUTE_GET_TAGS = `${ROUTE_FUNDS}/tag`
const ROUTE_GET_PORTFOLIO = `${ROUTE_FUNDS}/portfolio-product`
const ROUTE_GET_SEGMENTS = `${ROUTE_FUNDS}/segment`
const ROUTE_SETTING_PAGE = `/bo${ROUTE_PREFIX}`
const ROUTE_CRAWL_DATE = `/bo${ROUTE_PREFIX}/crawl-date/crawler`
const ROUTE_EVENTS = `/bo${ROUTE_PREFIX}/event`


const KEY_FUNDS = 'funds'
const KEY_STREAMS = 'streams'
const KEY_OFFICES = 'offices'
const KEY_VERSION = 'version'
const KEY_LAST_MODIFIED = 'last_modified'

export default class DefaultBackend implements Backend {
  adapter: Adapter

  constructor(uri: string) {
    this.adapter = new Adapter(uri)
  }

  async getBasicFunds(): Promise<FundMapped[]> {
    return this.adapter.getJson(ROUTE_FUNDS).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  async getLastUpdated(): Promise<string | undefined> {
    return this.adapter.getJson(ROUTE_FUNDS).then(response => {
      return ObjectUtil.getDateString(response, KEY_LAST_MODIFIED)
    })
  }

  async getTrends(): Promise<Map<string, boolean>> {
    return this.adapter.getJson(ROUTE_TRENDS).then(response => {
      return response
    })
  }

  async getEnv(): Promise<TEnv> {
    return this.adapter.getJson(ROUTE_ENV).then(response => {
      return response
    })
  }

  async getPublications(primaryStream?: string): Promise<Publication[]> {
    const route = primaryStream
        ? (ROUTE_PUBLICATIONS + '?primaryStream=' + primaryStream)
        : ROUTE_PUBLICATIONS
    return this.adapter.getJson(route).then(response => {
      return response
    })
  }

  async getNotifications(primaryStream?: string): Promise<Notification[]> {
    const route = primaryStream
        ? (ROUTE_NOTIFICATIONS + '?primaryStream=' + primaryStream)
        : ROUTE_NOTIFICATIONS
    return this.adapter.getJson(route).then(response => {
      return response
    })
  }

  async getOffices(): Promise<Office[]> {
    return this.adapter.getJson(ROUTE_OFFICES).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_OFFICES)
    })
  }

  /**
   * @method getBasicFundsByStreamName
   * @param streamName
   */

  async getBasicFundsByStreamName(streamName: string): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?primaryStream=${streamName}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * @method getBasicFundsByAmountGTE
   * @param fundingAmountGte – int, ge = 0 – filter funds with amount greater or equal provided value, include funds with funding volume = null
   */
  async getBasicFundsByAmountGTE(fundingAmountGte: number): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?fundingAmount_gte=${fundingAmountGte}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * @method getBasicFundsByKeyword
   * @param name – str, min_lenght = 1 – filter funds  with provided value in the name, case insensitiveput
   */
  async getBasicFundsByKeyword(name: string): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?search=${name}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * @method getBasicFundsByAmountLTE
   * @param fundingAmountLte – int, ge = 0 – filter funds with amount less or equal provided value, include funds with funding volume = null
   */
  async getBasicFundsByAmountLTE(fundingAmountLte: number): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?funding_amount_lte=${fundingAmountLte}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * @method getBasicFundsByGeoArea
   * @param geoArea – str, min_length = 1 – filtering by name of geo Area
   */
  async getBasicFundsByGeoArea(geoArea: string): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?geoArea=${geoArea}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * @method getBasicFundsByDeadline
   * @param deadline – date in ISO format e.g. api/v1/funds?deadline=2021-10-10'– filter funds by date of deadline, include funds with deadline = null
   */
  async getBasicFundsByDeadline(deadline: string): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?deadline=${deadline}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * Filtering with logical AND
   * @method getBasicFundsByFilters
   * @param filters - should be a string of all filters chosen by user
   */
  async getBasicFundsByFilters(filters: string): Promise<{ funds: FundMapped[], last_modified: string | undefined }> {
    const route = `${ROUTE_FUNDS}?${filters}`
    return this.adapter.getJson(route).then(response => {
    const funds = FundMapper.mapAll(response.funds)
    const last_modified = ObjectUtil.getDateString(response, KEY_LAST_MODIFIED)
      return {
        funds,
        last_modified
      }
    })
  }

  /**
   * Filtering with logical AND sorting
   * @method getBasicFundsByFiltersAndOrderedBy
   * @param params - should be a string of all filters chosen by user
   */
  async getBasicFundsByFiltersAndOrderedBy(params: string): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?${params}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }

  /**
   * Filtration: order_by
   * @method getBasicFundsOrderedBy
   * @param parameters - might be
   * +deadline – by deadline ascending
   * -deadline – by deadline descending
   * +fundingVolume – by funding volume ascending
   * -fundingVolume – by funding volume descending
   * +primaryStream – by stream name ascending
   * -primaryStream – by stream name descending
   */
  async getBasicFundsOrderedBy(parameters: string): Promise<FundMapped[]> {
    const route = `${ROUTE_FUNDS}?order_by=${parameters}`
    return this.adapter.getJson(route).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_FUNDS)
    }).then(rawFunds => {
      return FundMapper.mapAll(rawFunds)
    })
  }


  async getVersion(): Promise<string> {
    return this.adapter.getJson(ROUTE_VERSION).then(response => {
      return ObjectUtil.getStringOrThrow(response, KEY_VERSION)
    })
  }

  async getStreams(): Promise<Stream[]> {
    return this.adapter.getJson(ROUTE_STREAMS).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_STREAMS)
    }).then(rawStreams => {
      return StreamMapper.mapAll(rawStreams)
    })
  }

  async getRawStreams(): Promise<{streams:Stream[]}> {
    return this.adapter.getJson(ROUTE_STREAMS)
  }

  async getSubStreams(): Promise<Stream[]> {
    return this.adapter.getJson(ROUTE_STREAMS_SUB).then(response => {
      return ObjectUtil.getArrayOrThrow(response, KEY_STREAMS)
    }).then(rawStreams => {
      return StreamMapper.mapAll(rawStreams)
    })
  }

  async postStream(name:string, keywords: string[] | null): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(ROUTE_STREAMS_MAIN,{name: name, keywords: keywords}, "POST")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log('error')
            return error
          }
        })
  }

  async postSubStream(name:string, streamId: string, keywords: string[] | null): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(ROUTE_STREAMS_SUB,{name, parent_id: streamId, keywords: keywords}, "POST")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log(response)
            return error
          }
        })
  }

  async patchStream(name:string, streamId: string, keywords: string[] | null): Promise<any> {
    const path = `${ROUTE_STREAMS_BO}/${streamId}`
    const error = {detail: 'error'}
    return this.adapter.modifyJson(path, {name, keywords}, "PATCH")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log(response)
            return error
          }
        })
  }

  async postMainKeywords(keywords:string[]): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(ROUTE_MAIN_KEYWORDS,{'main_keywords': keywords}, "POST")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log('error')
            return error
          }
        })
  }

  async getMainKeywords(): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.getJson(ROUTE_MAIN_KEYWORDS,)
        .then( response => {
          if (response || response === null){
            return response["main_keywords"]
          } else {
            console.log('error')
            return error
          }
        })
  }

  async deleteStream(id:string): Promise<any> {
    const path = `${ROUTE_STREAMS_BO}/${id}`
    const error = {detail: 'error'}
    return this.adapter.deleteJson(path)
        .then( response => {
          if (response){
            return response
          } else {
            console.log('error')
            return error
          }
        })
  }

// probably do not needed
  async deleteSubStream(id:string): Promise<any> {
    const path = `${ROUTE_STREAMS_BO}/${id}`
    return this.adapter.deleteJson(path)
        .then( response => response)
  }

  async getMappedFund(id: string): Promise<FundMapped> {
    const route = `${ROUTE_FUNDS}/${id}`

    return this.adapter.getJson(route).then(response => {
      return FundMapper.mapOrThrow(response)
    })
  }

  async getFundById(id: string):Promise<any> {
    const route = `${ROUTE_FUNDS}/${id}`

    return this.adapter.getJson(route).then(response => response)
  }

  async getBOOffices():Promise<BO_OFFICE[]>{
    const route = `${ROUTE_OFFICES_BO}`
    return this.adapter.getJson(route).then( response => response)
  }

  async getBOOfficesOrderedBy(parameter: string):Promise<BO_OFFICE[]>{
    const route = `${ROUTE_OFFICES_BO}?order_by=${parameter}`
    return this.adapter.getJson(route).then( response => response)
  }

  async deleteBOOffice(id:string): Promise<any> {
    const path = `${ROUTE_OFFICES_BO}/${id}`
    return this.adapter.deleteJson(path)
        .then( response => response)
  }

  async patchBOOffice(parameters: any, id: string): Promise<any>{
    const route = `${ROUTE_OFFICES_BO}/${id}`
    return this.adapter.modifyJson(route, parameters, "PATCH").then(response => response)
  }

  async postBOOffice(parameters: any): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(ROUTE_OFFICES_BO, parameters, "POST")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log('error')
            return error
          }
        })
  }

  async getBOPublications(type: string):Promise<Publication[]>{
    const route = `${ROUTE_PUBLICATIONS_BO}/${type}`
    return this.adapter.getJson(route).then( response => response)
  }

  async getPublicationsBOByType(type: string): Promise<PublicationsByType> {
    const route = `${ROUTE_PUBLICATIONS_BO}/${type}`
    return this.adapter.getJson(route).then(response => {
      return response
    })
  }

  async getBOPublicationsOrderedBy(type: string, parameters: string):Promise<PublicationsByType>{
    const route = `/bo/api/v1/publications/${type}?order_by=${parameters}`
    return this.adapter.getJson(route).then( response => response)
  }

  async postPublicationsInternal(parameters: any): Promise<any>{
    return this.adapter.modifyJson(ROUTE_PUBLICATIONS_INTERNAL_BO, parameters, "POST").then(response => response)
  }

  async patchPublications(parameters: any, id: string): Promise<any>{
    const route = `${ROUTE_PUBLICATIONS_BO}/${id}`
    return this.adapter.modifyJson(route, parameters, "PATCH").then(response => response)
  }

  async getBOFunds(): Promise<Funds>{
    const route = ROUTE_FUNDS
    return this.adapter.getJson(route).then(response => response)
  }

  async getBOFundsByType(type: TableSource, params?:string,orderBy?:string): Promise<Funds>{
    let route = `${ROUTE_FUNDS_BO}/${type}`
    const allParams = []
    if(params) allParams.push(params)
    if(orderBy) allParams.push(`order_by=${orderBy}`)
    if(allParams.length > 0) route = `${route}?${allParams.join('&')}`
    return this.adapter.getJson(route).then(response => response)
  }

  getBOFundsByTypeAndOrderBy(type: string, parameters: string): Promise<Funds> {
    const route = `${ROUTE_FUNDS_BO}/${type}?order_by=${parameters}`
    return this.adapter.getJson(route).then(response => response)
  }

  async patchBOFund(params: any, fundsId: string): Promise<BEresponse> {
    const path = `${ROUTE_FUNDS_BO}/${fundsId}`
    return this.adapter.modifyJson(path, params, "PATCH").then(response => response)
  }

  async postBOFund(parameters: any): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(ROUTE_FUNDS_BO, parameters, "POST")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log('error', response)
            return error
          }
        })
  }

  async downloadPresentation(id: string, fileName:string):Promise<void | BEresponse> {
    const error = {detail: 'error'}
    return this.adapter.downloadFile(`/api/v1/funds/${id}/presentation`, fileName).catch(()=> error)
  }

  async copyBOFund(parameters: {id:string}): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(`${ROUTE_FUNDS_BO_COPY}/${parameters.id}`, parameters, 'POST')
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log('error', response)
            return error
          }
        })
  }

  async getRSSFeeds(): Promise<RSSFeed[]>{
    const route = ROUTE_RSS_FEEDS
    return this.adapter.getJson(route).then(response => response)
  }

  async getBORSSFeedOrderBy(parameter: string):Promise<RSSFeed[]>{
    const route = `${ROUTE_RSS_FEEDS}?order_by=${parameter}`
    return this.adapter.getJson(route).then( response => response)
  }

  async patchRSSFeed(parameters: any, rssFeedId: string): Promise<RSSFeed>{
    const route = `${ROUTE_RSS_FEEDS}/${rssFeedId}`
    return this.adapter.modifyJson(route, parameters, "PATCH").then(response => response)
  }

  async deleteBORSSFeed(id:string): Promise<any> {
    const path = `${ROUTE_RSS_FEEDS}/${id}`
    return this.adapter.deleteJson(path)
        .then( response => response)
  }

  async postRSSFeed(parameters: any): Promise<any> {
    const error = {detail: 'error'}
    return this.adapter.modifyJson(ROUTE_RSS_FEEDS, parameters, "POST")
        .then( response => {
          if (response || response === null){
            return response
          } else {
            console.log('error')
            return error
          }
        })
  }
  async addViewToElement(action:string) {
    const body = {
      click_type: action
    }

    await this.adapter.modifyJson(`${ROUTE_CLICK_COUNTER}`,body, "POST")
  }

  async getViewsAnalytics() {
    const error = {detail: 'error in getting list of clicks'}
    return this.adapter.getJson(ROUTE_CLICK_COUNTER)
      .then( response => {
        if (response || response === null){
          return response
        } else {
          console.log('error')
          return error
        }
      })
  }

  async getGeoAreaList():Promise<GeoAreaList> {
    return this.adapter.getJson(ROUTE_GET_AREAS).then(response => response)
  }

  async getTagsList(): Promise<TagsList> {
    return this.adapter.getJson(ROUTE_GET_TAGS).then(response => response)
  }

  async getPortfolioList(): Promise<TagsList> {
    return this.adapter.getJson(ROUTE_GET_PORTFOLIO).then(response => response)
  }

  async getSegmentsList():Promise<SegmentsList> {
    return this.adapter.getJson(ROUTE_GET_SEGMENTS).then(response => response)
  }

  async getItemsListForSettingsPage(page:SettingsPage):Promise<ItemsListForSettingsPage> {
    return this.adapter.getJson(`${ROUTE_SETTING_PAGE}/${page}`).then(response => response)
  }

  async createElementForSettingsPage(page: SettingsPage, body:SettingsElement):Promise<BEresponse> {
    const type = body.parent_id ? 'sub' : 'main'
    return this.adapter.modifyJson(`${ROUTE_SETTING_PAGE}/${page}/${type}`,body,'POST')
        .then(response => response)
  }

  async deleteElementFromSettingsPage(page: SettingsPage, id:SettingsElement['id']):Promise<BEresponse> {
    return this.adapter.deleteJson(`${ROUTE_SETTING_PAGE}/${page}/${id}`)
        .then(response => response)
  }

  async editElementInSettingsPage(page: SettingsPage, body:SettingsElement):Promise<BEresponse> {
    return this.adapter.modifyJson(`${ROUTE_SETTING_PAGE}/${page}/${body.id}`, body, 'PATCH')
        .then(response => response)
  }

  async getBOFundDetails(id:BOFundDetails['id']):Promise<BOFundDetails> {
    return this.adapter.getJson(`${ROUTE_FUNDS_BO}/${id}`).then(response => response)
  }

  async getCrawlDate():Promise<CrawlDate> {
    return this.adapter.getJson(ROUTE_CRAWL_DATE)
        .then(resp => resp)
  }

  async getBoEvent():Promise<BoEvents> {
    return this.adapter.getJson(ROUTE_EVENTS)
        .then(resp => resp)
  }

  async postBoEvent(body: BoEvents):Promise<BEresponse> {
    return this.adapter.modifyJson(ROUTE_EVENTS, body, 'POST')
        .then(resp => resp)
  }

  async patchBoEvent(body: BoEvents):Promise<BEresponse> {
    return this.adapter.modifyJson(ROUTE_EVENTS, body, 'PATCH')
        .then(resp => resp)
  }
}