import React from 'react'
import {NavLink} from "react-router-dom"

import i18n from '../i18n'
import { withTranslation, Translation } from 'react-i18next'

// styled components
import {ThemeProvider} from 'styled-components/macro'
import {MainTheme} from '../theme/MainTheme'

// scale components
import {
    AppMegaMenu,
    ScaleAppFooter,
    ScaleAppHeader,
    ScaleIconActionClose,
    ScaleIconActionSearch,
    ScaleIconCommunicationEmail,
    ScaleDropdown
} from '@telekom/scale-components-react'

// components
import RadarTooltip, {TooltipConfig} from '../components/Radar/RadarTooltip'
import Config from '../utils/Config'
import {SearchPage} from "./search/search-page";
import BackendFactory from "../backend/BackendFactory";
import { getContactEmail } from '../helpers/getContactEmail'
import { backofficeLinks } from '../app/RouterLinks'
import { SystemNotification } from '../components/SystemNotification/SystemNotification'

interface PageContainerProps {
    children?: React.ReactNode
    tooltipConfig?: TooltipConfig
    onChangeTooltip: (index: number) => void
}
interface PageContainerState {
    secondLayerIsOpened: boolean,
    searchModeIsActive: boolean,
    searchValue?: string,
    showSearch?: boolean,
    stream?: string,
    version?: string,
    language?: string
}
const FOOTER_NAVIGATION = [
  { name: "Contact", id: "Contact", href: "mailto:competencecenterfoerderung@telekom.de?subject=Fund2Cash" }
]

class PageContainer extends React.Component<PageContainerProps, PageContainerState> {
    url: string
    versionNCopy: string | undefined

    constructor(props: PageContainerProps) {
        super(props)
        this.url = window.location.protocol + "//" + window.location.host
        this.state = {
            secondLayerIsOpened: false,
            searchModeIsActive: false,
            showSearch: false,
            language: i18n.language
        }
        this.handleScroll = this.handleScroll.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

  componentDidUpdate() {
        if (this.state.version === undefined){
            this.loadBackendVersion()
        }
  }

  componentDidMount() {
        this.loadBackendVersion()
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('mousedown', this.handleClickOutside)
}

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  loadBackendVersion() {
      BackendFactory.produce().getVersion()
          .then(version => {
              this.setState({...this.state, version })
              return this.versionNCopy = `Version ${this.state.version} \xa0\xa0\xa0\xa0\xa0\xa0\xa0 © Deutsche Telekom AG`
          })
          .catch(error => this.setState({ version: JSON.stringify(error) }))
 }

  isMenuOpen(): boolean {
    const menuHeader = document.getElementsByClassName("header")[0]
    if (menuHeader) {
        return menuHeader.classList.contains("menu--open")
    }

    return false
  }

  closeMenu() {
    const closingIcon: HTMLElement = document.getElementsByClassName("meta-navigation__item-link")[0] as HTMLElement
    closingIcon.click()
  }

  handleClickOutside(event: any) {
    if (window.innerWidth <= 1023) {
      const header = document.getElementsByClassName("header")[0]
      if (header && !header.contains(event.target)) {
        if (this.isMenuOpen()) {
          this.closeMenu()
        }
      }
    } 
  }

  private handleScroll() {
    if (window.innerWidth <= 1023) {
      if (this.isMenuOpen()) {
        this.closeMenu()
      }
    }
  }

    isActive=((match : any) => {
    if (! match) { return false;}
    return  match.isExact
  })

    onSearchResults = (event: any) => {
     return  this.setState({
          searchModeIsActive: true,
         searchValue: event.target.value
      })
    }

    changeLanguageHandler = (e: any) => {
      const lang = e.target.value
      i18n.changeLanguage(lang)
      localStorage.setItem("i18nextLng", lang)
      window.location.reload()
    }

  render() {
      return (
      <ThemeProvider theme={MainTheme}>
        <div className="App" id="app">
            <ScaleAppHeader slot="header" id="header" portalName={Config.APP_NAME_STYLED} logo-title="Home" logo-href="/" >
              <div slot="menu-main" className="main-navigation">
                        <li className="main-navigation__item">
                            <NavLink to="/"    id="home" className="main-navigation__item-link"
                                     onClick={ (e)=> {this.setState({ secondLayerIsOpened: false})}}
                                     isActive={this.isActive}>
                                    <Translation>
                                      {(t) => <span className="main-navigation__item-link-text">{t("RP:RP-M1")}</span>}
                                    </Translation>
                            </NavLink>
                        </li>
                        <li className="main-navigation__item">
                            <NavLink to="/allocations"   id="allocations" className="main-navigation__item-link"
                                     isActive={this.isActive} 
                                     onClick={ (e)=> {this.setState({ secondLayerIsOpened: false})}}
                            >
                              <Translation>
                                {(t) => <span className="main-navigation__item-link-text">{t("RP:RP-M2")}</span>}
                              </Translation>
                            </NavLink>
                        </li>
                        <li className={this.state.secondLayerIsOpened  ? `main-navigation__item mega-menu--visible` : `main-navigation__item`}>
                            <span    id="backoffice" className="main-navigation__item-link"
                                     onClick={ (e)=> {this.setState({
                                         secondLayerIsOpened: !this.state.secondLayerIsOpened
                                     })}}
                            >
                                
                              <Translation>
                                {(t) => <span className={window.location.href.includes(`/backoffice/`)
                              ? "main-navigation__item-link-text active" :  "main-navigation__item-link-text"}>{t("RP:RP-M3")}</span>}
                              </Translation>
                            </span>

                            <AppMegaMenu aria-haspopup={true} >
                                 <div slot="custom-body">
                                     <div className="mega-menu__row-title"/>
                                     <ul >
                                       {backofficeLinks.map(link => (
                                           <li className='mega-menu__row-item' key={link.id}>
                                             <a href={link.href} id={link.id}
                                                      className={`mega-menu__item-link ${link.disabled && 'mega-menu__item-link--disabled'}`}
                                                      onClick={(e) => {
                                                        this.setState({ secondLayerIsOpened: false })
                                                      }}>
                                               <span className='main-navigation__item-link-text'>{link.linkName}</span>
                                             </a>
                                           </li>
                                         ))}
                                     </ul>
                                    </div>
                            </AppMegaMenu>
                        </li>
                    </div>
                <div slot='menu-icon'>
                    <SystemNotification  />
                </div>
              <Translation>
                {(t) => <ScaleDropdown
                  className='language-selector'
                  slot="menu-icon"
                  value={this.state.language}
                  size='small'
                  label={String(t("RP:RP-ML"))}
                  onScaleChange={this.changeLanguageHandler}
                >
                  <option value="en">English</option>
                  <option value="de">Deutsch</option>
                </ScaleDropdown>}
              </Translation>
                <div slot="menu-icon" onMouseOver={()=> this.setState({secondLayerIsOpened: false})}>
                    <ul className="meta-navigation">
                        <li className="meta-navigation__item">
                            <span className={`main-search-icon ${!this.state.showSearch ? 'main-search-icon--mobile-nav' : 'main-search-icon--hidden'}`}
                                  onClick={()=> this.setState({showSearch: true})}>
                                <ScaleIconActionSearch className={`main-search__icon  ${!this.state.showSearch ? 'main-search__icon--mobile-nav' : ''}`}/></span>
                            <label className={`main-search ${this.state.showSearch ? 'main-search--mobile-nav' : 'main-search--desktop-nav'}`}
                                   aria-label='Förderprogramme suchen' tabIndex={0}>
                                <ScaleIconActionSearch className="main-search__icon"/>
                                <Translation>
                                  {
                                    (t) => <input type={'search'}
                                    placeholder={String(t("RP:RP-MSearch"))}
                                    className="main-search__field"
                                    value={this.state.searchValue || ''}
                                    onBlur={()=> this.setState({showSearch: false})}
                                    onChange={(e)=> this.onSearchResults(e)}/>
                                  }
                                </Translation>
                                
                            </label>
                        </li>
                    </ul>
                </div>

                
                <div slot="menu-mobile">
                        <li className="main-navigation__item main-navigation__item--mobile">
                            <NavLink to="/"    id="home" className="main-navigation__item-link"
                                     onClick={ (e)=> {
                                         e.preventDefault();
                                         window.location.href = `${(this.url)}/`}
                                     }
                                     isActive={this.isActive}>
                                <Translation>
                                  {(t) => <span className="main-navigation__item-link-text">{t("RP:RP-M1")}</span>}
                                </Translation>
                                
                            </NavLink>
                        </li>
                        <li className="main-navigation__item main-navigation__item--mobile">
                            <NavLink to="/allocations"   id="allocations" className="main-navigation__item-link"
                                     isActive={this.isActive}>
                                
                                <Translation>
                                  {(t) => <span className="main-navigation__item-link-text">{t("RP:RP-M2")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li className="main-navigation__item main-navigation__item--mobile main-navigation__item--spacer">
                            <div className="navigation-spacer" />
                        </li>
                        <li className="main-navigation__item main-navigation__item--mobile">
                          <Translation>
                            {
                              (t) => {
                                const email = getContactEmail()
                                return (
                                  <a href={`mailto:${email}?subject=Fund2Cash`} id="contact" className="main-navigation__item-link">
                                    <span className="main-navigation__item-link-text main-navigation__item-link-text-smaller">
                                      <span className="navigation-email-icon">
                                        <ScaleIconCommunicationEmail size={14} />
                                      </span>
                                        {t("FP:RP-FP-extContact")}
                                    </span>
                                  </a>
                                )
                              }
                            }
                          </Translation>

                        </li>
                    </div>
            </ScaleAppHeader>

            <div className="main-container" onClick={()=> {this.setState({secondLayerIsOpened: false})}}>
              {this.state.searchModeIsActive && this.state.searchValue && this.state.searchValue.length > 1
                  ? <>
                      <div className="search-page-container-close"
                           onClick={()=> this.setState({
                               searchModeIsActive: false,
                               searchValue: ''
                           })}
                           title="Abbrechen" tabIndex={0}>
                          <ScaleIconActionClose size={32}/>
                      </div>
                      <SearchPage searchValue={this.state.searchValue}/>
                  </>: this.props.children}
                <RadarTooltip config={this.props.tooltipConfig as TooltipConfig} onChangeTooltip={this.props.onChangeTooltip}/>
            </div>


            {this.versionNCopy
            && <ScaleAppFooter footerNavigation={FOOTER_NAVIGATION} copyright={this.versionNCopy}/>}
          <div className="modal-background" id="modal-root"/>
        </div>
      </ThemeProvider>
    )
  }
}

export default withTranslation("menu")(PageContainer)