import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import SortingIcons from "../../../../components/SortingIcons/SortingIcons"
import { HeaderProps } from '../types'
import { FundsContext } from '../fundsContext'

export const PublishedHeader = ({onSortingAction}: HeaderProps) => {
    const { t } = useTranslation(["table","BO"])
    const { sortParams } = useContext(FundsContext)

    return (
        <>
            <tr>
            <th className='column column--with-padding column--small column--header'>
                    <SortingIcons sortParamName='fp_number' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-RFPNr")}</span>
                </th>
                <th className='column column--with-padding column--large column--header'>
                    <SortingIcons sortParamName='name' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-NFPC1")}</span>
                </th>
                <th className='column column--with-padding column--small column--header'>
                    <SortingIcons sortParamName='primaryStream' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("stream")}</span>
                </th>
                <th className='column column--with-padding column--medium column--header'>
                    <SortingIcons sortParamName='maturity' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-RFPPhase")}</span>
                </th>
                <th className='column column--with-padding column--medium column--header'>
                    <SortingIcons sortParamName='geoArea' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("F:RP-F-FPArea")}</span>
                </th>
                <th className='column column--with-padding column--medium column--header'>
                    <SortingIcons sortParamName='startApplicationPeriod' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-RFPSAn")}</span>
                </th>
                <th className='column column--with-padding column--medium column--header'>
                    <SortingIcons sortParamName='deadline' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-RFPEAn")}</span>
                </th>
                <th className='column column--with-padding column--medium column--header'>
                    <SortingIcons sortParamName='status' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-RFPHigh")}</span>
                </th>
                <th className='column column--with-padding column--medium column--header'>
                    <SortingIcons sortParamName='internalStatus' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("BO:BO-FP-NFPC5")}</span>
                </th>
                <th className='column column--large'/>
            </tr>
        </>
    )
}