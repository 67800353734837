import { Point } from "./GeometryModule"

export interface RadarConfig {
  radius: number
  leftMargin: number
  rightMargin: number
  headerHeight: number
  footerHeight: number
  seed: number
  diamondSize: number
  specialDiamondOuterSize: number
  specialDiamondInnerSize: number
  sectorLabelDistance: number
  colors: {
    grid: string
  },
  legend: {
    fontSize: string
    fontSizeInner: string
  },
  radarRingSizes: {
    maturity0: number
    maturity1: number
    maturity2: number
    maturity3: number
    maturity4: number
  }
  trend: {
    fontSize: number
    width: number
    height: number
  },
  labels: {
    fontSize: string
    dotRadius: number
    offset: Point
  },
  diamonds: {
    fontSize: string
    offset: Point
  },
  bigDiamonds: {
    diamondSize: number,
    fontSize: string,
  }
}


export const RADAR_CONFIG_NORMAL: RadarConfig = {
  radius: 520,
  leftMargin: 170,
  rightMargin: 170,
  headerHeight: 40,
  footerHeight: 68,
  seed: 44,
  diamondSize: 6.5,
  specialDiamondOuterSize: 15.556,
  specialDiamondInnerSize: 10.556,
  sectorLabelDistance: 550,
  colors: {
    grid: '#eee',
  },
  legend: {
    fontSize: '12.5px',
    fontSizeInner: '14.5px',
  },
  trend: {
    fontSize: 12,
    width: 65,
    height: 18,
  },
  labels: {
    fontSize: '15px',
    dotRadius: 5,
    offset: { x: -14, y: 2 },
  },
  radarRingSizes: {
    maturity0: 0.82,
    maturity1: 0.65,
    maturity2: 0.44,
    maturity3: 0.15,
    maturity4: 0.06,
  },
  diamonds: {
    fontSize: '10px',
    offset: { x: 12, y: 4 },
  },
  bigDiamonds: {
    diamondSize: 8,
    fontSize: '28px',
  },
} as const


export const RADAR_CONFIG_SMALLER: RadarConfig = {
  radius: 360,
  leftMargin: 130,
  rightMargin: 130,
  headerHeight: 100,
  footerHeight: 65,
  seed: 44,
  diamondSize: 4.5,
  specialDiamondOuterSize: 13.556,
  specialDiamondInnerSize: 8.556,
  sectorLabelDistance: 385,
  colors: {
    grid: '#eee',
  },
  legend: {
    fontSize: '9px',
    fontSizeInner: '12px',
  },
  trend: {
    fontSize: 10,
    width: 54,
    height: 14,
  },
  labels: {
    fontSize: '12px',
    dotRadius: 3.5,
    offset: { x: 8, y: 5 },
  },
  radarRingSizes: {
    maturity0: 0.82,
    maturity1: 0.65,
    maturity2: 0.44,
    maturity3: 0.15,
    maturity4: 0.06,
  },
  diamonds: {
    fontSize: '9px',
    offset: { x: 7, y: 3 },
  },
  bigDiamonds: {
    diamondSize: 6,
    fontSize: '22px',
  },
} as const


export class RadarConfigQuarter implements RadarConfig {
  radius = 919
  mobileWidth = 446
  mobileHeight = 484
  leftMargin = 5
  rightMargin = 5
  headerHeight = 0
  footerHeight = 65
  seed = 44
  diamondSize = 11
  specialDiamondOuterSize = 20.556
  specialDiamondInnerSize = 15.556
  sectorLabelDistance = 470
  colors = {
    grid: '#eee',
  }
  legend = {
    fontSize: '11px',
    fontSizeInner: '13px',
  }
  trend = {
    fontSize: 12,
    width: 62,
    height: 18,
  }
  labels = {
    fontSize: '15px',
    dotRadius: 5,
    offset: { x: 12, y: 5 },
  }
  radarRingSizes = {
    maturity4: 0.5,
    maturity3: 0.6,
    maturity2: 0.7,
    maturity1: 0.8,
    maturity0: 0.9
  }
  diamonds = {
    fontSize: '17px',
    offset: { x: 22, y: 5 },
  }
  bigDiamonds = {
    diamondSize: 10,
    fontSize: '35px',
  }
}

export const RADAR_CONFIG_QUARTER = new RadarConfigQuarter()