import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PlusIcon } from '../../../../icons/plus-icon.svg'
import { ScaleTable } from '@telekom/scale-components-react'
import { RelevantHeader } from './relevant-header'
import { RelevantRow } from './relevant-row'

import { FundTableProps } from '../types'


export const RelevantTable = (
    {
        funds,
        changeSortParams,
        openCreateModal,
        openEditModal,
        editFund,
        copyFund,
        openDeleteModal,
        openArchiveModal,
    }: FundTableProps) => {
    const { t } = useTranslation('table')
    const [activeId, setActiveId] = useState<string>()


    const onSortingAction = (params: string) => {
        changeSortParams(params)
    }
    return (
        <>
            <div className='bo-button-container'>
                <div />
                <button className='action-button action-button--magenta'
                        onClick={() => {
                            if (openCreateModal) {
                                openCreateModal()
                            }
                        }}>
                    <PlusIcon /> {t('BO:BO-FP-RFPCreate')}
                </button>
            </div>

            <ScaleTable size={'medium'} className='publications-table'>
                <table>
                    <thead>
                    <RelevantHeader onSortingAction={onSortingAction} />
                    </thead>
                    <tbody>
                    {funds.map((fund) => (
                        <RelevantRow
                            key={fund.id}
                            fund={fund}
                            setActiveRow={setActiveId}
                            active={activeId === fund.id}
                            openAlertDeleteDialog={() => openDeleteModal(fund.id)}
                            openAlertArchiveDialog={() => openArchiveModal(fund.id)}
                            openEditDialog={() => openEditModal(fund.id)}
                            editFund={(id, body) => editFund(id, body)}
                            onCopyFund={() => copyFund(fund.id)}
                        />
                    ))}
                    </tbody>
                </table>
            </ScaleTable>
        </>
    )
}
