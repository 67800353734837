import styled from 'styled-components/macro'

type Props = {
  theme: any
}

const ListItemTitle = styled('p')<Props>`
  position: relative;
  margin: 0 0 ${props => props.theme.spacings.smaller.margin};
  font-size: ${props => props.theme.typography.tooltip.listItem.title.fontSize};
  font-weight: ${props => props.theme.typography.tooltip.listItem.title.fontWeight};
`

export default ListItemTitle