import styled from 'styled-components/macro'

type Props = {

}

const StepItemNumber = styled('span')<Props>`
  display: block;
  position: relative;
  font-size: ${props => props.theme?.typography?.detailPage.step.fontSize};
  font-weight: ${props => props.theme?.typography?.detailPage.step.fontWeight};
  text-align: center;
  z-index: 5;

  @media (min-width: ${props => props.theme?.breakpoints?.laptop}) {
    display: none;
  }
`

export default StepItemNumber