import React, { useEffect, useState } from 'react'
import { ReactComponent as DetailsIcon } from '../../../icons/details-icon.svg'
import BreadcrumbsElem from '../../../components/breadcrumbs/breadcrumbsElem'
import styles from './reports.module.scss'
import { useTranslation } from 'react-i18next'
import BackendFactory from '../../../backend/BackendFactory'
import { CrawlDate } from '../../../backend/respType'
import moment from 'moment'

export const ReportsPage = () => {
    const { t } = useTranslation(['reports'])
    const backend = BackendFactory.produce()
    const [crawlDate, setCrawlDate] = useState<CrawlDate>()

    useEffect(() => {
        backend.getCrawlDate().then(resp => {
            setCrawlDate(resp)
        })
    }, [])
    return (
        <div>
            <BreadcrumbsElem
                linksArray={[
                    { href: '/', text: 'Förderradar' },
                    { href: '/', text: 'Backoffice', disabled: true },
                ]}
                current={{ href: '/backoffice/reports', text: t('menu:linkReports') }}
            />
            <div className={styles.wrapper}>
                <div className={styles.linkContainer}>
                    <a className='link link--no-underline'
                       href='../bo/api/v1/click-counter/export/csv'
                       target={'_blank'}
                       rel='noreferrer'
                    >
                        <span className='link__icon'><DetailsIcon /> Statistik zur Nutzung</span>
                    </a>
                </div>
                <div className={styles.linkContainer}>
                    <a className='link link--no-underline'
                       href='../bo/api/v1/funds/export-csv'
                       target={'_blank'}
                       rel='noreferrer'
                    >
                        <span className='link__icon'><DetailsIcon /> Die FP des Förderradar</span>
                    </a>
                </div>
                <div className={styles.linkContainer}>
                    <a className='link link--no-underline'
                       href='../bo/api/v1/funds/export-json'
                       target={'_blank'}
                       rel='noreferrer'
                    >
                        <span className='link__icon'><DetailsIcon /> Alle FP (Crawler und Förderradar)</span>
                    </a>
                </div>
                <div className={styles.linkContainer}>
                    <hr />

                    {crawlDate ?
                      <div>
                        <h2>{t('reports:BO-BuM-CRSt')}:</h2>
                        <p>{t('reports:BO-BuM-CRLr')}: {crawlDate.last_run ? moment(crawlDate.last_run).format('YYYY-MM-DD / HH:MM') : '-- / --'}</p>
                        <p>{t('reports:BO-BuM-CRRe')}: {crawlDate.status}</p>
                        <p>{t('reports:BO-BuM-CRNr')}: {crawlDate.next_run ? moment(crawlDate.next_run).format('YYYY-MM-DD / HH:MM') : '-- / --'}</p>
                        <p>{t('reports:BO-BuM-CRh')}: {crawlDate.intervals}</p>
                      </div>
                        :
                        <h2>
                           ERR: Failed to obtain crawler status
                        </h2>
                    }
                    <hr />
                </div>

            </div>
        </div>

    )
}