import './legend.scss'
import { useTranslation } from 'react-i18next'

import React from 'react'

export interface LegendObject  {
title: string, icon?: any
}

interface Props{
    itemsArray: LegendObject[]
}

export  const Legend = ({itemsArray}: Props) => {
    const { t } = useTranslation()

    return <div className="legend">
        <div className="legend__title">{t("RP:RP-LegendInfo")}</div>
        <div className="legend__container">
            {itemsArray.map( (item, i)=> <div  className="legend__item" key={i*Math.floor(i) + '_legend_item'}>
                <item.icon/>
                <div className="legend__name">{item.title}</div>
            </div>
            )}
        </div>
    </div>
}

export default Legend