import React from 'react'
import { t } from 'i18next'
import { withTranslation } from 'react-i18next'

import ProgressbarInner from './__styled__/ProgressbarInner'
import StepItem from './__styled__/StepItem'
import StepItemNumber from './__styled__/StepItemNumber'
import StepItemTitle from './__styled__/StepItemTitle'

interface Props {
  maturity?: string,
  maturities?: string[]
}

class Progressbar extends React.Component<Props, any> {

  render() {
    const MATURITIES = [
    "Ressortzuteilung",
    "Förderrichtlinie",
    "Notifizierung",
    "Ausschreibung",
    "Zuteilung",
    ] as const
    
    const items = this.props.maturities || MATURITIES

    return (
      <ProgressbarInner>
        {
          items.map((it, index) =>
            <StepItem isActive={this.props.maturity === it} key={index}>
              <StepItemNumber>{index + 1}</StepItemNumber>
              <StepItemTitle>{t(`RP:RP-Status-${index + 1}`)}</StepItemTitle>
            </StepItem>
          )
        }
      </ProgressbarInner>
    )
  }
}

export default withTranslation("details")(Progressbar)