import styled from 'styled-components/macro'

type Props = {
  isClear?: boolean
}

const ListItemInner = styled('li')<Props>`
  position: relative;
  margin-bottom: ${props => props.theme.spacings.small.margin};
  padding-bottom: ${props => props.theme.spacings.small.margin};
`

export default ListItemInner