import {useEffect} from "react";

export const useOutsideCloser = (ref:any, closeDialog: ()=> void) => {
    useEffect(() => {

        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                closeDialog()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, closeDialog]);
}
