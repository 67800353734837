import React, { useState } from 'react'

import { ArchivedRow } from './archived-row'
import { ArchivedHeader } from './archived-header'
import { ScaleTable } from '@telekom/scale-components-react'
import { Fund } from '../../../../model/FundNew'

import { FundTableProps } from '../types'
// import { ModalActions } from '../../../../components/F2CFormGenerator/types'
// import { useTranslation } from 'react-i18next'


export const ArchivedTable = (
    {
        funds,
        changeSortParams,
        openDeleteModal,
        openEditModal,
        copyFund,
        editFund
    }: FundTableProps) => {
    // const { t } = useTranslation("table")
    const [activeId, setActiveId] = useState<string>()

    const onSortingAction = (params: string) => {
      changeSortParams(params)
    }

    const renderList = (funds: Fund[])=>{

        return (<>
                <div className="funds-spacer-title"></div>
                {funds && <ScaleTable size={"medium"} className="publications-table" >
                    <table>
                        <thead>
                            <ArchivedHeader onSortingAction={onSortingAction}/>
                        </thead>
                        <tbody>
                            {funds?.map((fund) => (
                                <ArchivedRow
                                    key={fund.id}
                                    fund={fund}
                                    setActiveRow={setActiveId}
                                    active={activeId === fund.id}
                                    openAlertDeleteDialog={() => openDeleteModal(fund.id)}
                                    openEditDialog={() => openEditModal(fund.id)}
                                    editFund={(id, body) => editFund(id, body)}
                                    onCopyFund={() => copyFund(fund.id)}
                                />
                            ))}
                        </tbody>
                    </table>
                </ScaleTable>}
            </>
        )
    }
    return renderList(funds)
}