import styled from 'styled-components/macro'

type Props = {

}

const StepItemTitle = styled('span')<Props>`
  display: none;
  position: relative;
  font-size: ${props => props.theme.typography?.detailPage?.step.fontSize};
  font-weight: ${props => props.theme.typography?.detailPage?.step.fontWeight};
  text-align: center;
  z-index: 5;

  @media (min-width: ${props => props.theme?.breakpoints?.laptop}) {
    display: block;
  }
`

export default StepItemTitle