import React, { FC, useEffect, useRef, useState } from 'react'
import { F2CAccordion } from '../../../components/F2CAccordion/F2CAccordion'
import { useTranslation } from 'react-i18next'
import { AlertModal } from './AlertModal'
import { ScaleButton } from '@telekom/scale-components-react'
import {
    SettingsElement,
    ItemsListForSettingsPage,
} from '../../../backend/respType'
import Backend from '../../../backend/Backend'
import BackendFactory from '../../../backend/BackendFactory'
import styles from './PageContent.module.scss'
import { Toast } from 'primereact/toast'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { geoAreaFormConfig } from '../../../env/settingsGeoArea/geoAreaFormConfig'
import { geoAreaFormSchema } from '../../../env/settingsGeoArea/validationSchema'
import { ModalActions } from '../../../components/F2CFormGenerator/types'


export const GeoArea: FC = () => {
    const initContact: SettingsElement = { name: '', id: '', email: '', affectedFunds: [], subList: [] }
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)
    const [currentContact, setCurrentContact] = useState<SettingsElement>(initContact)
    const [geoAreaList, setGeoAreaList] = useState<ItemsListForSettingsPage>()
    const backend: Backend = BackendFactory.produce()
    const toast = useRef<Toast>(null)
    const { t } = useTranslation('SettingsPage')

    useEffect(() => {
        updateGeoAreaList()
    }, [])
    // console.log(currentContact)
    const updateGeoAreaList = (): void => {
        backend.getItemsListForSettingsPage('geo_areas').then(resp => {
            if(resp.data) {
                setGeoAreaList(resp)
            }else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
            }
        })
    }


    const deleteContact = (): void => {
        backend.deleteElementFromSettingsPage('geo_areas', currentContact.id)
            .then((resp) => {
                if (resp?.detail === 'SUCCESS') {
                    updateGeoAreaList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('SettingsPage:geoAreaDeletedSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: t('SettingsPage:geoAreaDeletedError'),
                        life: 5000,
                    })
                }
            })

    }

    const openModal = (modalType: ModalActions, geoAreaContact: SettingsElement): void => {
        setModal(modalType)
        setCurrentContact(geoAreaContact)
    }

    const closeModal = () => {
        setModal(ModalActions.REMOVE)
        setCurrentContact(initContact)
    }

    const createGeoArea = (data: SettingsElement) => {
        backend.createElementForSettingsPage('geo_areas',{
            affectedFunds: [], id: '', subList: [],
            name:data.name,
            email: data.email,
            parent_id: modal === 'createSub' ? data.id : undefined
        }).then(resp => {
            if (resp?.detail === 'SUCCESS') {
                updateGeoAreaList()
                setModal(ModalActions.REMOVE)
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('SettingsPage:geoAreaCreateSuccess'),
                    life: 5000,
                })
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:geoAreaCreateError'), life: 5000 })
            }
        })
    }

    const editGeoArea = (formData:SettingsElement) => {
        backend.editElementInSettingsPage('geo_areas',formData)
            .then(resp => {
                if (resp?.detail === 'SUCCESS') {
                    updateGeoAreaList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('SettingsPage:geoAreaEditSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:geoAreaEditError'), life: 5000 })
                }
            })
    }

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.settingsHead}>
                <div>
                    <h1>{t('SettingsPage:BO-Set-Item1-titel')}</h1>
                    <h2>{t('SettingsPage:BO-Set-Item1-sub')}</h2>
                </div>
                <ScaleButton
                    variant='primary'
                    onClick={() => openModal(ModalActions.CREATE, initContact)}
                >
                    {t('SettingsPage:addContact')}
                </ScaleButton>
            </div>

            {geoAreaList &&
              <F2CAccordion data={geoAreaList.data}
                            onItemEdit={item => openModal(ModalActions.EDIT, item as SettingsElement)}
                            onItemDelete={item => {
                                if (item.subList.length > 0) openModal(ModalActions.ALERT_DELETE, item as SettingsElement)
                                else openModal(ModalActions.ALERT_DELETE, item as SettingsElement)
                            }}
                            onItemCreate={id => openModal(ModalActions.CREATE_SUB, {
                                ...initContact,
                                id: id,
                            })}
                            onSubItemDelete={item => openModal(ModalActions.ALERT_DELETE, item as SettingsElement)}
                            onSubItemEdit={item => openModal(ModalActions.EDIT, item as SettingsElement)}
              />}
            <AlertModal isOpen={modal === ModalActions.ALERT_DELETE || modal === ModalActions.ALERT}
                        title={String(t('SettingsPage:BO-Set-I1EM-titel'))}
                        modal={modal}
                        onClose={() => closeModal()}
                        onAccept={() => deleteContact()}
                        currentContact={currentContact}
            />
             <F2CFormGenerator isOpened={modal === ModalActions.EDIT || modal === ModalActions.CREATE || modal === ModalActions.CREATE_SUB}
                               modalType={modal}
                               modalTitle={String(t('BO-Set-I1EM-titel'))}
                               onClose={() => {
                                   closeModal()
                               }}
                               onValidationCreate={(formData) => {
                                   createGeoArea(formData as SettingsElement)

                               }}
                               onEdit={(formData) => {
                                   const updatedGeoArea = {
                                       ...currentContact,
                                       ...formData
                                   }
                                   editGeoArea(updatedGeoArea)
                               }}
                               formConfig={geoAreaFormConfig}
                               initialFormValues={currentContact}
                               validationSchema={geoAreaFormSchema}
            />
            <Toast ref={toast} position='bottom-right' />
        </div>
    )
}