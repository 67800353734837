import React from 'react'
import * as types from '../../types'

import A from '../A'
import Span from '../Span'

import LinkInner from './__styled__/LinkInner'
import LinkIcon from './__styled__/LinkIcon'

interface Props {
  href: any
  linkTitle: string
  linkType?: types.LinkType
  target?: string
  rel?: string
  onClick?: ()=> void
}

const Link = ({
  href,
  linkTitle,
  linkType,
  target = '_self',
  onClick
}: Props) => {

  return (
    <LinkInner onClick={onClick}>
      <A href={href} target={target} rel="noreferrer">
        {linkType === 'default' ? (
          <LinkIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.47 29.41">
              <path fill='currentColor' d="M3.17,29.41a6.33,6.33,0,0,1-1.91-4.63,6.14,6.14,0,0,1,1.91-4.6L10.33,13a6.72,6.72,0,0,1,4.12-1.85,5.49,5.49,0,0,1,.58,0,6.5,6.5,0,0,1,3.76,1.23,1.93,1.93,0,0,1,.74,1.2,1.73,1.73,0,0,1,0,.32,2,2,0,0,1-.35,1,1.85,1.85,0,0,1-1.2.74,1.47,1.47,0,0,1-.33,0,1.94,1.94,0,0,1-1.16-.43,2.33,2.33,0,0,0-1.46-.45.85.85,0,0,0-.32,0,3.18,3.18,0,0,0-1.78.81L5.77,22.77a2.86,2.86,0,0,0-.81,2,2.93,2.93,0,0,0,.81,2,2.77,2.77,0,0,0,2,.77,2.78,2.78,0,0,0,2-.77L12.63,24a1.68,1.68,0,0,1,1.28-.55,1.82,1.82,0,0,1,1.31.53,1.74,1.74,0,0,1,.55,1.3,1.78,1.78,0,0,1-.55,1.31L12.4,29.41a6.29,6.29,0,0,1-4.61,1.91A6.33,6.33,0,0,1,3.17,29.41Zm9.66-8.49a2.1,2.1,0,0,1-.68-1.23.75.75,0,0,1,0-.26,2.49,2.49,0,0,1,.39-1.1,1.52,1.52,0,0,1,1.23-.71h.52a2,2,0,0,1,.94.45,2.61,2.61,0,0,0,1.59.49H17a2.54,2.54,0,0,0,1.85-.81l7.38-7.35a2.93,2.93,0,0,0,.81-2,2.86,2.86,0,0,0-.81-2,2.92,2.92,0,0,0-4,0l-3,3a1.81,1.81,0,0,1-1.31.52A1.78,1.78,0,0,1,16,8.08a1.87,1.87,0,0,1,.52-1.31l3.05-3a6.14,6.14,0,0,1,4.63-1.85,6.26,6.26,0,0,1,4.6,1.85,6.27,6.27,0,0,1,1.91,4.6A6.33,6.33,0,0,1,28.83,13l-7.39,7.38a6,6,0,0,1-4.37,1.91A6.35,6.35,0,0,1,12.83,20.92Z" transform="translate(-1.26 -1.91)" />
            </svg>
          </LinkIcon>
        ) : null}

        {linkType === 'mail' ? (
          <LinkIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.47 20.24">
              <path fill="currentColor" d="M16,13.86,1.26,5.54H30.74Zm.45,1.88L30.74,7.48V24.87a.87.87,0,0,1-.28.65.85.85,0,0,1-.63.26H2.17a.85.85,0,0,1-.63-.26.83.83,0,0,1-.28-.65V7.48l14.29,8.26a1.06,1.06,0,0,0,.9,0Z" transform="translate(-1.26 -5.54)" />
            </svg>
          </LinkIcon>
        ) : null}

        {linkType === 'phone' ? (
          <LinkIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 10 50 50">
              <path fill="#00739F" d="M7.49,16.223c-0.566,4.101,6.223,13.718,16.404,23.9c10.183,10.183,19.799,16.97,23.9,16.405 c2.264-0.143,3.678-1.557,3.678-1.557l2.828-2.828c0.707-0.707,0.707-2.121,0-2.828L43.693,38.708 c-0.707-0.707-2.121-0.707-2.828,0l-2.828,2.828c0,0-3.535-2.12-8.486-7.07c-4.949-4.949-7.07-8.485-7.07-8.485l2.828-2.828 c0.707-0.707,0.707-2.122,0-2.829L14.703,9.717c-0.709-0.707-2.123-0.706-2.83,0.001l-2.828,2.828 C9.045,12.546,7.631,13.96,7.49,16.223z" />
            </svg>

          </LinkIcon>
        ) : null}


        <Span>{linkTitle}</Span>
      </A>
    </LinkInner>
  )
}

export default React.memo(Link)