import BackendFactory from "../../backend/BackendFactory";
import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next'
import FundMapped from "../../model/FundMapped";
import DataTable from "../../components/DataTable/dataTable";
import './search-page.scss'
import {ReactComponent as ErrorIcon} from "../../icons/error_placeholder.svg";
import Legend from "../../components/legend/Legend";
import useDebounce from '../../utils/useDebounce'

import { ReactComponent as DeadlineIcon} from '../../icons/deadline-fund.svg'
import { ReactComponent as DefaultIcon} from '../../icons/default-fund.svg'
import { ReactComponent as NewIcon} from '../../icons/new-fund.svg'
import { ReactComponent as UpdateIcon} from '../../icons/update-fund.svg'

interface SearchProps {
    searchValue: string
}

export const SearchPage = ({searchValue}: SearchProps) => {
    const { t } = useTranslation(["details", "home"])
    const [funds, setFunds] = useState<FundMapped[]>([])
    const debouncedValue = useDebounce<string>(searchValue, 1000)

    useEffect(()=> {
        getFundsBySearch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue])

    const getFundsBySearch = (sortParam?:string) => {
        let params = searchValue
        if(sortParam) {
            params = `${searchValue}&${sortParam}`
        }
        BackendFactory.produce().getBasicFundsByKeyword(params).then(res => {
              setFunds(res);
          }
        ).catch(reason => console.log(reason))
    }

    return <div className="search-page-container">
        <h1 className="search-page-container__title">{t("search")}</h1>
        <div className="search-page-container__legend">
            <Legend itemsArray={[
                {title: t("RP:RP-DiamondsDefault"), icon: DefaultIcon},
                {title: t("RP:RP-DiamondsNew"), icon: NewIcon},
                {title: t("RP:RP-DiamondsUpdate"), icon: UpdateIcon},
                {title: t("RP:RP-DiamondsDeadline"), icon: DeadlineIcon}
              ]}/>
        </div>
        {funds.length > 0 &&
          <h2 className="search-page-container__subtitle">{funds.length} {funds.length === 1
            ? t("searchResultsTo") : t("searchesResultsTo")} „{searchValue}“</h2>}
        {funds.length > 0 &&
          <DataTable data={funds}
                    isOnSearchMode={true}
                    onSortingAction={params => {
                        getFundsBySearch(params)
                    }}
                    onInit={params => {
                        getFundsBySearch(params)
                    }}
        />}
        {funds.length === 0 && searchValue !=='' && <div className='error-notification'>
            <ErrorIcon width="64" height="64" />
            <div className=' bold'>{t("nothingFound")}!</div>
            <div className=''>{t("zeroSearchResultsToo")} „{searchValue}“</div>
        </div>}
    </div>
}