import * as types from '../types'

import CardTitle from '../components/Card/__styled__/CardTitle'
import CardValue from '../components/Card/__styled__/CardValue'

type Props = {
  theme: any
}

export const setCardsStyles = (
  props: Props,
  cardType: types.CardColor
) => {

  return `
    color: ${props.theme.cards[cardType].frame.color};
    background: ${props.theme.cards[cardType].frame.backgroundColor};
    border-radius: ${props.theme.borderRadius.default};
    overflow: visible;

    ${CardTitle} {
      display: block;
      margin-bottom: 12px;
      font-size: ${props.theme.cards[cardType].title.fontSize};
      font-weight: ${props.theme.cards[cardType].title.fontWeight};
    }

    ${CardValue} {
      display: block;
      font-size: ${props.theme.cards[cardType].value.fontSize};
      font-weight: ${props.theme.cards[cardType].value.fontWeight};
    }
  `
}
