import * as crypto from "crypto-js"

export default class Utils {
  static getWeakHashNumber(content: any): number {
    const contentAsString = `${content}`
    const md5 = crypto.MD5(contentAsString)
    return md5.words[0]
  }

  static formatDate(milliseconds: number): string {
    const day = new Intl.DateTimeFormat('de', {day: '2-digit'}).format(milliseconds)
    const month = new Intl.DateTimeFormat('de', {month: '2-digit'}).format(milliseconds)
    const year = new Intl.DateTimeFormat('de', {year: 'numeric'}).format(milliseconds)

    return `${day}.${month}.${year}`
  }
}