import React, {useEffect, useRef, useState} from 'react'
import isDeepEqual from 'fast-deep-equal/react'
import { useTranslation } from 'react-i18next'
import Section from '../../components/Section'
import HeadlineInner from './__styled__/HeadlineInner'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'
import FundMapped from '../../model/FundMapped'
import FundingVolumeHelper, { FundingVolumeStats } from './fundingVolumeHelper'
import AllocationStats from './allocationStats'
import AllocationTable from './allocationTable'
import './allocationPage.module.scss'
import BreadcrumbsElem from '../../components/breadcrumbs/breadcrumbsElem'

const MATURITY_IN_ALLOCATION = 'Zuteilung'
interface State {
  fundsInAllocation?: FundMapped[],
  fundingVolumeStats?: FundingVolumeStats
}

export  const AllocationPage = () => {
    const { t } = useTranslation("menu")

    const backend: Backend = BackendFactory.produce()
    const initialState: State = {}
    const [compState , setCompState] =  useState(initialState)

    // fix specially for linter
    const backendRef = useRef(backend)
    if (!isDeepEqual(backendRef.current, backend)) {
        backendRef.current = backend
    }

  const onFundsRetrieved = (funds: FundMapped[]) => {
      const fundsInAllocation = funds.filter(({maturity}) => maturity === MATURITY_IN_ALLOCATION)
      const fundingVolumeStats = FundingVolumeHelper.analyzeFundingVolumeStats(fundsInAllocation)

      setCompState({
          fundsInAllocation,
          fundingVolumeStats
      })
  }

  useEffect(()=>{
      backendRef.current.addViewToElement('Page loaded: In Zuteilung')

      backendRef.current.getBasicFunds().then(funds => {
          onFundsRetrieved(funds)
      })
  }, [])

      return (
          <>
            <BreadcrumbsElem linksArray={[{href: '/', text: t("RP:RP-M1")}]}
                current={{href: '/allocations', text: t("RP:RP-M2")}}/>
            <div className='allocation-page-inner'>
                
                    <Section isContent>
                    <HeadlineInner>
                        <h1 className='extrabold scl-font-variant-heading-2'>{t("allocationHeadline")}</h1>
                    </HeadlineInner>
                    </Section>

                {compState.fundingVolumeStats
                && <AllocationStats
                count={compState.fundingVolumeStats.count}
                fundingVolume={compState.fundingVolumeStats.totalInAllocation}/>}

                {compState.fundsInAllocation && <AllocationTable funds={compState.fundsInAllocation}/>}
            </div>
          </>
      )

}