import { t } from 'i18next'
import { Fund } from '../../../model/FundNew'
import BackendFactory from '../../../backend/BackendFactory'
import ObjectUtil from '../../../utils/ObjectUtil'
import Backend from '../../../backend/Backend'
import { TableSource, NotificationType, TableComponentState } from './types'
import { BEresponse } from '../../../backend/respType'

const backend: Backend = BackendFactory.produce()

type Notifications = {
    type: NotificationType,
    text: string
}[]

export const getDeletedIndex = (id: string, funds: Fund[]) => {
    const deletedFund = funds.find(fund => fund.id === id)
    return funds.indexOf(deletedFund!)
}

export const getFundsByType = (type: TableSource, sortingParam?: string) => {
    return sortingParam
        ? BackendFactory.produce()
            .getBOFundsByTypeAndOrderBy(type, sortingParam)
            .then((res) => ObjectUtil.getArrayOrThrow(res, 'funds'))
        : BackendFactory.produce()
            .getBOFundsByType(type)
            .then((res) => ObjectUtil.getArrayOrThrow(res, 'funds'))
}

export const submitForm = (sortingDirection: string, fundStatus: TableSource, filterUrlParams: string, notificationType?: NotificationType) => {

    const notifications: Notifications = [
        { type: 'archive', text: t('BOFundTranslationKeys:fundsArchiveTextSuccess') },
        { type: 'delete', text: t('BOFundTranslationKeys:fundsDeleteTextSuccess') },
        { type: 'edit', text: t('BOFundTranslationKeys:fundsEditRelevantSuccess') },
        { type: 'error', text: t('BOFundTranslationKeys:fundEditError') },
        { type: 'create', text: t('BOFundTranslationKeys:fundsCreateRelevantSuccess') },
        { type: 'relevant', text: t('BOFundTranslationKeys:fundsNewRelevantSuccess') },
        { type: 'publish', text: t('BOFundTranslationKeys:fundPublishRelevantSuccess') },
        { type: 'duplicate', text: t('BOFundTranslationKeys:fundCopySuccess') },
    ]
    const message = notifications.find(msg => msg.type === notificationType)?.text
    const error = notificationType === 'error'
    const params = sortingDirection + '&' + filterUrlParams

    return backend.getBOFundsByTypeAndOrderBy(fundStatus, params)
        .then(funds => {
            return {
                funds: ObjectUtil.getArrayOrThrow(funds, 'funds'),
                success: !error,
                error: error,
                message: message,
            }
        })
}


export const onDuplicateFund = async (fundID: string): Promise<BEresponse> => {
    return await BackendFactory.produce().copyBOFund({ id: fundID })
}

export const changeFundStatus = (status: string, fundId: string) => {
    return backend
        .patchBOFund({ fundingStatus: status }, fundId)
}
