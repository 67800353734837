import { MultiselectOptions } from '../model/form-field'
import BackendFactory from '../backend/BackendFactory'
import Stream from '../model/Stream'

export const getCleanedSegmentsList = async ():Promise<MultiselectOptions[]> => {
    const backend = BackendFactory.produce();
    return await backend.getSegmentsList().then(resp => {
        const result:MultiselectOptions[] = []
        resp.data.forEach(elem => {
            const { sugSegments, ...otherProps} = elem
            //removing sugSegments before we're pushing to result to make objects look the same for Multiselect
            result.push(otherProps)
            if(sugSegments && sugSegments.length > 0) {
                sugSegments.forEach(sub => {
                        const { parentId, ...otherProps} = sub
                        //removing parentId before we're pushing to result to make objects look the same for Multiselect
                        result.push(otherProps)
                    }
                )
            }
        })
        return result
    })
}

export const getStreamList = async  ():Promise<(Stream | undefined)[]> => {
    const backend = BackendFactory.produce();
    const result = await backend.getRawStreams().then(resp => resp.streams)
    return result;
}