import { string, object, array } from 'yup'
import { t } from 'i18next'
import { isGermanEnv } from '../envBased'


const req = t('BOFundTranslationKeys:validationNotFilled') || ''
const linkValidationText = t('BOFundTranslationKeys:validationWrongLink2fpdb') || ''
const spaceInLink = t('BOFundTranslationKeys:validationSpaceInLink') || ''

const germanSchema = {
    longText: string().required(req),
    shortText_simple: string().required(req),
    creationDate: string().required(req),
    startApplicationPeriod: string().required(req),
    endDate: string().required(req),
    office: object().required(req),
    fundingSponsor: string().required(req),
    fundingType: string().required(req),
    fundingTypeDetails: string().required(req),
    link2fpdb: string().notRequired().test('contains-test', linkValidationText, function(value) {
        if (!value) return true;
        return value.toLowerCase().startsWith('https://www.foerderdatenbank.de/fdb/content/de');
    }).test('no-space', spaceInLink, (value)=> {
        if (!value) return true;
        return !/\s/.test(value)
    })
}

const euSchema = {

}

const envDependantSchema = isGermanEnv ? germanSchema : euSchema

export const editFundModalSchema = object().shape({
    name: string().required(req),
    shortname: string().required(req),
    url: string().required(req).url(t('BOFundTranslationKeys:validationWrongUrl') || ''),
    // status:string().required(req),
    internalStatus: string().required(req),
    primaryStream: object().required(req),
    maturity: string().required(req),
    deadline: string().required(req),
    summary: string().required(req),
    fundingObjectType: string().required(req),
    targetGroup: string().required(req),
    geoArea: array().required().min(1, req),
    description: string().required(req),
    ...envDependantSchema

})