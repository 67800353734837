import React from 'react'
import { useTranslation } from 'react-i18next'

import { prettifyDate } from '../../../../helpers/dates'
import { Fund } from '../../../../model/FundNew'
import { ReactComponent as ShareIcon } from '../../../../icons/share-icon.svg'
import cn from 'classnames'
import { GroupedButtons } from '../../../../components/GroupedButtons/GroupedButtons'
import { BOFundDetails } from '../../../../backend/respType'

interface Props {
  fund: Fund
  openAlertDeleteDialog: Function
  openEditDialog: Function
  setActiveRow: Function
  onCopyFund:Function
  editFund: (id: BOFundDetails['id'], body: any) => void
  active: boolean
}

export const ArchivedRow = ({
    fund,
    openAlertDeleteDialog,
    openEditDialog,
    setActiveRow,
    onCopyFund,
    active
  }: Props) => {
    const { t } = useTranslation("table")

  const startDate = prettifyDate(fund.info.startApplicationPeriod) || '-'
  const endDate = prettifyDate(fund.info.deadline) || '-'


  return (
    <tr id={fund.id}
        key={`${fund.id}_archived-row`}
        onClick={() => setActiveRow(fund.id)} 
        className={cn('row', {
          'active': active,
        })}
    >
      <td className="column column--with-padding">
        <div className="publication-item publication-item--title bold">
          {fund.fp_number}
        </div>
      </td>
      <td className="column column--with-padding">
        <div className="publication-item publication-item--title bold">
          {fund.name}
        </div>
        <div className="publication-item publication-item--summary" dangerouslySetInnerHTML={{__html:  fund.info.summary as string}}/>
      </td>
      <td className="column  column--with-padding">
        <div className="publication-item publication-item--title bold">
          {fund.info.primaryStream}
        </div>
      </td>
      <td className="column column--with-padding">
        {startDate}
      </td>
      <td className="column column--with-padding">{endDate}</td>
      <td className="column column--with-padding">{fund.internalStatus}</td>
      <td className="column column--no-wrap column--right column--with-padding">
        <div style={{marginBottom:'30px'}}>
          <GroupedButtons onDelete={()=> openAlertDeleteDialog()}
                          onEdit={()=> openEditDialog()}
                          lastModified={fund.info.last_modified}
                          onCopy={()=> onCopyFund()}
          />
        </div>

        <div className="action-link-container">
          {fund.info.url && (
            <a href={fund.info.url} target={'_blank'} rel="noreferrer" >
              <span className="action-link">
                <ShareIcon />
              </span>
              <span className="action-link__text">{fund.info.url}</span>
            </a>
          )}
        </div>
      </td>
    </tr>
  )
}
