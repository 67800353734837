import React from 'react';
import { useTranslation } from 'react-i18next'
import SortingIcons from "../../../components/SortingIcons/SortingIcons";
import { HeaderProps } from '../funds/types'

export const RSSFeedHeader = ({onSortingAction, sortParams}: HeaderProps) => {
    const { t } = useTranslation(["table"])
    return (
        <>
            <tr>
                <th className='column column--with-padding column--small column--header'>
                    <SortingIcons sortParamName='source' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("table:source")}</span>
                </th>
                <th className='column column--with-padding column--small column--header'>
                    <SortingIcons sortParamName='link_type' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("table:type")}</span>
                </th>
                <th className='column column--with-padding column--large column--header'>
                    <SortingIcons sortParamName='url' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("table:url")}</span>
                </th>
                <th className='column column--with-padding column--small column--header'>
                    <SortingIcons sortParamName='is_active' sortParams={sortParams} onSortingAction={onSortingAction}/>
                    <span className="title">{t("table:active")}</span>
                </th>
               {/*  <th className='column column--large'/> */}
            </tr>
        </>
    )
}