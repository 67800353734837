import urlRegex from 'url-regex';

export const isValidUrl = (url: string): boolean => {
  if (!url.startsWith('https://') && !url.startsWith('http://')) return false
  const pattern = urlRegex({ strict: true });
  return pattern.test(url);
}

export const isValidMail = (email: string) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

export const isEmptyObject = (object: object) => {
  return Object.keys(object).length === 0
}