import styles from './F2CAccordion.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import React, { useState } from 'react'
import {ReactComponent as ArrowDownIcon} from "../../icons/long-arrow-down-right-icon.svg";
import { GroupedButtons } from '../GroupedButtons/GroupedButtons'
import { UUID } from 'react-accessible-accordion/dist/types/components/ItemContext'

type F2CAccordionProps<T extends AccordionEntity> = {
  data: AccordionList<T>[];
  onItemEdit?: (item: AccordionList<T>) => void;
  onItemDelete?: (item: AccordionList<T>) => void;
  onItemCreate?: (id: AccordionList<T>['id']) => void;
  onSubItemEdit?: (item: T) => void;
  onSubItemDelete?: (item: T) => void;
};


export interface AccordionList<T extends AccordionEntity> extends AccordionEntity{
  subList: T[]
}

export interface AccordionEntity {
  id:string
  name:string
  email?:string
}


export const F2CAccordion = <T extends AccordionEntity>({ data, onItemCreate, onItemEdit, onSubItemEdit, onSubItemDelete, onItemDelete }: F2CAccordionProps<T>) => {
  const [activeTabs, setActiveTabs] = useState<UUID[]>([])

  return (
    <>

      <Accordion allowMultipleExpanded
                 allowZeroExpanded
                 onChange={(uuids)=> setActiveTabs(uuids)}
      >
        {data.map((item, index) => (
          <AccordionItem key={'accordion_' + item.id }
                         className={`active`}
                         uuid={item.id}>
            <AccordionItemHeading>
              <AccordionItemButton >
                <div className={`${styles.row} ${activeTabs.includes(item.id) && (item.subList && item.subList.length) > 0 ? styles.activeRow : ''}`}>
                  <div className={styles.indexColumn}>{index + 1}.</div>

                  <div className={`${styles.column} ${styles.title}`}>{`${item.name} ${(item.subList && item.subList.length >= 0) ? `(${item.subList.length})` : ''}`}</div>
                  {item.email &&
                      <div className={styles.column}>{item.email}</div>
                  }
                  <GroupedButtons onEdit={onItemEdit ? (event) => {
                                    event.stopPropagation();
                                    onItemEdit(item);
                                  } : undefined}
                                   onDelete={onItemDelete ? (event) => {
                                     event.stopPropagation()
                                     onItemDelete(item)
                                   }:undefined
                                   }
                                   onCreate={onItemCreate ? (event) => {
                                     event.stopPropagation()
                                     onItemCreate(item.id)
                                   } : undefined}
                  />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {item.subList && item.subList.map((subItem, subIndex) => (
                <div className={`${styles.row} ${styles.subRow}`} key={subItem.id}>
                  <ArrowDownIcon className={'icon'}/>
                  <div className={styles.indexColumn}>{`${index + 1}.${subIndex + 1}.`}</div>
                  <div className={`${styles.column} ${styles.title}`}>{subItem.name}</div>
                  <div className={styles.column}>{subItem.email}</div>
                  <GroupedButtons onEdit={onSubItemEdit ? ()=> {
                                    onSubItemEdit(subItem)
                                  }: undefined}
                                  onDelete={onSubItemDelete ? ()=> {
                                     onSubItemDelete(subItem)
                                  }: undefined}

                  />
                </div>
              ))}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  )
}