import styled from 'styled-components/macro'

type Props = {
  isContent?: boolean
  hasSpacingBottom?: boolean
}

const SectionInner = styled('section')<Props>`

  ${props => props.isContent && `

    @media (min-width: ${props.theme?.breakpoints?.desktopXXl}) {
      max-width: 1600px;
      margin: 0 auto;
    }
  `}

  ${props => props.hasSpacingBottom && `
    margin-bottom: ${props.theme?.spacings?.small.gutter};

    @media (min-width: ${props.theme?.breakpoints?.desktopXXl}) {
      margin-bottom: ${props.theme?.spacings?.large.gutter};
    }
  `}
`

export default SectionInner