import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'

import home_en from './translation/en/home.json'
import menu_en from './translation/en/menu.json'
import BOFundTranslationKeys_en from './translation/en/BOFundTranslationKeys.json'
import notifications_en from './translation/en/notifications.json'
import publications_en from './translation/en/publications.json'
import details_en from './translation/en/details.json'
import table_en from './translation/en/table.json'
import datepicker_en from './translation/en/datepicker.json'
import f2cFilter_en from './translation/en/F2CFilter.json'
import settingsPage_en from './translation/en/SettingsPage.json'
import formGenerator_en from './translation/en/formGenerator.json'
import reports_en from './translation/en/reports.json'

import RP_en from './translation/en/RP.json'
import BO_en from './translation/en/BO.json'
import FP_en from './translation/en/FP.json'
import F_en from './translation/en/F.json'

import home_de from './translation/de/home.json'
import menu_de from './translation/de/menu.json'
import BOFundTranslationKeys_de from './translation/de/BOFundTranslationKeys.json'
import notifications_de from './translation/de/notifications.json'
import publications_de from './translation/de/publications.json'
import details_de from './translation/de/details.json'
import table_de from './translation/de/table.json'
import datepicker_de from './translation/de/datepicker.json'
import f2cFilter_de from './translation/de/F2CFilter.json'
import settingsPage_de from './translation/de/SettingsPage.json'
import formGenerator_de from './translation/de/formGenerator.json'
import reports_de from './translation/de/reports.json'


import RP_de from './translation/de/RP.json'
import BO_de from './translation/de/BO.json'
import FP_de from './translation/de/FP.json'
import F_de from './translation/de/F.json'

const resources = {
  en: {
    home: home_en,
    menu: menu_en,
    BOFundTranslationKeys: BOFundTranslationKeys_en,
    notifications: notifications_en,
    publications: publications_en,
    details: details_en,
    table: table_en,
    datepicker: datepicker_en,
    f2cFilter: f2cFilter_en,
    SettingsPage: settingsPage_en,
    formGenerator: formGenerator_en,
    reports: reports_en,

    RP: RP_en,
    BO: BO_en,
    FP: FP_en,
    F: F_en,
  },
  de: {
    home: home_de,
    menu: menu_de,
    BOFundTranslationKeys: BOFundTranslationKeys_de,
    notifications: notifications_de,
    publications: publications_de,
    details: details_de,
    table: table_de,
    datepicker: datepicker_de,
    f2cFilter: f2cFilter_de,
    SettingsPage: settingsPage_de,
    formGenerator: formGenerator_de,
    reports: reports_de,

    RP: RP_de,
    BO: BO_de,
    FP: FP_de,
    F: F_de,
  },
}

const options = {
  order: ['querystring', 'localStorage', 'navigator'],
  lookupQuerystring: 'lng',
}

i18n
  .use(backend)
  .use(new LanguageDetector(null, { lookupLocalStorage: 'i18nextLng' }))
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: options,
    ns: ['RP', 'BO', 'FP', 'F', 'home', 'menu', 'BOFundTranslationKeys', 'notifications', 'publications', 'details', 'table', 'datepicker', 'f2cFilter', 'SettingsPage', 'formGenerator', 'reports'],
    supportedLngs: ['de', 'en'],
    fallbackLng: 'de',
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n