import { KeyValuePair } from './KeyValuePair'
import ObjectUtil, { DataObject } from '../utils/ObjectUtil'
import FundMapped, { Contact, Link, Office, Product } from './FundMapped'
import { TRANSLATIONS } from '../theme/constants/Translations'
import SecurityUtil from '../utils/SecurityUtil'
import NumberFormatter from '../utils/NumberFormatter'
import Publication, { PublicationsByType } from './Publication'
import Notification from './Notification'
import BO_Office from './Office'
import { PhaseValues } from '../utils/phaseTranslation'
import { Fund } from './FundNew'
import { isGermanEnv } from '../env/envBased'


const PATH_NAME = `name`
const PATH_ID = `id`
const PATH_INFO = `info`
// const PATH_STATUS = `status`
const PATH_FUNDING_STATUS = `fundingStatus`
const PATH_INFO_PRIMARYSTREAM = `${PATH_INFO}.primaryStream`
const PATH_INFO_PARENTSTREAM = `${PATH_INFO}.parent_stream_name`
const PATH_INFO_MATURITY = `${PATH_INFO}.maturity`
const PATH_INFO_FUNDINGTYPE = `${PATH_INFO}.fundingType`
const PATH_INFO_FUNDINGOBJECTTYPE = `${PATH_INFO}.fundingObjectType`
const PATH_INFO_TARGETGROUP = `${PATH_INFO}.targetGroup`
const PATH_INFO_DEADLINE = `${PATH_INFO}.deadline`
const PATH_INFO_ENDDATE = `${PATH_INFO}.endDate`
const PATH_INFO_FUNDINGVOLUME = `${PATH_INFO}.fundingVolume`
const PATH_INFO_FUNDINGVOLUMEAVAILABLE = `${PATH_INFO}.fundingVolume_available`
const PATH_INFO_FUNDINGAMOUNT = `${PATH_INFO}.fundingAmount`
const PATH_INFO_FUNDINGAMOUNT_FLOAT = `${PATH_INFO}.fundingAmount_float`
const PATH_INFO_GEOAREA = `${PATH_INFO}.geoArea`
const PATH_INFO_PORTFOLIOPRODUCTS = `${PATH_INFO}.portfolioProducts`
const PATH_INFO_OFFICE = `${PATH_INFO}.office`
const PATH_INFO_OFFICE_NAME = `${PATH_INFO_OFFICE}.name`
const PATH_INFO_OFFICE_CITY = `${PATH_INFO_OFFICE}.city`
const PATH_INFO_OFFICE_ZIPCODE = `${PATH_INFO_OFFICE}.zipCode`
const PATH_INFO_OFFICE_MAIL = `${PATH_INFO_OFFICE}.mail`
const PATH_INFO_OFFICE_PHONE = `${PATH_INFO_OFFICE}.phone`
const PATH_INFO_OFFICE_URL = `${PATH_INFO_OFFICE}.url`
const PATH_INFO_OFFICE_STREET = `${PATH_INFO_OFFICE}.street` // optional
const PATH_INFO_CONTACT = `${PATH_INFO}.internalContact`
const PATH_INFO_CONTACT_NAME = `${PATH_INFO_CONTACT}.name`
const PATH_INFO_CONTACT_MAIL = `${PATH_INFO_CONTACT}.mail`
const PATH_INFO_CONTACT_PHONE = `${PATH_INFO_CONTACT}.phone`
const PATH_INFO_CONTACT_ROLE = `${PATH_INFO_CONTACT}.role`
const PATH_INFO_HANDLER = `${PATH_INFO}.handler` // ignored
const PATH_INFO_NUMBER = `${PATH_INFO}.number` // ignored
const PATH_INFO_SUMMARY = `${PATH_INFO}.summary`
const PATH_INFO_LINKS = `${PATH_INFO}.links`
const PATH_INFO_URL = `${PATH_INFO}.url`
const PATH_INFO_YAMLINK = `${PATH_INFO}.YAMLink`
const PATH_INFO_SHORTNAME = `${PATH_INFO}.shortname`
const PATH_INFO_LASTMODIFIED = `${PATH_INFO}.last_modified`
const PATH_INFO_LASTCRAWLEDDATE = `${PATH_INFO}.lastCrawledDate`
const PATH_INFO_CREATIONDATE = `${PATH_INFO}.creationDate`
const PATH_DETAILS = `details`
const PATH_DETAILS_DESCRIPTION = `${PATH_DETAILS}.description`
const PATH_INFO_INT_COM = `${PATH_DETAILS}.internalComment`
const PATH_INFO_INT_STAT = `${PATH_DETAILS}.internalStatus`

const PATH_INFO_START_APP_PERIOD = `${PATH_INFO}.startApplicationPeriod`

const KEY_PORTFOLIOPRODUCT_NAME = `name`
const KEY_PORTFOLIOPRODUCT_URL = `url`
const KEY_LINK_TYPE = `type`
const KEY_LINK_URL = `url`

// TODO: Improve already mapped fields (e.g. use json file)
const ALREADY_MAPPED_FIELDS = [
    PATH_NAME, PATH_INFO_PRIMARYSTREAM, PATH_INFO_MATURITY,
    PATH_INFO_FUNDINGTYPE, PATH_INFO_FUNDINGVOLUME,
    PATH_INFO_GEOAREA, PATH_INFO_PORTFOLIOPRODUCTS, KEY_PORTFOLIOPRODUCT_NAME,
    KEY_PORTFOLIOPRODUCT_URL, PATH_INFO_OFFICE, PATH_INFO_OFFICE_NAME,
    PATH_INFO_OFFICE_CITY, PATH_INFO_OFFICE_ZIPCODE, PATH_INFO_OFFICE_MAIL,
    PATH_INFO_OFFICE_PHONE, PATH_INFO_OFFICE_URL, PATH_INFO_OFFICE_STREET,
    PATH_INFO_CONTACT, PATH_INFO_CONTACT_NAME, PATH_INFO_CONTACT_MAIL,
    PATH_INFO_CONTACT_PHONE, PATH_INFO_CONTACT_ROLE, PATH_INFO_SUMMARY,
    PATH_INFO_LINKS, KEY_LINK_TYPE, KEY_LINK_URL, PATH_DETAILS_DESCRIPTION,
    PATH_INFO_HANDLER, PATH_INFO_NUMBER, PATH_INFO_FUNDINGAMOUNT, PATH_INFO_LASTCRAWLEDDATE, PATH_INFO_CREATIONDATE,
]

const ENTRY_ORDER = [
    TRANSLATIONS.de.labels.startApplicationPeriod,
    TRANSLATIONS.de.labels.endDate,
    TRANSLATIONS.de.labels.fundingObjectType,
    TRANSLATIONS.de.labels.targetGroup,
    TRANSLATIONS.de.labels.fundingAmountDetails,
    TRANSLATIONS.de.labels.fundingTypeDetails,
    TRANSLATIONS.de.labels.requirements,
    TRANSLATIONS.de.labels.fundingProcess,
    TRANSLATIONS.de.labels.segment,
    ...(isGermanEnv ? [
        TRANSLATIONS.de.labels.related_portfolio_products
    ] : [
        TRANSLATIONS.de.labels.relatedPortfolio
    ]),
    TRANSLATIONS.de.labels.last_modified,
] as const


type DetailGetter = (object: any, path: string) => string | undefined


const detailGetters = new Map<string, DetailGetter>([
    [PATH_INFO_LASTMODIFIED, ObjectUtil.getDateString],
    [PATH_INFO_ENDDATE, ObjectUtil.getDateString],
    [PATH_INFO_DEADLINE, ObjectUtil.getDateString],
    [PATH_INFO_START_APP_PERIOD, ObjectUtil.getDateString],
    [PATH_INFO_LASTCRAWLEDDATE, ObjectUtil.getDateString],
    [PATH_INFO_FUNDINGVOLUMEAVAILABLE, (obj: any, path: string) => {
        const possibleVolume = ObjectUtil.parseNumber(obj, path)

        if (!possibleVolume) {
            return undefined
        }

        return NumberFormatter.prettifyEuro(possibleVolume)
    },
    ],
])


export default class FundMapper {
    private constructor() {
    }

    static mapAll(rawFunds: any): FundMapped[] {
        const funds: FundMapped[] = []

        for (const rawFund of rawFunds) {
            try {
                const fund = FundMapper.mapOrThrow(rawFund)
                funds.push(fund)
            } catch (error) {
                // errors are ignored
                console.debug(`Couldn't map fund in list`, error)
            }
        }

        return funds
    }

    static mapOrThrow(rawFund: any): FundMapped {
        const possibleLinks = FundMapper.mapLinks(rawFund)

        return {
            // necessities
            id: SecurityUtil.getSafeUrlParamOrThrow(ObjectUtil.getStringOrThrow(rawFund, PATH_ID)),
            name: ObjectUtil.getStringOrThrow(rawFund, PATH_NAME),
            primaryStream: ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_PRIMARYSTREAM),
            parent_stream_name: ObjectUtil.getString(rawFund, PATH_INFO_PARENTSTREAM),
            maturity: ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_MATURITY) as PhaseValues,
            status: rawFund.status,
            fundingStatus: ObjectUtil.getString(rawFund, PATH_FUNDING_STATUS),
            shortname: ObjectUtil.getString(rawFund, PATH_INFO_SHORTNAME),
            lastCrawledDate: ObjectUtil.getString(rawFund, PATH_INFO_LASTCRAWLEDDATE),
            internalComment: ObjectUtil.getString(rawFund, PATH_INFO_INT_COM),
            internalStatus: ObjectUtil.getString(rawFund, PATH_INFO_INT_STAT),
            url: ObjectUtil.getString(rawFund, PATH_INFO_URL),
            YAMLink: ObjectUtil.getString(rawFund, PATH_INFO_YAMLINK),

            // key info panels
            geoArea: rawFund.info.geoArea,
            related_portfolio_products: rawFund.info.related_portfolio_products,
            deadline: ObjectUtil.getDate(rawFund, PATH_INFO_DEADLINE),
            endDate: ObjectUtil.getDate(rawFund, PATH_INFO_ENDDATE),
            fundingVolume: ObjectUtil.getNumber(rawFund, PATH_INFO_FUNDINGVOLUME),
            fundingAmount: ObjectUtil.getString(rawFund, PATH_INFO_FUNDINGAMOUNT),
            fundingType: ObjectUtil.getString(rawFund, PATH_INFO_FUNDINGTYPE),
            fundingObjectType: ObjectUtil.getString(rawFund, PATH_INFO_FUNDINGOBJECTTYPE),
            lastModified: FundMapper.mapLastModified(rawFund),
            fundingAmount_float: ObjectUtil.getNumber(rawFund, PATH_INFO_FUNDINGAMOUNT_FLOAT),
            startApplicationPeriod: ObjectUtil.getDate(rawFund, PATH_INFO_START_APP_PERIOD),

            // cards light
            internalContact: FundMapper.mapContact(rawFund),
            portfolioProducts: FundMapper.mapPortfolioProducts(rawFund),
            office: FundMapper.mapOffice(rawFund),
            links: possibleLinks,
            link2fpdb:rawFund.link2fpdb,

            // summary
            summary: ObjectUtil.getString(rawFund, PATH_INFO_SUMMARY),

            // tabs
            description: ObjectUtil.getString(rawFund, PATH_DETAILS_DESCRIPTION),
            details: FundMapper.mapDetails(rawFund),

            // undefined
            targetGroup: ObjectUtil.getString(rawFund, PATH_INFO_TARGETGROUP),

            // flags
            flags: {
                sw1: rawFund.info.sw1,
                sw2: rawFund.info.sw2,
                sw3: rawFund.info.sw3,
                sw4: rawFund.info.sw4,
            },
            news: rawFund.news,
        }
    }

    static mapPublicationStuff(pubs: any[]): any[] {
        return pubs.map(pub => {
            return {
                id: pub.id,
                url: pub.url,
                source: pub.source,
                title: pub.title,
                published: pub.published,
                summary: pub.summary,
                stream: pub.stream,
                tags: pub.tags,
                source_type: pub.source_type,
                icon: pub.icon,
                guidislink: pub.guidislink,
                author: pub.author,
                keyword: pub.keyword,
                status: pub.status,
            }
        })
    }

    static mapPublicationByType(pubs: Publication[]): PublicationsByType {
        const mappedPubs = pubs.map(pub => {
            return {
                id: pub.id,
                url: pub.url,
                source: pub.source,
                title: pub.title,
                published: pub.published,
                summary: pub.summary,
                stream: pub.stream,
                tags: pub.tags,
                source_type: pub.source_type,
                icon: pub.icon,
                guidislink: pub.guidislink,
                author: pub.author,
                keyword: pub.keyword,
                status: pub.status,
            }
        })
        return {
            publications: mappedPubs,
        }
    }

    static mapNotificationStuff(pubs: Notification[]): Notification[] {
        return pubs.map(pub => {
            return {
                id: pub.id,
                name: pub.name,
                status: pub.status,
                stream: pub.stream,
                status_updated_at: pub.status_updated_at,
                geoAreas: pub.geoAreas,
            }
        })
    }

    static mapOffices(offices: BO_Office[]): BO_Office[] {
        return offices.map(office => {
            return {
                id: office.id,
                office: office.office,
                name: office.name,
                url: office.url,
                zipCode: office.zipCode,
                city: office.city,
                country: office.country,
                mail: office.mail,
                phone: office.phone,
                street: office.street,
                affectedFunds: office.affectedFunds
            }
        })
    }

    private static mapLastModified(rawFund: any): Date | undefined {
        const possibleLastModified = ObjectUtil.getDate(rawFund, PATH_INFO_LASTMODIFIED)
        const possibleLastCrawledDate = ObjectUtil.getDate(rawFund, PATH_INFO_LASTCRAWLEDDATE)
        const possibleLastCreationDate = ObjectUtil.getDate(rawFund, PATH_INFO_CREATIONDATE)

        const allAvailableDates = [possibleLastCrawledDate, possibleLastModified, possibleLastCreationDate].filter(date => !!date)

        const sortedDatesDescending = allAvailableDates.sort().reverse()
        return sortedDatesDescending && sortedDatesDescending[0]
    }

    private static mapDetails(rawFund: any): KeyValuePair[] | undefined {
        const possibleRawInfo = ObjectUtil.getDataObject(rawFund, PATH_INFO)
        if (!possibleRawInfo) {
            return undefined
        }

        const mappedKeyValuePairs = FundMapper.mapDetailsFromRawInfo(possibleRawInfo, rawFund)

        if (mappedKeyValuePairs.length === 0) {
            return undefined
        }

        return mappedKeyValuePairs
    }

    private static mapDetailsFromRawInfo(rawInfo: DataObject, rawFund: Fund): KeyValuePair[] {
        const keyValuePairs: KeyValuePair[] = []
        for (const infoKey in rawInfo) {

            if (rawInfo.hasOwnProperty(infoKey)) {
                const possibleInfoKeyValuePair = FundMapper.createInfoKeyValuePair(infoKey, rawInfo, rawFund)
                if (possibleInfoKeyValuePair) {
                    keyValuePairs.push(possibleInfoKeyValuePair)
                }
            }
        }

        if (!keyValuePairs.some(pair => pair.key === TRANSLATIONS.de.labels.endDate)) {
            keyValuePairs.push({
                key: TRANSLATIONS.de.labels.endDate,
                value: TRANSLATIONS.de.labels.noDataAvailable,
            })
        }

        return FundMapper.sortEntries(keyValuePairs)
    }

    private static sortEntries(entries: KeyValuePair[]): KeyValuePair[] {
        const filteredAndSortedEntries = entries

            .filter(entry => ENTRY_ORDER.includes(entry.key))
            .sort(function(a, b) {
                return ENTRY_ORDER.indexOf(a.key) - ENTRY_ORDER.indexOf(b.key)
            })

        return filteredAndSortedEntries
    }

    private static dateRange = ''

    private static createInfoKeyValuePair(infoKey: string, rawInfo: any, rawFund: any): KeyValuePair | undefined {

        try {
            if (!(infoKey in rawInfo)) {
                return undefined
            }

            if (FundMapper.isInfoFieldAlreadyUsed(infoKey)) {
                return undefined
            }

            const path = `${PATH_INFO}.${infoKey}`

            const possibleDetailGetter = detailGetters.get(path)

            let value: any | undefined
            if (possibleDetailGetter) {
                value = possibleDetailGetter(rawFund, path)
            } else {
                const rawValue = ObjectUtil.getField(rawFund, path)
                value = rawValue ? `${rawValue}` : TRANSLATIONS.de.labels.noDataAvailable
            }

            if (!value) {
                return undefined
            }

            const label = FundMapper.getLabelForKeyOrThrow(infoKey)

            if (infoKey === 'startApplicationPeriod') {
                if (FundMapper.dateRange) {
                    FundMapper.dateRange = value + ' ' + FundMapper.dateRange
                    return {
                        key: label,
                        value: FundMapper.dateRange,
                    }
                }
            }
            if(infoKey === 'segment') {
                value = rawInfo.segment
            }
            if(infoKey === 'related_portfolio_products') {
                value = rawInfo.related_portfolio_products
            }

            if (infoKey === 'deadline') {
                FundMapper.dateRange += 'bis ' + value
            } else {
                return {
                    key: label,
                    value,
                }
            }
        } catch (error) {
            // too much errors here
            // console.debug(`Couldn't map key value pair (infos)`, error)
            return undefined
        }
    }

    private static isInfoFieldAlreadyUsed(infoKey: string): boolean {
        const fullPath = `${PATH_INFO}.${infoKey}`
        return ALREADY_MAPPED_FIELDS.includes(fullPath)
    }

    private static mapOffice(rawFund: any): Office | undefined {
        const officeObj: Office = {
            name: '', mail: '', phone: '', url: '', city: '', street: '', zipCode: '',
        }
        try {
            officeObj.name = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_OFFICE_NAME)
        } catch (error) {
            officeObj.name = ''
        }
        try {
            officeObj.city = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_OFFICE_CITY)
        } catch (error) {
            officeObj.city = ''
        }
        try {
            officeObj.zipCode = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_OFFICE_ZIPCODE)
        } catch (error) {
            officeObj.zipCode = ''
        }
        try {
            officeObj.mail = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_OFFICE_MAIL)
        } catch (error) {
            officeObj.mail = ''
        }

        try {
            officeObj.phone = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_OFFICE_PHONE)
        } catch (error) {
            officeObj.phone = ''
        }

        try {
            officeObj.url = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_OFFICE_URL)
        } catch (error) {
            officeObj.url = ''
        }

        try {
            officeObj.street = ObjectUtil.getString(rawFund, PATH_INFO_OFFICE_STREET)
        } catch (error) {
            officeObj.street = ''
        }

        return officeObj
    }

    private static mapLinkOrThrow(rawLink: any): Link {
        return {
            url: ObjectUtil.getStringOrThrow(rawLink, KEY_LINK_URL),
            title: ObjectUtil.getStringOrThrow(rawLink, KEY_LINK_TYPE),
        }
    }

    private static mapLinks(rawFund: any): Link[] | undefined {
        return this.mapArray(rawFund, PATH_INFO_LINKS, this.mapLinkOrThrow)
    }

    private static mapPortfolioProducts(rawFund: any): Product[] | undefined {
        return this.mapArray(rawFund, PATH_INFO_PORTFOLIOPRODUCTS, this.mapPortfolioProductOrThrow)
    }

    private static mapPortfolioProductOrThrow(rawPortfolioProduct: any): Product {
        return {
            name: ObjectUtil.getStringOrThrow(rawPortfolioProduct, KEY_PORTFOLIOPRODUCT_NAME),
            url: ObjectUtil.getStringOrThrow(rawPortfolioProduct, KEY_PORTFOLIOPRODUCT_URL),
        }
    }

    private static mapContact(rawFund: any): Contact {
        const contact: Contact = { name: '', mail: '', phone: '', role: '' }
        try {
            contact.name = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_CONTACT_NAME)
        } catch (error) {
            // console.debug(`Couldn't map contact name`, error)
        }
        try {
            contact.mail = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_CONTACT_MAIL)
        } catch (error) {
            // console.debug(`Couldn't map contact mail`, error)
        }
        try {
            contact.phone = ObjectUtil.getStringOrThrow(rawFund, PATH_INFO_CONTACT_PHONE)
        } catch (error) {
            //  console.debug(`Couldn't map contact phone`, error)
        }
        try {
            contact.role = ObjectUtil.getString(rawFund, PATH_INFO_CONTACT_ROLE)
        } catch (error) {
            //  console.debug(`Couldn't map contact role`, error)
        }
        return contact
    }

    private static getLabelForKeyOrThrow(key: string): string {
        if (!(key in TRANSLATIONS.de.labels)) {
            throw new Error(`No translation found for key ${key}`)
        }

        return TRANSLATIONS.de.labels[key]
    }

    private static mapArray<TElement>(rawFund: any, path: string, throwableMapper: (rawElement: any) => TElement): TElement[] | undefined {
        try {
            const rawElements: any[] = ObjectUtil.getFieldOfTypeOrThrow(rawFund, path, 'array')

            const elements: TElement[] = []

            rawElements.forEach(rawElement => {
                try {
                    const element = throwableMapper(rawElement)
                    elements.push(element)
                } catch (error) {
                    // ignore mapping errors
                }
            })

            if (elements.length === 0) {
                return undefined
            }

            return elements
        } catch (error) {
            return undefined
        }
    }
}