import { BorderRadius } from './styles/BorderRadius'
import { Breakpoints } from './styles/Breakpoints'
import { Cards } from './styles/Cards'
import { Colors } from './styles/Colors'
import { Cols } from './styles/Cols'
import { Spacings } from './styles/Spacings'
import { Trend } from './styles/Trend'
import { Typography } from './styles/Typography'
import { ZIndex } from './styles/ZIndex'

export const MainTheme = {
  borderRadius: BorderRadius,
  breakpoints: Breakpoints,
  cards: Cards,
  colors: Colors,
  cols: Cols,
  spacings: Spacings,
  typography: Typography,
  zIndex: ZIndex,
  trend: Trend,
} as const
