import { createContext } from 'react'

interface FundsContextType {
    sortParams:string
}

export const FundsContext = createContext<FundsContextType>({
    sortParams:''
})

