import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningSign2 } from '../../icons/warning-sign-2.svg'
import { ScaleButton, ScaleModal } from '@telekom/scale-components-react'
import styles from './F2CAlertModal.module.scss'

type FundAlertModalProps = {
    isOpen: boolean
    onClose: () => void
    onAccept: () => void
    buttonConfirmText: string
    title: string
    message: string
}

export const F2CAlertModal = ({ isOpen, onAccept, onClose, buttonConfirmText, title, message }: FundAlertModalProps) => {
    const { t } = useTranslation(['SettingsPage', 'details', 'BO'])

    return (
        <ScaleModal heading={title}
                    opened={isOpen}
                    size={'small'}
                    onScaleClose={() => onClose()}

        >
            <div className={styles.body} >
                <WarningSign2 />
                <span>{message}</span>
            </div>

            <div slot='action' className={styles.footer}>
                <ScaleButton
                    variant='secondary'
                    onClick={() => onClose()}
                >
                    {t("details:modalActionInterrupt")}
                </ScaleButton>
                <ScaleButton onClick={() => onAccept()}>
                    {buttonConfirmText}
                </ScaleButton>
            </div>

        </ScaleModal>
    )
}