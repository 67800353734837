import { escape } from 'html-escaper'
import sanitizeHtml from 'sanitize-html'

const ALLOWED_TAGS_IN_HTML = [
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'div',
  'hr', 'li', 'ol', 'p', 'pre',
  'ul', 'abbr', 'b', 'br', 'cite', 'code', 'em', 'i', 's', 'small', 'span', 'strong', 'sub', 'sup','a'
]
const  ALLOWED_ATTRS_IN_HTML = {
  a: [ 'href', 'name', 'target']
}
const ALLOWED_SELF_CLOSING_IN_HTML =['br', 'hr',  'link']

export default class SecurityUtil {
  private constructor() {}

  static secureObject(rawObject: any): { [key: string]: any } {
    const secureObject: { [key: string]: any }= {}

    for (const key in rawObject) {
      if(rawObject.hasOwnProperty(key)) {
        const rawEntity = rawObject[ key ]
        secureObject[ key ] = SecurityUtil.secureEntityOfUnknownType( rawEntity )
      }
    }

    return secureObject
  }

  static secureArray(rawArray: any[]): any[] {
    return rawArray.map( rawElement => SecurityUtil.secureEntityOfUnknownType(rawElement) )
  }

  static secureString(rawString: string): string {
    return escape(rawString)
  }

  static secureEntityOfUnknownType(rawEntity: any): any {
    if (typeof(rawEntity) === 'string') {
      return SecurityUtil.secureString(rawEntity)
    } else if (Array.isArray(rawEntity)) {
      return SecurityUtil.secureArray(rawEntity)
    } else if (typeof(rawEntity) === 'object') {
      return SecurityUtil.secureObject(rawEntity)
    } else {
      // we don't have to sanitize the boolean, numbers, ...
      return rawEntity
    }
  }

  static secureUrl(rawUrl: string): string {
    return encodeURI(rawUrl)
  }

  static getSafeUrlParamOrThrow(rawUrlParam: any): string {
    if (typeof(rawUrlParam) !== 'string') {
      throw new Error(`URL param is not a string, which is illegal`)
    }

    const safeUrlParam = rawUrlParam.replace(/[^a-zA-Z0-9-_.~]/g, '')

    if (safeUrlParam !== rawUrlParam) {
      throw new Error(`URL param contains illegal characters. URL Param after sanitizing: ${safeUrlParam}`)
    }

    return safeUrlParam
  }

  static secureHtml(rawHtml: any): string {
    return sanitizeHtml(rawHtml, {
      allowedTags: ALLOWED_TAGS_IN_HTML,
      allowedAttributes:  ALLOWED_ATTRS_IN_HTML,
      selfClosing: ALLOWED_SELF_CLOSING_IN_HTML,
    });
  }
}