import * as d3 from 'd3'

import { Point, Sector, Ring } from './GeometryModule'
import RandomNumberGenerator from './RandomNumberGenerator'
import RadarData, { RadarView } from './RadarData'
import { TooltipConfig, TooltipInfo } from './RadarTooltip'
import { RADAR_CONFIG_NORMAL, RADAR_CONFIG_SMALLER, RADAR_CONFIG_QUARTER, RadarConfig } from './RadarConfig'
import Stream from '../../model/Stream'
import { t } from 'i18next'
import { Colors } from '../../theme/styles/Colors'
import { RadarBackground } from './RadarBackground'
import { RadarForeground } from './RadarForeground'
import { FundsAccessor } from './FundsAccessor'

export type ClipFunction = (point: Point, view?: string) => string

export interface ExtendedEntry {
    name: string
    shortname?: string
    status?: string
    ring: StyledRing
    sector: NamedSector
    clipFunction: ClipFunction
    x: number
    y: number
    color: string
    id: string
    tooltipInfo: TooltipInfo
}

export interface StyledRing extends Ring {
    name: string | string[]
    color: string
    label: string
}

export type IRadarType = 'four-rings' | 'five-rings';

export const COLOR_MAGENTA = Colors.primary.magenta
const COLOR_GRAY0 = Colors.gray.gray10
const COLOR_GRAY1 = Colors.gray.gray20
export const COLOR_GRAY2 = Colors.gray.gray03
const COLOR_GRAY3 = Colors.gray.gray50

const COLOR_STAGE4 = COLOR_MAGENTA
const COLOR_STAGE3 = COLOR_GRAY3
const COLOR_STAGE2 = COLOR_GRAY2
const COLOR_STAGE1 = COLOR_GRAY1
const COLOR_STAGE0 = COLOR_GRAY0


// TODO: this values cant be translated there are connected to radar logic
export const MATURITY0 = "Ressortzuteilung"
export const MATURITY1 = "Förderrichtlinie"
export const MATURITY2 = "Notifizierung"
export const MATURITY3 = "Ausschreibung"
export const MATURITY4 = "Zuteilung"

interface MinMaxValues {
    'min': number
    'max': number
}

interface NamedSector extends Sector {
    name: string
    radarName: string
}

export type SectorMap = Map<string, NamedSector>

export class RadarRenderer {

    private constructor() {

    }
    static extendedEntries: ExtendedEntry[] = []

    static drawRadar(data: RadarData, svgElement: SVGSVGElement, showModalWithFunds: (stageName: string | string[], sectorName: string) => void, onUpdateTooltip?: (config: TooltipConfig) => void, env: string = "dev", radarType?: IRadarType) {
        
        let radarConfig: RadarConfig = RADAR_CONFIG_NORMAL
        svgElement.removeAttribute('viewBox')

        if (data.view === 'quarter') {
            radarConfig = RADAR_CONFIG_QUARTER
        } else if (data.view === 'smaller') {
            radarConfig = RADAR_CONFIG_SMALLER
        }

        // radial_min / radial_max are multiples of PI
        const fourRingsConfig: StyledRing[] = [
            { 
                radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity4, 
                radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity3, 
                name: MATURITY4, 
                color: COLOR_STAGE4, 
                label: t("RP:RP-N-Status-4")  
            },
            { 
                radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity3, 
                radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity1, 
                name: MATURITY3, 
                color: COLOR_STAGE2, 
                label: t("RP:RP-N-Status-3")
            },
            { 
                radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity1, 
                radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity0, 
                name: MATURITY2, 
                color: COLOR_STAGE1, 
                label: t("RP:RP-N-Status-2")  
            },
            { 
                radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity0, 
                radiusMax: radarConfig.radius * 1.0, 
                name: [MATURITY0, MATURITY1],
                color: COLOR_STAGE0, 
                label: t("RP:RP-N-Status-1") 
            },
        ];

        const fiveRingsConfig: StyledRing[] = [
            { radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity4, radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity3, name: MATURITY4, color: COLOR_STAGE4, label: t("RP:RP-Status-5")  },
            { radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity3, radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity2, name: MATURITY3, color: COLOR_STAGE3, label: t("RP:RP-Status-4")  },
            { radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity2, radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity1, name: MATURITY2, color: COLOR_STAGE2, label: t("RP:RP-Status-3")  },
            { radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity1, radiusMax: radarConfig.radius * radarConfig.radarRingSizes.maturity0, name: MATURITY1, color: COLOR_STAGE1, label: t("RP:RP-Status-2")  },
            { radiusMin: radarConfig.radius * radarConfig.radarRingSizes.maturity0, radiusMax: radarConfig.radius * 1.0, name: MATURITY0, color: COLOR_STAGE0, label: t("RP:RP-Status-1") },
        ];

        const rings = radarType === 'four-rings' ? fourRingsConfig : fiveRingsConfig;

        const ringForName = new Map<string, StyledRing>()
        rings.forEach(ring => {
            if(typeof ring.name === 'string') {
                ringForName.set(ring.name, ring)
            } else {
                (ring.name as string[]).forEach(mixedRingName => {
                    ringForName.set(mixedRingName, ring)
                })
            }
        })

        const width = (data.view === 'quarter') ? RADAR_CONFIG_QUARTER.mobileWidth : (radarConfig.leftMargin + radarConfig.radius * 2 + radarConfig.rightMargin)

        const height = (data.view === 'quarter') ? RADAR_CONFIG_QUARTER.mobileHeight : radarConfig.radius + radarConfig.headerHeight + radarConfig.footerHeight

        const svg = d3.select(svgElement)

        const sectorForName = RadarRenderer.convertStreamsIntoSectorMap(data.streams, data.view)

        const randomNumberGenerator = new RandomNumberGenerator(radarConfig.seed)

        const extendedEntries = FundsAccessor.transformRawEntries(data.basicFunds, sectorForName, ringForName, randomNumberGenerator, data.view, env)
        this.extendedEntries = extendedEntries
        svg
            .selectAll("*")
            .remove()

        svg
            .attr("width", width)
            .attr("height", height)

        if (data.view === 'quarter') {
            svg
                .attr("width", width)
                .attr("height", height)
                .attr('viewBox', `-${width/2} 0 ${width} ${height}`)
        } else {
            svg
                .attr("width", width)
                .attr("height", height)
        }

        const radarRadiusWithMargin = radarConfig.radius + 5

        const cutOffX = (data.view === 'quarter') ? -radarRadiusWithMargin : -radarRadiusWithMargin
        const cutOffWidth = (data.view === 'quarter') ? radarRadiusWithMargin * 2 : radarRadiusWithMargin * 2

        svg.append("clipPath")
            .attr('id', 'cut-off')
            .append('rect')
            .attr('x', cutOffX)
            .attr('y', -radarRadiusWithMargin)
            .attr('width', cutOffWidth)
            .attr('height', radarRadiusWithMargin)

        const radar = svg.append("g");

        // draws rings, stream names, trend indicators, separation lines, legend

        RadarBackground.appendTo(radar, data.view === "quarter", radarConfig, sectorForName, rings, showModalWithFunds, radarType)

        // draws small diamonds + big allocation diamonds

        RadarForeground.appendTo(radar, radarConfig, data, rings, extendedEntries, onUpdateTooltip)
    }

    private static getMinMax(valueA: number, valueB: number): MinMaxValues {
        if (valueA > valueB) {
            return {
                'min': valueB,
                'max': valueA,
            }
        } else {
            return {
                'min': valueA,
                'max': valueB
            }
        }
    }

    private static convertStreamsIntoSectors(streams: Stream[], view?: RadarView): NamedSector[] {
        const n = streams.length

        const thetaPerStream = (view === 'quarter') ? (Math.PI / (2*n)) : (Math.PI / n)

        const s = [...streams]
        s.reverse()

        return s.map((stream: Stream, index: number) => {
          const thetaA = - ((index + 1) * thetaPerStream)
          const thetaB = - (index * thetaPerStream)
          const { min: thetaMin, max: thetaMax } = RadarRenderer.getMinMax(thetaA, thetaB)
          const name: string = stream.name
          const radarName = stream.name_radar || ''

          return {
            name,
            radarName,
            thetaMin,
            thetaMax,
          }
        })
      }

    private static convertStreamsIntoSectorMap(streams: Stream[], view?: RadarView): SectorMap {
        const sectors = RadarRenderer.convertStreamsIntoSectors(streams, view)

        const sectorForName = new Map<string, NamedSector>()

        sectors.forEach(sector => {
            sectorForName.set(sector.name, sector)
        })
        
        return sectorForName
    }
}