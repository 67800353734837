import useDebounce from '../../../../utils/useDebounce'
import BackendFactory from '../../../../backend/BackendFactory'
import React, { useEffect, useRef, useState } from 'react'
import { ScaleTextarea } from '@telekom/scale-components-react'
import { InputChangeEventDetail } from '@telekom/scale-components/dist/types/components/input/input'
import { useTranslation } from 'react-i18next'
import { BEresponse } from '../../../../backend/respType'

interface CommentTextAreaProps {
  activeRowId: string
  response: (result:BEresponse) => void,
  propComment: string | undefined
  commentFor: 'press' | 'fund'
}




export const CommentTextArea = ({activeRowId, response, propComment, commentFor }:CommentTextAreaProps) => {
  const { t } = useTranslation("table")
  const [comment, setComment] = useState('')
  const debouncedValue = useDebounce<string>(comment, 3000)
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return
    }
    const backend = BackendFactory.produce()

    switch (commentFor) {
      case 'fund':
        backend
          .patchBOFund({
            internalComment: comment
          }, activeRowId).then(res => response(res))
        break
      case 'press':
        backend
          .patchPublications({
            comment
          }, activeRowId).then(res => response(res))
        break
    }

       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const commentEdit = (event:  CustomEvent<InputChangeEventDetail>) => {
    // Triggers useEffect when "debouncedValue" changes
    if(typeof(event.detail.value) === 'string') {
      setComment(event.detail.value)
    }
  }


  return (
    <div style={{minWidth:'200px'}}>
      <ScaleTextarea
        value={propComment}
        label={String(t("comment"))}
        onScaleChange={e => commentEdit(e)}
      />
    </div>
  )

};