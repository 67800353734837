import React from  'react'

import { ScaleSwitch} from '@telekom/scale-components-react'

import ToggleLabelsInner from './__styled__/ToggleLabelsInner'

type Props = {
  checked: any
  label: string
  onScaleChange: (event: any) => void
}

const ToggleLabels = ({
  checked,
  label,
  onScaleChange
}: Props) => {

  return (
    <ToggleLabelsInner>
      <ScaleSwitch checked={checked} label={label} onScaleChange={onScaleChange} />
    </ToggleLabelsInner>
  )
}

export default React.memo(ToggleLabels)