import React from "react";
import Span from "../../components/Span";
import { TRANSLATIONS } from '../../theme/constants/Translations'

interface Props {
  deadline: Date
}

export interface BadgeInfo {
  deadline: number,
  deadlineClass: string,
  deadlineLabel: string
}
export default class DeadlineBadge extends React.Component<Props, any> {

  static calculateDeadline(deadline: Date): BadgeInfo {
    const oneMinute = 60 * 1000
    const oneHour = oneMinute * 60
    const oneDay = oneHour * 24
    const today = new Date()

    const deadlineYear = deadline.getFullYear()
    const currentYear = today.getFullYear()

    const yearDifference = deadlineYear - currentYear
    let deadlineDays = deadline.getTime() - today.getTime()
    deadlineDays = Math.floor(deadlineDays/oneDay)

    const deadlineLabelName = (deadlineDays === 1) ? TRANSLATIONS?.de.labels.day : TRANSLATIONS?.de.labels.days

    let deadlineClassName
    if (yearDifference === 1) {
      deadlineClassName = "deadline-yellow"
    } else {
      deadlineClassName = "deadline-orange"
    }

    return { deadline: deadlineDays, deadlineClass: deadlineClassName, deadlineLabel: deadlineLabelName }
  }
  
  render() {
    const currentBadgeInfo: BadgeInfo = DeadlineBadge.calculateDeadline(this.props.deadline)
    if (currentBadgeInfo.deadline <= 90) {
      return (
        <div className={`tag-deadline ${currentBadgeInfo.deadlineClass}`}>
          <div className="tag-deadline-inner">
            <Span isBlock className="deadline-days-number">{currentBadgeInfo.deadline}</Span>
            <Span isBlock className="deadline-days-text">{currentBadgeInfo.deadlineLabel}</Span>
          </div>
        </div>
      )
    }
    else return null
  }
}