import styled from 'styled-components/macro'

import * as types from '../../../types'

import { setCardsStyles } from '../../../helpers/cards'

type Props = {
  theme: any
  cardType?: types.CardColor
}

const CardInner = styled('div')<Props>`

  ${props => setCardsStyles(props,
    props.cardType || 'default'
  )}

  position: relative;

  & ~ .card__inner {
    margin-top: 24px;
  }
`

export default CardInner