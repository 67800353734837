import styled from 'styled-components/macro'

type Props = {

}

const ContactPhone = styled('span')<Props>`
  display: block;
`

export default ContactPhone