import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningSign2 } from '../../../../icons/warning-sign-2.svg'
import styles from './deleteModal.module.scss'
import { ScaleButton, ScaleModal } from '@telekom/scale-components-react'
import Office from '../../../../model/Office'

type DeleteOfficeModalProps = {
    isOpen: boolean
    onClose: () => void
    onAccept: () => void
    currentContact: Office
}

export const DeleteOfficeModal = ({ isOpen, onAccept, onClose, currentContact }: DeleteOfficeModalProps) => {
    const { t } = useTranslation(['SettingsPage', 'details'])

    return (
        <ScaleModal heading={String(t('details:contactTableTitle'))}
                    opened={isOpen}
                    size={'small'}
                    onScaleClose={() => onClose()}

        >
            <div className={styles.alertModalBody}>
                <WarningSign2 />
                <div>
                    <span>{t('SettingsPage:deleteMessage', { name: currentContact.name })}?</span>
                    {currentContact.affectedFunds.length > 0 &&
                      <>
                        <p>{t('SettingsPage:affectedFunds')}:</p>
                        <ul className={styles.modalList}>
                            {currentContact.affectedFunds.map(fund =>
                                <li key={fund.id}><a target='_blank' href={`/more/${fund.id}`}>{fund.name}</a></li>,
                            )}
                        </ul>
                      </>
                    }
                </div>

            </div>

            <div slot='action' className={styles.modalFooter}>
                <ScaleButton
                    variant='secondary'
                    onClick={() => onClose()}
                >
                    {t('details:modalActionInterrupt')}
                </ScaleButton>
                <ScaleButton onClick={() => onAccept()}>
                    {t('BO:BO-FP-NFPB1')}
                </ScaleButton>
            </div>

        </ScaleModal>
    )
}