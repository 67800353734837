import React from "react";

export type Renderer = () => JSX.Element | undefined | null

export interface BreakpointDependentRenderer {
  breakpoint: number
  renderer: Renderer
}

export interface WidthDependentProps {
  renderers: BreakpointDependentRenderer[]
}

export interface WidthDependentState {
  renderer?: Renderer
}

export default class WidthDependent extends React.Component<WidthDependentProps, WidthDependentState> {
  descendingBreakpointRenderers: BreakpointDependentRenderer[];

  constructor(props: WidthDependentProps) {
    super(props)

    this.descendingBreakpointRenderers = this.sortBreakpointRenderersDescending(this.props.renderers)

    this.state = {}


    this.updateSize = this.updateSize.bind(this)
  }

  updateSize() {
    const width = window.innerWidth
    const renderer = this.getRendererFor(width)

    this.setState((prevState) => {
      if (prevState.renderer === renderer) {
        return null
      } else {
        return {
          renderer
        }
      }
    })
  }

  componentDidMount() {
    this.updateSize()
    window.addEventListener('resize', this.updateSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize)
  }

  render() {
    if (this.state.renderer) {
      return this.state.renderer()
    }

    return null
  }

  private getRendererFor(width: number): Renderer | undefined {
    for (const { breakpoint, renderer } of this.descendingBreakpointRenderers) {
      if (width >= breakpoint) {
        return renderer
      }
    }

    return undefined
  }

  protected sortBreakpointRenderersDescending(breakpointRenderers: BreakpointDependentRenderer[]) {
    const sortedBreakpointRenderers = [...breakpointRenderers]
    sortedBreakpointRenderers.sort((a, b) => b.breakpoint - a.breakpoint)

    return sortedBreakpointRenderers
  }
}