import React from 'react'
import ReactDOM from 'react-dom'
import * as loader from '@telekom/scale-components/loader'
import '@telekom/scale-components/dist/scale-components/scale-components.css'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import App from './app/App'
import reportWebVitals from './reportWebVitals'
import Env from './utils/Env'
import './scss/app.scss'
import GlobalStyle from './theme/globalStyle'

Env.getAll().forEach((value, key) => console.log(`${key}: "${value}"`))

loader.applyPolyfills().then(() => {
  loader.defineCustomElements(window).then()
})


ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()