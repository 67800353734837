import styled from 'styled-components/macro'

type Props = {
  theme: any
}

const TooltipTitle = styled('p')<Props>`
  position: relative;
  max-width: 80%;
  margin: ${props => props.theme.spacings.smaller.gutter} 0 ${props => props.theme.spacings.smaller.gutter};
  font-size: ${props => props.theme.typography.tooltip.title.fontSize};
  font-weight: ${props => props.theme.typography.tooltip.title.fontWeight};
`

export default TooltipTitle