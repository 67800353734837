import * as d3 from 'd3'
import { COLOR_MAGENTA, ExtendedEntry, MATURITY4, StyledRing } from "./RadarRenderer";
import { Point } from "./GeometryModule";
import { RadarConfig } from "./RadarConfig";
import RadarData, { RadarView } from "./RadarData";
import { TooltipConfig } from "./RadarTooltip";
import SVGHelper from "./SVGHelper";
import { t } from 'i18next'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'
import { HIGHLIGHT } from '../../constants/BEstatuses'

type D3SVGGElement = d3.Selection<SVGGElement, unknown, null, undefined>


export class RadarForeground {
    private constructor() {

    }
    static appendTo(radar: D3SVGGElement, radarConfig: RadarConfig, data: RadarData, rings: StyledRing[], extendedEntries: ExtendedEntry[], onUpdateTooltip?: (config: TooltipConfig) => void) {
        // layer for entries
        let isTooltipClosedHere = true;
        let lastClickedDiamondEntryId: string | undefined;
        const backend: Backend = BackendFactory.produce()

        const rink = radar.append("g")
            .attr("id", "rink")
            .on("click", function (d: MouseEvent) {
                if(d.target instanceof SVGPathElement) {

                }
                else {
                    const diamond = d3.select('#_' + lastClickedDiamondEntryId + "diamond")
                    diamond.style("opacity", 1)
                }
            });

        // draw blips on radar
        const blips = rink.selectAll(".blip")
            .data(extendedEntries)
            .enter()
            .append("g")
            .attr('id', (entry: ExtendedEntry) => {
                return `blip_${entry.id}`
            })
            .attr("class", "blip")
            .style('cursor', 'pointer')
            .on('mousedown', function (d: any, entry: ExtendedEntry) {
                d3.select(d.target)
                    .style("opacity", 0.5)
            })
            // TODO: change back to 'click' -> 'mousemove' when hover feature is demanded
            .on("click", function (d: any, entry: ExtendedEntry) {
                backend.addViewToElement('Radar fund: ' + (entry.status ? `${entry.status}` : 'Keine Änderung'))
                if (onUpdateTooltip) {
                    onUpdateTooltip({
                        info: entry.tooltipInfo,
                        view: data.view,
                        isClosed: isTooltipClosedHere,
                        currentTarget: d.currentTarget,
                        getPivotCoordinates: () => {
                            return RadarForeground.getPivotCoordinates(entry)
                        }
                    })
                    isTooltipClosedHere = !isTooltipClosedHere
                    if (lastClickedDiamondEntryId) {
                        const diamond = d3.select('#_' + lastClickedDiamondEntryId + "diamond")
                        diamond.style("opacity", 1)
                    }
                    lastClickedDiamondEntryId = entry.id
                }
            })
            .on("mouseout", function (d: MouseEvent, entry) {
                // TODO: uncomment when hover feature is demanded
                /* d3.select('#tooltip').interrupt().transition().duration(150).style('opacity', 0).style('visibility', 'hidden') */
            })


        // configure each blip
        const diamondPath = SVGHelper.getDiamondPathString(radarConfig.diamondSize)

        let countStage4 = 0
        let oneDiamond
        // small diamonds with funding names
        blips.each(function (entry: ExtendedEntry) {

            const blip: any = d3.select(this)
            const fillColor = COLOR_MAGENTA
            const isStage4 = (entry.ring.name === MATURITY4)
            const isShowLabels = data.showLabels

            if (!isStage4) {
                RadarForeground.drawBlip(blip, radarConfig, entry, isShowLabels, diamondPath, fillColor, data.view, onUpdateTooltip);
            } else {
                countStage4++
                oneDiamond = entry
            }

        });


        // big diamonds with number of programmes in allocation
        const bigDiamondsPath = SVGHelper.getDiamondPathString(radarConfig.bigDiamonds.diamondSize)

        const middleDiamonds = RadarForeground.appendBigAllocationDiamondTo(radar, bigDiamondsPath, radarConfig, countStage4, oneDiamond)

        // make sure that blips stay inside their segment
        function ticked() {
            blips.attr("transform", function (d: ExtendedEntry) {
                return d.clipFunction(d, data.view)
            })
        }

        d3.forceSimulation()
            .nodes(extendedEntries)
            .velocityDecay(0.19) // magic number (found by experimentation)
            .force("collision", d3.forceCollide().radius(12).strength(0.85))
            .on("tick", ticked)


        const postCall = () => {
            const middleDiamondsWidth = middleDiamonds.node()?.getBBox().width;

            if (!middleDiamonds.node() || middleDiamondsWidth === 0) {
                setTimeout(postCall, 25)
            }

            if (!middleDiamondsWidth) {
                return
            }

            blips.each(function (entry: ExtendedEntry) {
                const rectId = "_" + entry.id + "rect"
                const blipNode: SVGGraphicsElement | null = (d3.select(`#blip_${entry.id}`)?.node() as any)
                const blipBBox: DOMRect | undefined = blipNode?.getBBox()

                if (blipNode && blipBBox) {
                    const blipWidth = blipBBox.width
                    const textBackground = d3.select(`#${rectId}`)

                    // following numbers found through experimentation
                    textBackground
                        .attr("width", blipWidth + 14)
                        .attr("height", 28)
                }
            });

            const radialRadiusMin = rings[0].radiusMax

            let x = 0
            let y = 0
            if (data.view === 'quarter') {
                x = - middleDiamondsWidth / 2 - radarConfig.bigDiamonds.diamondSize / 2
                y = - rings[0].radiusMax + 65
            }
            else {
                x = - middleDiamondsWidth / 2 + radarConfig.bigDiamonds.diamondSize / 2 + 2
                y = - (radialRadiusMin / 2) + radarConfig.bigDiamonds.diamondSize / 2
            }

            const middleDiamondsHoverBackground = d3.select(`#middleDiamondsRect`)

            // following numbers found through experimentation
            middleDiamondsHoverBackground
                .attr("width", middleDiamondsWidth + 14)
                .attr("height", 28)

            middleDiamonds
                .attr('transform', SVGHelper.getTranslateString(x, y))
            middleDiamonds
                .transition()
                .duration(300)
                .ease(d3.easeLinear)
                .style('opacity', 1)
        }

        setTimeout(postCall, 25)
    }

    static getPivotCoordinates(entry: ExtendedEntry): Point {
        const svgBBox: DOMRect = (d3.select(`#svg`).node() as any)?.getBoundingClientRect()
        const svgOrigin: Point = { x: svgBBox?.left, y: svgBBox?.top }
        const targetOffset: Point = { x: 12 + 10, y: -12 }
        const possibleTarget = d3.select(`#blip_${entry.id}`).node() as any

        const targetCoords: Point = possibleTarget ? SVGHelper.getElementCoords(possibleTarget, targetOffset) : { x: 0, y: 0 }

        const pivotCoords: Point = { x: targetCoords.x + svgOrigin.x, y: targetCoords.y + svgOrigin.y }

        if (possibleTarget === undefined || possibleTarget === null) {
            console.error(`No target blip for tooltip`)
        }
        return pivotCoords
    }

    private static drawBlip(blip: any, radarConfig: RadarConfig, entry: ExtendedEntry, showLabels: boolean, diamondPath: string, fillColor: string | undefined, view?: RadarView, onUpdateTooltip?: (config: TooltipConfig) => void) {
        // draw background for hover
        const rectId = "_" + entry.id + "rect"
        const diamondId = "_" + entry.id + "diamond"
        const textId = "_" + entry.id + "text"

        if (view !== 'quarter') {
            blip.append("rect")
                .style("fill", "white")
                .attr("id", rectId)
                .style("opacity", "0")
                .attr("x", -13)
                .attr("y", -14)
                .attr("rx", 6)
                .attr("ry", 6)
                .attr("pointer-events", "none")

            const label = entry.shortname ? entry.shortname : entry.name;
            const labelColor = 'black'
            const diamondsFontSize = radarConfig.diamonds.fontSize
            const textOffset = {
                x: radarConfig.diamonds.offset.x,
                y: radarConfig.diamonds.offset.y
            }

            blip.append("text")
                .attr("id", textId)
                .text(label)
                .attr("x", textOffset.x)
                .attr("y", textOffset.y)
                .attr("text-anchor", "right")
                .style("fill", labelColor)
                .style("font-family", `'TeleNeoWeb', sans-serif`)
                .style('font-weight', '500')
                .style("font-size", diamondsFontSize)
                .style("pointer-events", "none")
                .style("user-select", "none")

            if (!showLabels) {
                d3.select(`#${textId}`)
                    .attr("opacity", "0")
            }
        }

        // draw actual diamond
        if (entry.status === HIGHLIGHT.NO_CHANGE || entry.status === undefined) {
            const diamondIcon = blip.append('path')
                .attr('d', diamondPath)
                .attr("fill", fillColor)
                .attr("stroke", "black")
                .attr("stroke-width", "1px")
                .attr("id", diamondId)
            this.appendTabbingFunctionality(diamondIcon, view, onUpdateTooltip)
        } else if (entry.status === HIGHLIGHT.NOTE_DEADLINES) {
            this.drawDeadlineIcon(blip, radarConfig, view, onUpdateTooltip)
        } else if (entry.status === HIGHLIGHT.CONTENT_UPLOADED) {
            this.drawUpdatedIcon(blip, radarConfig, view, onUpdateTooltip)
        } else if (entry.status === HIGHLIGHT.NEW_FUNDING_PROGRAM) {
            this.drawNewFundIcon(blip, radarConfig, view, onUpdateTooltip)
        }

        // configure hover
        if (view !== 'quarter') {
            blip
                .on('mouseover', function () {
                    d3.select(`#${diamondId}`)
                        .attr("fill", "black")

                    d3.select(`#${textId}`)
                        .attr("opacity", "1")
                        .transition()
                        .duration(300)

                    d3.select(`#${rectId}`)
                        .style("opacity", "1")
                        .attr("pointer-events", "auto")
                        .style("user-select", "auto")

                    d3.select(`#blip_${entry.id}`)
                        .raise()
                })
                .on('mouseout', function () {
                    d3.select(`#${diamondId}`)
                        .attr("fill", "#e20074")

                    d3.select(`#${rectId}`)
                        .style("opacity", "0")
                        .attr("pointer-events", "none")
                        .style("user-select", "none")

                    if (!showLabels) {
                        d3.select(`#${textId}`).attr("opacity", "0")
                    }
                })
            blip.style('opacity', 0)
            blip.transition().duration(500).ease(d3.easeLinear).style('opacity', 1)
        }
    }

    private static drawUpdatedIcon(blip: any, radarConfig: RadarConfig, view?: RadarView, onUpdateTooltip?: (config: TooltipConfig) => void) {
        const updatedIcon = blip.append("g")
            .attr("class", "updated-icon")
            .attr("transform", "translate(-11 -11)")

        const updatedIconBackground = updatedIcon.append("g")
            .attr("transform", "translate(11) rotate(45)")
            .attr("stroke", "#000")
            .attr("stroke-width", "1")

        updatedIconBackground.append("rect")
            .attr("width", radarConfig.specialDiamondOuterSize)
            .attr("height", radarConfig.specialDiamondOuterSize)
            .attr("stroke", "none")

        const updatedIconInner = updatedIcon.append("g")
            .attr("transform", "translate(11.172 3) rotate(45)")
            .attr("stroke", "#fff")
            .attr("stroke-width", "1")
            .attr("fill", "none")

        updatedIconInner.append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", radarConfig.specialDiamondInnerSize)
            .attr("height", radarConfig.specialDiamondInnerSize)
            .attr("fill", "none")

        let transformAttribute
        if (view === 'quarter') {
            transformAttribute = "translate(16.687 9) rotate(90) scale(1.5)"
        } else if (view === 'smaller') {
            transformAttribute = "translate(14 7) rotate(90) scale(0.8)"
        } else {
            transformAttribute = "translate(14.687 7) rotate(90)"
        }

        const updatedFundIconPath = updatedIcon.append("g")
            .attr("transform", transformAttribute)

        updatedFundIconPath.append("path")
            .attr("d", "M0,0H5L0,5Z")
            .attr("stroke", "none")
            .attr("fill", "none")

        updatedFundIconPath.append("path")
            .attr("d", "M 1 1 L 1 2.585779905319214 L 2.585779905319214 1 L 1 1 M 0 0 L 5 0 L 0 5 L 0 0 Z")
            .attr("stroke", "none")
            .attr("fill", "#fff")

        this.appendTabbingFunctionality(updatedIcon, view, onUpdateTooltip)
    }

    private static drawNewFundIcon(blip: any, radarConfig: RadarConfig, view?: RadarView, onUpdateTooltip?: (config: TooltipConfig) => void) {
        const newFundIcon = blip.append("g")
            .attr("class", "new-icon")
            .attr("transform", "translate(-11 -11)")

        const newIconBackground = newFundIcon.append("g")
            .attr("transform", "translate(11) rotate(45)")
            .attr("stroke", "#0096d5")
            .attr("stroke-width", "1")
            .attr("fill", "#0096d5")

        newIconBackground.append("rect")
            .attr("width", radarConfig.specialDiamondOuterSize)
            .attr("height", radarConfig.specialDiamondOuterSize)
            .attr("stroke", "none")

        const newIconInner = newFundIcon.append("g")
            .attr("transform", "translate(11.172 3) rotate(45)")
            .attr("stroke", "#fff")
            .attr("stroke-width", "1")
            .attr("fill", "none")

        newIconInner.append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", radarConfig.specialDiamondInnerSize)
            .attr("height", radarConfig.specialDiamondInnerSize)
            .attr("fill", "none")

        let transformAttribute
        if (view === 'quarter') {
            transformAttribute = "translate(9.709 16.344) rotate(90) scale(1.5)"
        } else if (view === 'smaller') {
            transformAttribute = "translate(10.55 10.55) rotate(90) scale(0.8)"
        } else {
            transformAttribute = "translate(9.709 12.344) rotate(90)"
        }

        const newFundIconPath = newFundIcon.append("g")
            .attr("transform", transformAttribute)

        newFundIconPath.append("path")
            .attr("d", "M0,0H5.461L0,5.461Z")
            .attr("stroke", "none")
            .attr("fill", "none")

        newFundIconPath.append("path")
            .attr("d", "M 0.9999985694885254 0.9999985694885254 L 0.9999985694885254 3.046718835830688 L 3.046718835830688 0.9999985694885254 L 0.9999985694885254 0.9999985694885254 M -1.430511474609375e-06 -1.430511474609375e-06 L 5.460938930511475 -1.430511474609375e-06 L -1.430511474609375e-06 5.460938930511475 L -1.430511474609375e-06 -1.430511474609375e-06 Z")
            .attr("stroke", "none")
            .attr("fill", "#fff")

        this.appendTabbingFunctionality(newFundIcon, view, onUpdateTooltip)
    }

    private static drawDeadlineIcon(blip: any, radarConfig: RadarConfig, view?: RadarView, onUpdateTooltip?: (config: TooltipConfig) => void) {
        const deadlineIcon = blip.append("g")
            .attr("class", "deadline-icon")
            .attr("transform", "translate(-11 -11)")

        const deadlineIconBackground = deadlineIcon.append("g")
            .attr("transform", "translate(11) rotate(45)")
            .attr("stroke", "#ffce00")
            .attr("stroke-width", "1")
            .attr("fill", "#ffce00")

        deadlineIconBackground.append("rect")
            .attr("width", radarConfig.specialDiamondOuterSize)
            .attr("height", radarConfig.specialDiamondOuterSize)
            .attr("stroke", "none")

        const deadlineIconInner = deadlineIcon.append("g")
            .attr("transform", "translate(11.172 3) rotate(45)")
            .attr("stroke", "#000")
            .attr("stroke-width", "1")
            .attr("fill", "none")

        deadlineIconInner.append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", radarConfig.specialDiamondInnerSize)
            .attr("height", radarConfig.specialDiamondInnerSize)
            .attr("fill", "none")

        let transformAttribute
        if (view === 'quarter') {
            transformAttribute = "translate(13.575 22.352) scale(1.5)"
        } else if (view === 'smaller') {
            transformAttribute = "translate(12.575 14) scale(0.8)"
        } else {
            transformAttribute = "translate(12.575 16.352)"
        }

        deadlineIcon.append("path")
            .attr("d", "M-1.965-4.011H-1.2L-.774-7.3V-9.352H-2.393V-7.3Zm-.611,2.659h2V-2.921h-2Z")
            .attr("transform", transformAttribute)
            .attr("fill", "#282828")

        this.appendTabbingFunctionality(deadlineIcon, view, onUpdateTooltip)
    }

    private static appendTabbingFunctionality(blip: any, view?: RadarView, onUpdateTooltip?: (config: TooltipConfig) => void) {
        blip.attr("tabindex", 0)
            .on("keydown", function (d: KeyboardEvent, entry: ExtendedEntry) {
                // Number 13 is the "Enter" key on the keyboard
                if (d.keyCode === 13) {
                    if (onUpdateTooltip) {
                        onUpdateTooltip({
                            info: entry.tooltipInfo,
                            view,
                            getPivotCoordinates: () => {
                                return RadarForeground.getPivotCoordinates(entry)
                            }
                        })
                    }

                    d3.select('#tooltip')
                        .style('visibility', 'visible')
                        .transition().duration(150).ease(d3.easeLinear).style("opacity", 1)
                }

                if (d.keyCode === 27) {
                    const tooltipClose = document.getElementById("tooltipClose")
                    if (tooltipClose) {
                        tooltipClose.click()
                    }
                }
            })
    }

    private static appendBigAllocationDiamondTo(radar: D3SVGGElement, bigDiamondsPath: string, radarConfig: RadarConfig, countStage4: number, entryIfOnlyOne?: ExtendedEntry): D3SVGGElement {
        const bigDiamondFontSize = radarConfig.bigDiamonds.fontSize
        const bigDiamondSize = radarConfig.bigDiamonds.diamondSize

        const rectMiddleDiamondsId = "middleDiamondsRect"
        const bigDiamondOneId = "BigDiamondOne"
        const bigDiamondTwoId = "BigDiamondTwo"
        const bigDiamondTextId = "BigDiamondText"

        const middleDiamonds = radar.append("g")
            .attr("id", "diamonds")
            .style('opacity', 0)

        middleDiamonds
            .on('mouseover', function () {
                d3.select(`#${bigDiamondOneId}`)
                    .attr("fill", "black")

                d3.select(`#${bigDiamondTwoId}`)
                    .attr("fill", "black")
                    .attr('stroke', "white")

                d3.select(`#${bigDiamondTextId}`)
                    .attr("fill", "black")
                    .transition()
                    .duration(300)

                d3.select(`#${rectMiddleDiamondsId}`)
                    .style("opacity", "1")
                    .attr("pointer-events", "auto")
                    .style("user-select", "auto")
            })
            .on('mouseout', function () {
                d3.select(`#${bigDiamondOneId}`)
                    .attr("fill", "white")

                d3.select(`#${bigDiamondTwoId}`)
                    .attr("fill", "white")
                    .attr('stroke', COLOR_MAGENTA)

                d3.select(`#${bigDiamondTextId}`)
                    .attr("fill", "white")
                    .transition()
                    .duration(300)

                d3.select(`#${rectMiddleDiamondsId}`)
                    .style("opacity", "0")
                    .attr("pointer-events", "none")
                    .style("user-select", "none")
            })

        middleDiamonds.append("rect")
            .style("fill", "white")
            .attr("id", rectMiddleDiamondsId)
            .style("opacity", "0")
            .attr("x", -13)
            .attr("y", -14)
            .attr("rx", 6)
            .attr("ry", 6)
            .attr("pointer-events", "none")

        let middleDiamondsLink

        if (countStage4 === 1 && entryIfOnlyOne) {
            middleDiamondsLink = middleDiamonds.append('a')
                .attr('xlink:href', '/detail/' + entryIfOnlyOne.id)
                .attr('title', t("details:goToDetailPage") + entryIfOnlyOne.name)
                .attr('alt', t("details:goTo") + " " + t("details:goToDetailPage") + entryIfOnlyOne.name)

            middleDiamondsLink.append("svg:title")
                .text(t("details:goToDetailPage") + entryIfOnlyOne.name)
        }
        else {
            middleDiamondsLink = middleDiamonds.append('a')
                .attr('xlink:href', '/allocations')
                .attr('title', t("details:goToAllocationPage"))
                .attr('alt', t("details:goTo") + " " + t("details:goToAllocationPage"))

            middleDiamondsLink.append("svg:title")
                .text(t("details:goToAllocationPage"))
        }

        middleDiamondsLink.append('path')
            .attr('d', bigDiamondsPath)
            .attr("fill", "white")
            .attr('id', bigDiamondOneId)
            .attr("transform", SVGHelper.getTranslateString(bigDiamondSize, 0))

        middleDiamondsLink.append('path')
            .attr('d', bigDiamondsPath)
            .attr("fill", "white")
            .attr('id', bigDiamondTwoId)
            .attr("transform", SVGHelper.getTranslateString(0, 0))
            .attr('stroke', COLOR_MAGENTA)

        middleDiamondsLink.append("text")
            .text(countStage4)
            .attr('id', bigDiamondTextId)
            .attr("fill", "white")
            .attr("transform", SVGHelper.getTranslateString(bigDiamondSize * 3, bigDiamondSize * 1.1))
            .style("font-family", "'TeleNeoWeb', sans-serif")
            .style('font-weight', '600')
            .style("font-size", bigDiamondFontSize)
            .style("text-decoration", 'none')

        return middleDiamonds
    }
}