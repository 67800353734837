export const Spacings = {
  tiny: {
    margin: '5px',
    gutter: '12px'
  },
  smaller: {
    margin: '8px',
    gutter: '16px'
  },
  small: {
    margin: '16px',
    gutter: '24px'
  },
  large: {
    margin: '24px',
    gutter: '32px'
  },
  larger: {
    margin: '56px',
    gutter: '64px'
  }
} as const
