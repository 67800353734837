import getEnvConfig from '../backend/config'

export const getContactEmail = ():string => {
  const envConfig = getEnvConfig()

  const defaultEmail = 'competencecenterfoerderung@telekom.de'
  switch (envConfig.APP_ENV) {
    case "EU-prod":
      return 'eu_fondovi@t.ht.hr'
    default:
      return defaultEmail
  }
}