import styled from 'styled-components/macro'

type Props = {
  isClear?: boolean
}

const ListItemInner = styled('div')<Props>`
  position: relative;
`

export default ListItemInner