import React from 'react'
import styles from './CharacterCounter.module.scss'

interface CharacterCounterProps {
    maxLength: number;
    children: React.ReactChild
    inputValue: string
}

const CharacterCounter: React.FC<CharacterCounterProps> = ({ maxLength, children, inputValue }) => {

    const removeHtmlTags = (input: string): string => {
        if (input) {
            const regex = /(<([^>]+)>)/gi
            const result = input.replace(regex, '').replace(/&nbsp;/g, ' ')
            return result
        }
        return ''
    }

    const valueWithoutTags = removeHtmlTags(inputValue)
    const characterCount = valueWithoutTags.length
    return (
        <div className={styles.wrapper}>
            {children}
            <div className={`${styles.counter} ${valueWithoutTags.length >= maxLength ? styles.counter__full : ''}`}>
                {characterCount}/{maxLength}
            </div>
        </div>
    )
}

export default CharacterCounter
