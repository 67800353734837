import React from 'react'

import ListInner from './__styled__/ListInner'

type Props = {
  isClear?: boolean
  children?: any
}

const List = ({
  isClear,
  children
}: Props) => {

	return (
    <ListInner className="list-inner" isClear={isClear}>
      {children}
    </ListInner>
	)
}

export default React.memo(List)