import React from 'react'
import './sort-icons.scss'

interface Props {
  sortParamName: string
  onSortingAction: Function
  sortParams?:string
}

const SortingIcons = ({
    sortParamName,
    onSortingAction,
    sortParams
  }: Props) => {

  return (
    <span className="sort">
      <span onClick={() => onSortingAction('+' + sortParamName)}
            className={`sort__icon sort__icon--ascending ${sortParams === ('+'+sortParamName) ? 'active' : ''}`}>
        <svg viewBox="0 0 14 14" >
          <polygon transform="translate(8.242641, 10.242641) rotate(45.000000) translate(-8.242641, -10.242641) "
              points="5.24264069 7.24264069 11.2426407 7.24264069 5.24264069 13.2426407"/>
        </svg>
      </span>
      <span onClick={() => onSortingAction('-'  + sortParamName)}
            className={`sort__icon sort__icon--descending ${sortParams === ('-'+sortParamName) ? 'active': ''}`}>
        <svg viewBox="0 0 14 14">
          <polygon transform="translate(8.242641, 6.242641) scale(1, -1) rotate(45.000000) translate(-8.242641, -6.242641) "
              points="5.24264069 3.24264069 11.2426407 3.24264069 5.24264069 9.24264069"/>
        </svg>
      </span>
    </span>
  )
}
export default React.memo(SortingIcons)