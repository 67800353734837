import {FormField} from "../../../../model/form-field";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {ScaleButton, ScaleTextarea} from "@telekom/scale-components-react";
import BackendFactory from "../../../../backend/BackendFactory";
import {IKeyWords} from "./form-types";

interface Props {
    content: FormField[],
    onClose: (type?:string)=> void,
    onSubmit: (type?:string)=> void,
    type: string,
    index?: number
}

export const CreateMainKeywords = ({content, onClose, onSubmit, type}: Props) => {
    const { t } = useTranslation()
    const callTo =  BackendFactory.produce()
    const initialState: IKeyWords  = {
        "main-keywords": []
    }
    const [formState, setFormState] = useState(initialState)

    const handleChange = (event: any) =>{
        const list = event.target.value.split(",")
        const tr = list.map((element: string) => {
            return element.trim()
        });
        setFormState({...formState, "main-keywords": tr})
    }

    const handleSubmit = () => {
        let validated = true
        switch (type){
            case 'create':
                callTo.postMainKeywords(formState["main-keywords"]).then(res => {
                }).catch( reason => {
                    console.error(reason)
                }).finally( ()=> validated && onSubmit())
            break
        }
    }

    const inputFields = (fields: FormField[]) => {
        let value = formState["main-keywords"].join(", ")
        return fields.map( (field, i) => 
            <div key={`${field.label}-${i}`}>
                
                <ScaleTextarea
                    value={value}
                    label={field.label}
                    rows={8}
                    onScaleChange={e => handleChange(e)}
                    // disabled={true}
                />
            </div>)
    }

    const getKeywords = async () => {
        const keywordsAll = await callTo.getMainKeywords()
        setFormState({...formState, "main-keywords": keywordsAll})
        
    }

    useEffect(()=>{
        getKeywords()
        
    }, [])


    const customContent = () => {
        return <>
            <div className="modal__sub-title">{t("table:keywordManagementExample")}</div>

            <div className="content-wrapper">
                {inputFields(content)}
            </div>
            <div className="modal__actions">
                    <ScaleButton slot="action" variant="secondary" onClick={()=>onClose && onClose()} >
                        {t("details:modalActionInterrupt")}
                    </ScaleButton>
                    <ScaleButton slot="action" type='submit' onClick={ ()=> handleSubmit()}>
                        {t("details:modalActionSave")}
                    </ScaleButton>
                </div>
        </>
    }

    return customContent()
}