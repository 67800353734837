import React, { createRef } from 'react'

import { TooltipConfig } from './RadarTooltip'
import RadarData from './RadarData'
import { RadarRenderer } from './RadarRenderer'

export interface RadarProps {
  data?: RadarData,
  onModalOpened: (stageName: string | string[], sectorName: string) => void,
  onUpdateTooltip?: (config: TooltipConfig) => void
}

export default class RadarSVG extends React.Component<RadarProps, any> {
  ref: React.RefObject<SVGSVGElement>;
  
  constructor(props: RadarProps) {
    super(props)
    this.ref = createRef<SVGSVGElement>()
  }

  shouldComponentUpdate(prevProps: RadarProps) {
    return prevProps.data !== this.props.data
  }

  componentDidMount() {
    this.updateRadar()
  }

  componentDidUpdate() {
    this.updateRadar()
  } 

  private updateRadar() {
    const env = this.context.data
    if (this.props.data && this.ref.current) {
      const data = {...this.props.data}

      if (data.view === 'quarter') {
        data.streams = [data.streams[0]]
      }
      //here use env variable to define radar type. Currently it's 'four-rings' | 'five-rings'
      RadarRenderer.drawRadar(data, this.ref.current, this.props.onModalOpened, this.props.onUpdateTooltip, env, 'four-rings')
    }
  }

  render() {
    return (
      <>
        <svg
          className="radar-component"
          id='svg'
          width={1}
          height={1}
          style={{zIndex: 0}}
          ref={this.ref}
        />
      </>
    )
  }
}