import React from 'react'

import ListItemInner from './__styled__/ListItemInner'

type Props = {
  children?: any
}

const ListItem = ({
  children
}: Props) => {

	return (
    <ListItemInner className="list-item-inner">
      {children}
    </ListItemInner>
	)
}

export default React.memo(ListItem)