import React, { useEffect, useRef, useState } from 'react'
import { ItemsListForSettingsPage, SettingsElement } from '../../../backend/respType'
import { ModalActions } from '../../../components/F2CFormGenerator/types'
import Backend from '../../../backend/Backend'
import BackendFactory from '../../../backend/BackendFactory'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import styles from './PageContent.module.scss'
import { ScaleButton } from '@telekom/scale-components-react'
import { F2CAccordion } from '../../../components/F2CAccordion/F2CAccordion'
import { AlertModal } from './AlertModal'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { settingsFormConfig } from '../../../env/settingsSegments/segmentsFormConfig'
import { segmentsFormSchema } from '../../../env/settingsSegments/segmentsValidationSchema'

export const Segments = () => {
    const initContact: SettingsElement = { name: '', id: '', email: '', affectedFunds: [], subList: [] }
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)
    const [currentSegment, setCurrentSegment] = useState<SettingsElement>(initContact)
    const [segmentList, setSegmentList] = useState<ItemsListForSettingsPage>()
    const backend: Backend = BackendFactory.produce()
    const toast = useRef<Toast>(null)
    const { t } = useTranslation('SettingsPage')

    useEffect(() => {
        updateSegmentsList()
    }, [])
    // console.log(currentContact)
    const updateSegmentsList = (): void => {
        backend.getItemsListForSettingsPage('segments').then(resp => {
            if(resp.data) {
                setSegmentList(resp)
            }else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
            }
        })
    }


    const deleteContact = (): void => {
        backend.deleteElementFromSettingsPage('segments', currentSegment.id)
            .then((resp) => {
                if (resp?.detail === 'SUCCESS') {
                    updateSegmentsList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('SettingsPage:geoAreaDeletedSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: t('SettingsPage:geoAreaDeletedError'),
                        life: 5000,
                    })
                }
            })

    }

    const openModal = (modalType: ModalActions, geoAreaContact: SettingsElement): void => {
        setModal(modalType)
        setCurrentSegment(geoAreaContact)
    }

    const closeModal = () => {
        setModal(ModalActions.REMOVE)
        setCurrentSegment(initContact)
    }

    const createSegment = (data: SettingsElement) => {
        backend.createElementForSettingsPage('segments',{
            affectedFunds: [], id: '', subList: [],
            name:data.name,
            email: data.email,
            parent_id: modal === 'createSub' ? data.id : undefined
        }).then(resp => {
            if (resp?.detail === 'SUCCESS') {
                updateSegmentsList()
                setModal(ModalActions.REMOVE)
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('SettingsPage:geoAreaCreateSuccess'),
                    life: 5000,
                })
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:geoAreaCreateError'), life: 5000 })
            }
        })
    }

    const editGeoArea = (formData:SettingsElement) => {
        backend.editElementInSettingsPage('segments', formData)
            .then(resp => {
                if (resp?.detail === 'SUCCESS') {
                    updateSegmentsList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('SettingsPage:geoAreaEditSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:geoAreaEditError'), life: 5000 })
                }
            })
    }

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.settingsHead}>
                <div>
                    <h1>{t('SettingsPage:BO-Set-Item2-titel')}</h1>
                    <h2>{t('SettingsPage:BO-Set-Item2-sub')}</h2>
                </div>
                <ScaleButton
                    variant='primary'
                    onClick={() => openModal(ModalActions.CREATE, initContact)}
                >
                    {t('SettingsPage:BO-Set-Item2-new')}
                </ScaleButton>
            </div>

            {segmentList &&
              <F2CAccordion data={segmentList.data}
                            onItemEdit={item => openModal(ModalActions.EDIT, item as SettingsElement)}
                            onItemDelete={item => {
                                if (item.subList.length > 0) openModal(ModalActions.ALERT_DELETE, item as SettingsElement)
                                else openModal(ModalActions.ALERT_DELETE, item as SettingsElement)
                            }}
                            onItemCreate={id => openModal(ModalActions.CREATE_SUB, {
                                ...initContact,
                                id: id,
                            })}
                            onSubItemDelete={item => openModal(ModalActions.ALERT_DELETE, item as SettingsElement)}
                            onSubItemEdit={item => openModal(ModalActions.EDIT, item as SettingsElement)}
              />}
            <AlertModal isOpen={modal === ModalActions.ALERT_DELETE || modal === ModalActions.ALERT}
                        title={t('SettingsPage:BO-Set-Item2-titel')}
                        modal={modal}
                        onClose={() => closeModal()}
                        onAccept={() => deleteContact()}
                        currentContact={currentSegment}
            />
            <F2CFormGenerator isOpened={modal === ModalActions.EDIT || modal === ModalActions.CREATE || modal === ModalActions.CREATE_SUB}
                              modalType={modal}
                              modalTitle={String(t('BO-Set-I2EM-titel'))}
                              onClose={() => {
                                  closeModal()
                              }}
                              onValidationCreate={(formData) => {
                                  createSegment(formData as SettingsElement)

                              }}
                              onEdit={(formData) => {
                                  const updatedGeoArea = {
                                      ...currentSegment,
                                      ...formData
                                  }
                                  editGeoArea(updatedGeoArea)
                              }}
                              formConfig={settingsFormConfig}
                              initialFormValues={currentSegment}
                              validationSchema={segmentsFormSchema}
            />
            <Toast ref={toast} position='bottom-right' />
        </div>
    )
}