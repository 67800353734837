import React, { useEffect, useRef, useState } from 'react'
import styles from '../PageContent.module.scss'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'primereact/calendar'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { useFormik } from 'formik'
import { notificationFormlSchema, notificationFormValues } from './formConfig'
import { Button } from 'primereact/button'
import Backend from '../../../../backend/Backend'
import BackendFactory from '../../../../backend/BackendFactory'
import { BoEvents } from '../../../../backend/respType'
import { Toast } from 'primereact/toast'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'
import CharacterCounter from '../../../../components/CharacterCounter/CharacterCounter'


export const UserNotification = () => {
    const { t } = useTranslation(['SettingsPage', 'details'])
    const [editMode, setEditMode] = useState(false)
    const backend: Backend = BackendFactory.produce()
    const toast = useRef<Toast>(null)
    const formik = useFormik({
        initialValues: notificationFormValues,
        onSubmit: (values, formikHelpers) => {

            editMode ? editBoEvent(formik.values) : createBoEvent(formik.values)
            // if (onSubmit) onSubmit(formik.touched)
            formikHelpers.setSubmitting(false)
        },
        validationSchema: notificationFormlSchema,
        validateOnMount: true,
    })

    useEffect(() => {
        getBoEvents()
    }, [])


    const getBoEvents = () => {
        backend.getBoEvent().then(resp => {
            if (resp) {
                formik.setValues(resp)
                setEditMode(true)
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Failed to get event info',
                    detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
            }
        })
    }

    const createBoEvent = (body: BoEvents) => {
        backend.postBoEvent(body).then(resp => {
            if (resp.detail) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Failed to create',
                    // detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
            } else {
                toast.current?.show({
                    severity: 'success',
                    summary: 'Saved',
                    // detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
            }
        })
    }

    const editBoEvent = (body: BoEvents) => {
        // @ts-ignore
        backend.patchBoEvent(body).then(resp => {
            if (resp.detail) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Failed to save',
                    // detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
            } else {
                toast.current?.show({
                    severity: 'success',
                    summary: 'Saved',
                    // detail: t('SettingsPage:getAreaListError'),
                    life: 5000,
                })
                formik.setTouched({})
            }
        })
    }



    const calendarDate = formik.values.event_datetime ? new Date(formik.values.event_datetime) : null

    const hasValidationErrors = Object.keys(formik.errors).length > 0

    let minDate = new Date()

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.settingsHead}>
                <div>
                    <h1>{t('SettingsPage:notification-title')}</h1>
                    <h2>{t('SettingsPage:notification-desc')}</h2>
                </div>
            </div>
            <form className={`${styles.notificationsForm} notification-form`} onSubmit={formik.handleSubmit}>
                <div>
                    <p>{t('SettingsPage:notification-input-title')}</p>
                    <CharacterCounter maxLength={35} inputValue={formik.values.title}>
                        <InputText name='title'
                                   placeholder={t('SettingsPage:notification-input-title-placeholder') || ''}
                                   value={formik.values.title}
                                   onChange={(e) => {
                                       formik.handleChange(e)
                                       formik.setFieldTouched('title')
                                   }} />
                    </CharacterCounter>

                </div>
                <div>
                    <p>{t('SettingsPage:notification-input-desc')}</p>
                    <InputTextarea value={formik.values.body}
                                   placeholder={t('SettingsPage:notification-input-desc-placeholder') || ''}
                                   onChange={(e) => {
                        formik.setFieldValue('body', e.target.value)
                        formik.setFieldTouched('body')
                    }} rows={3} />

                </div>
                <div>
                    <p>{t('SettingsPage:notification-input-date')}</p>
                    <Calendar name='event_datetime'
                              placeholder={t('SettingsPage:notification-input-date-placeholder') || ''}
                              value={calendarDate}
                              onChange={(e) => {
                                  formik.handleChange(e)
                                  formik.setFieldTouched('event_datetime')
                              }}
                              showTime
                              showIcon
                              minDate={minDate}
                              hourFormat='12'
                    />
                </div>
                <div className={styles.timeBlock}>
                    <div>
                        <p>{t('SettingsPage:notification-input-before')}</p>
                        <InputNumber name='min_before_urgent'
                                     placeholder={t('SettingsPage:notification-input-before-placeholder') || ''}
                                     value={formik.values.min_before_urgent}
                                     onChange={(e) => {
                                         console.log(e)
                                         formik.setFieldValue('min_before_urgent', e.value)
                                         formik.setFieldTouched('min_before_urgent')
                                     }}
                        />
                    </div>
                    <div>
                        <p>{t('SettingsPage:notification-input-after')}</p>
                        <InputNumber name='min_to_deactivate'
                                     placeholder={t('SettingsPage:notification-input-after-placeholder') || ''}
                                     value={formik.values.min_to_deactivate}
                                     onChange={(e) => {
                                         formik.setFieldValue('min_to_deactivate', e.value)
                                         formik.setFieldTouched('min_to_deactivate')
                                     }}
                        />
                    </div>
                </div>
                <div className={`${styles.switchBox} ${hasValidationErrors ? styles.validationError : ''} `}>
                    <InputSwitch name='active'
                        // disabled={hasErrors}
                                 checked={formik.values.active}
                                 onChange={(e) => {
                                     formik.handleChange(e)
                                     formik.setFieldTouched('active')
                                 }}
                                 className={hasValidationErrors ? 'p-invalid' : ''}
                    />
                    <span>{t('SettingsPage:notification-switch')}</span>
                </div>
                <Button label={editMode ? (t('SettingsPage:notification-save-button') || '') : 'Create'}
                        type='submit'
                        disabled={!(Object.keys(formik.touched).length > 0) || hasValidationErrors}
                />

            </form>
            <Toast ref={toast} position='bottom-right' />
        </div>
    )
}