import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import BreadcrumbsElem from '../../../components/breadcrumbs/breadcrumbsElem'
import '../backoffice.scss'
import {ScaleTabHeader, ScaleTabNav, ScaleTabPanel} from "@telekom/scale-components-react";
import BackendFactory from "../../../backend/BackendFactory";
import Stream from "../../../model/Stream";
import Publication from "../../../model/Publication";
import { PublicationsTable } from './publications-table'
import { FormatDate } from '../../../utils/FormatDate'

export enum PublicationTypes {
  UNPUBLISHED = 'unpublished',
  ARCHIVED = 'archived',
  PUBLISHED = 'published',
  INITIAL = ''
}

export const PressReleasePage = () => {
    const { t } = useTranslation(["menu", 'table'])
    const SLOT_NAMES = {
        headings: 'headings',
        content: 'content',
        tab: 'tab',
        panel: 'panel'
    }

    const [streams, setStreams] = useState<Stream[]>([])
    const [publications, setPublications] = useState<Publication[]>([])
    const [tab, setTab] = useState<PublicationTypes>(PublicationTypes.INITIAL)
    const [lastCrawlDate, setLastCrawlDate] = useState(t('table:unknownDate') || '')

    useEffect(()=>{
        getStreams()
        changeTab(PublicationTypes.UNPUBLISHED)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const changeTab = (newTab: PublicationTypes) => {
        if (newTab !== tab){
          BackendFactory.produce().getPublicationsBOByType(newTab)
            .then(res => {
              if(newTab === PublicationTypes.UNPUBLISHED && res.last_modified) {
                const date = FormatDate(res.last_modified)
                setLastCrawlDate(date)
              }
              setPublications(res.publications)
            } ).then(()=> setTab(newTab))
        }
    }

    const getStreams = () => {
      BackendFactory.produce().getStreams()
        .then(res => setStreams(res))
    }

    return (
        <>
            <BreadcrumbsElem linksArray={[{href: '/', text: t("RP:RP-M1")}, {href: '/', text: t("RP:RP-M3"), disabled: true}]}
                             current={{href: '/backoffice/pressreleases', text:t("linkPressReport")}}/>
            <div className="press-releases-inner">

                    <ScaleTabNav>
                        <ScaleTabHeader slot={SLOT_NAMES.tab} key='header-1' selected={tab === PublicationTypes.UNPUBLISHED}
                                        onClick={()=> changeTab(PublicationTypes.UNPUBLISHED)}>
                            {t("pressRealeseasNew")} {tab === PublicationTypes.UNPUBLISHED && `(${publications.length})`}
                        </ScaleTabHeader>
                        {tab === PublicationTypes.UNPUBLISHED && <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-1'>
                            <PublicationsTable publications={publications}
                                               statusType={PublicationTypes.UNPUBLISHED}
                                               streams={streams}
                                               lastCrawlDate={lastCrawlDate}
                            />
                        </ScaleTabPanel>}
                        <ScaleTabHeader slot={SLOT_NAMES.tab} key='header-2' selected={tab === PublicationTypes.PUBLISHED}
                                        onClick={()=>  changeTab(PublicationTypes.PUBLISHED)}>
                            {t("pressRealeseasPublished")} {tab === PublicationTypes.PUBLISHED && `(${publications.length})`}
                        </ScaleTabHeader>
                        {tab === PublicationTypes.PUBLISHED && <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-2'>
                            <PublicationsTable publications={publications}
                                               statusType={PublicationTypes.PUBLISHED}
                                               streams={streams}
                                               lastCrawlDate={lastCrawlDate}
                            />
                        </ScaleTabPanel>}
                        <ScaleTabHeader slot={SLOT_NAMES.tab} key='header-3' selected={tab === PublicationTypes.ARCHIVED}
                                        onClick={()=> changeTab(PublicationTypes.ARCHIVED)}>
                            {t("pressRealeseasArchived")} {tab === PublicationTypes.ARCHIVED && `(${publications.length})`}
                        </ScaleTabHeader>
                        {tab === PublicationTypes.ARCHIVED && <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-3'>
                            <PublicationsTable publications={publications}
                                               statusType={PublicationTypes.ARCHIVED}
                                               streams={streams}
                                               lastCrawlDate={lastCrawlDate}
                            />
                        </ScaleTabPanel>}
                    </ScaleTabNav>
            </div>
        </>
    )
}