import { ModalFormConfig } from '../../components/F2CFormGenerator/types'
import { t } from 'i18next'

// {
//     "office": null,
//     "name": "atene KOM GmbHdd asdq",
//     "url": "https://atenekom.eu/das-unternehmen/kontakt/dd",
//     "zipCode": "101152",
//     "city": " Berlins",
//     "country": "Deutschlande",
//     "mail": "info@atenekom.euq",
//     "phone": "+49 30 22183-03",
//     "street": "Invalidenstraße 91w",
//     "id": "639071f0dc36a7f1d39cbb5f"
// }

export const contactPointFormConfig:ModalFormConfig = {
    lines: [
        {
            index:1,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officeName'),
                            dataField: 'name',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
        {
            index:2,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officeStreet'),
                            dataField: 'street',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
        {
            index:3,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officeZipCode'),
                            dataField: 'zipCode',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
                {
                    colIndex:2,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officeCity'),
                            dataField: 'city',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
        {
            index:4,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officeCountry'),
                            dataField: 'country',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
        {
            index:5,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officeMail'),
                            dataField: 'mail',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
        {
            index:6,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('BOFundTranslationKeys:officePhone'),
                            dataField: 'phone',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
        {
            index:7,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: null,
                            label: t('BOFundTranslationKeys:officeURL'),
                            dataField: 'url',
                            info: false,
                            inputType: 'text',
                        },
                    ],
                },
            ],
        },
    ]
}