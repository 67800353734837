import { FormField } from '../../model/form-field'
import React from 'react'
import { FormikProps } from 'formik'
import {
    ScaleDatePicker,
    ScaleDropdown,
    ScaleSwitch,
    ScaleTextarea,
    ScaleTextField,
} from '@telekom/scale-components-react'
import CharacterCounter from '../CharacterCounter/CharacterCounter'
import { TRANSLATIONS } from '../../theme/constants/Translations'
import { DatePickerLocale } from '../../utils/date-picker-locale'
import { MultiSelect } from 'primereact/multiselect'
import { Editor } from 'primereact/editor'
import styles from './F2CFormGenerator.module.scss'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { Chips } from 'primereact/chips';
import moment from 'moment/moment'
import { Button } from 'primereact/button'


export const GetFormElement = (
    formik: FormikProps<any>,
    formField: FormField,
) => {

    const { handleChange, setFieldValue, setFieldTouched } = formik
    const { t, i18n } = useTranslation('SettingsPage')
    const chooseOption = t('BOFundTranslationKeys:chooseOption')
    const { inputType, dataField, label, maxLength, options, img, multiselectOptions, value, disabled, limitDate, richTextCustomHeight } = formField
    let actualValue = formik.values[dataField as FormField['dataField'] as string]
    let element:JSX.Element | undefined
    switch (inputType) {
        case 'text':
        case 'number':
            const textField = <ScaleTextField
                inputId={dataField}
                label={label}
                value={actualValue}
                size={'small'}
                disabled={disabled}
                type={inputType}
                name={dataField}
                onScaleChange={e => {
                    if(dataField) {
                        if (inputType === 'text' && typeof e.detail.value === 'string') {
                            setFieldValue(dataField, e.detail.value.trim())
                        } else {
                            const cleanedValueForNumber = e.detail.value === '' ? null : Number(e.detail.value)
                            setFieldValue(dataField, cleanedValueForNumber)
                        }
                        setFieldTouched(dataField, true)
                    }
                }}
            />
            element = maxLength ? <CharacterCounter maxLength={maxLength} inputValue={actualValue || ''}>
                    {textField}
                </CharacterCounter>
                :
                textField
            break
        case 'toggle':
            element = (
                <div className='toggle-container'>
                    {dataField && <ScaleSwitch checked={actualValue}
                                               onScaleChange={async (e) => {
                                                   await setFieldValue(dataField, e.detail.value)
                                                   await setFieldTouched(dataField, true)
                                               }}
                                               name={dataField}
                    />}
                    <img title={TRANSLATIONS.de.labels[dataField!]} src={img} alt={label} />
                    <h4>{label}</h4>
                </div>
            )
            break
        case 'richText':
            const editorControls = () => (
                <span className='ql-formats'>
                        <button className='ql-bold' aria-label='Bold'></button>
                        <button className='ql-italic' aria-label='Italic'></button>
                        <button className='ql-underline' aria-label='Underline'></button>
                        <button className='ql-link' aria-label='Link'></button>
                        <button className='ql-list' value='ordered' aria-label='Ordered List'></button>
                        <button className='ql-list' value='bullet' aria-label='Unordered List'></button>
                        <button className='ql-clean' aria-label='Clean'></button>
                    </span>
            )
            const richTextEditor = (
                dataField &&
                <div>
                  <h3>{label}</h3>
                  <Editor value={actualValue}
                          readOnly={disabled}
                          name={dataField}
                          headerTemplate={editorControls()}
                          style={{ height: richTextCustomHeight ? (richTextCustomHeight + 'px') : '250px' }}
                          onTextChange={async (val) => {
                              await setFieldValue(dataField, val.htmlValue)
                              await setFieldTouched(dataField, true)
                          }}
                  />
                </div>

            )
            element =  (
                <>
                    {maxLength && richTextEditor ?
                        <CharacterCounter maxLength={maxLength} inputValue={actualValue || ''}>
                            {richTextEditor}
                        </CharacterCounter>
                        :
                        richTextEditor}
                </>
            )
            element = (
                <>
                    {maxLength && richTextEditor ?
                        <CharacterCounter maxLength={maxLength} inputValue={actualValue || ''}>
                            {richTextEditor}
                        </CharacterCounter>
                        :
                        richTextEditor}
                </>
            )
            break
        case 'textarea':
            element =  (
                <>
                    <ScaleTextarea
                        label={label}
                        value={actualValue}
                        disabled={disabled}
                        onScaleChange={e => {
                            handleChange(e)
                            dataField && setFieldTouched(dataField, true)
                        }}
                        name={dataField}
                    />
                </>
            )
            break
        case 'date':
            actualValue = moment(actualValue).format('YYYY-MM-DD')
            element =  (
                 <ScaleDatePicker
                  max={limitDate}
                  localization={i18n.language === 'de' ? DatePickerLocale : undefined}
                  label={label}
                  value={actualValue}
                  onScaleChange={async (e) => {
                      if(dataField) {
                          const date = e.detail.value ||  null
                          await setFieldValue(dataField, date)
                          await setFieldTouched(dataField, true)
                      }
                  }}
                  name={dataField}
                />
            )
            break
        case 'dropdown':
            if (options) {
                element =
                    <ScaleDropdown
                        value={actualValue || options[0].value}
                        name={dataField}
                        placeholder={label}
                        size='medium'
                        label={label}
                        onScaleChange={e => {
                            if (dataField) {
                                setFieldTouched(dataField, true)
                                setFieldValue(dataField, e.detail.value)
                            }
                        }}
                    >
                        {options.map((option) => (
                            <option value={option.value} key={option.value} disabled={option.value === chooseOption}>
                                {option.name}
                            </option>
                        ))}
                    </ScaleDropdown>
            }
            break
        case 'objectDropdown':
            // console.log(actualValue)

            element =  (
                <span className="p-float-label w-full md:w-14rem">
                    <Dropdown value={actualValue}
                              placeholder={label}
                              name={dataField}
                              disabled={disabled}
                              filter
                              optionLabel='name'
                              options={options}
                              onChange={async (e) => {
                                  if(dataField) {
                                      await setFieldTouched(dataField, true)
                                      await setFieldValue(dataField, e.value)
                                  }
                              }}
                    />
                    <label htmlFor="dd-city">{label}</label>
                </span>

            )
            break
        case 'groupedDropdown':
            const currentOption = actualValue && options?.flatMap(streams => streams.sub_streams)
                .find(sub => sub.id === actualValue.id)
            element = (
                <span className="p-float-label w-full md:w-14rem">
                         <Dropdown value={currentOption}
                                   disabled={disabled}
                                   placeholder={label}
                                   inputId="groupedDropdown"
                                   name={dataField}
                                   optionLabel='name'
                                   optionGroupLabel='name'
                                   optionGroupChildren='sub_streams'
                                   options={options}
                                   onChange={async (e) => {
                                       if (dataField) {
                                           await setFieldTouched(dataField, true)
                                           await setFieldValue(dataField, e.value)
                                       }
                                   }}
                         />
                    <label htmlFor="groupedDropdown">{label}</label>
                </span>

            )
            break
        case 'title':
            element =  (
                <span className="modal__subheading">{label}</span>
            )
            break
        case 'multiselect':
            element =  (
                <span className='p-float-label w-full md:w-20rem'>
                    <MultiSelect value={actualValue}
                       className={styles.multiselect}
                       optionLabel='name'
                    // display='chip'
                       disabled={disabled}
                       maxSelectedLabels={2}
                       filter
                       name={dataField}
                       placeholder={label}
                       options={multiselectOptions}
                       onChange={async (e) => {
                           if (dataField) {
                               await setFieldTouched(dataField, true)
                               handleChange(e)
                           }
                       }}
                    />
                    <label htmlFor='ms-cities'>{label}</label>
                </span>

            )
            break
        case 'chips':
            // temporary only for funds
            const chipsValue = actualValue ? actualValue.map((val:any) => val.url) : []
            const customChip = (item: string) => {
                return (
                    <div className={styles.chipsContainer}>
                        <span>{item}</span>
                        <Button icon="pi pi-copy"
                                className={styles.copyButton}
                                rounded
                                text
                                size="small"
                                onClick={(e)=> {
                                    e.preventDefault()
                                    navigator.clipboard.writeText(item);
                                }
                        }
                        />
                    </div>
                );
            };
            element =  (
                <Chips value={chipsValue}
                       name={dataField}
                       placeholder={label}
                       itemTemplate={customChip}
                       onChange={async (e) => {
                            if(dataField && e.target.value) {
                                const newValue = e.target.value.map(val => {
                                    return {
                                        type: "additional_link",
                                        url: val
                                    }
                                })
                                await setFieldTouched(dataField, true)
                                await setFieldValue(dataField, newValue)
                            }}
                } />

            )
            break
        case 'link':
            element = (
                <a className={styles.officeLink}
                   rel="noreferrer"
                   href={value}
                   target='_blank'
                >
                    {label}
                </a>
            )
            break
        case 'textElement':
            element = (
                <div className={styles.textElement}>
                    <h3>
                        {label}
                    </h3>
                    <span>
                        {value}
                    </span>
                </div>
            )
            break
        default:
            element = <span>{ dataField }</span>
            break
    }
    const validationError = formik.errors[dataField as string]

    return (
        <div key={dataField} className={`${styles.element} ${validationError ? 'validationError' : ''}`}
        >
            {element}
            <span
                className={styles.errorText}>{formik.errors[dataField as string]}</span>
        </div>
    )

}