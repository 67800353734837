import React, { useEffect, useRef, useState } from 'react'
import styles from './F2CFilter.module.scss'
import { ScaleButton, ScaleDropdown, ScaleIconActionClose, ScaleTag, ScaleSwitch } from '@telekom/scale-components-react'
import { InputChangeEventDetail } from '@telekom/scale-components/dist/types/components/input/input'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';
import ToggleLabels from '../../pages/radar/ToggleLabels'
import { ResetButton } from './ResetButton'
import { updateUrlWithParams } from '../../utils/updateUrlWithParams'

export type FilterValue = {
  label: string;
  val: string | number;
};

export type FilterOption = {
  name: string;
  urlParam: string;
  filterLabel: string;
  values: FilterValue[];
  id: number;
};

type SelectedFilters = {
  [key: string]: string | number;
};

type FundFilterProps = {
  filters: FilterOption[];
  checkboxes?: CheckboxFilter[];
  headerSwitch?: string;
  onHeaderSwitch?: () => void;
  onFiltering: (result:string) => void
  onInit?: (result:string) => void
};

type Label = {
  name:string
  value:string
}

export type CheckboxFilter = {
  label:string;
  urlParam: string;
  active:boolean;
  imgUrl: string;
  title: string;
}

const DEFAULT_VALUE = 0

export const F2CFilter: React.FC<FundFilterProps> = ({
  filters,
  checkboxes,
  headerSwitch,
  onHeaderSwitch,
  onFiltering,
  onInit
}) => {
  const { t } = useTranslation("f2cFilter")
  const defaultFilters = filters.reduce<SelectedFilters>((acc, filter) => {
    return { ...acc, [filter.urlParam]: DEFAULT_VALUE };
  }, {});

  const filtersChanged = useRef(false);
  const filtersListRef = useRef<SelectedFilters>(defaultFilters);

  const [isOpen, setIsOpen] = useState(false)
  const [additionalSwitch, setAdditionalSwitch] = useState(false)
  const [filtersList, setFiltersList] = useState<SelectedFilters>(defaultFilters);
  const [labelsForActiveFilters, setLabelsForActiveFilters] = useState<Label[]>([]);

  const [checkboxList, setCheckboxList] = useState<CheckboxFilter[]>(checkboxes || []);
  const [activeCheckboxes, setActiveCheckboxes] = useState<string[]>([]);

  function handleCheckboxChange(name:string) {
    const updatedFilters = checkboxList.map((checkbox) =>
      checkbox.label === name ? { ...checkbox, active: !checkbox.active } : checkbox
    );
    setCheckboxList(updatedFilters);
  }
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {

    if (filtersChanged.current) {
      submitHandler();
      filtersChanged.current = false;
    }
  }, [labelsForActiveFilters,activeCheckboxes]);

  useEffect(()=> {
    getUrlParams().then(()=> submitHandler(true))
  },[])

  const handleFilterChange = (event: CustomEvent<InputChangeEventDetail>) => {
    filtersListRef.current = {
      ...filtersList,
      // @ts-ignore
      [event.target.id]: event.detail.value,
    }
    setFiltersList({
      ...filtersList,
      // @ts-ignore
      [event.target.id]: event.detail.value,
    });
  };

  const getSortedUrlParams = (): {allUrlParams: SelectedFilters[], checkBoxParams: string[], otherUrlParams: string, filterParams: SelectedFilters[]} => {
    const checkboxKeys = checkboxList.map(checkbox => checkbox.urlParam)
    const filterKeys = filters.map(filter => filter.urlParam)

    const allUrlParams:SelectedFilters[] = []
    const checkBoxParams:string[] = []
    const filterParams:SelectedFilters[] = []
    const otherParams:SelectedFilters[] = []

    for (const param of searchParams.entries()) {
      const [name, value] = param;
      const currentParam = {name, value}
      allUrlParams.push(currentParam)

      if(filterKeys.includes(name)) {
        filterParams.push(currentParam)
      }
      else if(checkboxKeys.includes(name)) {
        checkBoxParams.push(name)
      }
      else {
        otherParams.push(currentParam)
      }
    }
    const otherUrlParams = otherParams.map(param => `${param.name}=${param.value}`).join('&')

    return {
      allUrlParams,
      checkBoxParams,
      filterParams,
      otherUrlParams
    }
  }
  const getUrlParams = async () => {
    const {filterParams, checkBoxParams} = getSortedUrlParams()

    const activeFilters:SelectedFilters = {}
    const checkboxListBasedOnParams = checkboxList
    checkboxListBasedOnParams.forEach(checkbox => {
      if(checkBoxParams.includes(checkbox.urlParam)){
        checkbox.active = true
      }
    })
    filterParams.forEach(filter => {
      activeFilters[filter.name] = filter.value
    })

    const newFilterList = {
      ...filtersList,
      ...activeFilters
    }

    filtersListRef.current = newFilterList
    setFiltersList(newFilterList)
    setCheckboxList(checkboxListBasedOnParams)
  }
  const getCheckboxParams = () => {
    return checkboxList
      .filter((filter) => filter.active)
      .map((filter) => `${filter.urlParam}=true`)
      .join('&');
  }

  const resetFiltersAndCheckbox = () => {
    setActiveCheckboxes([])
    setLabelsForActiveFilters([])
    setFiltersList(defaultFilters);
    checkboxList.forEach(checkbox => {
      checkbox.active = false
    })
    filtersChanged.current = true;
  };

  const removeFilterLabel = (labelName:string) => {
    const filteredLabels = labelsForActiveFilters.filter(filter => filter.name !== labelName)
    setLabelsForActiveFilters(filteredLabels)
    setFiltersList({
      ...filtersList,
      [labelName]: defaultFilters[labelName]
    })
    filtersChanged.current = true;
  }
  const removeCheckboxLabel = (labelName:string) => {
    const filteredCheckboxes = activeCheckboxes.filter(label => label !== labelName)
    setActiveCheckboxes(filteredCheckboxes)
    handleCheckboxChange(labelName)
    filtersChanged.current = true;
  }


  const submitHandler = (isOnInit?:boolean) => {

    const filtersWithValue:Array<FilterOption["filterLabel"]> = []
    const rawCheckboxParams = getCheckboxParams()
    const checkboxParams = rawCheckboxParams ? ('&'+rawCheckboxParams) : ''
    const activeCheckboxes = checkboxList.filter(f => f.active).map(f => f.label)
    checkboxList.forEach(checkbox => {
      if(checkbox.active) searchParams.set(checkbox.urlParam, 'true')
      else searchParams.delete(checkbox.urlParam)
    })
    const dropdownParams = filters
      .map((filter) => {
        const selectEl = document.getElementById(filter.urlParam) as HTMLSelectElement;
        const selectedValue = selectEl.value.toString().replace('&', '%26');

        if(selectedValue === DEFAULT_VALUE.toString()) {
          searchParams.delete(filter.urlParam)
          return undefined
        }
        else {
          searchParams.set(filter.urlParam, selectedValue)
          filtersWithValue.push(filter.urlParam)
          return `${filter.urlParam}=${selectedValue}`;
        }
      })
      .filter((param) => param)
      .join('&')

    const allFilterParams = dropdownParams ? (dropdownParams + checkboxParams) : rawCheckboxParams
    if(isOnInit && onInit){
      onInit(allFilterParams)
    }else  {
      onFiltering(allFilterParams)
    }

    setActiveCheckboxes(activeCheckboxes)
    handleActiveFilters(filtersWithValue)
    updateUrlWithParams(searchParams.toString())
  };

  const handleActiveFilters = (filtersWithValue:string[]) => {
    let labels:Label[] = []
    filtersWithValue.forEach(filterKey => {
      const filter = filters.find(f => f.urlParam === filterKey);
      if(filter) {
        const activeStateValue = filtersListRef.current[filterKey].toString().replace('%26', '&')
        const value = filter.values.find(v => v.val.toString() === activeStateValue);
        if(value) {
          labels.push({name: filterKey, value: value.label})
        }
      }
    });
    setLabelsForActiveFilters(labels)
  }

  const resetButtonClass = (labelsForActiveFilters.length >= 1 || activeCheckboxes.length >= 1) ? styles.show : ""


  return (
    <div className={styles.filter}>
      <div className={styles.filterBarMobile_view_icon}>
        <div className={styles.filterBarIconContainer} onClick={() => setIsOpen(!isOpen)}>
          <span className={styles.filterBarLabel__icon}><FilterIcon /></span>
        </div>
      </div>
      <div className={`${styles.filterBar} ${isOpen ? styles.filterBar__opened : styles.filterBar__closed }`}>
        <div className={styles.filterBarMobileViewContainer}>
          <div className={styles.filterBarContainer}>
            <div className={styles.filterBarLabel}
                 onClick={() => setIsOpen(!isOpen)}
                 onKeyPress={(e)=> {
                   if (e.key === 'Enter'){
                     return setIsOpen(!isOpen)
                   }
                 }}
                 tabIndex={0}>
              <span className={styles.filterBarLabel__icon}><FilterIcon /></span>
              <span className={styles.filterBarLabel__text}>{t('RP:RP-Filter')}</span>
            </div>
            <div className={`${styles.filterBarLabel_refresh} ${resetButtonClass}`}
                 tabIndex={0}
                 onKeyPress={resetFiltersAndCheckbox}>
              <ResetButton onClick={() => resetFiltersAndCheckbox()} />
            </div>
            {isOpen && <div className={styles.close_icon} onClick={() => setIsOpen(!isOpen)}>
              <ScaleIconActionClose/>
            </div>}
            <div className={styles.wrapper}>
              {labelsForActiveFilters.map(filterLabel => (
                <ScaleTag className={styles.filterTag}
                          onClick={() => removeFilterLabel(filterLabel.name)}
                          key={filterLabel.name + 'filter'}
                          size="small"
                >
                  <span className={styles.closeTag}>x</span> {filterLabel.value}
                </ScaleTag>
              ))}
              {activeCheckboxes.map(checkboxLabel => (
                  <ScaleTag className={styles.filterTag}
                            onClick={() => removeCheckboxLabel(checkboxLabel)}
                            key={checkboxLabel + 'checkbox'}
                            size="small"
                  >
                    <span className={styles.closeTag}>x</span> {checkboxLabel}
                  </ScaleTag>

              ))}
            </div>
            {(isOpen && headerSwitch && onHeaderSwitch) && <div className={styles.wrapper}>
              <ToggleLabels checked={additionalSwitch} label={headerSwitch} onScaleChange={() => {
                setAdditionalSwitch(!additionalSwitch)
                onHeaderSwitch()
              }} />
            </div>}

            <div className={`${isOpen ? styles.open : ''} ${styles.filterBody}`}>
              <div className={styles.dropdownContainer}>
                {filters.map((filter) => {
                  const filterValue = filtersList[filter.urlParam].toString().replace('%26', '&')
                  return (
                    <ScaleDropdown value={filterValue}
                                   key={filter.name + filter.id}
                                   label={filter.name} size='small'
                                   onScaleChange={(e) => handleFilterChange(e)}
                                   id={filter.urlParam}
                    >
                      {filter.values.map((value, i) =>
                        <option
                          value={value.val}
                          key={value.val + '_option' + i}>
                          {value.label}
                        </option>)}
                    </ScaleDropdown>
                  )
                })}
                <div className={styles.filterSwitchContainer}>
                  {checkboxList.map(checkbox => (
                    <div key={checkbox.label} className={styles.toggleContainer}>
                      <ScaleSwitch checked={checkbox.active}
                                   onScaleChange={() => handleCheckboxChange(checkbox.label)}
                      />
                      <img title={checkbox.title} src={checkbox.imgUrl} alt={checkbox.label} />
                    </div>
                  ))}
                </div>

              </div>
              <div className={styles.filterBarButtons}>
                <ResetButton onClick={() => resetFiltersAndCheckbox()} />
                <ScaleButton type={"submit"} onClick={() => {
                  setIsOpen(false)
                  submitHandler()
                }}>{t("F:RP-F-BSet")}
                </ScaleButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};