import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Backend from '../../../backend/Backend';
import isDeepEqual from 'fast-deep-equal/react';
import BreadcrumbsElem from '../../../components/breadcrumbs/breadcrumbsElem'
import '../backoffice.scss'
import { RSSFeedTable } from './rssFeeds-table'
import BackendFactory from '../../../backend/BackendFactory'

export const RSSFeedPage = () => {

  const { t } = useTranslation(['RP','menu'])

  const backend: Backend = BackendFactory.produce()

  // fix specially for linter
  const backendRef = useRef(backend)
  if (!isDeepEqual(backendRef.current, backend)) {
      backendRef.current = backend
  }

  const [rssFeeds, setRssFeeds] = useState()

  useEffect(()=>{
    BackendFactory.produce().getBORSSFeedOrderBy('-is_active')
        .then( res => {
          //@ts-ignore
          setRssFeeds(res.links)
          }
        )
  },[])

  return (
    <>
      <BreadcrumbsElem
        linksArray={[
          { href: '/', text: t("RP:RP-M1") },
          { href: '/', text: t("RP:RP-M3"), disabled: true },
        ]}
        current={{ href: '/backoffice/rss-feeds', text: t("menu:linkRSSFeeds") }}
      />
      <div className="funds-inner">
        {<RSSFeedTable rssFeeds={rssFeeds!} />}
      </div>
    </>
  )
}
