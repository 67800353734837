import React from 'react'

import { Translation } from 'react-i18next';

import RadarStatusChart from './chart/RadarStatusChart'
import NumberFormatter from '../../utils/NumberFormatter'
import { COLOR_MAGENTA } from "../Radar/RadarRenderer"
import './radar-status.scss'

interface Props {
  total: number
  available: number
}

interface Stats {

}

class RadarStatus extends React.Component<Props, Stats> {
  constructor(props: Props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { total, available } = this.props
    const prettifiedTotal = NumberFormatter.prettifyBigNumber(total)
    const prettifiedAvailable = NumberFormatter.prettifyBigNumber(available)

    return (
      <div className='radar-status-inner'>
        <div className='radar-status-content'>
          <div className='radar-status-item'>
            <Translation>
              { (t) => <span className='span span--bold'  >{t("RP:RP-OpenSum")}: </span>}
            </Translation>
            <span className='span' >ca. {prettifiedTotal} €</span>
          </div>

          <div style={{ color: COLOR_MAGENTA }} className='radar-status-item'>
            <Translation>
              { (t) => <span className='span span--bold' >{t("RP:RP-FixedSum")}: </span>}
            </Translation>
            <span className='span' >ca. {prettifiedAvailable} €</span>
          </div>
        </div>
        <RadarStatusChart total={total} available={available}/>
      </div>
    )
  }
}

export default RadarStatus