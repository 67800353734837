import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningSign2 } from '../../../icons/warning-sign-2.svg'
// import { ContactSettingsModalTypes} from './ContactContent'
import styles from './PageContent.module.scss'
import { ScaleButton, ScaleModal } from '@telekom/scale-components-react'
import { SettingsElement } from '../../../backend/respType'
import { ModalActions } from '../../../components/F2CFormGenerator/types'

type AlertModalProps = {
  isOpen:boolean
  onClose: () => void
  onAccept: () => void
  currentContact: SettingsElement
  modal:ModalActions
  title: string
}

export const AlertModal = ({isOpen,onAccept, onClose, currentContact, modal, title}:AlertModalProps) => {
  const {t} = useTranslation(['SettingsPage','details'])

  const hasSubAreas = currentContact.subList && currentContact.subList.length > 0

  return (
    <ScaleModal heading={title}
                opened={isOpen}
                size={'small'}
                onScaleClose={()=> onClose()}

    >
      <div className={styles.alertModalBody}>
        <WarningSign2 />
        {modal === ModalActions.ALERT_DELETE  &&
            <>
              {hasSubAreas ?
                  <span>{t("SettingsPage:cantDeleteMessage")} </span>
              :
                  <div>
                    <span>{t("SettingsPage:deleteMessage", {name: currentContact.name})}?</span>
                    {currentContact.affectedFunds.length > 0 &&
                        <>
                          <p>{t("SettingsPage:affectedFunds")}:</p>
                          <ul className={styles.modalList}>
                            {currentContact.affectedFunds.map(fund =>
                                <li key={fund.id}><a target='_blank' href={`/more/${fund.id}`}>{fund.name}</a></li>
                            )}
                          </ul>
                        </>
                    }
                  </div>
              }
            </>

        }

      </div>

      <div slot="action" className={styles.modalFooter} >
        {modal === ModalActions.ALERT_DELETE && !hasSubAreas ?
          <>
            <ScaleButton
              variant="secondary"
              onClick={() => onClose()}
            >
              {t("details:modalActionInterrupt")}
            </ScaleButton>
            <ScaleButton onClick={()=> onAccept()} >
              {t('BO:BO-FP-NFPB1')}
            </ScaleButton>
          </>
          :
          <ScaleButton
          variant="secondary"
          onClick={() => onClose()}
          >
           {t("table:btnTitleOk")}
          </ScaleButton>
        }

      </div>

    </ScaleModal>
  )
}