import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EditIcon } from '../../icons/edit-icon.svg'
import { ReactComponent as ArchiveIcon } from '../../icons/archive-icon.svg'
import { ReactComponent as DeleteIcon } from '../../icons/trash-icon.svg'
import { ReactComponent as CopyIcon } from '../../icons/copy-icon.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus-icon.svg';
import { prettifyDate } from '../../helpers/dates'

interface GroupedButtonsProps {
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onArchive?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onEdit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCopy?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCreate?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onActionButton?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  actionButtonText?: string
  onCheckRelevance?: () => void
  lastModified?:Date | null
}

export const GroupedButtons = ({onEdit,onArchive,onDelete,onCopy, onCheckRelevance, lastModified, onCreate, onActionButton, actionButtonText}:GroupedButtonsProps) => {
  const { t } = useTranslation("table")
  const publishedDate = (date: string | null | Date) => date !== null ? prettifyDate(date) : '-';
  return (
    <div className="action-buttons-container">
      {onDelete &&
        <button
          className="action-button action-button--delete"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            onDelete(event)
          }}
          title={String(t("BO:BO-FP-NFPB1"))}
        >
          <DeleteIcon />
        </button>}
      {onCheckRelevance &&
        <button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation()
          onCheckRelevance()
        }}
                className="action-button action-button--magenta action-button--publish" >
          {t("BO:BO-FP-NFPB2")}
        </button>}
      {onArchive &&
        <button
          className="action-button action-button"
          onClick={(event) => onArchive(event)}
          title={String(t("BO:BO-FP-RFPB4"))}
        >
          <ArchiveIcon />
        </button>}
      {onEdit &&
        <button
          className="action-button action-button"
          onClick={(event) => onEdit(event)}
          title={String(t("btnTitleEdit"))}
        >
          <EditIcon />
        </button>}
      {onCopy &&
        <button
          className="action-button action-button"
          onClick={(event) => onCopy(event)}
          title={String(t("btnTitleCopy"))}
        >
          <CopyIcon/>
        </button>}
      {onCreate &&
        <button
          className="action-button action-button"
          onClick={(event) => onCreate(event)}
          title={String(t('btnTitleCreate'))}
        >
          <PlusIcon/>
        </button>}
      {onActionButton &&
        <button
          className="action-button action-button--magenta action-button--publish"
          onClick={(event) => onActionButton(event)}
        >
          {actionButtonText || 'use actionButtonText props'}
        </button>}
      {lastModified && (
        <span className='last-edited'>
              {t("BO:BO-FP-RFPLastEdit")}: <span className='last-edited__bold'>{publishedDate(lastModified)}</span>
          </span>
      )}
    </div>
  )
}