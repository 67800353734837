import ObjectUtil from "../utils/ObjectUtil"
import Stream from "./Stream"

export const PATH_ID = `id`
export const PATH_NAME = `name`
export const PATH_MAIN_STREAM = `main_stream`
export const PATH_SUBSTREAMS = `sub_streams`
export const PATH_HAS_SUBSTREAMS = `has_sub_streams`
export const PATH_INDEX_NUMBER = `index_number`
export const PATH_KEYWORDS = `keywords`


export default class StreamMapper {
  private constructor() { }

  static mapAll(rawStreams: any): Stream[] {
    const streams: Stream[] = []

    for (const rawStream of rawStreams) {
      try {
        const stream = StreamMapper.mapOrThrow(rawStream)
        StreamMapper.setRadarName(stream)
        streams.push(stream)
      } catch (error) {
        // errors are ignored
        console.debug(`Couldn't map stream in list`, error)
      }
    }

    return streams
  }

  static setRadarName(stream: Stream): Stream {
    stream.sub_streams?.forEach((stream) => {
      const radarName = (stream.name.includes('Sonstige') && !stream.has_sub_streams) ? 'Sonstige' : stream.name
      stream.name_radar = radarName

    })
    return stream
  }

  static mapOrThrow(rawStream: any): Stream {
    return {
      name: ObjectUtil.getStringOrThrow(rawStream, PATH_NAME),
      name_radar: ObjectUtil.getStringOrThrow(rawStream, PATH_NAME),
      id: ObjectUtil.getStringOrThrow(rawStream, PATH_ID),
      keywords: ObjectUtil.getField(rawStream, PATH_KEYWORDS),
      main_stream: ObjectUtil.getField(rawStream, PATH_MAIN_STREAM),
      has_sub_streams: ObjectUtil.getField(rawStream, PATH_HAS_SUBSTREAMS),
      sub_streams: ObjectUtil.getField(rawStream, PATH_SUBSTREAMS),
      index_number: ObjectUtil.getField(rawStream, PATH_INDEX_NUMBER),
    }
  }
}
