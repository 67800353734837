
import { Translation } from 'react-i18next';
import DescriptionCard from '../../../components/cards/DescriptionCard'
import Link from '../../../components/Link'
import FundMapped, { Office} from '../../../model/FundMapped'
import React from 'react'
import Contact from '../../../components/Contact'
import BackendFactory from '../../../backend/BackendFactory'
import { Button } from 'primereact/button'
import i18n from 'i18next'


interface Props{
    fundOffice?: Office
    fund: FundMapped
}

export default class ContactInformation extends React.Component<Props, any> {
    backend = BackendFactory.produce()
    constructLink(link: any, i: any) {
        const linkTitle = link.url.substr(0, 25) + "...";

        return <Link linkType='default'  key={i + '_link-of-links'}
                      href={link.url}
                      linkTitle={linkTitle}
                      target="_blank"
                      onClick={()=> this.backend.addViewToElement('Fund link clicked')}
        />
    }

    downloadReport() {
        this.backend.downloadPresentation(this.props.fund.id, this.props.fund.name)
            .then(response => {
                if(response?.detail === 'error') {
                    alert(i18n.t('BOFundTranslationKeys:fileDownloadFailed'))
                }
            })
    }

    render() {
        const {geoArea, links, url, YAMLink} = this.props.fund

        if (this.props.fundOffice === undefined) {
            return (<></>)
          }

        return (
          <div className='contact-info col-flex-element'>
            <DescriptionCard  key={'dc-link-1'}>
                <Translation>
                  {
                      (t) => {
                          const hasGeoArea = Boolean(geoArea && geoArea.length > 0)
                        return <>
                            <span className="card-title">{t("FP:RP-FP-intContact")}</span>
                            {hasGeoArea ?
                                geoArea?.map((area, index) =>
                                        <Link linkType='mail'
                                              href={`mailto:${area.email}?subject=Fund2Cash`}
                                              linkTitle={area.name}
                                              onClick={()=> this.backend.addViewToElement('Fund Mailto clicked')}
                                              key={area.id + index}
                                        />
                                )
                                :  <Link linkType='mail'
                                         href={`mailto:competencecenterfoerderung@telekom.de?subject=Fund2Cash?subject=Fund2Cash`}
                                         linkTitle={'Contact'}
                                         onClick={()=> this.backend.addViewToElement('Fund Mailto clicked')}
                                />

                            }


                        </>
                      }
                  }
                </Translation>
            </DescriptionCard>
            {this.props.fundOffice ? (
              <DescriptionCard  key={'dc-link-2'}>
                <Translation>
                  {
                      (t) => <span className="card-title">{t("FP:RP-FP-extContact")}</span>
                  }
                </Translation>
                  <Contact
                      company={this.props.fundOffice.name}
                      street={this.props.fundOffice.street}
                      zipcode={this.props.fundOffice.zipCode}
                      city={this.props.fundOffice.city}
                      phoneNumber={this.props.fundOffice.phone || ''}
                  />
                  <Link linkType='mail' href={`mailto:${this.props.fundOffice.mail}`} linkTitle={this.props.fundOffice.mail || ''}  key={'mail-link-1'}/>
                  <Link linkType='default' href={this.props.fundOffice.url} linkTitle='Website' target='_blank' key={'default-link-1'}/>
              </DescriptionCard>
            ) : null}

            {url || YAMLink || links ? (
              <DescriptionCard  key={'dc-link-3'}>
                <Translation>
                  {
                      (t) => <span className="card-title">{t("FP:RP-FP-RelLinkTitel")}</span>
                  }
                </Translation>

              {url &&
              <Translation>
                {
                    (t) => <Link linkType='default'  key={'main_link-of-links'}
                    href={url}
                    linkTitle={t("details:originalDocument")} />
                }
              </Translation>}

              {YAMLink && <Translation>
                {
                    (t) => <Link linkType='default' key={'yam_link-of-links'}
                    href={YAMLink}
                    linkTitle={t("details:YAMLink")} 
                    target="_blank"
                    onClick={()=> this.backend.addViewToElement('Fund YAM link clicked')}  
                  />
                }
              </Translation>}
              {links &&
                  links.map((link: any , i: any) =>
                    this.constructLink(link, i))
              }
            </DescriptionCard>
            ) : null}
              <div>
                  <Translation>
                      {(t) =>
                          <Button style={{width:'100%'}}
                                  label={t('details:downloadButton') || '.pptx'}
                                  severity="secondary"
                                  outlined
                                  onClick={()=> this.downloadReport()}
                          />
                        }
                  </Translation>
              </div>


          </div>
    )}
}