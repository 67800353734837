import styled from 'styled-components/macro'

type Props = {
  isClear?: boolean
}

const ListInner = styled('ul')<Props>`

  ${props => props.isClear && `
    margin: 0;
    padding: 0;
    list-style: none;
  `}
`

export default ListInner