import React from 'react'
import { Data } from 'react-minimal-pie-chart/types/commonTypes'
import { Colors } from '../../../theme/styles/Colors'
import './radar-status-chart.scss'

interface Props {
  total: number
  available: number
}

interface Stats {
  showTooltip?: boolean
}

const KEY_AVAILABLE = 'available'
const KEY_REMAINDER = 'remainder'
const CHART_COLOR_AVAILABLE = Colors.primary.magenta
const CHART_COLOR_REMAINDER = Colors.gray.gray20


export default class RadarStatusChart extends React.Component<Props, Stats> {
  static testChartData(total: number, available: number) {
    const { relativeAvailable, relativeRemainder } = RadarStatusChart.computeRelativeValues(total, available)
    return RadarStatusChart.generateDataForChart(relativeAvailable, relativeRemainder)
  }
  constructor(props: Props) {
    super(props)

    this.state = {
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  private handleScroll(event: any) {
    if (window.innerWidth <= 1023) {
      if (this.state.showTooltip) {
        this.setState({showTooltip: false})
      }
    }
  }

  private handleClickOutside(event: any) {
    if (window.innerWidth <= 1023) {
      const scaleInfoIcon = document.getElementById("scaleIconInfo")
      if (scaleInfoIcon && !scaleInfoIcon.contains(event.target)) {
        if (this.state.showTooltip) {
          this.setState({showTooltip: false})
        }
      }
    }
  }

  private static computeRelativeValues(total: number, available: number): { relativeAvailable: number, relativeRemainder: number } {
    const relativeAvailable = (total === 0) ? 0.0 : available / total

    return {
      relativeAvailable,
      relativeRemainder: 1.0 - relativeAvailable,
    }
  }

  private static generateDataForChart(relativeAvailable: number, relativeRemainder: number): Data {
    return [
      { title: KEY_AVAILABLE, value: relativeAvailable, color: CHART_COLOR_AVAILABLE },
      { title: KEY_REMAINDER, value: relativeRemainder, color: CHART_COLOR_REMAINDER },
    ]
  }

  render() {
    const { total, available } = this.props
    const { relativeAvailable, relativeRemainder } = RadarStatusChart.computeRelativeValues(total, available)
    const label = `${(relativeAvailable * 100).toFixed(0)} %`
    const widthRelativeAvailable = `${relativeAvailable * 100}%`
    const widthRelativeRemainder = `${relativeRemainder * 100}%`

    return (
      <>
        <div className='chart-status-inner'>
          <div className='chart-status-title'>{label}</div>
          <div className='chart-status-holder'>
            <div className='chart-status-holder__available' style={{ width: widthRelativeAvailable, backgroundColor: CHART_COLOR_AVAILABLE }} />
            <div className='chart-status-holder__reminder' style={{ width: widthRelativeRemainder, backgroundColor: CHART_COLOR_REMAINDER }} />
          </div>
        </div>
      </>
    )
  }
}