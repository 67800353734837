import ObjectAccessor, { ObjectAccessorType } from "./ObjectAccessor";


export type DataObject = { [key: string]: any }
export default class ObjectUtil {

  static hasValidType(field: any, type: ObjectAccessorType): boolean {
    if (type === 'array') {
      if (Array.isArray(field)) {
        return true
      }
    } else if (typeof(field) === type) {
      return true
    }

    return false
  }

  static hasValidFieldsOrThrow(object: any, fieldValidations: { [path: string]: ObjectAccessorType }) {
    for (const path in fieldValidations) {
      if(fieldValidations.hasOwnProperty(path)){
        const validation = fieldValidations[path]

        const currentChildObject = ObjectUtil.getFieldOrThrow(object, path)

        if (!ObjectUtil.hasValidType(currentChildObject, validation)) {
          throw new Error(`Field with path ${path} is not of type ${validation}!`)
        }
      }
    }
  }

  static getField(object: any, path: string): any | undefined {
    try {
      return ObjectUtil.getFieldOrThrow(object, path)
    } catch(error) {
      // ignore
      return undefined
    }
  }

  static getFieldOrThrow(object: any, path: string): any {
    const pathParts = path ? path.split('.') : []

    let currentChildObject: any = object
    const currentPath: string[] = []
    for (const pathPart of pathParts) {

      currentPath.push(pathPart)

      if (currentChildObject[pathPart] === undefined) {
        throw new Error(`Object doesn't contain ${currentPath.join('.')}!`)
      }

      currentChildObject = currentChildObject[pathPart]
    }

    return currentChildObject
  }

  static getFieldOfTypeOrThrow(object: any, path: string, type: ObjectAccessorType): any {
    const childObject = ObjectUtil.getFieldOrThrow(object, path)

    if (!ObjectUtil.hasValidType(childObject, type)) {
      throw new Error(`Field with path ${path} is not of type ${type}!`)
    }

    return childObject
  }

  static getStringOrThrow(object: any, path: string): string {
    return ObjectUtil.getFieldOfTypeOrThrow(object, path, 'string')
  }

  static getStringOrDefault(object: any, path: string, defaultValue: string): string {
    return ObjectUtil.getFieldOfTypeOrDefault(object, path, 'string', defaultValue)
  }

  static getString(object: any, path: string): string | undefined {
    return ObjectUtil.getFieldOfTypeOrDefault(object, path, 'string', undefined)
  }

  static getDate(object: any, path: string): Date | undefined {
    const possibleDateString = ObjectUtil.getFieldOfTypeOrDefault(object, path, 'string', undefined)
    if (!possibleDateString) {
      return undefined
    }

    const date = Date.parse(possibleDateString);

    return new Date(date)
  }

  static getDateString(object: any, path?: string): string | undefined {
    let possibleDate = object
    if(path) {
      possibleDate = ObjectUtil.getFieldOfTypeOrDefault(object, path, 'string', undefined)
      if (!possibleDate) {
        return undefined
      }
    }
    return ObjectUtil.formatDate(possibleDate)
  }

  static getDataObject(object: any, path: string): DataObject | undefined {
    return ObjectUtil.getFieldOfTypeOrDefault(object, path, 'object', undefined)
  }

  static getDataObjectOrThrow(object: any, path: string): DataObject {
    return ObjectUtil.getFieldOfTypeOrThrow(object, path, 'object')
  }

  static getArray(object: any, path: string, defaultValue: any[] | undefined): any[] | undefined {
    return ObjectUtil.getFieldOfTypeOrDefault(object, path, 'array', defaultValue)
  }

  static getArrayOrThrow(object: any, path: string): any[] {
    return ObjectUtil.getFieldOfTypeOrThrow(object, path, 'array')
  }

  static getNumber(object: any, path: string): number | undefined {
    return ObjectUtil.getFieldOfTypeOrDefault(object, path, 'number', undefined)
  }

  static parseNumber(object: any, path: string): number | undefined {
    const possibleNumberRepr = ObjectUtil.getField(object, path)

    const possibleNumber = parseFloat(possibleNumberRepr)

    if (isNaN(possibleNumber) || !possibleNumber) {
      return undefined
    }

    return possibleNumber
  }

  static transformNumberOrThrow<T>(object: any, path: string, transformer: (it: number) => T): T {
    const value: number = ObjectUtil.getFieldOfTypeOrThrow(object, path, 'number')
    return transformer(value)
  }

  static transformNumberOrDefault<T>(object: any, path: string, transformer: (it: number) => T, defaultValue: T): T {
    try {
      const value: number = ObjectUtil.getFieldOfTypeOrThrow(object, path, 'number')
      return transformer(value)
    } catch (error) {
      // no debug necessary because of default behavior
      return defaultValue
    }
  }

  static getFieldOfTypeOrDefault(object: any, path: string, type: ObjectAccessorType, defaultValue: any): any {
    try {
      return ObjectUtil.getFieldOfTypeOrThrow(object, path, type)
    } catch (error) {
      // no debug necessary because of default behavior
      return defaultValue
    }
  }


  static getFieldByAccessorOrThrow<T>(object: any, objectAccessor: ObjectAccessor<T>): T {
    const rawValue: any = ObjectUtil.getFieldOfTypeOrThrow(object, objectAccessor.fullPath, objectAccessor.type)
    return objectAccessor.transformer ? objectAccessor.transformer(rawValue) : rawValue
  }

  static getFieldByAccessor<T>(object: any, objectAccessor: ObjectAccessor<T>): T | undefined {
    try {
      const rawValue: any = ObjectUtil.getFieldOfTypeOrThrow(object, objectAccessor.fullPath, objectAccessor.type)
      return objectAccessor.transformer ? objectAccessor.transformer(rawValue) : rawValue
    } catch (e) {
      return undefined
    }
  }

  static formatDateObj(dateObj: Date): string {
    const date = new Date(dateObj)

    const day = new Intl.DateTimeFormat('de', {day: '2-digit'}).format(date)
    const month = new Intl.DateTimeFormat('de', {month: '2-digit'}).format(date)
    const year = new Intl.DateTimeFormat('de', {year: 'numeric'}).format(date)

    return `${day}.${month}.${year}`
  }

  private static formatDate(dateRpr: string): string {
    const date = Date.parse(dateRpr)
    const day = new Intl.DateTimeFormat('de', {day: '2-digit'}).format(date)
    const month = new Intl.DateTimeFormat('de', {month: '2-digit'}).format(date)
    const year = new Intl.DateTimeFormat('de', {year: 'numeric'}).format(date)

    return `${day}.${month}.${year}`
  }
}