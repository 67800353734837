import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Publication from '../../../model/Publication'
import Stream from '../../../model/Stream'
import { ReactComponent as AlertIcon } from '../../../icons/warning-triangle-icon.svg'
import { ReactComponent as ShareIcon } from '../../../icons/share-icon.svg'
import { ReactComponent as PlusIcon } from '../../../icons/plus-icon.svg'
import { ScaleTable } from '@telekom/scale-components-react'
import SortingIcons from '../../../components/SortingIcons/SortingIcons'
import BackendFactory from '../../../backend/BackendFactory'
import { prettifyDate } from '../../../helpers/dates'
import './publications-table.scss'
import cn from 'classnames'

import { CommentTextArea } from './components/commentTextArea'
import { PublicationTypes } from './pressReleasePage'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { ModalActions, ModalFormConfig } from '../../../components/F2CFormGenerator/types'
import { getValuesFromConfig } from '../../../env/fundEditModal/fundModalConfig'
import { Toast } from 'primereact/toast'
import { FormikProps } from 'formik'
import { allStreamsValue, getPressReleaseConfig } from '../../../env/pressReleaseModal/pressReleaseFormConfig'
import { pressReleaseFormSchema } from '../../../env/pressReleaseModal/pressReleaseValidationSchema'
import { F2CAlertModal } from '../../../components/F2CAlertModal/F2CAlertModal'
import { GroupedButtons } from '../../../components/GroupedButtons/GroupedButtons'
import { getModalText, PR_STATUSES } from './helper'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Button } from 'primereact/button'
import { ALL_STREAMS } from './forms/form-types'

interface Props{
    publications: Publication[],
    streams: Stream[],
    statusType: PublicationTypes
    lastCrawlDate:string
}
interface ComponentState {
    sortingDirection: string
    activeRow?: string
}

export  const PublicationsTable = ({publications, streams, statusType, lastCrawlDate}: Props) => {
    const { t } = useTranslation(["table", "BOFundTranslationKeys", "publications"])
    const toast = useRef<Toast>(null)
    const op = useRef<OverlayPanel>(null);

    const initialState: ComponentState  = {
        sortingDirection: '-published',
    }
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)

    const [compState, setCompState] = useState(initialState)
    const [statePublications, setStatePublications] = useState<Publication[]>(publications)
    const [currentPublication, setCurrentPublication] = useState<Publication | undefined>(undefined)
    const [pressReleaseFormConfig, setPressReleaseFormConfig] = useState<ModalFormConfig>()

    useEffect(()=> {
        getPressReleaseConfig()
            .then(config => setPressReleaseFormConfig(config))
            .catch(()=>  toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to get modal config', life: 5000 }))
    }, [])

    const streamNames = streams?.map(item => {
        return {
            name: item.name,
            id: item.id,
        }
    })
    if (streamNames.length > 2) {
        streamNames.unshift(allStreamsValue)
    }

    const createPublication = (pressRelease:Publication) => {
        BackendFactory.produce().postPublicationsInternal(pressRelease)
            .then(resp => {
                if (resp?.detail === 'SUCCESS') {
                    refreshList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('BOFundTranslationKeys:pressReleaseCreateSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: t('BOFundTranslationKeys:pressReleaseCreateError'), life: 5000 })
                }
        })
    }

    const publishPublication = (changedFields: Publication, id: Publication['id']) => {
        const publishFields = {...changedFields, status: {status:  PR_STATUSES.PUBLISHED} }
        BackendFactory.produce()
            .patchPublications(publishFields, id)
            .then( res => {
                if(res.detail === 'SUCCESS') {
                    setModal(ModalActions.REMOVE)
                    refreshList()
                    toast.current?.show({ severity: 'success', summary: 'Success', detail: t("BOFundTranslationKeys:publishedText"), life: 5000 })
                }else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: t('BOFundTranslationKeys:publishedTextError'), life: 5000 })
                }
            })
    }

    const editPublication = (pressRelease:Partial<Publication>, formik?:FormikProps<Publication>) => {
        if(currentPublication) {
            if(pressRelease.stream === t('publications:allStreams')) pressRelease.stream = ALL_STREAMS
            BackendFactory.produce()
                .patchPublications(pressRelease, currentPublication.id)
                .then(res => {
                    if(res.detail?.includes('exist')) {
                        formik && formik.setFieldError('fund_number', t("BOFundTranslationKeys:validationFundNumberDoesNotExist") || '')
                    }
                    if(res.detail === 'SUCCESS') {
                        refreshList()
                        formik && formik.setTouched({})
                        toast.current?.show({ severity: 'success', summary: 'Success', detail: t("BOFundTranslationKeys:pressReleaseEditSuccess"), life: 5000 })
                    }else {
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: t("BOFundTranslationKeys:pressReleaseEditError"), life: 5000 })
                    }
                })
        }

    }

    const onSortingAction = (params: string) => {
        BackendFactory.produce().getBOPublicationsOrderedBy(statusType, params)
            .then(res => {
              setCompState({ ...compState, sortingDirection: params })
              setStatePublications(res.publications)
            })
    }

    const refreshList = () => {
      BackendFactory.produce().getBOPublicationsOrderedBy(statusType, compState.sortingDirection)
        .then(res => setStatePublications(res.publications))
    }

    const setActiveRow = (id: string) => {
        setCompState({
          ...compState,
          activeRow: id
        })
    }

    const renderPubRow = (pub: Publication)=>{
        const publishedDate = prettifyDate(pub.published)
        const updatedDate = pub.status?.updated_at && prettifyDate(pub.status?.updated_at)
        const buttonLabel = (pub.stream === ALL_STREAMS) ? t('publications:allStreams') : pub.stream
        return (
            <tr id={pub.id}
                key={pub.id + '_key'}
                onClick={() => {
                    setActiveRow(pub.id)
                    setCurrentPublication(pub)
                }}
                className={cn('row', {
                    'active': compState.activeRow === pub.id,
                    })
                }
            >
                <td className='column column--with-padding'>
                   <div className="publication-item publication-item--title bold"> {pub.title}</div>
                   <div className="publication-item publication-item--summary">{pub.summary}</div>
                </td>
                <td className='column column--medium column--with-padding'>{publishedDate || '-'}</td>
                <td className='column column--large column--with-padding'>
                  <div className="form-field">
                      <CommentTextArea activeRowId={pub.id}
                                       propComment={pub.comment || ''}
                                       commentFor='press'
                                       response={(resp) => {
                                           refreshList()
                                           if(resp.detail === 'SUCCESS') {
                                               toast.current?.show({ severity: 'success', summary: 'Success', detail: t('table:commentChanged'), life: 5000 })
                                           }else {
                                               toast.current?.show({ severity: 'error', summary: 'Error', detail: t('table:commentError'), life: 5000 })
                                           }
                                       }} />
                  </div>
                </td>
                <td className='column column--medium column--with-padding'>{pub.source}</td>
                <td className='column column--medium column--with-padding publication-tag'>
                    {pub.stream
                        ?
                        <Button label={buttonLabel}
                                className='press-release-button'
                                severity='secondary'
                                size='small'
                                icon='pi pi-check'
                                iconPos='right'
                                onClick={(event) => {
                                    if (op.current) {
                                        op.current.toggle(event)
                                    }
                                }}
                        />
                        :
                        <button onClick={(event) => {
                            if (op.current) {
                                op.current.toggle(event)
                            }
                        }}
                                className='action-button action-button--alert'
                        >
                            {t('table:assignStream')} {pub.stream_suggestions && pub.stream_suggestions?.length > 0 &&
                          <AlertIcon />}
                        </button>
                    }
                </td>
                <td className='column column--medium column--with-padding'>
                        {pub.segment && pub.segment.map((el,index) =>
                            <span key={el.id}>{el.name}{pub.segment && pub.segment[index + 1] ? ', ' : ''}</span>,
                        )}
                </td>
                <td className='column column--large column--no-wrap column--right column--with-padding'>
                    {statusType === PublicationTypes.PUBLISHED &&
                      <GroupedButtons onEdit={() => {
                                          setModal(ModalActions.EDIT)
                                      }}
                                      onArchive={() => openAlertArchiveDialog(pub.id)}
                                      onActionButton={() => alertDialogUnpublishedContent(pub.id)}
                                      actionButtonText={t("table:stopPublishing") || ''}
                      />
                    }
                    {statusType === PublicationTypes.ARCHIVED &&
                      <GroupedButtons onDelete={() => alertDialogDeleteContent(pub.id)}
                                      onEdit={() => {
                                          setModal(ModalActions.EDIT)
                                      }}
                                      onActionButton={() => alertDialogPublishedContent(pub.id)}
                                      actionButtonText={t("table:publish") || ''}
                      />
                    }
                    {statusType === PublicationTypes.UNPUBLISHED &&
                      <GroupedButtons onDelete={() => alertDialogDeleteContent(pub.id)}
                                      onEdit={() => {
                                          setModal(ModalActions.EDIT)

                                      }}
                                      onArchive={() => openAlertArchiveDialog(pub.id)}
                                      onActionButton={() => alertDialogPublishedContent(pub.id)}
                                      actionButtonText={t("table:publish") || ''}
                      />
                    }
                    <div className="action-buttons-container action-buttons-container--wmb">
                        {statusType !== PublicationTypes.UNPUBLISHED
                        && <div className="action-info">
                            {t("table:publishedOnRadar")}: <span className="action-info__empty">{updatedDate ? updatedDate : t("table:no")}
                        </span>
                        </div>}
                    </div>
                    <div className="action-link-container">
                        {pub.url && (
                            <a href={pub.url} target={'_blank'} rel="noreferrer">
                            <span className="action-link">
                                <ShareIcon />
                            </span>
                            <span className="action-link__text">{pub.url}</span>
                            </a>
                        )}
                    </div>
                </td>
            </tr>
        )
    }

    const renderTableHeader = () => {
        return (
            <>
                <tr>
                    <th className='column column--with-padding column--large column--header name-column'>
                        <SortingIcons sortParams={compState.sortingDirection} sortParamName='title' onSortingAction={onSortingAction}/>
                        <span className="title">{t("table:report")}</span>
                    </th>

                    <th className='column column--with-padding column--medium column--header'>
                        <SortingIcons sortParams={compState.sortingDirection}  sortParamName='published' onSortingAction={onSortingAction}/>
                        <span className="title">{t("table:created")}</span>
                    </th>
                    <th className='column column--with-padding column--medium column--header'>
                        <SortingIcons sortParams={compState.sortingDirection}  sortParamName='radar_published' onSortingAction={onSortingAction}/>
                        <span className="title">{t("table:comment")}</span>
                    </th>
                    <th className='column column--with-padding column--medium column--header'>
                        <SortingIcons sortParams={compState.sortingDirection}  sortParamName='source' onSortingAction={onSortingAction}/>
                        <span className="title">{t("table:source")}</span>
                    </th>
                    <th className='column column--with-padding column--medium column--header'>
                        <SortingIcons sortParams={compState.sortingDirection}  sortParamName='primaryStream' onSortingAction={onSortingAction}/>
                        <span className="title">{t("BO:BO-FP-RFPStream")}</span></th>
                    <th className='column column--with-padding column--medium column--header'>
                      <SortingIcons sortParams={compState.sortingDirection}  sortParamName='segment' onSortingAction={onSortingAction}/>
                      <span className="title">{t("FP:RP-FP-FSeg")}</span></th>
                    <th className='column column--large'/>
                </tr>

            </>
        )
   }


    const {title, text, buttonConfirmText, status, successMessage, errorMessage} = useMemo(()=> getModalText(modal), [modal])
    const changePublicationStatus = (id: string) => {
        BackendFactory.produce().patchPublications({status: {status}}, id)
            .then(resp => {
                if (resp.detail === 'SUCCESS') {
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: successMessage,
                        life: 5000,
                    })
                    setModal(ModalActions.REMOVE)
                    refreshList()
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                        life: 5000,
                    })
                }
            })
    }

    const alertDialogDeleteContent = (pubId: string) => {
        setModal(ModalActions.ALERT_DELETE)
    }

    const alertDialogUnpublishedContent = (pubId: string) => {
        setModal(ModalActions.ALERT_UNPUBLISHED)
    }

    const alertDialogPublishedContent = (pubId: string) => {
        setModal(ModalActions.ALERT_PUBLISH)
    }

    /** On click on archive button */
    const openAlertArchiveDialog = (pubId: string) => {
        setModal(ModalActions.ALERT_ARCHIVE)
    }
    const isNotPublished = statusType === 'published' ? 'published' : 'relevant'
    const isModalOpen = modal === ModalActions.ALERT_DELETE || modal === ModalActions.ALERT_ARCHIVE || modal === ModalActions.ALERT_UNPUBLISHED || modal === ModalActions.ALERT_PUBLISH
    const renderList = (pubs: Publication[])=>{
        return (<>

                <div className='bo-button-container'>
                  <div>
                    {statusType === PublicationTypes.UNPUBLISHED && <div className="crawl-updating-date">{t("BO:BO-FP-NFPLR")}: {lastCrawlDate}</div>}
                  </div>

                  <button className="action-button action-button--magenta"
                          onClick={()=> {
                              setCurrentPublication(undefined)
                              setModal(ModalActions.CREATE)
                          }}> <PlusIcon/> {t("table:createPressRelease")}</button>
                </div>

                {pubs &&  <ScaleTable size={"medium"} className="publications-table" >
                    <table>
                        <thead>
                          {renderTableHeader()}
                        </thead>
                        <tbody>
                          {pubs?.map( pub => renderPubRow(pub))}
                        </tbody>
                    </table>
                </ScaleTable>}
                {pressReleaseFormConfig &&
                  <F2CFormGenerator isOpened={modal === ModalActions.EDIT || modal === ModalActions.CREATE}
                                    currentTab={isNotPublished}
                                    modalType={modal}
                                    modalTitle={modal === ModalActions.CREATE ? t('table:createPressRelease') : t('table:editPressRelease')}
                                    onClose={() => {
                                        setModal(ModalActions.REMOVE)
                                        setCurrentPublication(undefined)
                                    }}
                                    onCreate={(formData) => {
                                        createPublication(formData as Publication)
                                    }}
                                    onPublish={(changedFields, formData) => {
                                        publishPublication(changedFields as Publication, formData.id)
                                    }}
                                    onEdit={(changedFields, formik) => {
                                        editPublication(changedFields as Publication, formik)
                                    }}
                                    formConfig={pressReleaseFormConfig}
                                    initialFormValues={currentPublication || getValuesFromConfig(pressReleaseFormConfig)}
                                    validationSchema={pressReleaseFormSchema}
                  />
                }
                <F2CAlertModal isOpen={isModalOpen}
                               title={title}
                               message={text}
                               buttonConfirmText={buttonConfirmText}
                               onClose={() => setModal(ModalActions.REMOVE)}
                               onAccept={() => {
                                    if (currentPublication?.id) changePublicationStatus(currentPublication?.id)
                                }}
                />
                <div className="card flex justify-content-center" >
                    <OverlayPanel ref={op} >
                        <div style={{display:'flex', flexDirection:'column', gap:'5px'}}>
                            {currentPublication && streamNames.map(stream =>
                                <Button label={stream.name}
                                        key={stream.id}
                                        severity="secondary"
                                        className="press-release-button"
                                        raised={currentPublication.stream_suggestions?.includes(stream.name)}
                                        size="small"
                                        // icon="pi pi-user"
                                        // iconPos="right"
                                        text={!(currentPublication.stream && currentPublication.stream === stream.id)}
                                        onClick={() => {
                                            editPublication({
                                                stream:stream.name
                                            })
                                            op.current && op.current.toggle(undefined)
                                        }}
                                />
                            )}
                        </div>
                    </OverlayPanel>
                </div>

                <Toast ref={toast} position='bottom-right' />
            </>
        )
    }

    return renderList(statePublications || publications)
}