export const Cols = {
  '1': '(100% / 12) * 1',
  '2': '(100% / 12) * 2',
  '3': '(100% / 12) * 3',
  '4': '(100% / 12) * 4',
  '5': '(100% / 12) * 5',
  '6': '(100% / 12) * 6',
  '7': '(100% / 12) * 7',
  '8': '(100% / 12) * 8',
  '9': '(100% / 12) * 9',
  '10': '(100% / 12) * 10',
  '11': '(100% / 12) * 11',
  '12': '(100% / 12) * 12'
} as const
