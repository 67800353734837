export interface EnvConfig {
  AUTHORIZATION_HEADER: string;
  APP_ENV: 'EU-prod' | 'test-a' | 'demo-a' | 'prod' | 'CZ-prod'
}

declare global {
  interface Window {
    _env_: EnvConfig;
  }
}


const getEnvConfig = (): EnvConfig => {
  if (window._env_) {
    return window._env_ as EnvConfig;
  }
  throw new Error('env-config.js is not loaded');
}

export default getEnvConfig;
