export function prettifyDate(date: any) {
    if(date === null) {
        return null
    }
    
    const dateString = date.toString()
    const parts = dateString.indexOf('T') > -1 ? dateString.substring(0, dateString.indexOf('T')).split('-') : dateString.split('-')
    return (parts && parts.length > 0) &&`${parts[2]}.${parts[1]}.${parts[0]}`
}

export const defaultDate = () => {
    let day
    let month

    const today = new Date()
    const yearNumber = today.getUTCFullYear()
    const monthNumber = (today.getUTCMonth() + 1).toString()
    const dayNumber = today.getUTCDate().toString()

    if (dayNumber.length === 1){
        day = dayNumber.padStart(2, '0')
    } else {day = dayNumber }

    if (monthNumber.length === 1){
        month = monthNumber.padStart(2, '0')
    } else {month = monthNumber }


    return `${yearNumber}-${month}-${day}`
}