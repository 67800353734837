import styled from 'styled-components/macro'

type Props = {

}

const ProgressbarInner = styled('div')<Props>`
  display: flex;
  margin-bottom: ${props => props?.theme.spacings?.small.margin};
`

export default ProgressbarInner