import { bu } from '../constants/BusinessUnit'

export const Typography = {
  default: {
    fontSize: bu(18),
    lineHeight: '1.27'
  },
  detailPage: {
    primaryStream: {
      fontSize: bu(18),
      fontWeight: '800'
    },
    title: {
      fontSize: {
        mobile: bu(32),
        static: bu(44)
      },
      fontWeight: '800'
    },
    step: {
      fontSize: bu(16),
      fontWeight: '800'
    },
    summary: {
      title: {
        fontSize: bu(24),
        fontWeight: '800'
      }
    },
    tabs: {
      title: {
        fontSize: bu(18),
        fontWeight: '800'
      }
    },
  },
  stageLabel: {
    fontSize: bu(14),
    fontWeight: '800'
  },
  stageTitle: {
    fontSize: bu(18),
    fontWeight: '800'
  },
  tooltip: {
    title: {
      fontSize: bu(24),
      fontWeight: '800'
    },
    listItem: {
      title: {
        fontSize: bu(16),
        fontWeight: 800
      }
    }
  }
} as const
