import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import BreadcrumbsElem from '../../../components/breadcrumbs/breadcrumbsElem'
import '../backoffice.scss'

import BackendFactory from "../../../backend/BackendFactory";
import Stream from "../../../model/Stream";
import {StreamsTable} from "./streams-table";


export const StreamsPage = () => {
    const { t } = useTranslation(['RP','menu'])

    const [streams, setStreams] = useState<Stream[]>([])

    useEffect(()=>{
        BackendFactory.produce().getStreams()
            .then( res => setStreams(res))
    },[])

    return (
        <>
            <BreadcrumbsElem linksArray={[{href: '/', text: t("RP:RP-M1")}, {href: '/', text: t("RP:RP-M3"), disabled: true}]}
                             current={{href: 'backoffice/streams', text:t("menu:linkStreams")}}/>
            <div className="press-releases-inner">
                {streams.length >= 0 && <StreamsTable streams={streams}/>}
            </div>
        </>
    )
}