import i18next from 'i18next'

export type PhaseValues = 'Zuteilung' | 'Ausschreibung' | 'Notifizierung' | 'Förderrichtlinie' | 'Ressortzuteilung'

export type PhaseKey = {
  label:string
  val: PhaseValues
}

export const phaseKeys:PhaseKey[] = [
  {label: i18next.t('RP:RP-Status-1'), val: 'Ressortzuteilung'},
  {label: i18next.t('RP:RP-Status-2'), val: 'Förderrichtlinie'},
  {label: i18next.t('RP:RP-Status-3'), val: 'Notifizierung'},
  {label: i18next.t('RP:RP-Status-4'), val: 'Ausschreibung'},
  {label: i18next.t('RP:RP-Status-5'), val: 'Zuteilung'},
]

export const PhaseTranslation = (phase:PhaseValues): string => {
  let key = phaseKeys.filter(key => key.val === phase)
  return key[0].label
}