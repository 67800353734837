import { bu } from '../constants/BusinessUnit'

import { Colors } from './Colors'

export const Cards = {
  default: {
    frame: {
      color: 'inherit',
      backgroundColor: Colors.primary.white,
      overflow: 'inherit'
    },
    title: {
      fontSize: 'inherit',
      fontWeight: 'inherit'
    },
    value: {
      fontSize: 'inherit',
      fontWeight: 'inherit'
    }
  },
  dark: {
    frame: {
      color: Colors.primary.white,
      backgroundColor: Colors.gray.doveGray,
      overflow: 'inherit',
    },
    title: {
      fontSize: bu(18),
      fontWeight: 400
    },
    value: {
      fontSize: bu(20),
      fontWeight: 800
    }
  },
  light: {
    frame: {
      color: Colors.gray.mineShaft,
      backgroundColor: Colors.gray.gallery,
      overflow: 'visible'
    },
    title: {
      fontSize: bu(18),
      fontWeight: 800
    },
    value: {
      fontSize: bu(16),
      fontWeight: 400
    }
  }
}
