import styled from 'styled-components/macro'

type Props = {

}

const BlackMask = styled('div')<Props>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    display: none;
  }
`

export default BlackMask