import React from 'react'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'

import { Translation } from 'react-i18next'

import List from '../../components/List'
import ListItem from '../../components/List/ListItem'
import ListItemContent from '../../components/List/ListItemContent'
import {PrimaryStreamDropdown} from "../../components/primaryStreamDropdown/primaryStreamDropdown"
import RadarStatus from '../../components/radar-status/RadarStatus'
import Radar from '../../components/Radar/Radar'
import RadarData, {RadarView} from '../../components/Radar/RadarData'
import {TooltipConfig} from '../../components/Radar/RadarTooltip'
import Section from '../../components/Section'
import DataTable from "../../components/DataTable/dataTable";
import WidthDependent, {BreakpointDependentRenderer as BreakpointRenderer} from '../../components/WidthDependent/WidthDependent'
import {ReactComponent as ListIcon} from '../../icons/list-icon.svg';
import {ReactComponent as RadarIcon} from '../../icons/radar-icon.svg';
import './radarPage.module.scss'

import FundMapped from '../../model/FundMapped'
import Stream from '../../model/Stream'

import {TrendingStreams} from '../../theme/constants/TrendingStreams'
import {BREAKPOINTS} from '../../theme/styles/Breakpoints'
import Legend from "../../components/legend/Legend";
import {PublicationsList} from "../../components/PublicationsList/publicationsList";
import {NotificationsList} from "../../components/PublicationsList/notificationsList";
import {ScaleIconNavigationRight, ScaleAccordion, ScaleCollapsible} from "@telekom/scale-components-react";
import { F2CFilter, FilterOption } from '../../components/F2CFilter/F2CFilter'
import { getRadarPageFilters } from './filterConfig'

import { ReactComponent as DeadlineIcon} from '../../icons/deadline-fund.svg'
import { ReactComponent as DefaultIcon} from '../../icons/default-fund.svg'
import { ReactComponent as NewIcon} from '../../icons/new-fund.svg'
import { ReactComponent as UpdateIcon} from '../../icons/update-fund.svg'
import { filterCheckboxBasedOnEnv} from '../../components/F2CFilter/commonFilterOptions'
import { updateUrlWithParams } from '../../utils/updateUrlWithParams'
import { TFunction } from 'i18next'
import i18n from '../../i18n'



interface Foerdersumme {
  fundingVolume: number
  fundingVolumeInLastStage: number
}

interface State {
  showLabels: boolean,
  streamTableView: boolean,
  tableView: boolean,
  streams?: Stream[],
  substreams?: Stream[],
  basicFunds?: FundMapped[],
  foerderSumme?: Foerdersumme,
  currentStream?: string,
  currentStreamObj?: Stream,
  lastUpdate: string,
  firstLevel?: boolean,
  secondLevel?: boolean,
  thirdLevel?: boolean,
  filterOptions:FilterOption[]
  radarInfoExpanded:boolean
}

interface Props {
  onUpdateTooltip?: (config: TooltipConfig) => void
  onUpdateStreams(streamName: Stream['name']): void,
  isTooltipClosed?: boolean,
}

type UrlParams = {
  streamParams: string
  filterParams: string
  sortParams: string
}


class RadarPage extends React.Component<Props, State> {
  backend: Backend;
  initialFundsArray: FundMapped[];
  initialStreamsArray: Stream[];
  grantsTotalPerStream: number;
  isOnTrend: boolean;
  currentSub: string | undefined
  prevStateConst: any
  urlParams:UrlParams
  urlSearchParams = new URLSearchParams(window.location.search);
  primaryStream = this.urlSearchParams.get('primaryStream');
  constructor(props: any) {
    super(props)
    this.backend = BackendFactory.produce()
    this.currentSub = undefined
    this.urlParams = {
      streamParams: '',
      filterParams: '',
      sortParams:''
    }

    this.state = {
      showLabels: false,
      streamTableView: true,
      tableView: !!this.urlSearchParams.get('view'),
      lastUpdate: i18n.t("RP:RP-ChangeDateDefault"),
      currentStream: this.primaryStream || 'Alle',
      currentStreamObj: undefined,
      firstLevel: true,
      secondLevel: false,
      thirdLevel: false,
      filterOptions: [],
      radarInfoExpanded:true,
      basicFunds: []
    }

    this.initialFundsArray = [];
    this.initialStreamsArray = [];
    this.grantsTotalPerStream = 0;
    this.isOnTrend = false;
    this.prevStateConst = null

    this.toggleShowLabelsState = this.toggleShowLabelsState.bind(this)
    this.onSelectStream = this.onSelectStream.bind(this)
  }

  componentDidMount() {
   this.getStatusOfRadarAdditionalInfo()
    getRadarPageFilters().then(filterOptions => {
      this.setState({filterOptions})
    })
    this.backend.addViewToElement('Page loaded: Förderradar')
      this.backend.getStreams().then(streams => {
        this.initialStreamsArray = streams
        return this.setState({ streams })
      })

    const searchParams = new URLSearchParams(window.location.search);
    this.getFundsByParams(searchParams.toString())

  }

  shouldComponentUpdate(prevProps: Props, prevState: State) {
    return (prevState.streams !== this.state.streams)
        || (prevState.streamTableView !== this.state.streamTableView)
        || (prevState.tableView !== this.state.tableView)
        || (prevState.basicFunds !== this.state.basicFunds)
        || (prevState.showLabels !== this.state.showLabels)
        || (prevState.currentStream !== this.state.currentStream)
        || (prevProps.isTooltipClosed !== this.props.isTooltipClosed)
  }

  getStatusOfRadarAdditionalInfo() {
    const isActive = localStorage.getItem("radarInfoExpanded");
    if (isActive) {
      this.setState({ radarInfoExpanded: JSON.parse(isActive) });
    }
  }

  changeRadarInfoStatus() {
    this.setState(
      (prevState) => ({ radarInfoExpanded: !prevState.radarInfoExpanded }),
      () => {
        localStorage.setItem("radarInfoExpanded", JSON.stringify(this.state.radarInfoExpanded));
      }
    );
  }

  setLevel (currentStreamObj: Stream | undefined){
    const firstLevel = !currentStreamObj
    const secondLevel = !!currentStreamObj && currentStreamObj.main_stream
        && currentStreamObj.sub_streams && currentStreamObj.sub_streams?.length > 0
    const thirdLevel = (!!currentStreamObj && currentStreamObj.main_stream === false
        && !currentStreamObj.has_sub_streams) || (currentStreamObj?.sub_streams && currentStreamObj.sub_streams?.length === 0)

    return {firstLevel, secondLevel, thirdLevel}
  }

  renderMobileStreamTableRows(data: RadarData) {
    let sortedStreams: any[]
    const streams = [...data.streams]
    sortedStreams = streams.sort()

    return sortedStreams.map(item => this.generateMobileStreamsTableRow(data, item));
  }

  renderMobileStreamTableHeader() {
    return (<Translation>
            { (t) => 
              <tr>
                <th><strong>{t("home:streamName")}</strong></th>
                <th><strong>{t("home:grants")}</strong></th>
                <th className="centered"><strong>{t("home:trend")}</strong></th>
              </tr> 
            }
          </Translation>)
  }

  radarToggle(tableViewState: boolean) {
    const searchParams = new URLSearchParams(window.location.search);

    if(tableViewState) {
      searchParams.set('view', 'table')
    }else searchParams.delete('view')
    updateUrlWithParams(searchParams.toString())

    return <div className="radar-toggle">
      <div className="radar-toggle__container">
        <span className="radar-toggle__icon" onClick={()=> this.setState({
          tableView: !tableViewState,
          streamTableView: !this.state.streamTableView
        })}>
        {tableViewState ? 
          <Translation>
            { (t) => <RadarIcon title={String(t("RP:RP-ListCake"))}/>  }
          </Translation>
          : 
          <Translation>
            { (t) => <ListIcon title={String(t("RP:RP-ListCake"))}/>}
          </Translation>
        }
        </span>
      </div>
    </div>
  }


  radarSubHeadline (lastUpdate: any, currentStreamObject?: Stream){
    const {thirdLevel, streams, currentStreamObj} = this.state
    const streamTitle = currentStreamObject ? currentStreamObject.name : 
    <Translation>
      { (t) => String(t("RP:RP-SelParStr")) }
    </Translation>
    const parentName = currentStreamObj?.parent_name
    const subStreams = currentStreamObj?.sub_streams
    //this.setLevel(currentStreamObject || currentStreamObj)

    const subTitle = (currentStreamObj?: Stream, t?: TFunction) => {
      if (currentStreamObj){
        if (subStreams
            && (subStreams.length > 1)){
          return `${subStreams.length} Themen`
        }
      } else {
        let streamsNumberInfo = ''
        if (streams){
          streamsNumberInfo = (streams.length > 1) || (streams.length === 0)
              ? `${streams.length} ${t!("RP:RP-SelPriStr")}` : `1 ${t!("RP:RP-SelPriStrOne")}`
        }
        return streamsNumberInfo
      }
    }

    return (
      <Translation>{ (t) =>
        <div className="radar-two-rows-container">
          <div className="radar-two-rows-container__inner radar-two-rows-container__inner--left">

            <h2 className="radar-subtitle-item label label--level-2" style={{ marginTop: '0px' }}>
              {!thirdLevel && streamTitle}
              {thirdLevel && parentName}
            </h2>
            <h3 className="radar-subtitle-item label label--level-3" style={{ marginTop: '0px' }}>
              {!thirdLevel && subTitle(currentStreamObj, t)}
              {thirdLevel && currentStreamObj?.name_radar}
            </h3>
          </div>
          <div className="radar-two-rows-container__inner radar-two-rows-container__inner--right">
            <div className="radar-status-updated">
              { String(t("RP:RP-ChangeDate")) }: {lastUpdate}
            </div>
          </div>
        </div>
        }</Translation>
    )
  }

  renderRadar(radarView?: RadarView, mobile?: boolean) {
    const { streams, showLabels, basicFunds, streamTableView, tableView } = this.state

    if (streams === undefined || basicFunds === undefined) {
      return null
    }

    if (!mobile) {
      if (this.state.streams !== this.initialStreamsArray && this.initialStreamsArray.length >= 0) {
        this.setState({
          streams: this.initialStreamsArray
        })
      }
    }

    const data: RadarData = {
      streams,
      basicFunds,
      view: radarView,
      showLabels,
    }    

    return (
      <>
        {tableView ? <DataTable data={data.basicFunds}
                                onSortingAction={params => {
                                    this.urlParams.sortParams = params
                                    this.getFundsByParams()
                                }}
                                onInit={params => this.urlParams.sortParams = params}
          />
          : <div className="radar-switcher" onClick={(e) => this.switchToStreamMode(e)}>
            {mobile
              ? <>
                {!streamTableView && <div className="centered">
                  <Radar secondLevel={this.state.secondLevel}
                      onUpdateStreams={this.updateCurrentStream.bind(this)}
                      data={data}
                      onUpdateTooltip={this.props.onUpdateTooltip} />
                  <span className="radar-link radar-link--with-icon" onClick={() => this.switchToTable()}>
                    <Translation>
                      { (t) => String(t("RP:RP-SelParStr")) }
                    </Translation>
                  </span>
                </div>
                }
                {streamTableView && <DataTable data={data.basicFunds}
                                               onSortingAction={params => {
                                                   this.urlParams.sortParams = params
                                                   this.getFundsByParams()
                                               }}
                                              streamMode={true}
                                              streamHeader={this.renderMobileStreamTableHeader()}
                                              streamRows={this.renderMobileStreamTableRows(data)}
                                              onInit={params => this.urlParams.sortParams = params}
                />}
              </>
              : <div style={{
                marginTop: (data.view === 'smaller' ? '-50px' : '0px'),
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Radar data={data} onUpdateTooltip={this.props.onUpdateTooltip} secondLevel={this.state.secondLevel}/>
              </div>}
          </div>}
        <div className="radar-legend-container">
          
          <Translation>
            {
              (t) => <Legend itemsArray={[
                {title: t("RP:RP-DiamondsDefault"), icon: DefaultIcon},
                {title: t("RP:RP-DiamondsNew"), icon: NewIcon},
                {title: t("RP:RP-DiamondsUpdate"), icon: UpdateIcon},
                {title: t("RP:RP-DiamondsDeadline"), icon: DeadlineIcon}
              ]} />
            }
          </Translation>
          {tableView && this.radarToggle(tableView)}
        </div>
      </>
    )
  }

  renderStatus(mobile: boolean){
    const {
      streams,
      basicFunds,
      streamTableView,
      currentStreamObj,
      currentStream,
      tableView,
      lastUpdate } = this.state
    if (streams === undefined || basicFunds === undefined) {
      return null
    }

    let adjustedCurrentStream = currentStream
    if (mobile) {
      if (tableView || streamTableView) {
        adjustedCurrentStream = ''
      }
    } else {
      adjustedCurrentStream = ''
      if (this.state.streams !== this.initialStreamsArray && this.initialStreamsArray.length >= 0) {
        this.setState({
          streams: this.initialStreamsArray
        })
      }
    }

    const foerderSumme = RadarPage.determineFundingVolume(basicFunds, adjustedCurrentStream)
    const checkboxes = filterCheckboxBasedOnEnv()
    return(
        <>
          <div className="radar-two-rows-container">
            <div className="radar-two-rows-container__inner radar-two-rows-container__inner--left">
            <h1 className="label label--level-1" style={{ marginTop: '0px' }}>
              <Translation>
                { (t) => String(t("RP:RP-T-M1")) }
              </Translation>
            </h1>
              
            </div>
            <div className="radar-two-rows-container__inner radar-two-rows-container__inner--right">
              {this.radarToggle(tableView)}
              {this.state.filterOptions.length > 0 && <Translation>
                {(t) => <F2CFilter
                  headerSwitch={String(t('home:showTitle'))}
                  onHeaderSwitch={this.toggleShowLabelsState}
                  onFiltering={(filterParams) => {
                    this.urlParams.filterParams = filterParams
                    this.getFundsByParams()
                  }}
                  onInit={(filterParams) => this.urlParams.filterParams = filterParams}
                  filters={this.state.filterOptions}
                  checkboxes={checkboxes}
                />}
              </Translation>}
              
            </div>
          </div>
          <div className="radar-status-heading">
            {/*stream select*/}
              <PrimaryStreamDropdown
                currentStreamName={currentStream || 'Alle'}
                onSelectStream={(currentStreamName, StreamObj)=> {
                    this.onSelectStream(currentStreamName,StreamObj)
                }}
                onInit={(currentStreamName) => {
                  const streamParams = currentStreamName === 'Alle' ? '' : ('primaryStream='+currentStreamName)
                  this.urlParams.streamParams = streamParams
                }}
                streams={this.state.streams || []}
            />
            <RadarStatus total={foerderSumme.fundingVolume} available={foerderSumme.fundingVolumeInLastStage}/>
          </div>
          {this.radarSubHeadline(lastUpdate, currentStreamObj)}
      </>
    )
  }



  renderBreadCrumbs(){
    const {currentStreamObj, secondLevel, thirdLevel, currentStream} = this.state
    const name = currentStreamObj?.parent_name
    const action = (name: string) => {
      if(name){
        this.backend.getStreams().then( res => {
          const obj = res.find( stream => stream.name === name)
          this.onSelectStream(name, obj)
        })
      }
    }

    const streamName = currentStream?.includes('Sonstige') ? 'Sonstige' : currentStream

    return <div className="breadcrumbs-container">
      {(secondLevel || thirdLevel)
      && <div className="breadcrumb">
        <ol className="breadcrumb__list">
          <li className="breadcrumb__list-item">
                  <span className="breadcrumb__link" key={`bc-link__first`} onClick={() =>{
                    this.onSelectStream('Alle')
                  }}>
                    <Translation>
                      { (t) => String(t("RP:RP-T-M1")) }
                    </Translation>
                  </span>
            <span className="breadcrumb__separator" ><ScaleIconNavigationRight size={12}/></span>
          </li>
          {thirdLevel && name && name !== currentStream &&<li className="breadcrumb__list-item">
                <span className="breadcrumb__link" key={`bc-link__second`}
                      onClick={() =>{action(name)}}>{name}</span>
            <span className="breadcrumb__separator" ><ScaleIconNavigationRight size={12}/></span>
          </li>}
          <li className="breadcrumb__list-item">
            <span className="breadcrumb__link">{streamName}</span>
          </li>
        </ol>
      </div>}
    </div>
  }

  render() {
    /* breakpoints */
    const breakpointRadarRenderers: BreakpointRenderer[] = [
      { breakpoint: 0, renderer: () => { return this.renderRadar('quarter', true) } },
      { breakpoint: BREAKPOINTS.tabletMax, renderer: () => { return this.renderRadar('smaller', false) } },
      { breakpoint: BREAKPOINTS.desktop, renderer: () => { return this.renderRadar() } },
    ]

    const breakpointStatusRenderers: BreakpointRenderer[] = [
      { breakpoint: 0, renderer: () => { return this.renderStatus(true) } },
      { breakpoint: BREAKPOINTS.tabletMax, renderer: () => { return this.renderStatus(false) } },
    ]


    return (
        <div className="radar-page">
          {this.renderBreadCrumbs()}
          <div className="radar-page-inner">
            <Section>
              {(this.state.streams) && <WidthDependent renderers={breakpointStatusRenderers} />}
              {(this.state.basicFunds) && <WidthDependent renderers={breakpointRadarRenderers} />}
            </Section>

            <Section isContent>
              <div className="radar-stages-inner">
              <ScaleAccordion>
                <ScaleCollapsible expanded={this.state.radarInfoExpanded} onClick={()=> this.changeRadarInfoStatus()}>
                  <span slot="heading">
                    <Translation>
                      { (t) => t("home:stageTitle")}
                    </Translation>
                  </span>
                  <List isClear>
                    <ListItem>
                      <ListItemContent>
                        <span className="radar-stage-label radar-stage-label__first">
                          <Translation>
                            { (t) => t("RP:RP-Status-1")}
                          </Translation>
                        </span>
                        <Translation>
                          { (t) => <p className="radar-paragraph">{t("home:stageDescription1")}</p>}
                        </Translation>
                      </ListItemContent>
                    </ListItem>

                    <ListItem>
                      <ListItemContent>
                        <span className="radar-stage-label radar-stage-label__second">
                          <Translation>
                            { (t) => t("RP:RP-Status-2")}
                          </Translation>
                        </span>
                        <Translation>
                          { (t) => <p className="radar-paragraph is-small2">{t("home:stageDescription2")}</p>}
                        </Translation>
                      </ListItemContent>
                    </ListItem>

                    <ListItem>
                      <ListItemContent>
                        <span className="radar-stage-label radar-stage-label__third">
                          <Translation>
                            { (t) => t("RP:RP-Status-3")}
                          </Translation>
                        </span>
                        <Translation>
                          { (t) => <p className="radar-paragraph">{t("home:stageDescription3")}</p>}
                        </Translation>
                      </ListItemContent>
                    </ListItem>

                    <ListItem>
                      <ListItemContent>
                        <span className="radar-stage-label radar-stage-label__fourth">
                          <Translation>
                            { (t) => t("RP:RP-Status-4")}
                          </Translation>
                        </span>
                        <Translation>
                          { (t) => <p className="radar-paragraph">{t("home:stageDescription4")}</p>}
                        </Translation>
                      </ListItemContent>
                    </ListItem>

                    <ListItem>
                      <ListItemContent>
                        <span className="radar-stage-label radar-stage-label__fifth">
                          <Translation>
                            { (t) => t("RP:RP-Status-5")}
                          </Translation>
                        </span>
                        <Translation>
                          { (t) => <p className="radar-paragraph">{t("home:stageDescription5")}</p>}
                        </Translation>
                      </ListItemContent>
                    </ListItem>
                  </List>
                </ScaleCollapsible>
              </ScaleAccordion>


              </div>
            </Section>
            <Section isContent>
              <div className="publications">
                <NotificationsList primaryStream={this.state.currentStream} />
                <PublicationsList primaryStream={this.state.currentStream} />
              </div>
            </Section>
          </div>
        </div>
    )
  }

  private static determineFundingVolume(funds: FundMapped[], streamName?: string): Foerdersumme {
    let fundingVolume = 0.0
    let fundingVolumeInLastStage = 0.0

    funds.forEach((fund) => {
      let isCorrectStream = true
      if (streamName) {
        if (streamName !== fund.primaryStream) {
          isCorrectStream = false
        }
      }

      if (fund.fundingVolume && isCorrectStream) {
        fundingVolume += fund.fundingVolume
        if (fund.maturity === 'Zuteilung') {
          fundingVolumeInLastStage += fund.fundingVolume
        }
      }
    })

    return {
      fundingVolume, fundingVolumeInLastStage
    }
  }

  private static countFundsWithStream(funds: FundMapped[], streamName: string) {
    let numberOfValidFunds = 0

    for (const fund of funds) {
      if (fund.parent_stream_name === streamName) {
        numberOfValidFunds++
      }
    }
    return numberOfValidFunds;
  }

  private toggleShowLabelsState() {
    this.setState({
      showLabels: !this.state.showLabels
    })
  }

  streamsSorting(streamName: string) {
    const { basicFunds, streams } = this.state
    const basicFundsFiltered = basicFunds?.filter(item => item.parent_stream_name === streamName) || [];
    const basicFundsSorted = basicFunds?.filter(item => item.parent_stream_name !== streamName).concat(basicFundsFiltered).reverse() || [];
    const streamsFiltered = streams?.filter(item => item.name === streamName) || [];
    const streamsSorted = streams?.filter(item => item.name !== streamName).concat(streamsFiltered).reverse() || [];

    return {
      basicFundsSorted, streamsSorted
    }
  }

  private switchToRadar(streamName: string) {
    const { streamTableView } = this.state
    const { streamsSorted, basicFundsSorted } = this.streamsSorting(streamName)

    this.setState({
      streamTableView: !streamTableView,
      streams: streamsSorted,
      basicFunds: basicFundsSorted
    });

    this.grantsTotalPerStream = RadarPage.countFundsWithStream(basicFundsSorted, streamName)
    this.isOnTrend = TrendingStreams.includes(streamName);
  }

  private switchToTable() {
    this.setState({
      streamTableView: !this.state.streamTableView
    })
    this.onSelectStream('Alle')
  }

  private generateMobileStreamsTableRow(data: RadarData, stream: Stream) {

    this.isOnTrend = TrendingStreams.includes(stream.name);

    const total = RadarPage.countFundsWithStream(data.basicFunds, stream.name);

    return <>
      <tr id={stream.name} key={stream.name + '_key'}>
        <td className="column column--no-wrap"><span onClick={() => this.switchToRadar(stream.name)}
                                                      className="radar-link">{stream.name}</span></td>
        <td className="bold centered"><p className="column column--forAlign-radar">{total}</p></td>
        {/* Hide "Im Trend" Label inside the Table on smaller screens */}
        {/*  <td className="centered column column--no-wrap">{trend}</td> */}
      </tr>
    </>
  }

  updateCurrentStream(streamName: string) {
    if (this.state.basicFunds) {
      this.grantsTotalPerStream = RadarPage.countFundsWithStream(this.state.basicFunds, streamName)
    }
    this.isOnTrend = TrendingStreams.includes(streamName)
  }

  onSelectStream(streamName: string, stream?: Stream) {
    // window.history.replaceState(null, 'Fund2Cash','/')
    streamName = streamName.replaceAll('%20',' ')
    const chosenStreams = this.state.streams?.filter( val=> val.name === streamName) || []
    const streamObj: Stream | undefined = stream || (chosenStreams && chosenStreams[0])
    const searchParams = new URLSearchParams(window.location.search);

    this.setState({
      firstLevel: this.setLevel(streamObj).firstLevel,
      secondLevel: this.setLevel(streamObj).secondLevel,
      thirdLevel: this.setLevel(streamObj).thirdLevel
    })

    switch (streamName) {
      case 'Alle':
        Promise.all([this.backend.getStreams()])
          .then(([streams]) => {
            this.initialStreamsArray = streams
          }).finally(() => {
            this.setState({
              currentStream: 'Alle',
              currentStreamObj: undefined,
              streams: this.initialStreamsArray,
              substreams: []
            })
          })
        searchParams.delete('primaryStream')
        break;
      default:
        searchParams.set('primaryStream', streamName)
        let subs: Stream[] = []
        this.currentSub = streamName
        if (  this.setLevel(streamObj).secondLevel  ){
          this.initialStreamsArray = (chosenStreams.length > 0 && chosenStreams[0].sub_streams) || stream?.sub_streams || []
        } else if ( this.setLevel(streamObj).thirdLevel ) {
          this.initialStreamsArray = [streamObj || stream]
        }
        this.setState({
          streams: this.initialStreamsArray,
          substreams: subs,
          currentStream: streamName,
          currentStreamObj: streamObj,
        })
        this.updateCurrentStream(streamName)
        break
    }
    // URL params
    const streamParams = streamName === 'Alle' ? '' : ('primaryStream='+streamName)
    this.urlParams.streamParams = streamParams
    //Table component do its own API calls with its order_by='' & filter params from this page
    this.getFundsByParams()
    updateUrlWithParams(searchParams.toString())

    if(this.state.streamTableView) {
      this.switchToRadar(streamName)
    }
  }

  private getParams():string {
    const {streamParams, filterParams, sortParams} = this.urlParams

    const isContainSymbol = streamParams.search('&')
    const secureStreamParams = isContainSymbol > 0 ?
      streamParams.replace('&', '%26') :
      streamParams

    let combinedUrlParams = (secureStreamParams && filterParams)
      ? `${secureStreamParams}&${filterParams}`
      : secureStreamParams
      || filterParams
      || ''
    if(sortParams) combinedUrlParams = `${combinedUrlParams}&${sortParams}`
    return combinedUrlParams
  }

  private getFundsByParams(initParams?:string) {

    const params = initParams ? initParams : this.getParams()
    this.backend.getBasicFundsByFilters(params).then(resp => {
      const lastUpdate = resp.last_modified || String(i18n.t("RP:RP-ChangeDateDefault"))
      this.setState({
        basicFunds:resp.funds,
        lastUpdate
      })
    })
  }

  switchToStreamMode (event: any) {
    const appElement = document.getElementsByClassName('App')[0];
    const target = event.target.innerHTML.replaceAll('&amp;', '&').replaceAll(' &lt;', '');
    
    if (target !== '' && target.indexOf('clipPath') < 0  && !appElement.classList.contains('with-modal-opened')) {
      if (this.state.streams && this.state.streams.length === 1){
        this.onSelectStream('Alle')
      } else {
        const find = this.initialStreamsArray?.find(item => item.name === target || (target === 'Sonstige' && item.name.includes('Sonstige')))
        const stream = find?.name || 'Alle'
        this.onSelectStream(stream)
      }
    }
  }
}

export default RadarPage