import Portal from "./portal";
import {createPortal} from "react-dom";

interface Wrapper {
    idParam: string,
    children: any
}

export const DialogWrapper = ({ idParam, children }: Wrapper) => {
    const target = Portal(idParam);
    return createPortal(
        children,
        target,
    );
};