export enum HIGHLIGHT  {
    NO_CHANGE = 'Keine Änderung',
    NEW_FUNDING_PROGRAM = 'Neues Förderprogramm',
    CONTENT_UPLOADED = 'Inhalte aktualisiert',
    NOTE_DEADLINES = 'Fristen beachten'
}

export enum FUND_STATUS {
    ARCHIVED = 'Archivierte Programme',
    DELETED = 'Gelöscht'
}