import React, { useState } from 'react'
import {
  ScaleButton,
  ScaleSwitch,
  ScaleTextField,
} from '@telekom/scale-components-react'
import { RSSFeedFormState } from './formType'
import { ReactComponent as ChainIcon } from '../../../../icons/chain-icon.svg'
import { isValidUrl } from '../../../../helpers/validation'
import BackendFactory from '../../../../backend/BackendFactory'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
  model: RSSFeedFormState
  onClose: () => void
  onSubmit: (type?: string, rssFeedId?: string) => void
}

export const RSSFeedForm = ({ onClose, model, onSubmit }: Props) => {
  const { t } = useTranslation(["table", "publications", "details"])
  const initialState: RSSFeedFormState = {
    source: model.source,
    link_type: model.link_type,
    url: model.url,
    is_active: model.is_active,
  }

  const [formState, setFormState] = useState(initialState)
  const [showTooltip, setShowTooltip] = useState(false)
  const [hasError, setHasError] = useState(false)

  const isEmpty = Object.values(formState).some((value) => {
    if (value === '') {
      return true
    }

    return false
  })

  const handleSubmit = () => {
    if (isEmpty) {
      setHasError(true)
    } else if (!isEmpty && isValidUrl(formState.url)) {
      setHasError(false)
      BackendFactory.produce()
        .postRSSFeed(formState)
        .then((res) => {
          console.log({ formRes: res })
          if (res.detail === 'error') {
            onSubmit('error')
          } else {
            onSubmit('create', res.id)
          }
        })
    }
  }

  return (
    <div>
      <div className="content-wrapper">
        <div
          className={cn('form-field', {
            invalid: hasError && !formState.source,
          })}
        >
          <ScaleTextField
            label={String(t("table:source"))}
            size={'small'}
            onScaleChange={(event: any) =>
              setFormState({ ...formState, source: event.target.value })
            }
            type="text"
          />
        </div>
        <div
          className={cn('form-field', {
            invalid: hasError && !formState.link_type,
          })}
        >
          <ScaleTextField
            label={String(t("table:type"))}
            size={'small'}
            onScaleChange={(event: any) =>
              setFormState({ ...formState, link_type: event.target.value })
            }
            type="text"
          />
        </div>
        <div
          className={cn('form-field', {
            invalid: hasError && formState.url === '',
            'invalid invalid--wrong-url-format':
              hasError && formState.url !== '' && !isValidUrl(formState.url),
          })}
        >
          <ScaleTextField
            label={String(t("table:url"))}
            size={'small'}
            onScaleChange={(event: any) =>
              setFormState({ ...formState, url: event.target.value })
            }
            type="text"
          />
          {showTooltip && (
            <div className="tooltip tooltip--black">{t("publications:copiedLink")}</div>
          )}
          <span
            onClick={() => {
              return navigator.clipboard
                .writeText(`${formState.url}`)
                .then(() => {
                  setShowTooltip(true)
                })
                .then(() =>
                  setTimeout(() => {
                    setShowTooltip(false)
                  }, 3300)
                )
            }}
            title={String(t("details:copyLink"))}
            className="link link--no-underline"
          >
            <ChainIcon />
          </span>
        </div>
        <div>
          <ScaleSwitch
            checked={formState.is_active}
            label={formState.is_active ? String(t("table:active")) : String(t("table:inactive"))}
            onScaleChange={(event: any) => {
              setFormState({ ...formState, is_active: event.detail.value })
            }}
          />
        </div>
      </div>
      <div className="modal__actions">
        <ScaleButton
          slot="action"
          variant="secondary"
          onClick={() => onClose()}
        >
          {t("details:modalActionInterrupt")}
        </ScaleButton>
        <ScaleButton slot="action" type="submit" onClick={() => handleSubmit()}>
          {t("details:modalActionSave")}
        </ScaleButton>
      </div>
    </div>
  )
}
