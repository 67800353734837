import React, { useEffect, useRef, useState } from 'react'
import { ItemsListForSettingsPage, SettingsElement } from '../../../backend/respType'
import { ModalActions } from '../../../components/F2CFormGenerator/types'
import Backend from '../../../backend/Backend'
import BackendFactory from '../../../backend/BackendFactory'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import styles from './PageContent.module.scss'
import { ScaleButton } from '@telekom/scale-components-react'
import { F2CAccordion } from '../../../components/F2CAccordion/F2CAccordion'
import { AlertModal } from './AlertModal'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { settingsFormConfig } from '../../../env/settingsTags/tagsFormConfig'
import { segmentsFormSchema } from '../../../env/settingsTags/tagsValidationSchema'

export const Tags = () => {
    const initTag: SettingsElement = { name: '', id: '', email: '', affectedFunds: [], subList: [] }
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)
    const [currentTag, setCurrentTag] = useState<SettingsElement>(initTag)
    const [tagsList, setTagsList] = useState<ItemsListForSettingsPage>()
    const backend: Backend = BackendFactory.produce()
    const toast = useRef<Toast>(null)
    const { t } = useTranslation('SettingsPage')

    useEffect(() => {
        updateTagsList()
    }, [])
    
    const updateTagsList = (): void => {
        backend.getItemsListForSettingsPage('tags').then(resp => {
            if(resp.data) {
                setTagsList(resp)
            }else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('SettingsPage:tagListError'),
                    life: 5000,
                })
            }
        })
    }

    const openModal = (modalType: ModalActions, geoAreaContact: SettingsElement): void => {
        setModal(modalType)
        setCurrentTag(geoAreaContact)
    }

    const closeModal = () => {
        setModal(ModalActions.REMOVE)
        setCurrentTag(initTag)
    }

    const createTag = (data: SettingsElement) => {
        backend.createElementForSettingsPage('tags',{
            affectedFunds: [], id: '', 
            subList: [],
            name:data.name,
            // email: data.email,
            // parent_id: modal === 'createSub' ? data.id : undefined
        }).then(resp => {
            if (resp?.detail === 'SUCCESS') {
                updateTagsList()
                setModal(ModalActions.REMOVE)
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('SettingsPage:tagCreateSuccess'),
                    life: 5000,
                })
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:tagCreateError'), life: 5000 })
            }
        })
    }

    const editTag = (formData: SettingsElement) => {
        backend.editElementInSettingsPage('tags', formData)
            .then(resp => {
                if (resp?.detail === 'SUCCESS') {
                    updateTagsList()
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('SettingsPage:tagEditSuccess'),
                        life: 5000,
                    })
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: t('SettingsPage:tagEditError'), life: 5000 })
                }
            })
    }

    const deleteTag = (): void => {
      backend.deleteElementFromSettingsPage('tags', currentTag.id)
          .then((resp) => {
              if (resp?.detail === 'SUCCESS') {
                  updateTagsList()
                  setModal(ModalActions.REMOVE)
                  toast.current?.show({
                      severity: 'success',
                      summary: 'Success',
                      detail: t('SettingsPage:tagDeletedSuccess'),
                      life: 5000,
                  })
              } else {
                  toast.current?.show({
                      severity: 'error',
                      summary: 'Error',
                      detail: t('SettingsPage:tagDeletedError'),
                      life: 5000,
                  })
              }
          })

  }

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.settingsHead}>
                <div>
                    <h1>{t('SettingsPage:BO-Set-Item3-titel')}</h1>
                    <h2>{t('SettingsPage:BO-Set-Item3-sub')}</h2>
                </div>
                <ScaleButton
                    variant='primary'
                    onClick={() => openModal(ModalActions.CREATE, initTag)}
                >
                    {t('SettingsPage:BO-Set-Item3-new')}
                </ScaleButton>
            </div>

            {tagsList &&
              <F2CAccordion data={tagsList.data}
                            onItemEdit={item => openModal(ModalActions.EDIT, item as SettingsElement)}
                            onItemDelete={item => {
                                openModal(ModalActions.ALERT_DELETE, item as SettingsElement)
                            }}
              />}
            <AlertModal isOpen={modal === ModalActions.ALERT_DELETE || modal === ModalActions.ALERT}
                        title={t('SettingsPage:BO-Set-Item3-titel')}
                        modal={modal}
                        onClose={() => closeModal()}
                        onAccept={() => deleteTag()}
                        currentContact={currentTag}
            />
            <F2CFormGenerator isOpened={modal === ModalActions.EDIT || modal === ModalActions.CREATE}
                              modalType={modal}
                              modalTitle={modal === ModalActions.CREATE ? t('BO-Set-I3EM-titel'): t('BO-Set-I3EM-titel-edit')}
                              onClose={() => {
                                  closeModal()
                              }}
                              onValidationCreate={(formData) => {
                                  createTag(formData as SettingsElement)

                              }}
                              onEdit={(formData) => {
                                  const updatedGeoArea = {
                                      ...currentTag,
                                      ...formData
                                  }
                                  editTag(updatedGeoArea)
                              }}
                              formConfig={settingsFormConfig}
                              initialFormValues={currentTag}
                              validationSchema={segmentsFormSchema}
            />
            <Toast ref={toast} position='bottom-right' />
        </div>
    )
}