import styled from 'styled-components/macro'

import CardInner from '../../Card/__styled__/CardInner'
import ListItemInner from '../../List/ListItem/__styled__/ListItemInner'
import LinkInner from '../../Link/__styled__/LinkInner'
import { MainTheme } from '../../../theme/MainTheme'

type Props = {
  theme: any
}

const TooltipInner = styled('div')<Props>`
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  min-width: 320px;
  z-index: ${props => MainTheme.zIndex.overlay};

  @media (min-width: ${props => MainTheme.breakpoints.laptop}) {
    position: fixed;
     min-width: 360px;
    max-width: 550px;
  }

  ${CardInner} {
    position: fixed;
    left: 50%;
    width: 90%;
    top: 55%;
    user-select: none;

    max-width: 550px;
    min-width: 250px;
    transform: translate(-50%, -50%);

    @media (min-width: ${props => MainTheme.breakpoints.laptop}) {
      position: relative;
      left: auto;
      height: auto
      max-width: auto;
      transform: translateX(0);
    }
  }

  ${ListItemInner} {

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.gray.alto};
      content: '';
    }

    .radar-paragraph {
      margin: 0;

      p {
        margin-block-end: 0;
        margin-block-start: 0;
      }
    }
  }

  ${LinkInner} {
    font-size: 18px;
    text-align: right;
  }
`

export default TooltipInner