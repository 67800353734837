import React, { useEffect, useMemo, useRef, useState } from 'react'
import BreadcrumbsElem from '../../../components/breadcrumbs/breadcrumbsElem'
import '../backoffice.scss'
import { ScaleTabHeader, ScaleTabNav, ScaleTabPanel } from '@telekom/scale-components-react'
import { Fund } from '../../../model/FundNew'
import { ArchivedTable } from './archived/archived-table'
import { PublishedTable } from './published/published-table'
import { RelevantTable } from './relevant/relevant-table'
import { NewTable } from './new/new-table'
import { TableSource } from './types'
import { useHistory } from 'react-router-dom'
import ObjectUtil from '../../../utils/ObjectUtil'
import { FormatDate } from '../../../utils/FormatDate'
import { F2CFilter, FilterOption } from '../../../components/F2CFilter/F2CFilter'
import { getFundsPageFilters } from './fundsFilterConfig'
import BackendFactory from '../../../backend/BackendFactory'
import { filterCheckboxBasedOnEnv } from '../../../components/F2CFilter/commonFilterOptions'
import { useTranslation } from 'react-i18next'
import { updateUrlWithParams } from '../../../utils/updateUrlWithParams'
import { ModalActions, ModalFormConfigs } from '../../../components/F2CFormGenerator/types'
import { F2CFormGenerator } from '../../../components/F2CFormGenerator/F2CFormGenerator'
import { editFundModalSchema } from '../../../env/fundEditModal/validationSchema'
import { F2CAlertModal } from '../../../components/F2CAlertModal/F2CAlertModal'
import { Toast } from 'primereact/toast'
import { getFundModalFields, getValuesFromConfig } from '../../../env/fundEditModal/fundModalConfig'
import { BOFundDetails } from '../../../backend/respType'
import { changeFundStatus, onDuplicateFund } from './helpers'
import { FormikProps } from 'formik'
import { FundsContext } from './fundsContext'
import { FUND_STATUS } from '../../../constants/BEstatuses'

type Modal = {
    show: boolean,
    id: string,
    pageSrc: string
}


export const FundsPage = () => {

    const { t } = useTranslation()
    const firstLoad = useRef(true)
    const [formSchema, setFormSchema] = useState<ModalFormConfigs>()
    const [formInitValues, setFormInitValues] = useState<Record<string, any>>()
    const [modal, setModal] = useState<ModalActions>(ModalActions.REMOVE)
    const [activeId, setActiveId] = useState<string>()

    useEffect(() => {
        let subscribed = true
        const openEditModalByLink = () => {
            const fundId = getUrlParams('fundId')
            const pageSrc = getUrlParams('pageSrc')
            if (fundId && pageSrc && subscribed) {
                setEditModal({ show: true, id: fundId, pageSrc: pageSrc })
                openEditModal(fundId)
            }
        }
        getFundModalFields().then(configs => {
            if(subscribed) {
                const initValues = getValuesFromConfig(configs.editFormConfig)
                setFormInitValues(initValues)
                setFormSchema(configs)
                openEditModalByLink()
            }
        })
        getFundsPageFilters().then(resp => setFilterParams(resp))

        return () => {
            subscribed = false
        }
    }, [])

    const SLOT_NAMES = {
        headings: 'headings',
        content: 'content',
        tab: 'tab',
        panel: 'panel',
    }

    const getUrlParams = (param: string) => {
        const search = window.location.search
        const params = new URLSearchParams(search)
        return params.get(param)
    }
    const searchParams = new URLSearchParams(window.location.search)
    const getExistingSearchParams = () => {
        const existingSortParams = searchParams.get('order_by')
        return existingSortParams || '-fp_number'
    }

    const getCurrentTab = (): TableSource => {
        const currentTab = searchParams.get('tab')
        switch (currentTab) {
            case 'published':
            case 'archived':
            case 'relevant':
            case 'new':
                return currentTab
            default:
                return 'new'
        }
    }

    const history = useHistory()
    const [funds, setFunds] = useState<Fund[]>([])
    const [filterParams, setFilterParams] = useState<FilterOption[]>()

    const [tab, setTab] = useState<TableSource>(getCurrentTab())
    const existingParams = getExistingSearchParams()
    const [sortParams, setSortParams] = useState<string>(existingParams)
    const [editModal, setEditModal] = useState<Modal>({ show: false, id: '', pageSrc: '' })
    const [lastCrawlDate, setLastCrawlDate] = useState(t('table:unknownDate') || '')
    const backend = BackendFactory.produce()
    const urlParams = useRef('')
    const toast = useRef<Toast>(null)


    const changeSortParams = (newParams: string) => {
        setSortParams(newParams)
    }
    useEffect(()=> {
        if(!firstLoad.current) {
            getFunds(tab)
        }
        firstLoad.current = false
    }, [sortParams])

    const changeTab = (name: TableSource) => {
        if (name !== tab) {
            getFunds(name)
        }
    }

    useEffect(() => {
        searchParams.set('tab', tab)
        searchParams.set('order_by', sortParams)
        updateUrlWithParams(searchParams.toString())
    }, [sortParams, tab])

    const getFunds = (tab: TableSource, initUrlParams?: string) => {
        const params = initUrlParams ? initUrlParams : urlParams.current
        backend.getBOFundsByType(tab, params, sortParams).then(res => {
            const funds = ObjectUtil.getArrayOrThrow(res, 'funds')
            if (tab === 'new' && res.last_modified) {
                const date = FormatDate(res.last_modified)
                setLastCrawlDate(date)
            }
            setFunds(funds)
        }).then(() => setTab(tab))
    }
    const checkboxes = useMemo(() => filterCheckboxBasedOnEnv(), [])

    const openCreateModal = () => {
        if (formSchema) {
            const initValues = getValuesFromConfig(formSchema.editFormConfig)
            setFormInitValues(initValues)
            setModal(ModalActions.CREATE)
        }
    }

    const openEditModal = (id: BOFundDetails['id']) => {
        getFundDetails(id)
        setModal(ModalActions.EDIT)
    }

    const openDeleteModal = (id: BOFundDetails['id']) => {
        setActiveId(id)
        setModal(ModalActions.ALERT_DELETE)

    }

    const openArchiveModal = (id: BOFundDetails['id']) => {
        setActiveId(id)
        setModal(ModalActions.ALERT_ARCHIVE)
    }

    const getFundDetails = (id: BOFundDetails['id']) => {
        backend.getBOFundDetails(id)
            .then(resp => {
                setFormInitValues(resp)
            })
    }

    const deleteOrArchiveFund = (fundID: string) => {
        const deleteOrArchiveStatus = modal === ModalActions.ALERT_DELETE ? FUND_STATUS.DELETED : FUND_STATUS.ARCHIVED
        changeFundStatus(deleteOrArchiveStatus, fundID)
            .then(resp => {
                if (resp.detail === 'SUCCESS') {
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: modal === ModalActions.ALERT_DELETE
                            ? t('BOFundTranslationKeys:fundsDeleteTextSuccess')
                            : t('BOFundTranslationKeys:fundsArchiveTextSuccess'),
                        life: 5000,
                    })
                    setModal(ModalActions.REMOVE)
                    getFunds(getCurrentTab())
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: modal === ModalActions.ALERT_DELETE
                            ? t('BOFundTranslationKeys:fundsDeleteTextError')
                            : t('BOFundTranslationKeys:fundsArchiveTextError'),
                        life: 5000,
                    })
                }

            })
    }

    const copyFund = (id: BOFundDetails['id']) => {
        onDuplicateFund(id).then(resp => {
            if (resp.detail === 'SUCCESS') {
                getFunds(getCurrentTab())
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: t('BOFundTranslationKeys:fundCopySuccess'),
                    life: 5000,
                })
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: t('BOFundTranslationKeys:fundCopyError'),
                    life: 5000,
                })
            }
        })
    }


    const createFund = (data: BOFundDetails): void => {
        const newRelevantFundData = { ...data, fundingStatus: 'Relevante Programme' }
        BackendFactory.produce()
            .postBOFund(newRelevantFundData)
            .then((res) => {
                if (res.detail === 'SUCCESS') {
                    setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: t('BOFundTranslationKeys:fundsCreateRelevantSuccess'),
                        life: 5000,
                    })
                    getFunds(getCurrentTab())
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: t('BOFundTranslationKeys:fundsCreateRelevantError'),
                        life: 5000,
                    })
                }
            })
    }

    const editFund = (id: BOFundDetails['id'], params: any, closeAfterEdit?: 'relevant' | 'publish', formik?: FormikProps<any>) => {
        let message:string
        switch (closeAfterEdit) {
            case 'relevant':
                message = t('BOFundTranslationKeys:fundsNewRelevantSuccess')
                break
            case 'publish':
                message =  t('BOFundTranslationKeys:fundPublishRelevantSuccess')
                break
            default:
                message = t('BOFundTranslationKeys:fundsEditRelevantSuccess')
                break
        }
        backend
            .patchBOFund(params, id)
            .then((resp) => {
                if (resp?.detail === 'SUCCESS') {
                    if (closeAfterEdit) setModal(ModalActions.REMOVE)
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: message,
                        life: 5000,
                    })
                    if(formik) {
                        formik.setTouched({})
                    }
                    getFunds(getCurrentTab())
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: t('BOFundTranslationKeys:fundEditError'),
                        life: 5000,
                    })
                }
            })
    }

    const MemoF2CFormGenerator = React.memo(F2CFormGenerator);


    const memoizedFilter = useMemo(()=> {
        return (
            filterParams &&
            <div style={{ position: 'absolute', right: '0' }}>
              <F2CFilter
                onFiltering={(params) => {
                    urlParams.current = params
                    getFunds(tab || 'new')
                }}
                filters={filterParams}
                checkboxes={checkboxes}
              />
            </div>
        )
    },[filterParams, tab])

    const modalForForm = modal === ModalActions.EDIT || modal === ModalActions.CREATE
    const isRelevanceModal = getCurrentTab() === 'new'
    const getModalTitle = ():string => {
        if(tab === 'new' && formInitValues) return formInitValues.name
        if(formInitValues && formInitValues.fp_number) return `[FP ${formInitValues.fp_number}]: ${formInitValues.shortname || null}`
        return String(t('BOFundTranslationKeys:addRelevantFund'))
    }

    const memoizedFormGenerator = useMemo(() => {
        return (
            formInitValues && formSchema &&
            <MemoF2CFormGenerator
              formConfig={isRelevanceModal ? formSchema.relevanceConfig : formSchema.editFormConfig}
              initialFormValues={formInitValues}
              validationSchema={isRelevanceModal ? undefined : editFundModalSchema}
              isOpened={(modal === ModalActions.EDIT || modal === ModalActions.CREATE)}
              currentTab={getCurrentTab()}
              modalType={modal}
              modalTitle={getModalTitle()}
              onClose={() => {
                  setModal(ModalActions.REMOVE)
                  if(editModal.pageSrc && editModal.id) {
                      history.push(`/${editModal.pageSrc}/${editModal.id}`)
                  }
              }}
              onPublish={(changedFields, formData) => {
                  const publishedData = { ...changedFields, fundingStatus: 'Veröffentlichte Programme' }
                  editFund(formData.id, publishedData, 'publish')
              }}
              onCreate={(changedFields) => {
                  createFund(changedFields as BOFundDetails)
              }}
              onRelevant={(changedFields, formData) => {
                  const relevantData = { ...changedFields, fundingStatus: 'Relevante Programme' }
                  editFund(formData.id, relevantData, 'relevant')
              }}
              onEdit={(changedFields, formik) => {
                  editFund(formik.values.id, changedFields, undefined, formik)
              }}
            />
        );
    }, [formInitValues, modalForForm, modal, editModal, tab]);

    const alertModalTitle = modal === ModalActions.ALERT_DELETE ? t('BOFundTranslationKeys:alertTitleDeleteFundsText') : t('BOFundTranslationKeys:alertTitleArchiveFundsText')
    const alertModalMessage = modal === ModalActions.ALERT_DELETE ? t('BOFundTranslationKeys:alertContentDeleteFundsText') :t('BOFundTranslationKeys:alertContentArchiveFundsText')
    return (
        <FundsContext.Provider value={{
            sortParams
        }}>
            <BreadcrumbsElem
                linksArray={[
                    { href: '/', text: t('RP:RP-M1') },
                    { href: '/', text: t('RP:RP-M3'), disabled: true },
                ]}
                current={{ href: '/backoffice/funds', text: t('menu:linkFundingPrograms') }}
            >
                {memoizedFilter}
            </BreadcrumbsElem>
            <div className='funds-inner'>
                <ScaleTabNav>
                    <ScaleTabHeader
                        slot={SLOT_NAMES.tab}
                        key='header-1'
                        selected={tab === 'new'}
                        onClick={() => changeTab('new')}
                    >
                        {t('BO:BO-FP-NFP')} {tab === 'new' && `(${funds.length})`}
                    </ScaleTabHeader>
                    {tab === 'new' && (
                        <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-1'>
                            <NewTable lastCrawlDate={lastCrawlDate}
                                      funds={funds}
                                      key={'list-1'}
                                      changeSortParams={changeSortParams}
                                      getFunds={getFunds}
                                      openEditModal={openEditModal}
                                      editFund={editFund}
                                      copyFund={copyFund}
                                      openDeleteModal={openDeleteModal}
                                      openArchiveModal={openArchiveModal}
                            />
                        </ScaleTabPanel>
                    )}

                    <ScaleTabHeader
                        slot={SLOT_NAMES.tab}
                        key='header-2'
                        selected={tab === 'relevant'}
                        onClick={() => changeTab('relevant')}
                    >
                        {t('BO:BO-FP-VFP')} {tab === 'relevant' && `(${funds.length})`}
                    </ScaleTabHeader>
                    {tab === 'relevant' && (
                        <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-2'>
                            <RelevantTable funds={funds}
                                           changeSortParams={changeSortParams}
                                           key={'list-2'}
                                           getFunds={getFunds}
                                           openCreateModal={openCreateModal}
                                           openEditModal={openEditModal}
                                           editFund={editFund}
                                           copyFund={copyFund}
                                           openDeleteModal={openDeleteModal}
                                           openArchiveModal={openArchiveModal}

                            />
                        </ScaleTabPanel>
                    )}

                    <ScaleTabHeader
                        slot={SLOT_NAMES.tab}
                        key='header-3'
                        selected={tab === 'published'}
                        onClick={() => changeTab('published')}
                    >
                        {t('BO:BO-FP-RFP')} {tab === 'published' && `(${funds.length})`}
                    </ScaleTabHeader>
                    {tab === 'published' && (
                        <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-3'>
                            <PublishedTable funds={funds}
                                            getFunds={getFunds}
                                            changeSortParams={changeSortParams}
                                            key={'list-3'}
                                            openEditModal={openEditModal}
                                            editFund={editFund}
                                            copyFund={copyFund}
                                            openDeleteModal={openDeleteModal}
                                            openArchiveModal={openArchiveModal}
                            />
                        </ScaleTabPanel>
                    )}

                    <ScaleTabHeader
                        slot={SLOT_NAMES.tab}
                        key='header-4'
                        selected={tab === 'archived'}
                        onClick={() => changeTab('archived')}
                    >
                        {t('BO:BO-FP-AFP')} {tab === 'archived' && `(${funds.length})`}
                    </ScaleTabHeader>
                    {tab === 'archived' && (
                        <ScaleTabPanel slot={SLOT_NAMES.panel} key='panel-4'>
                            <ArchivedTable funds={funds}
                                           getFunds={getFunds}
                                           changeSortParams={changeSortParams}
                                           key={'list-4'}
                                           openEditModal={openEditModal}
                                           editFund={editFund}
                                           copyFund={copyFund}
                                           openDeleteModal={openDeleteModal}
                                           openArchiveModal={openArchiveModal}
                            />
                        </ScaleTabPanel>
                    )}
                </ScaleTabNav>

            </div>
            {memoizedFormGenerator}
            <F2CAlertModal isOpen={modal === ModalActions.ALERT_DELETE || modal === ModalActions.ALERT_ARCHIVE}
                           title={alertModalTitle}
                           message={alertModalMessage}
                           buttonConfirmText={modal === ModalActions.ALERT_DELETE ? t('BO:BO-FP-NFPB1') : t('BO:BO-FP-RFPB4')}
                           onClose={() => setModal(ModalActions.REMOVE)}
                           onAccept={() => {
                                if (activeId) deleteOrArchiveFund(activeId)
                            }}
            />
            <Toast ref={toast} position='bottom-right' />
        </FundsContext.Provider>
    )
}
