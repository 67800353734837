import { t } from 'i18next'
import { FormField, MultiselectOptions } from '../../model/form-field'
import { ModalFormConfig, ModalFormConfigs } from '../../components/F2CFormGenerator/types'
import BackendFactory from '../../backend/BackendFactory'
import Office from '../../model/Office'
import { HIGHLIGHT } from '../../constants/BEstatuses'
import { Fund } from '../../model/FundNew'
import { radarCheckboxes, radarCheckboxesEU } from '../../components/F2CFilter/commonFilterOptions'
import getEnvConfig from '../../backend/config'
import { Flags } from '../../model/FundMapped'
import { getCleanedSegmentsList, getStreamList } from '../../helpers/dataForFormElements'
import { MATURITY0, MATURITY1, MATURITY2, MATURITY3, MATURITY4 } from '../../components/Radar/RadarRenderer'
import { isGermanEnv } from '../envBased'

const currentConfig = getEnvConfig()
export const getCheckboxes = (fund?: Fund):FormField[] => {
    const configRelatedCheckboxes = currentConfig.APP_ENV === 'EU-prod' ? radarCheckboxesEU : radarCheckboxes
    return configRelatedCheckboxes.reduce((acc:FormField[], checkbox) => {

        const fundValue = fund ? fund.info[checkbox.urlParam as keyof Flags] : false
        acc.push({value: fundValue, label: checkbox.label, dataField: checkbox.urlParam, info: true, inputType: 'toggle', mandatory: false, img: checkbox.imgUrl })
        return acc
    },[] )
}

export const chooseOption = t("BOFundTranslationKeys:chooseOption")
export const INTERNAL_STATUS_OPTIONS = [
    { value:chooseOption, name: chooseOption },
    { value: 'neu', name: t("BOFundTranslationKeys:neu")  },
    { value: 'in Bearbeitung', name: t("BOFundTranslationKeys:inBearbeitung")  },
    { value: 'zur Bearbeitung', name: t('BOFundTranslationKeys:statusOptionForProcessing')},
    { value: 'Rückfrage', name: t("BOFundTranslationKeys:Rückfrage")  },
    { value: 'zur Veröffentlichung', name: t("BOFundTranslationKeys:zurVeröffentlichung")  },
    { value: 'extern-fertig', name: t("BOFundTranslationKeys:extern-fertig")  },
    { value: 'veröffentlicht',name: t('BOFundTranslationKeys:statusOptionPublished')},
    { value: 'nicht relevant', name: t("BOFundTranslationKeys:nichtrelevant")  },
]
    // ['neu', 'in Bearbeitung', 'Rückfrage', 'zur Veröffentlichung', 'extern-fertig', 'nicht relevant']
export const STATUS_OPTIONS = [
    { value:chooseOption, name: chooseOption },
    { value: HIGHLIGHT.NO_CHANGE, name: t("BOFundTranslationKeys:KeineÄnderung") },
    { value: HIGHLIGHT.NEW_FUNDING_PROGRAM, name: t("BOFundTranslationKeys:NeuesFörderprogramm")  },
    { value: HIGHLIGHT.CONTENT_UPLOADED, name: t("BOFundTranslationKeys:Inhalteaktualisiert")  },
    { value: HIGHLIGHT.NOTE_DEADLINES, name: t("BOFundTranslationKeys:Fristenbeachten")  }
]
export const MATURITY_OPTIONS = [
    { value:chooseOption, name: chooseOption },
    { value: MATURITY0, name: t("BOFundTranslationKeys:Ressortzuteilung") },
    { value: MATURITY1, name: t("BOFundTranslationKeys:Förderrichtlinie") },
    { value: MATURITY2, name: t("BOFundTranslationKeys:Notifizierung") },
    { value: MATURITY3, name: t("BOFundTranslationKeys:Ausschreibung")},
    { value: MATURITY4, name: t("BOFundTranslationKeys:Zuteilung") }
]
export const ONEPAGER_OPTIONS = [
    { value:chooseOption, name: chooseOption },
    { value:'nicht erforderlich', name: t("BOFundTranslationKeys:nichterforderlich") },
    { value: 'erforderlich', name: t("BOFundTranslationKeys:erforderlich") },
    { value: 'angefordert', name: t("BOFundTranslationKeys:angefordert") },
    { value: 'vorhanden', name: t("BOFundTranslationKeys:vorhanden") }
]


const getGeoAreasList = async ():Promise<MultiselectOptions[]> => {
    const backend = BackendFactory.produce();
    return await backend.getGeoAreaList().then(resp => {
        return resp.data
    })
}

const getTagsList = async ():Promise<MultiselectOptions[]> => {
    const backend = BackendFactory.produce();
    return await backend.getTagsList().then(resp => {
        return resp.data
    })
}

const getPortfolioList = async ():Promise<MultiselectOptions[]> => {
    const backend = BackendFactory.produce();
    return await backend.getPortfolioList().then(resp => {
        return resp.data
    })
}

const getOfficeList = async ():Promise<Office[]> => {
    const backend = BackendFactory.produce();
    const offices = await backend.getBOOffices()
    return offices.map(office => office)
}
const checkboxes = getCheckboxes()


export const getFundModalFields = async ():Promise<ModalFormConfigs> => {
    const geoData = await getGeoAreasList()
    const tagsData = await getTagsList()
    const streamData = await getStreamList()
    const officeData = await getOfficeList()
    const segmentsData = await getCleanedSegmentsList()
    const portfolioData = await getPortfolioList()
    // const germanFields: ModalFormConfig = {
    //
    // }
    const editFormConfig:ModalFormConfig = {
        lines: [
            {
                index: 1,
                columns: [
                    {
                        colIndex:1,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:name"), dataField: 'name', inputType: 'text'},
                        ],
                    },
                ],
            },
            {
                index: 2,
                columns: [
                    {
                        colIndex:2,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:shortName"), dataField: 'shortname', inputType: 'text'},
                        ],
                    },
                    {
                        colIndex:3,
                        columnElements: [
                            {value: null, label: t("BOFundTranslationKeys:furtherLink"), dataField: 'url', inputType: 'text'},
                        ],
                    },
                ],
            },
            {
                index: 3,
                columns: [
                    {
                        colIndex:4,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:fundStatus"), dataField: 'status', inputType: 'dropdown', options: STATUS_OPTIONS},
                        ],
                    },
                    {
                        colIndex:5,
                        columnElements: [
                            {value: '', label: t("BO:BO-FP-NFPC5"), dataField: 'internalStatus', inputType: 'dropdown', options: INTERNAL_STATUS_OPTIONS},
                        ],
                    },
                ],
            },
            {
                index: 4,
                columns: [

                    {
                        colIndex:6,
                        columnElements: [
                            {value: null, label: t("details:validity"), dataField: 'validity', inputType: 'title'},
                            {value: null, label: t("BOFundTranslationKeys:creationDate"), dataField: 'creationDate', inputType: 'date'},
                        ],
                    },
                    {
                        colIndex:7,
                        columnElements: [
                            {value: null, label: t("details:deadlines"), dataField: 'deadlines', inputType: 'title'},
                            {value: null, label: t("BOFundTranslationKeys:startApplicationPeriod"), dataField: 'startApplicationPeriod', inputType: 'date'},
                        ],
                    },
                    {
                        colIndex:8,
                        columnElements: [
                            {value: null, label: t("details:streamAndPhase"), dataField: 'streamAndPhase', inputType: 'title'},
                            {value: '', label: t("BOFundTranslationKeys:primaryStream"), dataField: 'primaryStream', inputType: 'groupedDropdown', options: streamData},
                        ],
                    },
                    {
                        colIndex:9,
                        columnElements: [
                            {value: null, label: t("details:headAndVolume"), dataField: 'headAndVolume', inputType: 'title'},
                            {value: '', label: t("BOFundTranslationKeys:fundingAmount"), dataField: 'fundingAmount', inputType: 'text'},
                        ],
                    },
                ],
            },
            {
                index: 5,
                columns: [

                    {
                        colIndex:10,
                        columnElements: [
                            {value: null, label: t("BOFundTranslationKeys:endDate"), dataField: 'endDate', inputType: 'date'},
                        ],
                    },
                    {
                        colIndex:11,
                        columnElements: [
                            {value: null, label: t("BOFundTranslationKeys:deadline"), dataField: 'deadline', inputType: 'date'},
                        ],
                    },
                    {
                        colIndex:12,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:maturity"), dataField: 'maturity', inputType: 'dropdown', options: MATURITY_OPTIONS},
                        ],
                    },
                    {
                        colIndex:13,
                        columnElements: [
                            {value: null, label: t("BOFundTranslationKeys:fundingVolume"), dataField: 'fundingVolume', inputType: 'number', numberInput: true},
                        ],
                    },
                ],
            },
            {
                index: 6,
                columns: [
                    {
                        colIndex:14,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:internalComment"), dataField: 'internalComment', inputType: 'textarea'},
                        ]
                    }
                ]
            },
            {
                index: 7,
                columns: [
                    {
                        colIndex:14,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:news"), dataField: 'news', inputType: 'richText', maxLength: 140, richTextCustomHeight: 80},
                        ]
                    }
                ]
            },
            {
                index: 8,
                columns: [
                    {
                        colIndex:15,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:summary"), dataField: 'summary', inputType: 'richText', maxLength: 140},
                            {value: '', label: t("BO:BO-FP-NFPC2"), dataField: 'fundingObjectType', inputType: 'text', maxLength: 140},
                            {value: '', label: t("BOFundTranslationKeys:targetGroup"), dataField: 'targetGroup', inputType: 'text', maxLength: 140},
                            {value: '', label: t("BOFundTranslationKeys:crawled_office"), dataField: 'crawled_office', inputType: 'textarea', disabled: true},
                            {value: '', label: t("BOFundTranslationKeys:office"), dataField: 'office', inputType: 'objectDropdown', options: officeData},
                            {value: '/backoffice/offices', label: t("details:addContactPoint"), dataField: 'link', inputType: 'link'},
                            {value: [], label: t("F:RP-F-FPArea"), dataField: 'geoArea', inputType: 'multiselect',multiselectOptions: geoData},
                            {value: '', label: t("BOFundTranslationKeys:fundingSponsor"), dataField: 'fundingSponsor', inputType: 'text'},
                        ]
                    },
                    {
                        colIndex:16,
                        columnElements: [
                            {value: '', label: t("FP:RP-FP-DescTitel"), dataField: 'description', inputType: 'richText'},
                            {value: '', label: t("BOFundTranslationKeys:onePager"), dataField: 'onePager', inputType: 'dropdown', options: ONEPAGER_OPTIONS},
                            {value: null, label: t("BOFundTranslationKeys:YAMLink"), dataField: 'YAMLink', inputType: 'text'},
                            isGermanEnv ?
                                {value: '', label: t("BOFundTranslationKeys:relatedPortfolioProducts"), dataField: 'related_portfolio_products', inputType: 'multiselect', multiselectOptions: portfolioData}
                                :
                                {value: '', label: `${t("FP:RP-FP-Portfolio")} ${t("BOFundTranslationKeys:optional")}`, dataField: 'relatedPortfolio', inputType: 'richText', richTextCustomHeight: 80},
                            {value: null, label: t("BOFundTranslationKeys:link2fpdb"), dataField: 'link2fpdb', inputType: 'text'},
                            {value: '', label: t("BOFundTranslationKeys:segment"), dataField: 'segment', inputType: 'multiselect', multiselectOptions: segmentsData},
                            {value: null, label: t("BOFundTranslationKeys:furtherLinks"), dataField: 'links', inputType: 'chips'},                    ]
                    }
                ]
            },
            {
                index: 9,
                columns: [
                    {
                        colIndex:17,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:fundingAmountDetails"), dataField: 'fundingAmountDetails', inputType: 'richText'},
                            {value: '', label: t("FP:RP-FP-Type"), dataField: 'fundingType', inputType: 'text'},
                            {value: '', label: t("BOFundTranslationKeys:fundingTypeDetailsLongText"), dataField: 'fundingTypeDetails', inputType: 'richText'},

                        ]
                    },
                    {
                        colIndex:18,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:fundingProcess"), dataField: 'fundingProcess', inputType: 'richText'},
                            {value: '', label: t("BOFundTranslationKeys:requirements"), dataField: 'requirements', inputType: 'richText'},
                            ...checkboxes
                        ]
                    },
                ]
            },
            {
                index:10,
                columns: [
                    {
                        colIndex:19,
                        columnElements: [
                            {value: t('details:modalHint'), label: t('details:modalSubheading'), dataField: 'textElement', inputType: 'textElement'},
                        ]
                    }
                ]
            },
            {
                index: 11,
                columns: [
                    {
                        colIndex:20,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:shortText_simple"), dataField: 'shortText_simple', inputType: 'richText', richTextCustomHeight: 80},
                            {value: '', label: t("BOFundTranslationKeys:longText"), dataField: 'longText', inputType: 'richText', richTextCustomHeight: 80},

                        ]
                    },
                    {
                        colIndex:21,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:quickchecktype"), dataField: 'quick_check_type', inputType: 'text'},
                            {value: '', label: t("BOFundTranslationKeys:tags"), dataField: 'tags', inputType: 'text'},
                            {value: [], label: t("BOFundTranslationKeys:tags_added"), dataField: 'tags_added', inputType: 'multiselect', multiselectOptions: tagsData},
                            {value: '', label: t("BOFundTranslationKeys:SIAInfo"), dataField: 'SIAIno', inputType: 'text'},
                        ]
                    }
                ]
            }
        ],
    }
    const relevanceConfig:ModalFormConfig = {
        lines: [
            {
                index: 1,
                columns: [
                    {
                        colIndex:1,
                        columnElements:[
                            {value: '', label: t("BOFundTranslationKeys:name"), dataField: 'name', inputType: 'text', disabled:true},
                            {value: '', label: t("BOFundTranslationKeys:targetGroup"), dataField: 'targetGroup', inputType: 'text', maxLength: 140, disabled:true},
                            {value: '', label: t("F:RP-F-FPArea"), dataField: 'geoArea', inputType: 'textarea',disabled:true},
                            {value: '', label: t("BOFundTranslationKeys:office"), dataField: 'office', inputType: 'textarea', disabled:true},
                            {value: '', label: t("FP:RP-FP-Type"), dataField: 'fundingType', inputType: 'text', disabled:true}

                        ]
                    },
                    {
                        colIndex:2,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:summary"), dataField: 'summary', inputType: 'richText', disabled: true},
                        ]
                    }
                ]
            },
            {
                index:2,
                columns: [
                    {
                        colIndex:3,
                        columnElements:[
                            {value: '', label: t("BOFundTranslationKeys:requirements"), dataField: 'requirements', inputType: 'richText', disabled:true},
                            {value: '', label: t("BOFundTranslationKeys:longText"), dataField: 'longText', inputType: 'richText', disabled: true},

                        ]
                    },
                    {
                        colIndex:4,
                        columnElements: [
                            {value: '', label: t("BOFundTranslationKeys:guideline"), dataField: 'guideline', inputType: 'richText', disabled: true},
                            {value: '', label: t("BOFundTranslationKeys:internalComment"), dataField: 'internalComment', inputType: 'textarea'},

                        ]
                    }
                ]
            }
        ]
    }
    return { editFormConfig, relevanceConfig }
}



export const getValuesFromConfig = (formConfig: ModalFormConfig):Record<string, any> => {
    const result:Record<string, any> = {}
    formConfig.lines.forEach((line) => {
        line.columns.forEach((column) => {
            column.columnElements.forEach((element) => {
                result[element.dataField as string] = element.value;
            });
        });
    });
    return result
}