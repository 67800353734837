import React, {ReactNode} from "react";
import FundMapped from "../../model/FundMapped";
import DetailHyperlink from "../../model/DetailHyperlink";
import ObjectUtil from "../../utils/ObjectUtil";
import CardsCol from "../../pages/allocation/__styled__/CardsCol";
import {ScaleCard, ScaleTable} from "@telekom/scale-components-react";
import Span from "../Span";
import SortingIcons from '../../components/SortingIcons/SortingIcons'
import TableMobileView from "../../pages/allocation/__styled__/TableMobileView";
import WidthDependent, {BreakpointDependentRenderer as BreakpointRenderer} from "../WidthDependent/WidthDependent";
import {BREAKPOINTS} from "../../theme/styles/Breakpoints"

import { withTranslation, Translation } from 'react-i18next'
import i18next from "i18next";

import './data-table.scss'
import {ReactComponent as DefaultIcon} from "../../icons/default-fund.svg";
import {ReactComponent as DeadlineIcon} from "../../icons/deadline-fund.svg";
import {ReactComponent as UpdateIcon} from "../../icons/update-fund.svg";
import {ReactComponent as NewIcon} from "../../icons/new-fund.svg";
import {ReactComponent as ErrorIcon} from "../../icons/error_placeholder.svg";
import { updateUrlWithParams } from '../../utils/updateUrlWithParams'
import { PhaseTranslation } from '../../utils/phaseTranslation'
import { FUND_STATUS, HIGHLIGHT } from '../../constants/BEstatuses'

interface Props {
    data: FundMapped[],
    onSortingAction?: (params: string) => void,
    streamMode?: boolean,
    streamRows?: ReactNode,
    streamHeader?: ReactNode,
    currentStream?: string,
    isOnSearchMode?: boolean,
    onInit: (params:string)=> void
}

interface State {
    params: string,
    showTooltip?: string
}

class DataTable extends React.Component<Props, State> {
    searchParams = new URLSearchParams(window.location.search)
    DEFAULT_SORT_PARAM = '+name'

    currentOrderBy:string
    constructor(props: Props) {
        super(props);

        this.currentOrderBy = this.searchParams.get('order_by') || this.DEFAULT_SORT_PARAM
        this.onSortingAction = this.onSortingAction.bind(this)
        this.onInit = this.onInit.bind(this)
    }

    componentDidMount() {
        this.onInit(this.currentOrderBy)
    }

    public renderCard(fund: FundMapped): JSX.Element {
        const { id, name, primaryStream, deadline, maturity, geoArea, status, fundingAmount } = fund
        const hyperlink = DetailHyperlink.createHyperlinkForDetailPage(fund.id)
        const key = `_${id}`
        const lastModifiedDateString = deadline && ObjectUtil.formatDateObj(deadline)

        return (
            <CardsCol key={key}>
                <ScaleCard className='card card-is-default' style={{overflow: "visible"}}>
                    <div className="card-inner-layout">
                        <div className="card-inner-layout__left">
                            <div className="publication-item publication-item--icon" style={{ display: 'inline-block' }}>
                                {DataTable.renderStatusDiamond(status)}
                            </div>
                        </div>
                        <div className="card-inner-layout__right">
                            <Span isBlock className='bold  scl-font-variant-heading-5'>
                                {name}</Span>
                            <Span isBlock className="card-mobile-label"><Translation>{ (t) => t("details:tableStream")}</Translation></Span>
                            <Span isBlock className='thin scl-font-variant-body'>{primaryStream}</Span>

                            {this.props.isOnSearchMode && <>
                                <Span isBlock className="card-mobile-label"><Translation>{ (t) => t("details:tableFundingAmount")}</Translation></Span>
                                <Span isBlock className='thin scl-font-variant-body'>{fundingAmount}</Span>
                            </>
                            }

                            <Span isBlock className="card-mobile-label"><Translation>{ (t) => t("details:tablePhase")}</Translation></Span>
                            <Span isBlock className='thin scl-font-variant-body'>{maturity}</Span>

                            {geoArea ?
                                <>
                                    <Span isBlock className="card-mobile-label"><Translation>{ (t) => t("F:RP-F-FPArea")}</Translation></Span>
                                    <Span isBlock className='thin scl-font-variant-body'>{geoArea}</Span>
                                </>
                                : <></>}
                            {lastModifiedDateString ?
                                <>
                                    <Span isBlock className="card-mobile-label"><Translation>{ (t) => t("details:tableLastUpdate")}</Translation></Span>
                                    <Span isBlock className='thin scl-font-variant-body'>{lastModifiedDateString}</Span>
                                </>
                                : <></>}
                            <div className="row row--last">
                                <a className="link link--no-underline" href={hyperlink.href} target='_self'
                                    rel="noreferrer">{hyperlink.title}</a>
                            </div>
                        </div>
                    </div>
                </ScaleCard>
            </CardsCol>
        )
    }

    public renderTableRows(basicFunds: FundMapped[]): ReactNode {
        return basicFunds.map((item, i) => {
            const hyperlink = DetailHyperlink.createHyperlinkForDetailPage(item.id)
            return <tr id={item.id} key={item.id + i + '_key'}>
                 <td className='column column--small'>
                    <div className="publication-item publication-item--icon" style={{ display: 'inline-block' }}>
                        {DataTable.renderStatusDiamond(item.status)}
                    </div>
                </td>
                <td className='fund-name column column--large'>
                    {item.fundingStatus === FUND_STATUS.ARCHIVED && <span className='archive-tag'>Archive</span>}

                    <a className='link'
                       title={item.name}
                       href={hyperlink.href} target='_self'
                       rel='noreferrer'>{item.name}</a>
                </td>
                <td className='stream-name column column--medium'>{item.primaryStream}</td>
                {this.props.isOnSearchMode && <td className='column column--medium'>{item.fundingAmount}</td>}
                <td className='column column--medium'>{item.maturity && PhaseTranslation(item.maturity)}</td>
                <td className='teritorial-coverage column column--medium'>
                    <div style={{display:'flex', flexDirection:'column', gap:'12px'}}>
                        {item.geoArea && item.geoArea.map(area =>
                            <div key={area.id} className="publication-item publication-item--geo bold">
                                {area.name}
                            </div>
                        )}
                    </div>

                </td>
                <td className='aplication-period column column--medium'>{item.deadline && ObjectUtil.formatDateObj(item.deadline)}</td>
            </tr>
        });
    }

    public renderTableHeader(): ReactNode {
        return <tr>
            <th className='status-column column column--small' >
                <SortingIcons sortParamName='status' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:status")}</Translation></span>
            </th>
            <th className='fund-name column column--large'>
                <SortingIcons sortParamName='name' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:fundingName")}</Translation></span>
            </th>
            <th className='stream-name column column--medium'>
                <SortingIcons sortParamName='primaryStream' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:primaryStream")}</Translation></span>
            </th>
            {this.props.isOnSearchMode
            && <th className=' column column--medium'>
                <SortingIcons sortParamName='fundingAmount' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:fundingAmount")}</Translation></span></th>}
            <th className=' column column--medium' >
                <SortingIcons sortParamName='maturity' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("BO:BO-FP-RFPPhase")}</Translation></span>
            </th>
            <th className='teritorial-coverage column column--medium' title={String(i18next.t("details:geoArea"))}>
                <SortingIcons sortParamName='geoArea' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("F:RP-F-FPArea")}</Translation></span></th>
            <th className='aplication-period column column--medium' title={String(i18next.t("details:deadline"))}>
                <SortingIcons sortParamName='deadline' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("F:RP-D-FPDeadline")}</Translation></span>
            </th>
        </tr>
    }
    public renderMobileView(cards: JSX.Element[]) {
        return (
            <TableMobileView>
                {cards}
            </TableMobileView>
        )
    }
    public renderDesktopView(headerRows: ReactNode, tableRows: ReactNode) {
        return (
            <ScaleTable size={"medium"} className="funds-table" style={{ overflow: "visible" }}>
                <table>
                    <thead>
                        {headerRows}
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </ScaleTable>
        )
    }
    public errorNotification() {
        return <div className='error-notification'>
            <ErrorIcon width="64" height="64" />
            <div className=' bold'>
                <Translation>{ (t, { i18n }) => <p>{t("details:nothingFound")}!</p>}</Translation>
            </div>
            <div className=''>
                <Translation>
                    { (t, { i18n }) => <p>{t("details:noGrantsToCurrentFilter")}!</p>}
                </Translation>
            </div>
        </div>
    }

    private static renderStatusDiamond(status: string | undefined): ReactNode {

        if (status === HIGHLIGHT.NO_CHANGE || status === undefined) {
            return <DefaultIcon />
        } else if (status === HIGHLIGHT.NOTE_DEADLINES) {
            return <DeadlineIcon />
        } else if (status === HIGHLIGHT.CONTENT_UPLOADED) {
            return <UpdateIcon />
        } else if (status === HIGHLIGHT.NEW_FUNDING_PROGRAM) {
            return <NewIcon />
        }
    }



    onSortingAction(orderBy: string) {
        this.currentOrderBy = orderBy
        const paramString = `order_by=${orderBy}`
        this.handleUrlParams(orderBy)
        this.props.onSortingAction && this.props.onSortingAction(paramString)
    }

    onInit(orderBy: string) {
        this.currentOrderBy = orderBy
        const paramString = `order_by=${orderBy}`
        this.props.onInit && this.props.onInit(paramString)
    }

    handleUrlParams(orderBy: string):void {
        const searchParams =  new URLSearchParams(window.location.search)
        if(orderBy === this.DEFAULT_SORT_PARAM) searchParams.delete('order_by')
        else searchParams.set('order_by',orderBy)
        updateUrlWithParams(searchParams.toString())
    }

    render() {
        const { streamMode, streamRows, streamHeader, data } = this.props;

        /* breakpoints */
        const breakpointRenderers: BreakpointRenderer[] = [
            {
                breakpoint: 0, renderer: () => {
                    return this.renderMobileView(this.props.data.map(fund => this.renderCard(fund)))
                }
            },
            {
                breakpoint: BREAKPOINTS.tabletMax + 1, renderer: () => {
                    return this.renderDesktopView(this.renderTableHeader(), this.renderTableRows(this.props.data))
                }
            },
            {
                breakpoint: BREAKPOINTS.desktop + 1, renderer: () => {
                    return this.renderDesktopView(this.renderTableHeader(), this.renderTableRows(this.props.data))
                }
            },
        ]
        return (
            <div className="data-table-container">
                {streamMode ? this.renderDesktopView(streamHeader, streamRows)
                    : (data.length > 0 || this.props.data.length > 0) ? <WidthDependent renderers={breakpointRenderers} /> : this.errorNotification()}
            </div>
        )

    }
}

export default withTranslation("details")(DataTable)