import { useTranslation } from 'react-i18next'
import { FormField } from '../../../../model/form-field'
import React, { useEffect, useState } from 'react'
import { ScaleTextarea, ScaleTextField, ScaleButton } from '@telekom/scale-components-react'
import BackendFactory from '../../../../backend/BackendFactory'
import { StreamsFormState } from './form-types'


interface Props {
    content: FormField[],
    onClose: (type?:string)=> void,
    onSubmit: (type?:string)=> void,
    streams: string[],
    model?: StreamsFormState,
    type: string,
    index?: number
}

export const serverErrorResponse = {
    unnamed: 'something wrong',
    nameExist: 'There is stream with the same name.',
    empty: 'empty'
}

export const CreateStreamForm = ({content, onClose, onSubmit, streams, model, type}: Props) => {
    const { t } = useTranslation()
    const backend =  BackendFactory.produce()
    const initialState: StreamsFormState  = {
        name: model?.name || '',
        id: model?.id || '',
        primaryStreamId: model?.primaryStreamId || '',
        sub_streams: model?.sub_streams || [],
        has_sub_streams: model?.has_sub_streams,
        keywords: model?.keywords
    }

    const [formState, setFormState] = useState(initialState)
    const [hasError, setHasError] = useState({error: false, field:'', desc: ''})
    const [left, setLeft] = useState(initialState.name.length)

    const handleChange = (event: any, name: string) =>{
        switch (name) {
            case "Name":
                setFormState({...formState, name: event.target.value.trim()})
                break;
            case "Keywords":
                setFormState({...formState, keywords: event.target.value.split(",")})
                break;
        }

        setLeft(event.target.value.length)
        
    }

    useEffect(()=> {
        setFormState({
            name: formState.name,
            id: formState.id,
            primaryStreamId: formState.primaryStreamId,
            sub_streams: formState.sub_streams,
            has_sub_streams: formState.has_sub_streams,
            keywords: formState.keywords
        })
    }, [
        formState.name,
        formState.id,
        formState.primaryStreamId,
        formState.sub_streams,
        formState.has_sub_streams,
        formState.keywords
    ])



    const getErrorMessage = ():string => {
        switch (hasError.desc) {
            case serverErrorResponse.nameExist:
                return t('BOFundTranslationKeys:validationStreamName')
            case serverErrorResponse.empty:
                return t('BOFundTranslationKeys:validationNotFilled')
            default:
                return t('BOFundTranslationKeys:validationError')
        }
    }

    const handleSubmit = () => {
        const errorCondition = (res: any) => {
            return (typeof (res) === 'object' && res?.detail === 'error') || res === undefined
                || res?.detail === serverErrorResponse.nameExist || res?.detail === serverErrorResponse.unnamed
        }
        let validated = true

        if (formState.name !== '') {
            switch (type){
                case 'create':
                    backend.postStream(formState.name, formState.keywords!).then(res => {
                        if (errorCondition(res)) {
                            setHasError({error: true, field: 'Name', desc: res.detail})
                            validated = !errorCondition(res)
                        }
                    }).catch( reason => {
                        setHasError({error: true, field: 'name', desc: reason.detail})
                        console.error(reason)
                    }).finally( ()=> validated && onSubmit())
                break
                case 'edit':
                    formState.id && backend
                        .patchStream(formState.name,formState.id, formState.keywords ? formState.keywords! : null)
                        .then(res => {
                        if (errorCondition(res)) {
                            setHasError({error: true, field: 'Name', desc: res.detail})
                            validated = !errorCondition(res)
                        }
                    }).catch( reason => {
                        setHasError({error: true, field: 'name', desc: reason.detail})
                    }).finally( ()=> validated && onSubmit())
                break
                case 'create-sub-stream':
                    formState.primaryStreamId && backend
                        .postSubStream(formState.name, formState.primaryStreamId, formState.keywords!)
                        .then(res => {
                        if (errorCondition(res)) {
                            setHasError({error: true, field: 'Name', desc: res.detail})
                            validated = !errorCondition(res)
                        }
                    }).catch( reason => {
                        setHasError({error: true, field: 'name', desc: reason.detail})
                        console.error(reason)
                    }).finally( ()=> validated && onSubmit())
                break
                case 'edit-sub-stream':
                    formState.primaryStreamId && formState.id && backend
                        .patchStream(formState.name, formState.id,formState.keywords ? formState.keywords! : null)
                        .then(res => {
                        if (errorCondition(res)) {
                            setHasError({error: true, field: 'Name', desc: res.detail})
                            validated = !errorCondition(res)
                        }
                    }).catch( reason => {
                        setHasError({error: true, field: 'name', desc: reason.detail})
                        console.error(reason)
                    }).finally( ()=> validated && onSubmit())
                break
            }

        } else {
            if (formState.name === ''){
                setHasError({error: true, field: 'Name', desc: serverErrorResponse.empty})
            }
        }
    }

    const inputFields = (fields: FormField[]) => {
        let value: any
        fields.forEach( field => {
            switch (field.label) {
                case 'Name':
                    value = formState.name
                    break;
                case "Keywords":
                    value = formState.keywords
                    break;
            }
        })
        return fields.map( (field, i) => 
            <div key={field.label}
                className={ (hasError.error && hasError.field === field.label)
                    ? `form-field invalid` : 'form-field'}
            >
                { field.label === "Name" && 
                    <>
                        <ScaleTextField  key={`${field.label}_${i}`} inputAutofocus
                                    maxLength={29}
                                    value={value} size={'small'}
                                    onScaleChange={e => handleChange(e, field.label)}
                        />
                        <span className="form-field__limit">{left}/29</span>
                    </>
                }
                { field.label === "Keywords" && 
                    <>
                        <ScaleTextarea
                            key={`${field.label}`}
                            label={field.label}
                            value={value}
                            onScaleChange={e => handleChange(e, field.label)}
                            // disabled={true}
                        />
                    </>
                }
                {hasError.error && hasError.field === field.label &&
                    <div className='validation-error'>
                        {getErrorMessage()}
                    </div>
                    }
            </div>)
    }


    const customContent = (fields: FormField[]) => {

        const fieldsAsTitleInput = fields.filter( item => item.label === 'Name')
        const fieldsAsKeywords = fields.filter( item => item.label === 'Keywords')
        const streamsAmount =  streams.length
        const subStreamsAmount = formState.sub_streams?.length || 0


        return <>
            {streamsAmount >= 0 && streamsAmount < 10 && type === 'create'
            && <div className="modal__sub-title">{t("BOFundTranslationKeys:create10Streams", {streamsAmount: streamsAmount + 1})}</div>}
            {subStreamsAmount >= 0 && subStreamsAmount < 9 && type === 'create-sub-stream'
            && <div className="modal__sub-title">{t("BOFundTranslationKeys:createFrom9Topics", {subStreamsAmount: subStreamsAmount + 1})}</div>}

            {type ==='create' && streamsAmount === 10
            && <div className="modal__sub-title--alert">{t("BOFundTranslationKeys:maximumStreamsNotificationText")}</div>}
            {type ==='create-sub-stream' && subStreamsAmount === 9
            && <div className="modal__sub-title--alert">{t("BOFundTranslationKeys:maxTopics")}</div>}

            <div className="content-wrapper">

                {inputFields(fieldsAsTitleInput)}
                {inputFields(fieldsAsKeywords)}


            </div>
            <div className="modal__actions">
                <ScaleButton slot="action" variant="secondary" onClick={()=>onClose && onClose()} >
                    {t("details:modalActionInterrupt")}
                </ScaleButton>
                <ScaleButton slot="action" type='submit' onClick={ ()=> handleSubmit()}>
                    {t("details:modalActionSave")}
                </ScaleButton>
            </div>
        </>
    }

    return customContent(content)
}