import React from 'react'
import { ScaleCard } from '@telekom/scale-components-react'

import { Translation } from 'react-i18next'

import NumberFormatter from '../../utils/NumberFormatter'
import Section from '../../components/Section'
import Span from '../../components/Span'
import CardsCol from './__styled__/CardsCol'
import CardsInner from './__styled__/CardsInner'
import { NON_BREAKING_SPACE } from '../../theme/constants/NonBreakingSpace'


interface Props {
  count: number
  fundingVolume: number
}


export default class AllocationStats extends React.Component<Props, any> {
  componentDidMount() {
    // instead of just displaying static numbers, this will count up towards the number of e.g. funding programs
      AllocationStats.startCountAnimation(this.props.count, this.props.fundingVolume)
  }

  private static startCountAnimation(programCount: number, fundingVolume: number) {
    let styledFundingVolume = NumberFormatter.prettifyBigNumber(fundingVolume)

    // remove thousands-separator, so turn 3.800 to 3800 to count properly
    if (styledFundingVolume.indexOf(".") !== undefined) {
      styledFundingVolume = styledFundingVolume.replace(".", "")
    }

    // split 3 mrd. into the number and the corresponding string
    const fundingVolumeStringAndNumber = styledFundingVolume.split(" ")
    let fundingVolumeString = "€"

    if (fundingVolumeStringAndNumber[1]) {
      fundingVolumeString = " " + fundingVolumeStringAndNumber[1] + `.${NON_BREAKING_SPACE}€`
    }
    const fundingVolumeNumber = +fundingVolumeStringAndNumber[0]

    if(programCount > 0) {
      AllocationStats.makeCountAnimation("fundingProgramsCount", 0, programCount, 3500)
    }
    if(fundingVolumeNumber > 0) {
      setTimeout(() => {
        AllocationStats.makeCountAnimation("fundingVolumeValue", 0, fundingVolumeNumber, 3500, fundingVolumeString)
      }, 500);
    }
  }

  private static makeCountAnimation(id: string, start: number, end: number, duration: number, label?: string) {
    const range = end - start;
    let current = start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));
    const obj = document.getElementById(id);
    const timer = setInterval(function () {
      current += increment;
      if (obj) {
        obj.innerHTML = label ? current + label : current + ""
      }
      if (current === end) {
        clearInterval(timer);
      }
    }, stepTime);
  }

  render() {
    return (
      <Section isContent hasSpacingBottom>
        <CardsInner>
          <CardsCol>
            <ScaleCard className='card card-is-default'>
              <Translation>
                {(t) => <Span isBlock className='thin scl-font-variant-body'>{t("details:numberOfFundingPrograms")}</Span>}
              </Translation>
              
              <Span isBlock className='extrabold scl-font-variant-heading-2' id="fundingProgramsCount">0</Span>
            </ScaleCard>
          </CardsCol>

          <CardsCol>
            <ScaleCard className='card card-is-dark'>
              <Translation>
                {(t) => <Span isBlock className='thin scl-font-variant-body'>{t("details:totalFunding")}</Span>}
              </Translation>
              
              <Span isBlock className='extrabold scl-font-variant-heading-2' id="fundingVolumeValue">0 Mrd. €</Span>
            </ScaleCard>
          </CardsCol>
        </CardsInner>
      </Section>
    )
  }
}