import { CheckboxFilter, FilterValue } from './F2CFilter'
import BackendFactory from '../../backend/BackendFactory'
import i18next from 'i18next'
import getEnvConfig from '../../backend/config'
import { getCleanedSegmentsList } from '../../helpers/dataForFormElements'

export const getGeoAreasList = async ():Promise<FilterValue[]> => {
  const backend = BackendFactory.produce();
  const geoData = await backend.getGeoAreaList().then(resp => {
    return resp.data.map(val => {
      return {
        label: val.name,
        val: val.id
      }
    })
  })

  return geoData
}
export const getSegmentsList = async ():Promise<FilterValue[]> => {
  const segments = await getCleanedSegmentsList().then(resp => {
    return resp.map(val => {
      return {
        label: val.name,
        val: val.id,
      }
    })
  })
  return segments
}
export const getTagsList = async ():Promise<FilterValue[]> => {
  const backend = BackendFactory.produce();
  const tagsData = await backend.getTagsList().then(resp => {
    return resp.data.map(val => {
      return {
        label: val.name,
        val: val.id
      }
    })
  })

  return tagsData
}
export const getPortfolioList = async ():Promise<FilterValue[]> => {
  const backend = BackendFactory.produce();
  const portfolioData = await backend.getPortfolioList().then(resp => {
    return resp.data.map(val => {
      return {
        label: val.name,
        val: val.id
      }
    })
  })

  return portfolioData
}

export const DEFAULT_VALUE = {label: i18next.t('f2cFilter:allOption'), val: 0}

export const filterCheckboxBasedOnEnv = ():CheckboxFilter[] => {
  const currentEnv = getEnvConfig()
  if(currentEnv.APP_ENV === 'EU-prod') return radarCheckboxesEU
  else return radarCheckboxes
}

export const radarCheckboxes:CheckboxFilter[] = [
  {
    label: i18next.t("F:RP-F-Switch1"),
    urlParam: 'sw1',
    active:false,
    imgUrl: `/description-icons/sustainability.png`,
    title: i18next.t("F:RP-F-Switch1"),
  },
  {
    label: i18next.t("F:RP-F-Switch2"),
    urlParam: 'sw2',
    active:false,
    imgUrl: `/description-icons/security.png`,
    title: i18next.t("F:RP-F-Switch2"),
  },
  {
    label: i18next.t("F:RP-F-Switch3"),
    urlParam: 'sw3',
    active:false,
    imgUrl: `/description-icons/grw.png`,
    title: i18next.t("F:RP-F-Switch3"),
  },
]

export const radarCheckboxesEU:CheckboxFilter[] = [
  {
    label: i18next.t("F:RP-F-Switch1"),
    urlParam: 'sw1',
    active:false,
    imgUrl: `/description-icons/sw1.svg`,
    title: i18next.t("F:RP-F-Switch1"),
  },
  {
    label: i18next.t("F:RP-F-Switch2"),
    urlParam: 'sw2',
    active:false,
    imgUrl: `/description-icons/sw2.svg`,
    title: i18next.t("F:RP-F-Switch2"),
  },
  {
    label: i18next.t("F:RP-F-Switch3"),
    urlParam: 'sw3',
    active:false,
    imgUrl: `/description-icons/sw3.svg`,
    title: i18next.t("F:RP-F-Switch3"),
  },
  {
    label: i18next.t("F:RP-F-Switch4"),
    urlParam: 'sw4',
    active:false,
    imgUrl: `/description-icons/sw4.svg`,
    title: i18next.t("F:RP-F-Switch4"),
  },
]