export const DatePickerLocale: any = {
    buttonLabel: 'Datum wählen',
    placeholder: 'TT.MM.JJJJ',
    selectedDateMessage: 'Gewähltes Datum',
    prevMonthLabel: 'Vorheriger Monat',
    nextMonthLabel: 'Nächster Monat',
    monthSelectLabel: 'Monat',
    yearSelectLabel: 'Jahr',
    closeLabel: 'Fenster schließen',
    keyboardInstruction:
        'Sie können mit den Pfeiltasten vor und zurück navigieren',
    calendarHeading: 'Datum wählen',
    dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
    ],
    monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
    monthNamesShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
    ],
    today: 'heute',
    locale: 'de-DE',
}

export const localizeDatepicker = (picker: any) => {
    picker.localization = DatePickerLocale

    const DATE_FORMAT = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/
    picker.dateAdapter = {
        parse(value = '', createDate: any) {
            const matches = value.match(DATE_FORMAT);
            if (matches) {
            return createDate(matches[3], matches[2], matches[1])
            }
        },
        format(date: any) {
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
        }
    }
}
