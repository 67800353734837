export const Colors = {
  primary: {
    magenta: '#e20074',
    magentaDark: '#d1006c',
    magentaDarker: '#bd0061',
    black: '#000',
    white: '#fff',
  },
  gray: {
    wildSand: '#f6f6f6',
    gallery: '#ededed',
    alto: '#dcdcdc',
    silverChalice: '#b2b2b2',
    doveGray: '#6b6b6b',
    mineShaft: '#262626',
    gray10: '#e5e5e5',
    gray20: '#cccccc',
    gray03: '#a3a3a3',
    gray50: '#7f7f7f',

  },
  typography: {
    mineShaft: '#262626',
    allports: '#00739F',
    doveGray: '#6b6b6b',
    cerulean: '#009de0'
  },
  function: {
    guardsmanRed: '#d90000',
    supernova: '#fecb00',
    limeade: '#46a800',
    cerulean: '#009de0',
  },
  secondary: {
    sunglow: '#ffd329',
    treePoppy: '#ff9a1e',
    pictonBlue: '#53baf2',
    toryBlue: '#1063ad',
    easternBlue: '#1bada2',
    turmeric: '#bfcb44',
    green: '#73C354',
    teal: '#22ADB9',
    teal80: '#129CA3',
  }
} as const
