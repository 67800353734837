import { ScaleSidebarNav, ScaleSidebarNavItem } from '@telekom/scale-components-react'
import React, { useState } from 'react'
import { GeoArea } from './PageContent/GeoArea'
import { useTranslation } from 'react-i18next'
import { Segments } from './PageContent/Segments'
import { Tags } from './PageContent/Tags'
import { Portfolio } from './PageContent/Portfolio'
import { UserNotification } from './PageContent/UserNotification/UserNotification'

type SettingsTab = {
    name: string
    label:string
    link: '#'
    content: JSX.Element
}

export const SettingsPage = () => {
    const [activeNav, setActiveNav] = useState('contacts')
    const { t } = useTranslation('SettingsPage')

    const settingTabs:SettingsTab[] = [
        {
          name: 'contacts',
          label: t('SettingsPage:BO-Set-Item1-titel'),
          link: '#',
          content: <GeoArea />,
        },
        {
          name: 'settings',
          label: 'Sales segments',
          link: '#',
          content: <Segments />,
        },
        {
          name: 'tags',
          label: t('SettingsPage:BO-Set-Item3-titel'),
          link: '#',
          content: <Tags />,
        },
        {
          name: 'portfolio',
          label: t('SettingsPage:BO-Set-Item4-titel'),
          link: '#',
          content: <Portfolio />,
        },
        {
          name: 'notifications',
          label: t("SettingsPage:notification-accordion-title"),
          link: '#',
          content: <UserNotification />
        },
    ]
    return (
        <div style={{ padding: '0 24px' }}>
            <h1>{t('SettingsPage:mainTitle')}</h1>

            <div style={{ display: 'flex', gap: '50px' }}>
                <ScaleSidebarNav>
                    {settingTabs.map((navItem) => (
                      <ScaleSidebarNavItem
                        key={navItem.name}
                        active={navItem.name === activeNav}
                        onClick={() => setActiveNav(navItem.name)}
                      >
                        <a href={navItem.link}>{navItem.label}</a>
                      </ScaleSidebarNavItem>
                    ))}
                </ScaleSidebarNav>
                {settingTabs
                    .filter((tab) => tab.name === activeNav)
                    .map((tabContent) => (
                        <React.Fragment key={tabContent.name}>
                          {tabContent.content}
                        </React.Fragment>
                    ))}
            </div>
          </div>
    )
}
