const ENVVAR_NODE_ENV = 'NODE_ENV'
const ENVVAR_NODE_ENV_VALUE_DEVELOPMENT = 'development'
const ENVVAR_REACT_APP_BE_SERVER_NAME = 'REACT_APP_BE_SERVER'
const ENVVAR_REACT_APP_LOG_LEVEL_NAME = 'REACT_APP_LOG_LEVEL'

export default class Env {
  private static _ALL = new Map<string, any>()

  static NODE_ENV = Env.getAndRegisterEnvOrThrow( ENVVAR_NODE_ENV )
  static REACT_APP_BE_SERVER = Env.getAndRegisterEnvOrThrow( ENVVAR_REACT_APP_BE_SERVER_NAME )
  static REACT_APP_LOG_LEVEL = Env.getAndRegisterEnvOrThrow( ENVVAR_REACT_APP_LOG_LEVEL_NAME )

  static isDevelopment(): boolean {
    return Env.NODE_ENV === ENVVAR_NODE_ENV_VALUE_DEVELOPMENT
  }

  static getAll(): Map<string, any> {
    return new Map(Env._ALL)
  }

  private static getAndRegisterEnvOrThrow(envVariableName: string): string {
    const value =  process.env[ envVariableName ]
    if (typeof(value) !== 'string') {
      throw new Error(`Env variable "${envVariableName}" not set!`)
    }

    Env._ALL.set(envVariableName, value)

    return value
  }

  private constructor() {}
}