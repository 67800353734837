import { FormField } from '../../model/form-field'

export interface ModalFormConfig {
    lines: {
        index: number
        columns: {
            colIndex:number
            columnElements: FormField[]
        }[]
    }[]
}

export interface ModalFormConfigs {
    relevanceConfig:ModalFormConfig
    editFormConfig:ModalFormConfig
}

export enum ModalActions {
    EDIT = 'edit',
    CREATE = 'create',
    CREATE_SUB = 'createSub',
    ALERT_ARCHIVE = 'alert-archive',
    ALERT_DELETE = 'alert-delete',
    ALERT_PUBLISH = 'alert-publish',
    ALERT_UNPUBLISHED = 'alert-unpublished',
    ALERT = 'alert',
    REMOVE = ''
}