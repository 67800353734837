import styled from 'styled-components/macro'

type Props = {

}

const ToggleLabelsInner = styled('div')<Props>`
  position: relative;
  width: 100%;
  margin-top: ${props => props.theme.spacings.large.margin};

  .switch {
    --size: 26px;
  }

  .switch__wrapper {
    border: none;
    box-shadow: 0 0 0 1px ${props => props.theme.colors.gray.silverChalice};
  }

  .switch__text {

    &::before,
    &::after {
      content: '';
    }
  }

  .switch__label {
    --spacing-left: ${props => props.theme.spacings.smaller.gutter};

    position: relative;
    top: -7px;
    font-size: 18px;
    font-weight: 700;
  }
`

export default ToggleLabelsInner