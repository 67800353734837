import { useRef, useEffect } from 'react'


const createRootElement = (id: string)=> {
    const rootContainer = document.createElement('div')
    rootContainer.setAttribute('id', id)
    return rootContainer
}

const addRootElement = (rootElem: Element) => {
    document.body.insertBefore(
        rootElem,
        document.body.lastElementChild?.nextElementSibling || null,
    )
}

/**
 * Used idea from https://www.jayfreestone.com/writing/react-portals-with-hooks/
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const Portal = (id: string) =>{
    const initialValue = document.createElement('div')
    initialValue.classList.add('dialog-container')
    const rootElemRef = useRef(initialValue)

    useEffect(function setupElement() {
        const existingParent = document.querySelector(`#${id}`)
        const parentElem = existingParent || createRootElement(id)

        if (!existingParent) {
            addRootElement(parentElem);
        }

        parentElem.appendChild(rootElemRef.current)

    }, [id]);

    /**
     * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
     */
    const getRootElem = () => {
        if (!rootElemRef.current) {
            rootElemRef.current = initialValue
        }
        return rootElemRef.current
    }

    return getRootElem()
}

export default Portal