import {ScaleBreadcrumb} from "@telekom/scale-components-react";
import React from "react";
import '../../components/breadcrumbs/breadcrumsElem.scss'

export interface Link {
    href: string
    text: string
    disabled?: boolean
}

interface Props {
    linksArray: Link[],
    current?: Link,
    children?:React.ReactChild
}

export default function BreadcrumbsElem ({linksArray, current, children}: Props ) {
    return(
        <div className="breadcrumbs-container">
            <ScaleBreadcrumb >
                {linksArray.length >= 1 && linksArray.map((link, i)  =>  {
                  const notLink = <span key={`bc-link__${i}`}>{link.text}</span>

                  if(link.disabled) return notLink

                  return current
                      ? <a  href={link.href} key={`bc-link__${i}`}>{link.text}</a>
                      : notLink
                })}
                {current &&  <span>{current.text}</span>}
            </ScaleBreadcrumb>
          {children}
        </div>
    )
}