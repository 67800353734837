import styled from 'styled-components/macro'

const TableInner = styled('div')`
  th,
  td {
    width: calc((100% / 12) * 5);
    vertical-align: top;
  }
`

export default TableInner