import React from 'react'
import SafeHtml from "../../../components/safe-html/SafeHtml";
import './description-box.scss'
import { Flags } from '../../../model/FundMapped'
import { getCheckboxes } from '../../../env/fundEditModal/fundModalConfig'

interface Props {
  title: string
  content: string
  flags?:Flags
}

const DescriptionBox = ({
  title,
  content,
  flags
}: Props) => {
  const checkboxes = getCheckboxes()
  const iconsList = flags ? checkboxes.map(item => {
      return {
        ...item,
        value: flags[item.dataField as keyof Flags]
      }
    }) : []

  return (
    <div className='description-box'>
      <div>
        <h2 className='headline'>
          {title}
        </h2>
        <SafeHtml __html={content} refineOutsideLinks={true}/>
      </div>
        <div className='description-informative-icons'>
          {iconsList.length > 0 && iconsList.filter(flag => flag.value).map(flag => (
            <img key={flag.label} title={flag.label} src={flag.img} alt={flag.label} />
          ))}
        </div>

    </div>
  )
}

export default React.memo(DescriptionBox)