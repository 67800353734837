import styled from 'styled-components/macro'

import * as types from '../../../types'

import { setLinksStyles } from '../../../helpers/links'

import A from '../../A'

type Props = {
  theme: any
  linkType?: types.LinkType
}

const LinkInner = styled('div')<Props>`

  ${props => setLinksStyles(props,
    props.linkType || 'default'
  )}

  display: block;
  margin-top: 12px;
  clear: both;

  ${A} {
    display: flex;
  }


`

export default LinkInner