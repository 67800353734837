import React, { useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { ScaleTable } from '@telekom/scale-components-react'
import './new-funds-table.scss'

import { NewRow } from './new-row'
import { FundTableProps } from '../types'
import { NewHeaders } from './new-headers'
import { Toast } from 'primereact/toast'

export const NewTable = (
    {
        funds,
        lastCrawlDate,
        changeSortParams,
        getFunds,
        editFund,
        openDeleteModal,
        openEditModal
    }: FundTableProps) => {
    const { t } = useTranslation(['table', 'BOFundTranslationKeys'])
    const toast = useRef<Toast>(null)
    const [activeId, setActiveId] = useState<string>()
    const onSortingAction = (params: string) => {
        changeSortParams(params)
    }

    return (
        <>
            <div className='crawl-updating-date'>{t('BO:BO-FP-NFPLR')}: {lastCrawlDate}</div>

            {funds.length >= 0 && (
                <ScaleTable size={'medium'} className='publications-table'>
                    <table className={'new-funds-table'}>
                        <thead>
                        <NewHeaders onSortingAction={onSortingAction}/>
                        </thead>
                        <tbody>
                        {funds.map((fund) => (
                            <NewRow
                                fund={fund}
                                key={fund.id}
                                setActiveRow={id => setActiveId(id)}
                                changeInitialComment={resp => {
                                    if (resp.detail === 'SUCCESS') {
                                        toast.current?.show({
                                            severity: 'success',
                                            summary: 'Success',
                                            detail: t('table:commentChanged'),
                                            life: 5000,
                                        })
                                        getFunds('new')
                                    } else {
                                        toast.current?.show({
                                            severity: 'error',
                                            summary: 'Error',
                                            detail: resp.detail || t('table:commentError'),
                                            life: 5000,
                                        })
                                    }
                                }
                                }
                                active={activeId === fund.id}
                                openRelevanceDialog={(activeFund) => openEditModal(activeFund.id)}
                                openAlertDeleteDialog={() => openDeleteModal(fund.id)}
                                editFund={editFund}
                            />
                        ))}
                        </tbody>
                    </table>
                </ScaleTable>
            )}
            <Toast ref={toast} position='bottom-right' />
        </>
    )
}
