import React, { useEffect, useState } from 'react'
import { BoEvents } from '../../backend/respType'
import styles from './SystemNofification.module.scss'
import moment from 'moment'
import { Sidebar } from 'primereact/sidebar'
import Env from '../../utils/Env'
import { useTranslation } from 'react-i18next'

type SystemNotificationType = {
    event: BoEvents
    show_modal: boolean
    type: 'long' | 'short'
}

export const SystemNotification = () => {
    const [message, setMessage] = useState<SystemNotificationType>()
    const [visibleTop, setVisibleTop] = useState(false)
    const {t} = useTranslation('SettingsPage')

    const uri = Env.REACT_APP_BE_SERVER


    useEffect(() => {
        //todo URL and wss://
        let host = window.location.host
        let prefix = 'wss'
        if(host.includes('3000')) {
            host = host.replace(':3000', `:${uri}`)
            prefix = 'ws'
        }
        console.log('host: ', host)
        const webSocket = new WebSocket(`${prefix}://${host}/ws`)

        webSocket.onmessage = (event) => {
            const data = JSON.parse(event.data)
            if(data.show_modal) {
                setVisibleTop(true)
            }
            setMessage(data)
        }

        return () => {
            webSocket.close()
        }
    }, [])


    return (
        <>
            {message && message.event.active &&
              <div className={`${styles.wrapper} ${message.show_modal ? styles.important : ''}`}>
                <span>{message.event.title}
                  <b> ({moment(message.event.event_datetime).format('DD.MM hh:mm A')})</b>
                </span>
              </div>
            }
            {message && message.event.active && message.show_modal &&
              <Sidebar visible={visibleTop} position="top" onHide={() => setVisibleTop(false)}>
                <span className={styles.title}>
                    Less then {message.event.min_before_urgent} minutes left before update</span>
                <span>
                    {message.event.body}
                </span>
              </Sidebar>
            }
        </>
    )

}