import { ModalFormConfig } from '../../components/F2CFormGenerator/types'
import { t } from 'i18next'

export const settingsFormConfig:ModalFormConfig = {
    lines: [
        {
            index:1,
            columns: [
                {
                    colIndex:1,
                    columnElements: [
                        {
                            value: '',
                            label: t('SettingsPage:BO-Set-Item4-label-titel'),
                            dataField: 'name',
                            info: false,
                            inputType: 'text',
                            mandatory: true,
                            maxLength: 29
                        },
                    ],
                },
            ],
        }
    ]
}