import { date, object, string, number } from 'yup'
import { t } from 'i18next'
import { BoEvents } from '../../../../backend/respType'

export const notificationFormValues:BoEvents = {
    title: '',
    body: '',
    event_datetime: null,
    active: false,
    min_before_urgent:5,
    min_to_deactivate:5
}

const req = t('BOFundTranslationKeys:validationNotFilled') || ''
export const notificationFormlSchema = object().shape({
    title: string().required(req),
    body: string().required(req),
    event_datetime: date().required(req),
    min_before_urgent:  number().required(),
    min_to_deactivate:  number().required()
})