import React, { createRef } from 'react'
import { Translation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Card from '../Card'
import Link from '../Link'
import List from '../List'
import ListItem from '../List/ListItem'
import RadarButton from './RadarButton';

import * as d3 from 'd3'
import ListItemTitle from './__styled__/ListItemTitle'
import TooltipInner from './__styled__/TooltipInner'
import TooltipTitle from './__styled__/TooltipTitle'
import TooltipClose from './__styled__/TooltipClose'
import BlackMask from './__styled__/BlackMask'
import { Point } from './GeometryModule'
import { RadarView } from './RadarData'
import { Hyperlink } from '../../model/DetailHyperlink'
import { BREAKPOINTS } from '../../theme/styles/Breakpoints'
import SafeHtml from '../safe-html/SafeHtml';

import Stream from '../../model/Stream'
import Backend from '../../backend/Backend'
import BackendFactory from '../../backend/BackendFactory'

export interface KeyValuePair {
  key: string
  value: string
}
export interface TooltipInfo {
  title: string
  news?:string
  detailLink: Hyperlink
  entries: KeyValuePair[]
  stream?: Stream['name']
  position: {
    index: number,
    total: number,
  }
  // Add index + total? OR extra Component for slider?
}

export interface TooltipConfig {
  info: TooltipInfo
  view?: RadarView
  getPivotCoordinates: () => Point
  isClosed?: boolean
  currentTarget?: any
}

interface Props {
  config?: TooltipConfig
  view?: RadarView
  onChangeTooltip: (index: number) => void
}

interface State {
  closed: boolean
}


const TOOLTIP_MAX_WIDTH = 550
export default class RadarTooltip extends React.Component<Props, State> {
  ref: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)

    this.state = {
      closed: true
    }

    this.ref = createRef<HTMLDivElement>()

    this.updatePosition = this.updatePosition.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatePosition)
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePosition)
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    this.updatePosition()
    if (prevProps.config?.isClosed !== this.props.config?.isClosed) {
      if(prevProps.config?.info !== this.props.config?.info) {
        if(this.state.closed) {
          this.onOpen()
        }
      }
      else {
        this.updateTooltipVisibility()
      }
    }
  }

  private onClose() {
    const tooltip = this.ref.current

    if (!tooltip) {
      return null
    }

    tooltip.style.setProperty('opacity', '0')
    tooltip.style.setProperty('visibility', 'hidden')

    this.setState({ closed: true })
    document.documentElement.classList.remove('no-scroll')
  }

  private onOpen() {
    d3.select('#tooltip')
        .style('visibility', 'visible')
        .transition().duration(150).ease(d3.easeLinear).style("opacity", 1)

    this.setState({ closed: false })

    document.documentElement.classList.add('no-scroll')
  }


  /**
   * Alert if clicked on outside of element
  */
  handleClickOutside(event: any) {
    const modal = document.getElementsByClassName('card__inner')[0]
    if (!modal.contains(event.target)) {
      this.onClose()
    }
  }

  private handleScroll() {
    if (window.innerWidth >= BREAKPOINTS.laptop) {
      this.onClose()
    }
  }

  private updateTooltipVisibility(): boolean | null {
    if (this.state.closed) {
      this.onOpen()
      return false
    }
    else {
      this.onClose()
      return true
    }
  }

  private updatePosition() {
    if (this.ref.current && this.props.config) {
      const coords = this.computePositionForTooltip(this.props.config.getPivotCoordinates())

      this.ref.current.style.left = `${coords.x}px`
      this.ref.current.style.top = `${coords.y}px`
    }
  }

  private computePositionForTooltip(pivotCoords: Point): Point {
    if (this.ref.current === undefined) {
      /*
      tipX = (window.innerWidth - tooltip_rect.width) / 2.0
      tipY = (window.innerHeight - tooltip_rect.height) / 2.0
      */

      return {
        x: 0,
        y: 0,
      }

    } else {
      // Get calculated tooltip coordinates and size
      let { x, y } = pivotCoords
      const tooltipRect: DOMRect = this.ref.current?.getBoundingClientRect() || new DOMRect(x, y, 100, 100);

      // Corrections if out of window
      if (x + tooltipRect.width > window.innerWidth) {
        x = x - TOOLTIP_MAX_WIDTH;  // Simulate a "right: tipX" position
      }

      const windowHeight = document.documentElement.clientHeight

      const yBottom = y + tooltipRect.height

      if (yBottom > windowHeight) {
        y = windowHeight - tooltipRect.height;    // Align on the top
      }           // Out on the top
      // Apply corrected position
      if (x < 0) {
        x = 0
      }

      if (y < 0) {
        y = 0
      }

      return {
        x,
        y,
      }
    }
  }

  private addClickCount() {
    const backend: Backend = BackendFactory.produce()
    backend.addViewToElement('Radar: "more info"')
  }

  renderInner(info: TooltipInfo) {
    const {index, total} = info.position

    const width = window.innerWidth
    const isMobile = width <= BREAKPOINTS.tabletMax
    const backend: Backend = BackendFactory.produce()

    let streamName
    if (info.stream?.includes('Sonstige')){
      streamName = info.stream?.replace('Sonstige ','')
    } else {
      streamName = info.stream
    }
    const lines: any[] = info.entries.map(({ key, value }) => {
      let text: string
      let hidden: boolean
      if (value.length > 150) {
        text = value.slice(0, 149) + '...'
        hidden = true
      } else {
        text = value
        hidden = false
      }

      return (
        <ListItem key={key}>
          <ListItemTitle>
            {key}
          </ListItemTitle>

          <p className="radar-paragraph">
            <span className={hidden ? "text-container text-container--hidden" : "text-container"}>
              <SafeHtml __html={text} refineOutsideLinks={true}/>
            </span>
          </p>
        </ListItem>
      )
    })
    return (
      <div className="radar-tooltip">
        <h3>
          {streamName}
        </h3>

        <Translation>
          {
            (t) => <NavLink 
                      to={info.detailLink.href} 
                      title={String(t("home:moreInfo"))} 
                      className="tooltip-title-link" 
                      onClick={()=> backend.addViewToElement('Radar: title')}
                    >
                    <TooltipTitle>
                      {info.title}
                    </TooltipTitle>
                  </NavLink>
          }
        </Translation>
        

        <TooltipClose id="tooltipClose" onClick={() => { this.onClose() }} style={{ backgroundColor: '#fff' }} tabIndex={0}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 27.94" >
            <path fill='currentColor' d="M30,28.42a2.06,2.06,0,0,0-.66-1.49L19,16.57,29.34,6.2A2,2,0,0,0,30,4.71a2.45,2.45,0,0,0-.78-1.66,2,2,0,0,0-1.37-.42,2,2,0,0,0-1.49.6L16,13.59,5.63,3.23a2,2,0,0,0-1.49-.6,2.09,2.09,0,0,0-1.51.6A1.91,1.91,0,0,0,2,4.68,2.06,2.06,0,0,0,2.66,6.2L13,16.57,2.66,26.93A2.06,2.06,0,0,0,2,28.42a2.46,2.46,0,0,0,.78,1.67,2,2,0,0,0,1.36.42,2,2,0,0,0,1.49-.6L16,19.55,26.36,29.91a2.37,2.37,0,0,0,1.49.66,1.72,1.72,0,0,0,.78-.18A2,2,0,0,0,30,28.42Z" transform="translate(-2 -2.63)" />
          </svg>
        </TooltipClose>

        <List isClear>
          {lines}
        </List>

        <div className='radar-tooltip__footer'>
        <Translation>
          {
            (t) => <div className='alight-right radar-tooltip__details-link'>
              <Link onClick={this.addClickCount} href={info.detailLink.href} linkTitle={t("home:moreInfo")} />
            </div>
          }
        </Translation>
          

          {isMobile && (
            <div className='radar-tooltip__pagination'>
              <RadarButton type={'previous'} deactivated={ index === 0 }
                onClick={() => {
                  this.props.onChangeTooltip(index - 1)
                }}
              />
              <p className='centered'>{index + 1}/{total}</p>
              <RadarButton type={'next'} deactivated={ index + 1 === total }
                onClick={() => {
                  this.props.onChangeTooltip(index + 1)
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const inner = this.props.config ? this.renderInner(this.props.config.info) : null

    return (
      <>
        <TooltipInner ref={this.ref} id='tooltip'>
          <BlackMask />
          <Card>
            {inner}
          </Card>
        </TooltipInner>
      </>
    )
  }
}