import React from 'react'
import styled from 'styled-components/macro'

const PrimaryStream = styled('p')`
  position: relative;
  margin: ${props => props.theme.spacings.small.gutter} 0 ${props => props.theme.spacings.smaller.margin};
  font-size: ${props => props.theme.typography.detailPage.primaryStream.fontSize};
  font-weight: ${props => props.theme.typography.detailPage.primaryStream.fontWeight};

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin-top: 60px;
  }
`

export default React.memo(PrimaryStream)