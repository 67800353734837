import Backend from "./Backend"
import DefaultBackend from "./DefaultBackend"
import MockBackend from "./mock/MockBackend"
import Env from "../utils/Env"


const MOCK_URI = 'mock'


export default class BackendFactory {
  static produce(): Backend {
    const uri = Env.REACT_APP_BE_SERVER

    // if (uri === MOCK_URI) {
    //   return new MockBackend()
    // } else {
      return new DefaultBackend(uri)
    // }
  }
}
