import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadcrumbsElem from '../../../components/breadcrumbs/breadcrumbsElem'
import '../backoffice.scss'

import {OfficesTable} from "./offices-table";

export const OfficesPage = () => {

    const { t } = useTranslation(['RP','menu'])

    return (
        <>
            <BreadcrumbsElem linksArray={[{href: '/', text: t("RP:RP-M1")}, {href: '/', text:t("RP:RP-M3"), disabled:true}]}
                             current={{href: 'backoffice/offices', text: t("menu:linkResponsePoints")}}/>
            <div className="offices-inner">
                 <OfficesTable />
            </div>
        </>
    )
}