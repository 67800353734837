import styled from 'styled-components/macro'

type Props = {
}

const ContactCompany = styled('span')<Props>`
  display: block;
  font-weight: 800;
`

export default ContactCompany