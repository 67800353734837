import { string, object } from 'yup'
import { t } from 'i18next'


const req = t('BOFundTranslationKeys:validationNotFilled') || ''
const emailText = t('BOFundTranslationKeys:validationWrongEmail') || ''

export const contactPointModalSchema = object().shape({
    name: string().required(req),
    phone: string().required(req),
    mail: string().required(req).email(emailText),
    url: string().notRequired().url(t('BOFundTranslationKeys:validationWrongUrl') || ''),
})